import { bool, func, object, string } from 'prop-types'
import React from 'react'

import ACol from '../../../external/components/layout/ACol'
import ARow from '../../../external/components/layout/ARow'
import Cont from '../../../external/components/layout/Cont'
import { isEmpty, isUndefined } from '../../../external/utilities/GeneralUtils'
import { getAdObj } from '../../shared/ad/Advert'
import Article from './Article'
import ArticleAdvert from './ArticleAdvert'

const MAX_ARTICLES_DISPLAY = 8
const MAX_ARTICLES_DISPLAY_PER_ROW = 4

const ArticleCategory = ({
  theme,
  intl,
  getArticleSelectLinkObj,
  selectedCategory,
  articles,
  adConfig,
  showAds = true,
  onArticleSelectRequest,
}) => {
  const getArticlesForCategory = function () {
    const categoryArticles = []
    for (const article of articles.homeArticles) {
      if (article.category === selectedCategory.name) {
        categoryArticles.push(article)
        if (categoryArticles.length === MAX_ARTICLES_DISPLAY) {
          break
        }
      }
    }

    return categoryArticles
  }

  const getArticleOrAdvert = function (article) {
    if (article !== null && !isUndefined(article.isAd) && article.isAd) {
      return <ArticleAdvert id={article.adId} adConfig={adConfig} />
    }

    return (
      <Article
        theme={theme}
        intl={intl}
        getArticleSelectLinkObj={getArticleSelectLinkObj}
        article={article}
        onArticleSelectRequest={onArticleSelectRequest}
      />
    )
  }

  const isAdActive = function (id) {
    const adObj = getAdObj(id, adConfig)

    if (!isEmpty(adObj) && adObj.active) {
      return true
    }

    return false
  }

  const getRow = function (categoryArticles, ctr) {
    const rowArticles = []
    let articleCtr = ctr - MAX_ARTICLES_DISPLAY_PER_ROW
    for (let i = ctr - MAX_ARTICLES_DISPLAY_PER_ROW; i < ctr; i++) {
      if (categoryArticles[articleCtr] === undefined) {
        rowArticles.push(null)
      } else if (i === 1 && isAdActive('HP3') && showAds) {
        rowArticles.push({
          isAd: true,
          adId: 'HP3',
        })
      } else if (i === 6 && isAdActive('HP4') && showAds) {
        rowArticles.push({
          isAd: true,
          adId: 'HP4',
        })
      } else {
        rowArticles.push(categoryArticles[articleCtr])
        articleCtr += 1
      }
    }
    const ui = rowArticles.map((article, i) => (
      <ACol key={i} md={6} xl={3}>
        {getArticleOrAdvert(article)}
      </ACol>
    ))

    return <ARow key={ctr}>{ui}</ARow>
  }

  const getArticlesUI = function () {
    const categoryArticles = getArticlesForCategory()
    const ui = categoryArticles.map((article, i) => {
      const ctr = parseInt(i) + 1
      const mod = ctr % MAX_ARTICLES_DISPLAY_PER_ROW
      let rowUI = null
      if (mod === 0 && ctr !== 1) {
        rowUI = getRow(categoryArticles, ctr)
      } else if (ctr === categoryArticles.length) {
        let aCtr = ctr + (MAX_ARTICLES_DISPLAY_PER_ROW - mod)
        if (categoryArticles.length <= MAX_ARTICLES_DISPLAY_PER_ROW) {
          aCtr = MAX_ARTICLES_DISPLAY_PER_ROW
        }
        rowUI = getRow(categoryArticles, aCtr)
      }

      return rowUI
    })

    return ui
  }

  return <Cont>{getArticlesUI()}</Cont>
}

ArticleCategory.propTypes = {
  theme: string.isRequired,
  intl: object.isRequired,
  getArticleSelectLinkObj: func.isRequired,
  selectedCategory: object,
  articles: object.isRequired,
  adConfig: object.isRequired,
  showAds: bool.isRequired,
  onArticleSelectRequest: func.isRequired,
}

export default ArticleCategory
