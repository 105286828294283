import { simpleTranslate } from '../components/Translation'
import { PAGE_FACILITY_OVERVIEW } from '../components/facility/consts'
import {
  isEmpty,
  isUndefined,
  isValue,
  toTitleCase,
  uriEncode,
} from '../external/utilities/GeneralUtils'
import {
  getContractCodesForAllContractCarts,
  getFacilityIdsForAllOrders,
} from './CartUtils'

export const allLinesOfBusiness = []
export const allFacilityTypes = []

allLinesOfBusiness.CAMPING = { name: 'CAMPING' }
allLinesOfBusiness.TICKETING = { name: 'TICKETING' }
allLinesOfBusiness.PERMITS = { name: 'PERMITS' }
allLinesOfBusiness.RECAREA = { name: 'RECAREA' }
allLinesOfBusiness.RECFACILITY = { name: 'RECFACILITY' }
allLinesOfBusiness.MARINAS = { name: 'MARINAS' }
allLinesOfBusiness.DAYUSE = { name: 'DAYUSE' }
allLinesOfBusiness.HOURLYUSE = { name: 'HOURLYUSE' }
allLinesOfBusiness.DAYENTRANCE = { name: 'DAYENTRANCE' }

allFacilityTypes.CAMPGROUND = { name: 'Campground' }
allFacilityTypes.LODGING_BUSINESS = { name: 'LodgingBusiness' }
allFacilityTypes.PLACE = { name: 'Place' }

const lobTranslationPrepender = 'lob_'
const isLinesOfBusiness = function (linesOfBusiness) {
  let lob = false
  if (!isUndefined(linesOfBusiness) && !isEmpty(linesOfBusiness)) {
    lob = true
  }

  return lob
}
const hasCamping = function (linesOfBusiness) {
  let containsCamping = false
  if (isLinesOfBusiness(linesOfBusiness)) {
    for (const business of linesOfBusiness) {
      if (business === allLinesOfBusiness.CAMPING.name) {
        containsCamping = true
      }
    }
  }

  return containsCamping
}
const isFaciliy = function (facility) {
  if (!isUndefined(facility) && !isEmpty(facility)) {
    return true
  }

  return false
}
const isMainConfig = function (mainConfig) {
  if (!isUndefined(mainConfig) && !isEmpty(mainConfig)) {
    return true
  }

  return false
}
const prependLOBValues = function (intl, linesOfBusiness) {
  let lob = null
  let lobString = ''
  const separator = simpleTranslate(intl, 'generic_separator')
  const lobList = linesOfBusiness.map((item) => lobTranslationPrepender + item)
  const translatedLOBList = lobList.map((list) => simpleTranslate(intl, list))

  if (isLinesOfBusiness(linesOfBusiness) && linesOfBusiness.length > 1) {
    lob = translatedLOBList.slice(0, -1)
    if (lob.length > 1) {
      lobString = lob.join(separator)
    } else {
      lobString = lob
    }
  }

  return lobString
}
const appendLOBValues = function (intl, linesOfBusiness) {
  let lobString = ''
  let lob = null
  let translatedLOB = null
  if (isLinesOfBusiness(linesOfBusiness) && linesOfBusiness.length > 1) {
    lob = linesOfBusiness.slice(-1)
    translatedLOB = lobTranslationPrepender + lob
    lobString = simpleTranslate(intl, translatedLOB)
  }

  return lobString
}
const getStateCodeFromFacility = function (facility) {
  let facilityStateCode = null
  if (
    !isUndefined(facility) &&
    !isEmpty(facility) &&
    !isEmpty(facility.stateCodes)
  ) {
    ;[facilityStateCode] = facility.stateCodes
  }

  return facilityStateCode
}
const addFacilityNameToObj = function (facility, literalObj) {
  if (!isUndefined(facility) && !isEmpty(facility)) {
    literalObj.facilityName = toTitleCase(facility.name)
  }
}
const addFacilityFullStateNameToObj = function (
  brandConfig,
  facility,
  literalObj,
) {
  const facilityStateCode = getStateCodeFromFacility(facility)
  literalObj.fullStateName = facilityStateCode
  if (
    !isUndefined(brandConfig) &&
    !isEmpty(brandConfig) &&
    !isEmpty(brandConfig.states)
  ) {
    for (const state of brandConfig.states) {
      if (state.stateCode === facilityStateCode) {
        literalObj.fullStateName = state.stateName
        break
      }
    }
  }
}
const addFacilityAddressToObj = function (facility, literalObj) {
  if (
    isFaciliy(facility) &&
    !isUndefined(facility.contactAddress) &&
    !isEmpty(facility.contactAddress) &&
    !isUndefined(facility.contactAddress.address)
  ) {
    literalObj.facilityAddressLocality = facility.contactAddress.address.city
    literalObj.facilityAddressRegion =
      facility.contactAddress.address.stateProvince
    literalObj.facilityPostalCode = facility.contactAddress.address.zipCode
    literalObj.facilityAddressCountry = facility.contactAddress.address.country
    literalObj.facilityStreetAddress = facility.contactAddress.address.addrLine1
  }
}
const addFacilityPhoneToObj = function (facility, literalObj) {
  if (
    isFaciliy(facility) &&
    !isUndefined(facility.contactPhones) &&
    !isEmpty(facility.contactPhones)
  ) {
    literalObj.facilityTelephone = ''
    for (const phoneObj of facility.contactPhones) {
      if (phoneObj.phone.type === 'PUBLIC_PHONE') {
        literalObj.facilityTelephone = phoneObj.phone.number
        break
      }
    }
  }
}
const addFacilityGeoCoordinatesToObj = function (facility, literalObj) {
  literalObj.facilityLatitude = ''
  literalObj.facilityLongitude = ''
  if (
    isFaciliy(facility) &&
    !isUndefined(facility.coordinates) &&
    !isEmpty(facility.coordinates)
  ) {
    literalObj.facilityLatitude = facility.coordinates.latitude
    literalObj.facilityLongitude = facility.coordinates.longitude
  }
}
const addFacilityRatingsToObj = function (facility, literalObj) {
  literalObj.ratingValue = ''
  literalObj.reviewCount = ''
  literalObj.bestRating = ''
  literalObj.worstRating = ''
  if (
    isFaciliy(facility) &&
    !isUndefined(facility.rating) &&
    !isEmpty(facility.rating) &&
    !isUndefined(facility.rating.totalCount) &&
    facility.rating.totalCount > 0
  ) {
    literalObj.ratingValue = facility.rating.totalValue
    literalObj.reviewCount = facility.rating.totalCount
    literalObj.bestRating = facility.rating.summary.length
    literalObj.worstRating = 1
  }
}

export const getLiteralValuesForApp = function (mainConfig) {
  const literalObj = {}
  if (!isUndefined(mainConfig) && !isEmpty(mainConfig)) {
    literalObj.fullBrandName = mainConfig.app.fullBrandName
  }

  return literalObj
}
export const getLiteralValuesForFacility = function (
  intl,
  facility,
  mainConfig,
  brandConfig,
) {
  const literalObj = getLiteralValuesForApp(mainConfig)
  const linesOfBusiness = facility.lineOfBusinesses
  addFacilityNameToObj(facility, literalObj)
  addFacilityFullStateNameToObj(brandConfig, facility, literalObj)
  if (isLinesOfBusiness(linesOfBusiness)) {
    literalObj.prependFacilityLOB = prependLOBValues(intl, linesOfBusiness)
    literalObj.appendFacilityLOB = appendLOBValues(intl, linesOfBusiness)
    literalObj.facilitySingleLOB = simpleTranslate(
      intl,
      lobTranslationPrepender + linesOfBusiness[0],
    )
  }

  // New Structured Data
  if (
    isFaciliy(facility) &&
    !isEmpty(facility.originalPhotos) &&
    isMainConfig(mainConfig)
  ) {
    literalObj.facilityImage = uriEncode(
      mainConfig.app.baseURL + facility?.originalPhotos?.[0]?.photoURL,
    )
  } else {
    literalObj.facilityImage = ''
  }

  if (isLinesOfBusiness(linesOfBusiness)) {
    literalObj.facilityType = allFacilityTypes.PLACE.name
    if (!isEmpty(literalObj.facilityImage)) {
      if (hasCamping(linesOfBusiness)) {
        literalObj.facilityType += ` ,${allFacilityTypes.CAMPGROUND.name}`
      }
    }
  } else {
    literalObj.facilityType = allFacilityTypes.PLACE.name
  }

  addFacilityAddressToObj(facility, literalObj)
  addFacilityPhoneToObj(facility, literalObj)
  addFacilityGeoCoordinatesToObj(facility, literalObj)
  addFacilityRatingsToObj(facility, literalObj)

  return literalObj
}
export const getLiteralValuesForProduct = function (
  product,
  facility,
  mainConfig,
  brandConfig,
) {
  const literalObj = getLiteralValuesForApp(mainConfig)
  addFacilityNameToObj(facility, literalObj)
  addFacilityFullStateNameToObj(brandConfig, facility, literalObj)
  if (!isUndefined(product) && !isEmpty(product) && !isEmpty(product.records)) {
    literalObj.siteName = toTitleCase(product.records[0].details.siteCode)
    literalObj.siteLoopName = toTitleCase(product.records[0].details.loopName)
    literalObj.prodGrpName = product.records[0].prodGrpName
  }

  return literalObj
}
export const getLiteralValuesForInfo = function (mainConfig, infoData) {
  const literalObj = getLiteralValuesForApp(mainConfig)
  if (
    !isUndefined(infoData) &&
    !isEmpty(infoData) &&
    !isEmpty(infoData.infoContext)
  ) {
    literalObj.infoContext = infoData.infoContext
  }

  return literalObj
}
export const getKeyQualifiersForInfo = function (infoData) {
  let keyQualifier = []
  if (!isUndefined(infoData) && !isEmpty(infoData)) {
    keyQualifier = [infoData.infoKey]
  }

  return keyQualifier
}
export const createKeyQualifiersForLinesOfBusiness = function (
  linesOfBusiness,
  pageId,
) {
  let keyQualifier = []
  if (pageId === PAGE_FACILITY_OVERVIEW) {
    if (isLinesOfBusiness(linesOfBusiness)) {
      if (linesOfBusiness.length === 1 && hasCamping(linesOfBusiness)) {
        keyQualifier = ['campingOnly']
      } else if (linesOfBusiness.length > 1 && hasCamping(linesOfBusiness)) {
        keyQualifier = ['campingAndOthers']
      } else if (linesOfBusiness.length === 1 && !hasCamping(linesOfBusiness)) {
        keyQualifier = ['otherLOBSingle']
      } else {
        keyQualifier = ['otherLOBMultiple']
      }
    } else {
      keyQualifier = ['campingOnly']
    }
  }

  return keyQualifier
}
export const getPageMetaData = function (
  pageId = null,
  brandConfig = null,
  facility = null,
  cart = null,
) {
  const pageMetaData = {}
  pageMetaData.pageId = pageId
  pageMetaData.env =
    !isUndefined(brandConfig) && !isEmpty(brandConfig)
      ? brandConfig.appEnvType
      : null

  pageMetaData.contractCode = isFaciliy(facility) ? facility.contrCode : null
  pageMetaData.facilityId = isFaciliy(facility) ? facility.id : null

  if (!isUndefined(cart) && !isEmpty(cart)) {
    const contractCodes = getContractCodesForAllContractCarts(cart)
    const facilityIds = getFacilityIdsForAllOrders(cart)
    if (!isUndefined(contractCodes) && contractCodes.length === 1) {
      pageMetaData.contractCode = contractCodes[0]
    }

    if (!isUndefined(facilityIds) && facilityIds.length === 1) {
      pageMetaData.facilityId = facilityIds[0]
    }
  }

  return pageMetaData
}
export const getPageMetaForAdvert = function (pageMetaData) {
  const pageMetaObj = {}
  if (!isUndefined(pageMetaData) && !isEmpty(pageMetaData)) {
    if (!isUndefined(pageMetaData.pageId) && !isEmpty(pageMetaData.pageId)) {
      pageMetaObj.pagetype = pageMetaData.pageId
    }

    if (
      !isUndefined(pageMetaData.contractCode) &&
      !isEmpty(pageMetaData.contractCode)
    ) {
      pageMetaObj.keywords = pageMetaData.contractCode
    }

    if (isValue(pageMetaData.facilityId)) {
      pageMetaObj.PostID = pageMetaData.facilityId.toString()
    }

    if (!isUndefined(pageMetaData.env) && !isEmpty(pageMetaData.env)) {
      pageMetaObj.env = pageMetaData.env
    }
  }

  return pageMetaObj
}
