import * as types from '../actions/ActionTypes'

export const initialState = {
  iframeRef: null,
}

function setCardVaultIframeRef(state = initialState, action) {
  return {
    ...state,
    iframeRef: action.data,
  }
}

export default function cardvault(state = initialState, action) {
  switch (action.type) {
    case types.SET_CARDVAULT_IFRAME_REF:
      return setCardVaultIframeRef(state, action)
    default:
      return state
  }
}
