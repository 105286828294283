import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { useClickOutside } from '../../../../components/shared/hooks/useClickOutside'
import APanel from '../../container/APanel'
import ABaseLink from '../../link/ABaseLink'
import AImg from '../../media/img/AImg'

/** Used for menu items that have an icon and/or label and/or submenu.

@example  ../../../../../docs/external/navigation/menu/AMenuItem.md 
*/

const AMenuItem = ({
  defaultOpen,
  onClick,
  closeOnClickOutside,
  linkURL = null,
  imgURL,
  imgHoverURL,
  imgAlt,
  imgTitle,
  onClickAlt,
  iconBubbleNumber = 0,
  label,
  description,
  displayType,
  children,
  cmpId = null,
}) => {
  const [isOpen, setOpen] = useState(defaultOpen)
  const [isHovered, setHovered] = useState(false)

  const onToggleOpen = () => {
    setOpen((isOpen) => !isOpen)

    onClick?.()
  }

  const onClose = () => {
    setOpen(false)
  }

  const onClickOutside = () => {
    if (closeOnClickOutside) {
      onClose()
    }
  }

  const onMouseEnter = () => {
    setHovered(true)
  }

  const onMouseLeave = () => {
    setHovered(false)
  }

  const { containerRef } = useClickOutside({ onClickOutside })

  const isHorizontal = displayType === 'horizontal'

  return (
    <div
      className={`menu-item__container menu-item__container--${displayType}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={onMouseEnter}
      onBlur={onMouseLeave}
      ref={containerRef}
    >
      <ABaseLink
        to={linkURL}
        onClick={onToggleOpen}
        cmpLabel={onClickAlt}
        cmpControlsId={cmpId}
        cmpExpanded={!!children && isOpen}
      >
        <div className={`menu-item__icon-container--${displayType}`}>
          <div className={`menu-item__icon menu-item__icon--${displayType}`}>
            <AImg
              className={isHovered ? 'hidden' : ''}
              imageURL={imgURL}
              cmpLabel={imgAlt}
              cmpTitle={imgTitle}
              responsive={false}
            />
            <AImg
              className={isHovered ? '' : 'hidden'}
              imageURL={imgHoverURL}
              cmpLabel={imgAlt}
              cmpTitle={imgTitle}
              responsive={false}
            />
            {!!iconBubbleNumber && (
              <div className="menu-item__icon-badge">{iconBubbleNumber}</div>
            )}
          </div>
        </div>
        <div className={`menu-item__text-container--${displayType}`}>
          <div className="menu-item__text">
            {isHorizontal ? description : label}
          </div>
          {!!children && (
            <div className="menu-item__chevron">
              <AImg
                imageURL={`/newra/assets/images/ra/chevron-${
                  isOpen ? 'up' : 'down'
                }${isHovered ? '-orange' : ''}.svg`}
                responsive={false}
                cmpLabel=""
              />
            </div>
          )}
        </div>
      </ABaseLink>
      <div
        className={`sub-menu-item__container sub-menu-item__container--${
          isOpen ? 'open' : 'closed'
        }`}
      >
        <div className={'sub-menu-item__options-container'} onClick={onClose}>
          {!isHorizontal && children}
          {isHorizontal && !!children && (
            <APanel collapsible expanded={isOpen} id={cmpId}>
              {children}
            </APanel>
          )}
        </div>
      </div>
    </div>
  )
}

AMenuItem.propTypes = {
  /** link url - this will be used in non javascript environments. This will only kick in if no onClick or non javascript environemnt. */
  linkURL: PropTypes.string,
  /** url of image if menu item has an image */
  imgURL: PropTypes.string,
  /** image to display on hover (e.g switch from white icon to orange icon for accessibility) */
  imgHoverURL: PropTypes.string,
  /** alt text for image */
  imgAlt: PropTypes.string,
  /** title for image */
  imgTitle: PropTypes.string,
  /** function to run when menu item is clicked */
  onClick: PropTypes.func.isRequired,
  /** alt text for what will happen on click */
  onClickAlt: PropTypes.string,
  /** number to display in a bubble in top right corner of menu item */
  iconBubbleNumber: PropTypes.number,
  /** menu item label - string or an element */
  label: PropTypes.node,
  /** vertical means icon on top of label, horizontal means icon to the left of description */
  displayType: PropTypes.oneOf(['vertical', 'horizontal']),
  /** long menu item label - displays when displayType is 'horizontal'.  Used for mobile screens/burger menus with more room */
  description: PropTypes.node,
  /** if menu item has a submenu, whether or not to display it as open on load */
  defaultOpen: PropTypes.bool,
  /** whether or not to close the submenu if you click outside of it */
  closeOnClickOutside: PropTypes.bool,
  /** sub menu items */
  children: PropTypes.node,
  /** id for aria-controls */
  cmpId: PropTypes.string,
}

export { AMenuItem }
