import { array, bool, func, object, string } from 'prop-types'
import React from 'react'

import BoxContainer from '../../external/components/container/BoxContainer'
import DynamicGrid from '../../external/components/container/DynamicGrid'
import SimpleContainer from '../../external/components/container/SimpleContainer'
import FlipCard from '../../external/components/container/flipCard/FlipCard'
import ACol from '../../external/components/layout/ACol'
import AGrid from '../../external/components/layout/AGrid'
import ARow from '../../external/components/layout/ARow'
import Cont, {
  ALIGN_CENTER,
  ALIGN_RIGHT,
  PAD_OVERRIDE_10,
} from '../../external/components/layout/Cont'
import Section from '../../external/components/layout/Section'
import AIconAction from '../../external/components/link/AIconAction'
import TextHoverable from '../../external/components/link/TextHoverable'
import { isEmpty, isUndefined } from '../../external/utilities/GeneralUtils'
import { getAdConfig } from '../../utilities/ConfigUtils'
import { translate } from '../Translation'

/* Internal */
import ErrorBlock from '../shared/ErrorBlock'
import Advert, { getAdObj, isAdvert } from '../shared/ad/Advert'
import HeaderMessageBarSecondary from '../shared/header/HeaderMessageBarSecondary'
import HackMarketingContentWrapper from '../sharedInternal/container/HackMarketingContentWrapper'
import FacilitySummarySimple from '../sharedInternal/facility/FacilitySummarySimple'
import { SimpleNewsletterSignup } from '../sharedInternal/signup'
import HomeHeroCmp from './HomeHeroCmp'
import ArticlePanelCmp from './article/ArticlePanelCmp'
import MarketingSpot from './marketing/MarketingSpot'
import MarketingSpotInfo from './marketing/MarketingSpotInfo'

const HomeLayout = ({
  theme,
  intl,
  brandConfig,
  errors,
  onSearchTrigger,
  onSearchTermChange,
  allQuickSearchResults,
  allQuickSearchResultsAttribs,
  mainSearchConfig,
  brndConf,
  onSearchResultItemSelectRequest,
  currSearch,
  getArticleSelectLinkObj,
  getMarketingSpotSelectLinkObj,
  onAllArticlesSelectRequest,
  onMarketingSpotSelectRequest,
  onArticleSelectRequest,
  pageMetaData,
  doesNavigatorSupportsGeolocation,
  onSelectNearMeRequest,
  recentlyViewedFacilityData,
  onClearRecentlyViewedFacilityDataRequest,
  getFacilityLinkObj,
  onFacilitySelectRequest,
}) => {
  const getFlipCard = function (spot, cardSize, isShowCardBack = true) {
    const cardContentFront = (
      <MarketingSpot
        theme={theme}
        intl={intl}
        getMarketingSpotSelectLinkObj={getMarketingSpotSelectLinkObj}
        onMarketingSpotSelectRequest={onMarketingSpotSelectRequest}
        marketingSpot={spot}
        setNoReferrer={false}
      />
    )
    const cardLabelBtnFront = translate(intl, 'label_flip_card_front_btn', {
      description: spot.title,
    })
    const cardLabelBtnBack = translate(intl, 'label_flip_card_back_btn', {
      description: spot.title,
    })

    let cardContentBack = null
    if (isShowCardBack) {
      cardContentBack = (
        <MarketingSpotInfo
          theme={theme}
          intl={intl}
          getMarketingSpotSelectLinkObj={getMarketingSpotSelectLinkObj}
          onMarketingSpotSelectRequest={onMarketingSpotSelectRequest}
          marketingSpot={spot}
        />
      )
    }

    return (
      <FlipCard
        isShowMoreButton={isShowCardBack}
        cardSize={cardSize}
        cardContentFront={cardContentFront}
        cardContentBack={cardContentBack}
        cardLabelBtnFront={cardLabelBtnFront}
        cardLabelBtnBack={cardLabelBtnBack}
      />
    )
  }

  const isShowCardBack = function (spot) {
    return !isEmpty(spot.excerpt)
  }

  const getCardMarketingUI = function () {
    return (
      <HackMarketingContentWrapper style="container extra-large-width">
        <SimpleContainer>
          <AGrid fluid>
            <ARow>
              <ACol sm={12} md={6} lg={6}>
                {getFlipCard(
                  brandConfig.marketing.spots[0],
                  '6x',
                  isShowCardBack(brandConfig.marketing.spots[0]),
                )}
              </ACol>
              <ACol sm={12} md={6} lg={6}>
                <AGrid fluid>
                  <ARow>
                    <ACol sm={12} md={12} lg={12}>
                      {getFlipCard(
                        brandConfig.marketing.spots[1],
                        '3x',
                        isShowCardBack(brandConfig.marketing.spots[1]),
                      )}
                    </ACol>
                  </ARow>
                  <ARow>
                    <ACol sm={6} md={6} lg={6}>
                      {getFlipCard(
                        brandConfig.marketing.spots[2],
                        '3x',
                        isShowCardBack(brandConfig.marketing.spots[2]),
                      )}
                    </ACol>
                    <ACol sm={6} md={6} lg={6}>
                      {getFlipCard(
                        brandConfig.marketing.spots[3],
                        '3x',
                        isShowCardBack(brandConfig.marketing.spots[3]),
                      )}
                    </ACol>
                  </ARow>
                </AGrid>
              </ACol>
            </ARow>
            <Cont hideXS={brandConfig.marketing.mobileHideLastRow}>
              <ARow>
                <ACol sm={6} md={3} lg={3}>
                  {getFlipCard(
                    brandConfig.marketing.spots[4],
                    '3x',
                    isShowCardBack(brandConfig.marketing.spots[4]),
                  )}
                </ACol>
                <ACol sm={6} md={3} lg={3}>
                  {getFlipCard(
                    brandConfig.marketing.spots[5],
                    '3x',
                    isShowCardBack(brandConfig.marketing.spots[5]),
                  )}
                </ACol>
                <ACol sm={6} md={3} lg={3}>
                  {getFlipCard(
                    brandConfig.marketing.spots[6],
                    '3x',
                    isShowCardBack(brandConfig.marketing.spots[6]),
                  )}
                </ACol>
                <ACol sm={6} md={3} lg={3}>
                  {getFlipCard(
                    brandConfig.marketing.spots[7],
                    '3x',
                    isShowCardBack(brandConfig.marketing.spots[7]),
                  )}
                </ACol>
              </ARow>
            </Cont>
          </AGrid>
        </SimpleContainer>
      </HackMarketingContentWrapper>
    )
  }

  const getPartnerCardMarketingUI = function () {
    if (
      isUndefined(brandConfig.partner) ||
      isEmpty(brandConfig.partner) ||
      !brandConfig.partner.showSection
    ) {
      return null
    }

    const isAd = isAdvert('HP9', getAdConfig(mainSearchConfig))

    let advert = null
    if (isAd) {
      advert = (
        <Cont align={ALIGN_CENTER}>
          <Section />
          <Advert
            id={'HP9'}
            adConfig={getAdConfig(mainSearchConfig)}
            pageMetaData={pageMetaData}
          />
        </Cont>
      )
    }

    return (
      <div>
        <Section hideLG hideMD hideSM />
        <HackMarketingContentWrapper style="container extra-large-width">
          <SimpleContainer>
            <Cont>
              <BoxContainer borderColor={'grey-medium'}>
                <Cont align={ALIGN_CENTER} padOverride={PAD_OVERRIDE_10}>
                  <TextHoverable
                    selected
                    fontSizeType={2}
                    fontFamily={'Georgia'}
                    fontWeight={'400'}
                  >
                    {brandConfig.partner.heading}
                  </TextHoverable>
                </Cont>
              </BoxContainer>
              <Section />
              <AGrid fluid>
                <ARow>
                  <ACol sm={6} md={3} lg={3}>
                    {getFlipCard(
                      brandConfig.partner.spots[0],
                      '3x',
                      isShowCardBack(brandConfig.partner.spots[0]),
                    )}
                  </ACol>
                  <ACol sm={6} md={3} lg={3}>
                    {getFlipCard(
                      brandConfig.partner.spots[1],
                      '3x',
                      isShowCardBack(brandConfig.partner.spots[1]),
                    )}
                  </ACol>
                  <ACol sm={6} md={3} lg={3}>
                    {getFlipCard(
                      brandConfig.partner.spots[2],
                      '3x',
                      isShowCardBack(brandConfig.partner.spots[2]),
                    )}
                  </ACol>
                  <ACol sm={6} md={3} lg={3}>
                    {getFlipCard(
                      brandConfig.partner.spots[3],
                      '3x',
                      isShowCardBack(brandConfig.partner.spots[3]),
                    )}
                  </ACol>
                </ARow>
              </AGrid>
            </Cont>
          </SimpleContainer>
        </HackMarketingContentWrapper>
        {advert}
      </div>
    )
  }

  const getFacilitySummaryCard = function (spot) {
    if (isUndefined(spot) || isEmpty(spot)) {
      return null
    }
    const cardContentFront = (
      <FacilitySummarySimple
        intl={intl}
        facilitySummary={spot}
        getFacilitySummaryLinkObj={getFacilityLinkObj}
        onFacilitySelectRequest={onFacilitySelectRequest}
      />
    )
    let cardSize = '4x'
    if (recentlyViewedFacilityData.list.length < 4) {
      cardSize = '4x'
    }

    return (
      <FlipCard
        isShowMoreButton={false}
        cardSize={cardSize}
        cardContentFront={cardContentFront}
        cardContentBack={null}
      />
    )

    // return cardContentFront
  }

  const getCardRecentlyViewedUI = function () {
    if (
      isUndefined(recentlyViewedFacilityData) ||
      isEmpty(recentlyViewedFacilityData) ||
      isEmpty(recentlyViewedFacilityData.list)
    ) {
      return null
    }

    return (
      <div>
        <HackMarketingContentWrapper style="container extra-large-width">
          <SimpleContainer>
            <Cont>
              <Section />
              <Cont align={ALIGN_CENTER} padOverride={PAD_OVERRIDE_10}>
                <TextHoverable
                  selected
                  fontSizeType={2}
                  fontFamily={'Figtree'}
                  fontWeight={'600'}
                >
                  {translate(intl, 'recently_viewed')}
                </TextHoverable>
              </Cont>
              <Cont hideLG hideMD hideSM>
                <Section />
              </Cont>

              <Cont align={ALIGN_RIGHT}>
                <AIconAction
                  label={translate(intl, 'label_clear_search')}
                  faStyle={'fa fa-trash'}
                  isShowAsLink
                  onClick={onClearRecentlyViewedFacilityDataRequest}
                />
              </Cont>
              <Section />
              <DynamicGrid
                colSm={6}
                colMd={6}
                colLg={3}
                items={recentlyViewedFacilityData.list}
                getCellContent={getFacilitySummaryCard}
                fluid
              />
            </Cont>
          </SimpleContainer>
        </HackMarketingContentWrapper>
        <Section />
      </div>
    )
  }

  const getSignupUI = function () {
    if (
      isUndefined(brandConfig.newsletterSignup) ||
      isEmpty(brandConfig.newsletterSignup) ||
      !brandConfig.newsletterSignup.showSection
    ) {
      return null
    }

    return (
      <div>
        <Section />
        <HackMarketingContentWrapper style="container extra-large-width">
          <SimpleContainer>
            <SimpleNewsletterSignup intl={intl} theme={theme} />
          </SimpleContainer>
        </HackMarketingContentWrapper>
      </div>
    )
  }

  const getBroadcastUI = function () {
    if (isEmpty(brandConfig.systemBroadcastSecondary)) {
      return null
    }

    return (
      <HeaderMessageBarSecondary
        message={brandConfig.systemBroadcastSecondary}
      />
    )
  }

  const adConfig = getAdConfig(mainSearchConfig)

  const getTopAdvertisement = () => {
    if (isEmpty(adConfig) || !getAdObj('HP2', adConfig)) {
      return <br />
    }

    return (
      <div className="horizontal-ad-section top-ad">
        <Advert id={'HP2'} adConfig={adConfig} pageMetaData={pageMetaData} />
      </div>
    )
  }

  const getMiddleAdvertisement = () => {
    if (isEmpty(adConfig) || !getAdObj('HP6', adConfig)) {
      return <br />
    }

    return (
      <div className="horizontal-ad-section middle-ad">
        <Advert id={'HP6'} adConfig={adConfig} pageMetaData={pageMetaData} />
      </div>
    )
  }

  return (
    <div className="home-content home-marketing">
      <HomeHeroCmp
        brandConfig={brandConfig}
        theme={theme}
        intl={intl}
        onSearchTrigger={onSearchTrigger}
        onSearchTermChange={onSearchTermChange}
        allQuickSearchResults={allQuickSearchResults}
        allQuickSearchResultsAttribs={allQuickSearchResultsAttribs}
        mainSearchConfig={mainSearchConfig}
        brndConf={brndConf}
        onSearchResultItemSelectRequest={onSearchResultItemSelectRequest}
        currSearch={currSearch}
        doesNavigatorSupportsGeolocation={doesNavigatorSupportsGeolocation}
        onSelectNearMeRequest={onSelectNearMeRequest}
      />
      <div className="container">
        <ErrorBlock errors={errors} intl={intl} />
      </div>
      {getTopAdvertisement()}
      <div>
        <div>
          {getCardRecentlyViewedUI()}
          {/* <MarketingPanel theme={theme} intl={intl} brandConfig={brandConfig} getMarketingSpotSelectLinkObj={getMarketingSpotSelectLinkObj} onMarketingSpotSelectRequest={onMarketingSpotSelectRequest} /> */}
          {getCardMarketingUI()}
          {/* {getMarketingContentUI()} */}
          {getSignupUI()}

          <ArticlePanelCmp
            theme={theme}
            intl={intl}
            brandConfig={brandConfig}
            onAllArticlesSelectRequest={onAllArticlesSelectRequest}
            getArticleSelectLinkObj={getArticleSelectLinkObj}
            adConfig={getAdConfig(mainSearchConfig)}
            onArticleSelectRequest={onArticleSelectRequest}
          />
        </div>
      </div>
      {getMiddleAdvertisement()}
      {getBroadcastUI()}
    </div>
  )
}

HomeLayout.propTypes = {
  theme: string.isRequired,
  intl: object.isRequired,
  errors: array.isRequired,
  brandConfig: object.isRequired,
  onSearchTrigger: func.isRequired,
  onSearchTermChange: func.isRequired,
  allQuickSearchResults: array.isRequired,
  allQuickSearchResultsAttribs: array.isRequired,
  onSearchResultItemSelectRequest: func.isRequired,
  mainSearchConfig: object.isRequired,
  brndConf: object.isRequired,
  currSearch: object,
  getMarketingSpotSelectLinkObj: func.isRequired,
  getArticleSelectLinkObj: func.isRequired,
  onAllArticlesSelectRequest: func.isRequired,
  onMarketingSpotSelectRequest: func.isRequired,
  onArticleSelectRequest: func.isRequired,
  pageMetaData: object,
  doesNavigatorSupportsGeolocation: bool.isRequired,
  onSelectNearMeRequest: func.isRequired,
  recentlyViewedFacilityData: object,
  onClearRecentlyViewedFacilityDataRequest: func.isRequired,
  getFacilityLinkObj: func.isRequired,
  onFacilitySelectRequest: func.isRequired,
}

export default HomeLayout
