/* Home */
export const BASE = ''
export const ROUTE_BASE = BASE
export const ROOT = '/'
export const ROUTE_ROOT = ROOT
export const WELCOME = 'welcome'
export const ROUTE_WELCOME = `/${WELCOME}`

/* Search */
export const SEARCH_RESULTS = 'search-results'
export const ROUTE_SEARCH_RESULTS = `/${SEARCH_RESULTS}`

/* Facility - Overview */
export const OVERVIEW = 'overview'
export const CHUBCART = `${OVERVIEW}/chubcart`
export const CHUBCART_CONFIRMATION = `${OVERVIEW}/confirmation`
export const ROUTE_FACILITY = '/*/:contractId/:facilityId'
export const ROUTE_OVERVIEW = `/*/:contractId/:facilityId/${OVERVIEW}`
export const ROUTE_OVERVIEW_CHECKOUT = `/*/:contractId/:facilityId/${CHUBCART}`
export const ROUTE_OVERVIEW_CONFIRMATION = `/*/:contractId/:facilityId/${CHUBCART_CONFIRMATION}`
export const BOOKING_WINDOW = 'booking-window'
export const ROUTE_BOOKING_WINDOW = `/*/:contractId/:facilityId/${BOOKING_WINDOW}`
export const SEASON_DATES = 'season-dates'
export const ROUTE_SEASON_DATES = `/*/:contractId/:facilityId/${SEASON_DATES}`
export const FEES = 'fees'
export const ROUTE_FEES = `/*/:contractId/:facilityId/${FEES}`
export const VISITOR_PHOTOS = 'visitor-photos'
export const ROUTE_VISITOR_PHOTOS = `/*/:contractId/:facilityId/${VISITOR_PHOTOS}`

/* Facility - Sites */
export const SITE_LIST = 'campsites'
export const ROUTE_SITE_LIST = `/*/:contractId/:facilityId/${SITE_LIST}`
export const SITES_CALENDAR = 'campsite-availability'
export const ROUTE_SITES_CALENDAR = `/*/:contractId/:facilityId/${SITES_CALENDAR}`
export const SITES_MAP = 'sites-map'
export const ROUTE_SITES_MAP = `/*/:contractId/:facilityId/${SITES_MAP}`
export const DAY_PASSES = 'day-passes'
export const ROUTE_DAY_PASSES = `/*/:contractId/:facilityId/${DAY_PASSES}`
export const HOURLY_SITE_LIST = 'hourlysites'
export const ROUTE_HOURLY_SITE_LIST = `/*/:contractId/:facilityId/${HOURLY_SITE_LIST}`
export const HOURLY_SITES_CALENDAR = 'hourly-site-availability'
export const ROUTE_HOURLY_SITES_CALENDAR = `/*/:contractId/:facilityId/${HOURLY_SITES_CALENDAR}`

/* Facility - Site And Booking Details */
export const SITE_AND_BOOKING_DETAILS = 'campsite-booking'
export const ROUTE_SITE_AND_BOOKING_DETAILS = `/*/:contractId/:facilityId/:productId/${SITE_AND_BOOKING_DETAILS}`

/* Facility - Day Passes And Booking Details */
export const DAY_PASSES_AND_BOOKING_DETAILS = 'day-pass-booking'
export const ROUTE_DAY_PASSES_AND_BOOKING_DETAILS = `/*/:contractId/:facilityId/:productId/${DAY_PASSES_AND_BOOKING_DETAILS}`

/* Facility - Hourly Site And Booking Details */
export const HOURLY_SITE_AND_BOOKING_DETAILS = 'hourly-site-booking'
export const ROUTE_HOURLY_SITE_AND_BOOKING_DETAILS = `/*/:contractId/:facilityId/:productId/${HOURLY_SITE_AND_BOOKING_DETAILS}`

/* Product - Notifications */
export const PRODUCT_NOTIFICATION = 'product-notification'
export const ROUTE_PRODUCT_NOTIFICATION = `/*/:contractId/:facilityId/:productId/${PRODUCT_NOTIFICATION}`

/* Product - Booking Details */
export const PRODUCT_BOOKING_DETAILS = 'product-booking-details'
export const ROUTE_PRODUCT_BOOKING_DETAILS = `/${PRODUCT_BOOKING_DETAILS}`
export const PRODUCT_BOOKING_SUPPLEMENT_DETAILS =
  'product-booking-supplement-details'
export const ROUTE_PRODUCT_BOOKING_SUPPLEMENT_DETAILS = `/${PRODUCT_BOOKING_SUPPLEMENT_DETAILS}`

/* Cart */
export const CART_VIEW = 'view'
export const ROUTE_CART_VIEW = `/cart/${CART_VIEW}`
export const CART_ITEM = 'item'
export const ROUTE_CART_ITEM = `/cart/${CART_ITEM}`
export const CART_CHECKOUT = 'checkout'
export const ROUTE_CART_CHECKOUT = `/cart/${CART_CHECKOUT}`
export const CART_CONFIRMATION = 'confirmation'
export const ROUTE_CART_CONFIRMATION = `/cart/${CART_CONFIRMATION}`

/* List Campground */
export const LIST_CAMPGROUND_HOME = 'home'
export const ROUTE_LIST_CAMPGROUND_HOME = `/listcampground/${LIST_CAMPGROUND_HOME}`
export const LIST_CAMPGROUND_SELF_SERVE = 'self-serve'
export const ROUTE_LIST_CAMPGROUND_SELF_SERVE = `/listcampground/${LIST_CAMPGROUND_SELF_SERVE}`

/* My Account */
export const MY_ACCOUNT = 'my-account'
export const ROUTE_MY_ACCOUNT = `/${MY_ACCOUNT}`
export const DASHBOARD = 'dashboard'
export const ROUTE_DASHBOARD = `/${MY_ACCOUNT}/${DASHBOARD}`
export const RESERVATIONS = 'reservations'
export const ROUTE_RESERVATIONS = `/${MY_ACCOUNT}/${RESERVATIONS}`
export const RESERVATION_DETAILS = 'reservation-details'
export const ROUTE_RESERVATION_DETAILS = `/${MY_ACCOUNT}/${RESERVATION_DETAILS}`
export const RESERVATION_CANCEL = 'reservation-cancel'
export const ROUTE_RESERVATION_CANCEL = `/${MY_ACCOUNT}/${RESERVATION_CANCEL}`
export const RESERVATION_PRE_CHECKIN = 'reservation-pre-checkin'
export const ROUTE_RESERVATION_PRE_CHECKIN = `/${MY_ACCOUNT}/${RESERVATION_PRE_CHECKIN}`
export const RESERVATION_PAYMENT = 'reservation-payment'
export const ROUTE_RESERVATION_PAYMENT = `/${MY_ACCOUNT}/${RESERVATION_PAYMENT}`
export const RESERVATION_CHANGE = 'reservation-change'
export const ROUTE_RESERVATION_CHANGE = `/${MY_ACCOUNT}/${RESERVATION_CHANGE}`
export const RESERVATION_CHANGE_SUPPLEMENT_DETAILS =
  'reservation-change-supplement-details'
export const ROUTE_RESERVATION_CHANGE_SUPPLEMENT_DETAILS = `/${MY_ACCOUNT}/${RESERVATION_CHANGE_SUPPLEMENT_DETAILS}`
export const RESERVATION_CHANGE_CONFIRMATION = 'reservation-change-confirmation'
export const ROUTE_RESERVATION_CHANGE_CONFIRMATION = `/${MY_ACCOUNT}/${RESERVATION_CHANGE_CONFIRMATION}`
export const NOTIFICATIONS = 'notifications'
export const ROUTE_NOTIFICATIONS = `/${MY_ACCOUNT}/${NOTIFICATIONS}`
export const APPLICATIONS = 'applications'
export const ROUTE_APPLICATIONS = `/${MY_ACCOUNT}/${APPLICATIONS}`
export const PASSES = 'passes'
export const ROUTE_PASSES = `/${MY_ACCOUNT}/${PASSES}`
export const VOUCHERS = 'vouchers'
export const ROUTE_VOUCHERS = `/${MY_ACCOUNT}/${VOUCHERS}`
export const POINTS = 'points'
export const ROUTE_POINTS = `/${MY_ACCOUNT}/${POINTS}`
export const POINTS_DETAILS = 'points-details'
export const ROUTE_POINTS_DETAILS = `/${MY_ACCOUNT}/${POINTS_DETAILS}`
export const EQUIPMENT = 'equipment'
export const ROUTE_EQUIPMENT = `/${MY_ACCOUNT}/${EQUIPMENT}`
export const EQUIPMENT_DETAILS = 'equipment-details'
export const ROUTE_EQUIPMENT_DETAILS = `/${MY_ACCOUNT}/${EQUIPMENT_DETAILS}`
export const PROFILE = 'profile'
export const ROUTE_PROFILE = `/${MY_ACCOUNT}/${PROFILE}`
export const CAMP_CLUB_PROFILE = 'camp-club-profile'
export const ROUTE_CAMP_CLUB_PROFILE = `/${MY_ACCOUNT}/${CAMP_CLUB_PROFILE}`
export const PRINT_TICKETS_PERMITS = 'print-tickets-permits'
export const ROUTE_PRINT_TICKETS_PERMITS = `/${MY_ACCOUNT}/${PRINT_TICKETS_PERMITS}`
export const RATE_RESERVATION = 'rate-reservation'
export const ROUTE_RATE_RESERVATION = `/${MY_ACCOUNT}/${RATE_RESERVATION}`

/* Offer */
export const OFFER = 'offer'
export const ROUTE_OFFER = `/${OFFER}`

/* POS Item */
export const POS_ITEM = 'pos-item'
export const ROUTE_POS_ITEM = `/${POS_ITEM}`

/* Favorites */
export const FAVORITES = 'favorites'
export const ROUTE_FAVORITES = `/${FAVORITES}`

/* ContactUs */
export const CONTACT_US = 'contact-us'
export const ROUTE_CONTACT_US = `/${CONTACT_US}`

/* Advertise */
export const ADVERTISE = 'advertising'
export const ROUTE_ADVERTISE = `/${ADVERTISE}`

/* Info */
export const INFO = 'info'
export const ROUTE_INFO = `/:infoItemId/${INFO}`

/* Other */
export const ERROR = 'error'
export const ROUTE_ERROR = `/${ERROR}`
export const NOT_LOGGED_IN = 'not-logged-in'
export const ROUTE_NOT_LOGGED_IN = `/${NOT_LOGGED_IN}`
export const LOB_CAMP = 'camp'

/* Testing */
export const TEST_PAGE_ONE = '/pageone'
export const TEST_PAGE_TWO = '/pagetwo'
export const TEST_SUB_PAGEONE = '/subpageone'
export const TEST_SUB_PAGETWO = '/subpagetwo'

export const SEARCH_NOT_VISIBLE_ROUTES = [
  PRODUCT_BOOKING_DETAILS,
  CART_VIEW,
  CART_ITEM,
  CART_CHECKOUT,
  CART_CONFIRMATION,
  MY_ACCOUNT,
  ERROR,
]

export const SEARCH_FILTER_NOT_VISIBLE_MOBILE_ROUTES = [
  OVERVIEW,
  BOOKING_WINDOW,
  SEASON_DATES,
  VISITOR_PHOTOS,
]

export const HEADER_NOT_VISIBLE_ROUTES = [OFFER]

export const FOOTER_NOT_VISIBLE_ROUTES = [SEARCH_RESULTS, OFFER, FAVORITES]

export const FEEDBACK_NOT_VISIBLE_ROUTES = [CART_CONFIRMATION]

export const SSR_PATH = '/explore'
export const SSR_PRELOADER = 'preloader'
export const ROUTE_SSR_PRELOADER = `/${SSR_PRELOADER}`

export const HEADER_TRACE_ID_VISIBLE_ROUTES = [SEARCH_RESULTS]
