import { addZero, createDateFromYYYYMMDD } from './DateUtils'
import { getIntlObj, simpleTranslate } from './TranslationUtils'

/* Date Formats */
export const MMDDYYYY = 'MMDDYYYY'
export const DDMMYYYY = 'DDMMYYYY'
export const YYYYMMDD = 'YYYYMMDD'
export const DDMonthYYYY = 'DDMonthYYYY'
export const MonthDDYYYY = 'MonthDDYYYY'
export const DayMonthYYYY = 'DayMonthYYYY'

// NOTE - DO NOT ADD any more messages here.
// If you need to add messages move your code out
// of this external library. Current messages are
// just to keep the code refactor issues down.
const defaultIntlObj = {
  locale: 'en-US',
  messages: {
    'external.MONTH_1': 'Jan',
    'external.MONTH_2': 'Feb',
    'external.MONTH_3': 'Mar',
    'external.MONTH_4': 'Apr',
    'external.MONTH_5': 'May',
    'external.MONTH_6': 'Jun',
    'external.MONTH_7': 'Jul',
    'external.MONTH_8': 'Aug',
    'external.MONTH_9': 'Sep',
    'external.MONTH_10': 'Oct',
    'external.MONTH_11': 'Nov',
    'external.MONTH_12': 'Dec',
    'external.DAY_OF_WEEK_0': 'Sun',
    'external.DAY_OF_WEEK_1': 'Mon',
    'external.DAY_OF_WEEK_2': 'Tue',
    'external.DAY_OF_WEEK_3': 'Wed',
    'external.DAY_OF_WEEK_4': 'Thu',
    'external.DAY_OF_WEEK_5': 'Fri',
    'external.DAY_OF_WEEK_6': 'Sat',
  },
}

export function formatDate(date, format, intlObj = null, separator = '-') {
  if (format === DDMMYYYY) {
    return (
      addZero(date.getDate()) +
      separator +
      addZero(date.getMonth() + 1) +
      separator +
      date.getFullYear().toString()
    )
  } else if (format === MMDDYYYY) {
    return (
      addZero(date.getMonth() + 1) +
      separator +
      addZero(date.getDate()) +
      separator +
      date.getFullYear().toString()
    )
  } else if (format === DDMonthYYYY) {
    return (
      addZero(date.getDate()) +
      separator +
      simpleTranslate(
        getIntlObj(intlObj, defaultIntlObj),
        `external.MONTH_${date.getMonth() + 1}`,
        '',
      ) +
      separator +
      date.getFullYear().toString()
    )
  } else if (format === MonthDDYYYY) {
    return (
      simpleTranslate(
        getIntlObj(intlObj, defaultIntlObj),
        `external.MONTH_${date.getMonth() + 1}`,
        '',
      ) +
      separator +
      addZero(date.getDate()) +
      separator +
      date.getFullYear().toString()
    )
  } else if (format === DayMonthYYYY) {
    return (
      simpleTranslate(
        getIntlObj(intlObj, defaultIntlObj),
        `external.DAY_OF_WEEK_${date.getDay()}`,
        '',
      ) +
      separator +
      simpleTranslate(
        getIntlObj(intlObj, defaultIntlObj),
        `external.MONTH_${date.getMonth() + 1}`,
        '',
      ) +
      separator +
      addZero(date.getDate()) +
      separator +
      date.getFullYear().toString()
    )
  }

  return ''
}

export function defaultFormatDate(date, intlObj = null, separator = ' ') {
  return formatDate(date, MonthDDYYYY, intlObj, separator)
}

export function defaultFormatDateLiteral(
  daleLiteral,
  intlObj = null,
  separator = ' ',
) {
  return defaultFormatDate(
    createDateFromYYYYMMDD(daleLiteral),
    intlObj,
    separator,
  )
}
