import { isEmpty } from '../external/utilities/GeneralUtils'
import { getQueryStringFromObj } from '../utilities/QueryParamUtils'

export function sendToPath(path, newWindow = false) {
  if (newWindow) {
    window.open(path, '_blank')
  } else {
    window.location.href = path
  }
}

let routeData = null

export function setMainConfig(config) {
  routeData = config.extRoutes
}

/* Get Routes */
export function getHelpCenterRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.ROUTE_HELP_CENTER)) {
    return routeData.ROUTE_HELP_CENTER + getQueryStringFromObj(query)
  }

  return null
}

export function getListYourCampgroundRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.ROUTE_LIST_YOUR_CAMPGROUND)) {
    return routeData.ROUTE_LIST_YOUR_CAMPGROUND + getQueryStringFromObj(query)
  }

  return null
}

export function getArticlesRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.ROUTE_ARTICLES)) {
    return routeData.ROUTE_ARTICLES + getQueryStringFromObj(query)
  }

  return null
}

export function getDestinationsRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.ROUTE_DESTINATIONS)) {
    return routeData.ROUTE_DESTINATIONS + getQueryStringFromObj(query)
  }

  return null
}

export function getHuntingAndFishingRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.ROUTE_HUNTING_AND_FISHING)) {
    return routeData.ROUTE_HUNTING_AND_FISHING + getQueryStringFromObj(query)
  }

  return null
}

export function getMyProfileRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.ROUTE_MY_PROFILE)) {
    return routeData.ROUTE_MY_PROFILE + getQueryStringFromObj(query)
  }

  return null
}

export function getMyReservationsRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.ROUTE_MY_RESERVATIONS)) {
    return routeData.ROUTE_MY_RESERVATIONS + getQueryStringFromObj(query)
  }

  return null
}

export function getSignInRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.ROUTE_SIGNIN)) {
    return routeData.ROUTE_SIGNIN + getQueryStringFromObj(query)
  }

  return null
}

export function getSignUpRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.ROUTE_SIGNUP)) {
    return routeData.ROUTE_SIGNUP + getQueryStringFromObj(query)
  }

  return null
}

export function getForgotPasswordRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.ROUTE_FORGOT_PASSWORD)) {
    return routeData.ROUTE_FORGOT_PASSWORD + getQueryStringFromObj(query)
  }

  return null
}

export function getSignOutRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.ROUTE_SIGNOUT)) {
    return routeData.ROUTE_SIGNOUT + getQueryStringFromObj(query)
  }

  return null
}

export function getProductNotificationsSummaryRoute(query = null) {
  if (
    !isEmpty(routeData) &&
    !isEmpty(routeData.ROUTE_PRODUCT_NOTIFICATIONS_SUMMARY)
  ) {
    return (
      routeData.ROUTE_PRODUCT_NOTIFICATIONS_SUMMARY +
      getQueryStringFromObj(query)
    )
  }

  return null
}

export function getSubmissionsPolicyRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.ROUTE_SUBMISSIONS_POLICY)) {
    return routeData.ROUTE_SUBMISSIONS_POLICY + getQueryStringFromObj(query)
  }

  return null
}

export function getMyAccountSettingsRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.ROUTE_MY_ACCOUNT_SETTINGS)) {
    return routeData.ROUTE_MY_ACCOUNT_SETTINGS + getQueryStringFromObj(query)
  }

  return null
}

export function getCancelReservationRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.CANCEL_RESERVATION)) {
    return routeData.CANCEL_RESERVATION + getQueryStringFromObj(query)
  }

  return null
}

export function getChangeReservationRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.CHANGE_RESERVATION)) {
    return routeData.CHANGE_RESERVATION + getQueryStringFromObj(query)
  }

  return null
}

export function getPayReservationBalanceRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.PAY_RESERVATION_BALANCE)) {
    return routeData.PAY_RESERVATION_BALANCE + getQueryStringFromObj(query)
  }

  return null
}

export function getRenewReservationRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.RENEW_RESERVATION)) {
    return routeData.RENEW_RESERVATION + getQueryStringFromObj(query)
  }

  return null
}

export function getChangeReservationParticipantInfoRoute(query = null) {
  if (
    !isEmpty(routeData) &&
    !isEmpty(routeData.CHANGE_RESERVATION_PARTICIPANT_INFO)
  ) {
    return (
      routeData.CHANGE_RESERVATION_PARTICIPANT_INFO +
      getQueryStringFromObj(query)
    )
  }

  return null
}

export function getChangeTourReservationRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.CHANGE_TOUR_RESERVATION)) {
    return routeData.CHANGE_TOUR_RESERVATION + getQueryStringFromObj(query)
  }

  return null
}

export function getCancelTourReservationRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.CANCEL_TOUR_RESERVATION)) {
    return routeData.CANCEL_TOUR_RESERVATION + getQueryStringFromObj(query)
  }

  return null
}

export function getPreRegisterRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.PRE_REGISTER)) {
    return routeData.PRE_REGISTER + getQueryStringFromObj(query)
  }

  return null
}

export function getPrivacyPolicyRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.ROUTE_PRIVACY_POLICY)) {
    return routeData.ROUTE_PRIVACY_POLICY + getQueryStringFromObj(query)
  }

  return null
}

export function getCopyrightPolicyRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.ROUTE_COPYRIGHT_POLICY)) {
    return routeData.ROUTE_COPYRIGHT_POLICY + getQueryStringFromObj(query)
  }

  return null
}

export function getCookiePolicyRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.ROUTE_COOKIE_POLICY)) {
    return routeData.ROUTE_COOKIE_POLICY + getQueryStringFromObj(query)
  }

  return null
}

export function getTermsOfUseRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.ROUTE_TERMS_OF_USE)) {
    return routeData.ROUTE_TERMS_OF_USE + getQueryStringFromObj(query)
  }

  return null
}

export function getGearDealsRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.ROUTE_GEAR_DEALS)) {
    return routeData.ROUTE_GEAR_DEALS + getQueryStringFromObj(query)
  }

  return null
}

export function getContactUsRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.ROUTE_CONTACT_US)) {
    return routeData.ROUTE_CONTACT_US + getQueryStringFromObj(query)
  }

  return null
}

export function getAdvertisingRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.ROUTE_ADVERTISING)) {
    return routeData.ROUTE_ADVERTISING + getQueryStringFromObj(query)
  }

  return null
}

export function getAboutUsRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.ROUTE_ABOUT_US)) {
    return routeData.ROUTE_ABOUT_US + getQueryStringFromObj(query)
  }

  return null
}

export function getProductsServicesRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.ROUTE_PRODUCTS_SERVICES)) {
    return routeData.ROUTE_PRODUCTS_SERVICES + getQueryStringFromObj(query)
  }

  return null
}

export function getMyAccountOverviewRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.ROUTE_MY_ACCOUNT_OVERVIEW)) {
    return routeData.ROUTE_MY_ACCOUNT_OVERVIEW + getQueryStringFromObj(query)
  }

  return null
}

export function getLeaveBetaRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.ROUTE_LEAVE_BETA)) {
    return routeData.ROUTE_LEAVE_BETA + getQueryStringFromObj(query)
  }

  return null
}

export function getAppStoreRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.ROUTE_APP_STORE)) {
    return routeData.ROUTE_APP_STORE + getQueryStringFromObj(query)
  }

  return null
}

export function getPlayStoreRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.ROUTE_PLAY_STORE)) {
    return routeData.ROUTE_PLAY_STORE + getQueryStringFromObj(query)
  }

  return null
}

export function getFacebookRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.ROUTE_FACEBOOK)) {
    return routeData.ROUTE_FACEBOOK + getQueryStringFromObj(query)
  }

  return null
}

export function getTwitterRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.ROUTE_TWITTER)) {
    return routeData.ROUTE_TWITTER + getQueryStringFromObj(query)
  }

  return null
}

export function getInstagramRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.ROUTE_INSTAGRAM)) {
    return routeData.ROUTE_INSTAGRAM + getQueryStringFromObj(query)
  }

  return null
}

export function getGooglePlusRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.ROUTE_GOOGLE_PLUS)) {
    return routeData.ROUTE_GOOGLE_PLUS + getQueryStringFromObj(query)
  }

  return null
}

export function getPinterestRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.ROUTE_PINTEREST)) {
    return routeData.ROUTE_PINTEREST + getQueryStringFromObj(query)
  }

  return null
}

export function getCareersRoute(query = null) {
  if (!isEmpty(routeData) && !isEmpty(routeData.ROUTE_CAREERS)) {
    return routeData.ROUTE_CAREERS + getQueryStringFromObj(query)
  }

  return null
}

/* forward to Routes */
export function forwardToHelpCenter(query = null, newWindow = true) {
  sendToPath(getHelpCenterRoute(query), newWindow)
}

export function forwardToListYourCampground(query = null, newWindow = true) {
  sendToPath(getListYourCampgroundRoute(query), newWindow)
}

export function forwardToArticles(query = null, newWindow = false) {
  sendToPath(getArticlesRoute(query), newWindow)
}

export function forwardToDestinations(query = null, newWindow = false) {
  sendToPath(getDestinationsRoute(query), newWindow)
}

export function forwardToHuntingAndFishing(query = null, newWindow = false) {
  sendToPath(getHuntingAndFishingRoute(query), newWindow)
}

export function forwardToExternalMyProfile(query = null, newWindow = false) {
  sendToPath(getMyProfileRoute(query), newWindow)
}

export function forwardToExternalMyReservations(
  query = null,
  newWindow = false,
) {
  sendToPath(getMyReservationsRoute(query), newWindow)
}

export function forwardToSignIn(query = null, newWindow = false) {
  sendToPath(getSignInRoute(query), newWindow)
}

export function forwardToSignUp(query = null, newWindow = false) {
  sendToPath(getSignUpRoute(query), newWindow)
}

export function forwardToForgotPassword(query = null, newWindow = false) {
  sendToPath(getForgotPasswordRoute(query), newWindow)
}

export function forwardToSignOut(query = null, newWindow = false) {
  sendToPath(getSignOutRoute(query), newWindow)
}

export function forwardToProductNotificationsSummary(
  query = null,
  newWindow = false,
) {
  sendToPath(getProductNotificationsSummaryRoute(query), newWindow)
}

export function forwardToSubmissionsPolicyRoute(
  query = null,
  newWindow = true,
) {
  sendToPath(getSubmissionsPolicyRoute(query), newWindow)
}

export function forwardToMyAccountSettings(query = null, newWindow = false) {
  sendToPath(getMyAccountSettingsRoute(query), newWindow)
}

export function forwardToCancelReservation(query = null, newWindow = false) {
  sendToPath(getCancelReservationRoute(query), newWindow)
}

export function forwardToChangeReservation(query = null, newWindow = false) {
  sendToPath(getChangeReservationRoute(query), newWindow)
}

export function forwardToPayReservationBalance(
  query = null,
  newWindow = false,
) {
  sendToPath(getPayReservationBalanceRoute(query), newWindow)
}

export function forwardToRenewReservation(query = null, newWindow = false) {
  sendToPath(getRenewReservationRoute(query), newWindow)
}

export function forwardToChangeReservationParticipantInfo(
  query = null,
  newWindow = false,
) {
  sendToPath(getChangeReservationParticipantInfoRoute(query), newWindow)
}

export function forwardToChangeTourReservation(
  query = null,
  newWindow = false,
) {
  sendToPath(getChangeTourReservationRoute(query), newWindow)
}

export function forwardToCancelTourReservation(
  query = null,
  newWindow = false,
) {
  sendToPath(getCancelTourReservationRoute(query), newWindow)
}

export function forwardToPreRegister(query = null, newWindow = false) {
  sendToPath(getPreRegisterRoute(query), newWindow)
}

export function forwardToCareers(query = null, newWindow = false) {
  sendToPath(getCareersRoute(query), newWindow)
}
