import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

export const ANIMATE_TYPE_OPACITY = 'opacity'
export const ANIMATE_TYPE_SLIDE = 'slide'
export const ANIMATE_TYPE_EXPAND = 'expand'

export const CMP_TYPE_DIV = 'div'
export const CMP_TYPE_SPAN = 'span'
export const CMP_TYPE_UL = 'ul'
export const CMP_TYPES = [CMP_TYPE_DIV, CMP_TYPE_SPAN, CMP_TYPE_UL]

/** Used to animate the appearance or removal of a component. See https://facebook.github.io/react/docs/animation.html for more details */

const SimpleAnimate = ({
  type = ANIMATE_TYPE_OPACITY,
  transitionAppear = true,
  transitionEnter = true,
  transitionLeave = true,
  children,
  componentType,
}) => {
  const ref = useRef(null);

  const childTransitions = React.Children.map(children, (transition, idx) => (
    <CSSTransition key={idx} timeout={300} classNames={type} nodeRef={ref}>
      {transition}
    </CSSTransition>
  ))

  return (
    <TransitionGroup
      component={componentType}
      appear={transitionAppear}
      enter={transitionEnter}
      exit={transitionLeave}
    >
      {childTransitions}
    </TransitionGroup>
  )
}

SimpleAnimate.propTypes = {
  /** opacity - fade in or out, slide - slide in from the left (not fully supported by us right now), expand - expand and collapse (not fully supported by us right now) */
  type: PropTypes.oneOf(['opacity', 'slide', 'expand']),
  /** show animation on appearance */
  transitionAppear: PropTypes.bool,
  /** show animation on entering */
  transitionEnter: PropTypes.bool,
  /** show animation on leaving/removal of component */
  transitionLeave: PropTypes.bool,
  /** what to animate */
  children: PropTypes.node,
  /** a component is rendered by ReactCSSTransitionGroup - default is span */
  componentType: PropTypes.oneOf(CMP_TYPES),
}

export default SimpleAnimate
