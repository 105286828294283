import PropTypes from 'prop-types'
import React from 'react'

import Cont from './Cont'

/** Component for laying out a section of content with the ability to have different bottom margins and visibility properties on different resolutions

@example ../../../../docs/external/layout/Section.md*/

// currently bottom margin of 20
export const MAIN = 'section-main'
// currently bottom margin of 15
export const MAIN_INLINE = 'section-main-inline'
// currently bottom margin of 10
export const SUB = 'section-sub'
// currently bottom margin of 5
export const SUB_INLINE = 'section-sub-inline'
// currently top margin of 20
export const MAIN_TOP = 'section-main-top'
// currently top and bottom margin of 20
export const MAIN_TOP_BOTTOM = 'section-main-top-bottom'
// currently top margin of 15
export const MAIN_INLINE_TOP = 'section-main-inline-top'
// currently top margin of 10
export const SUB_TOP = 'section-sub-top'
// currently top margin of 5
export const SUB_INLINE_TOP = 'section-sub-inline-top'
// left margin of 10
export const SUB_BOTTOM_LEFT = 'section-sub-bottom-left'
// right margin of 10
export const SUB_BOTTOM_RIGHT = 'section-sub-bottom-right'

export const Section = ({
  style = MAIN,
  hideXS = false,
  hideSM = false,
  hideMD = false,
  hideLG = false,
  className = '',
  children,
}) => {
  const styleName = `${className} ${style}`

  return (
    <Cont
      className={styleName}
      hideXS={hideXS}
      hideSM={hideSM}
      hideMD={hideMD}
      hideLG={hideLG}
    >
      {children}
    </Cont>
  )
}

Section.propTypes = {
  // add section styles
  style: PropTypes.oneOf([
    MAIN,
    MAIN_INLINE,
    SUB,
    SUB_INLINE,
    MAIN_TOP,
    MAIN_TOP_BOTTOM,
    SUB_TOP,
    SUB_INLINE_TOP,
    SUB_BOTTOM_LEFT,
    SUB_BOTTOM_RIGHT,
  ]),
  // add class to section if really required - not recommended
  className: PropTypes.string,
  // add style to hide the component in XS devices
  hideXS: PropTypes.bool,
  // add style to hide the component in SM devices
  hideSM: PropTypes.bool,
  // add style to hide the component in MD devices
  hideMD: PropTypes.bool,
  // add style to hide the component in LG devices
  hideLG: PropTypes.bool,
  children: PropTypes.node,
}

export default Section
