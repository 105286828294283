import { isEmpty } from '../external/utilities/GeneralUtils'
import {
  forwardToLoginWithReturnPath,
  getCurrentPathFromProps,
} from '../router/RouteManager'

export function isLoggedIn(userProfile) {
  let loggedIn = false
  if (!isEmpty(userProfile) && userProfile.loggedIn) {
    loggedIn = true
  }

  return loggedIn
}

export function checkSecurity(userProfile, isLoading, props) {
  if (!isLoading && !isLoggedIn(userProfile)) {
    const route = getCurrentPathFromProps(props)
    forwardToLoginWithReturnPath(route)
  }
}
