import mixpanel from 'mixpanel-browser'

import process from '../../environment.json'
import { isEnvServer } from '../../external/utilities/GeneralServerUtils'
import { isEnvProd } from '../../utilities/AppUtils'
import { getMixpanelKey } from '../../utilities/ConfigUtils'

export const mixpenlSessionRedord = process.env.MIXPANEL_SESSION_RECORD

let ready = false
let turnOff = false

export const initMixpanel = (state) => {
  if (isEnvServer()) return

  const mainConfig = state.backend.config.main
  const brandConfig = state.backend.config.brand
  const key = getMixpanelKey(mainConfig, brandConfig.appEnvType)

  if (!key) {
    turnOff = true

    return
  }

  return new Promise((res) => {
    mixpanel.init(key, {
      debug: !isEnvProd(),
      loaded: res,
      record_sessions_percent: mixpenlSessionRedord,
    })
    ready = true
  })
}

const wrapTrackingActions = (trackingActions) => {
  const wrappedActions = {}
  Object.keys(trackingActions).forEach((key) => {
    const trackingAction = trackingActions[key]
    const wrappedAction = (...args) => {
      if (!ready) {
        if (!turnOff) {
          console.warn(`mixpanel tracking '${key}' called before init`)
        }

        return
      }
      try {
        trackingAction(...args)
      } catch (ex) {
        console.error('mixpanel tracking error:', ex)
      }
    }
    wrappedActions[key] = wrappedAction
  })

  return wrappedActions
}

const buildTrackPropsFromObj = (obj = null, name) => {
  const result = {}
  if (obj !== null) {
    Object.entries(obj).forEach(([key, value]) => {
      result[`${name}.${key}`] = value
    })
  }

  return result
}

const normalizeAgency = (data) => {
  return {
    ...data,
    agencyName:
      data.agencyName === 'S2n Agency' ? 'Spot2Nite' : data.agencyName,
  }
}

const trackingActions = {
  landingHomepage: () => mixpanel.track('landing_homepage'),
  homepageClickOnArticle: (article) =>
    mixpanel.track('homepage_click_on_article', {
      articleTitle: article.title,
      articleCategory: article.categoryDisplayName,
      articleLink: article.link,
    }),
  homepageSearch: (searchTerm, selectedItem) =>
    mixpanel.track('search_homepage', {
      searchTerm,
      ...buildTrackPropsFromObj(selectedItem, 'selectedItem'),
    }),
  homepageSelectSearchSuggestion: (selectedItem) =>
    mixpanel.track(
      'search_homepage_select_suggestion',
      buildTrackPropsFromObj(selectedItem, 'selectedItem'),
    ),
  landingFacilityFromArticle: (params) =>
    mixpanel.track('landing_facility_from_article', params),
  clickOnViewDetailsFrom: (containerUI, facility) => {
    if (containerUI !== null) {
      mixpanel.track(`click_on_view_details_from_${containerUI}`, {
        facilityID: facility.id,
        facilityName: facility.name,
        contractCode: facility.details.contractCode,
      })
    }
  },
  clickOnViewDetailsFromFacilityMapBubble: (markerData) =>
    mixpanel.track('click_on_view_details_from_facility_map_bubble', {
      facilityID: markerData.id,
      facilityName: markerData.name,
      contrCode: markerData.details.contrCode,
    }),
  searchFromHeader: (searchTerm, selectedItem) => {
    const currentUI = document.getElementById(
      'mixpanel_header_search_tracking_ui_name',
    )
    if (currentUI) {
      const trackProp = { searchTerm }
      if (selectedItem !== null) {
        Object.entries(selectedItem).forEach(([key, value]) => {
          trackProp[`selectedItem.${key}`] = value
        })
      }
      mixpanel.track(`search_from_header_on_${currentUI.value}`, trackProp)
    }
  },
  facilitySearchApplyFilter: (filterQueryObj) =>
    mixpanel.track(
      'facility_search_apply_filter',
      buildTrackPropsFromObj(filterQueryObj, 'filters'),
    ),
  facilityDetailCheckAvailability: (filterQueryObj, props) =>
    mixpanel.track('facility_detail_check_availability', {
      ...normalizeAgency(props),
      ...buildTrackPropsFromObj(filterQueryObj, 'filters'),
    }),

  facilityDetailCheckAvailabilityFailed: (filterQueryObj, props, reason) =>
    mixpanel.track('facility_detail_check_availability_failed', {
      ...normalizeAgency(props),
      ...buildTrackPropsFromObj(filterQueryObj, 'filters'),
      reason,
    }),
  facilityDetailScrollToAvailabilityFailed: (props) =>
    mixpanel.track(
      'facility_detail_scroll_to_availability',
      normalizeAgency(props),
    ),
  facilityDetailBookNow: (product) =>
    mixpanel.track('facility_detail_book_now', {
      productId: product.id,
      productName: product.name,
      prodGrpId: product.prodGrpId,
      prodGrpName: product.prodGrpName,
    }),
  siteDetailBookNow: (productParams, props = {}) =>
    mixpanel.track('site_detail_book_now', {
      ...normalizeAgency(props),
      ...buildTrackPropsFromObj(productParams, 'productParams'),
    }),
  siteDetailBookNowFailed: (productParams, props = {}) =>
    mixpanel.track('site_detail_book_now-failed', {
      ...normalizeAgency(props),
      ...buildTrackPropsFromObj(productParams, 'productParams'),
    }),
  continueToCartSuccessful: (order) => {
    const props = {
      emailId: order.emailId,
      parkId: order.parkId,
      parkName: order.parkName,
      parkState: order.parkState,
      orderType: order.orderType,
      contractCode: order.contractCode,
      contractType: order.contractType,
      agencyName: order.agencyName,
    }
    if (order.webOrder) {
      props.orderUnits = order.webOrder.units
      props.arrivalDate = order.webOrder.arrivalDate
      props.endDate = order.webOrder.endDate
      if (order.webOrder.site) {
        props.siteId = order.webOrder.site.id
      }
    }
    mixpanel.track('continue_to_cart_successful', normalizeAgency(props))
  },
  continueToCartFailed: (order) => {
    const props = {
      parkId: order.parkId,
      parkName: order.parkName,
      parkState: order.parkState,
      orderType: order.orderType,
      contractCode: order.contractCode,
      contractType: order.contractType,
      agencyName: order.agencyName,
      reason: order.reason,
    }
    if (order.webOrder) {
      props.orderUnits = order.webOrder.units
      props.arrivalDate = order.webOrder.arrivalDate
      props.endDate = order.webOrder.endDate
      if (order.webOrder.site) {
        props.siteId = order.webOrder.site.id
      }
    }
    mixpanel.track('continue_to_cart_failed', normalizeAgency(props))
  },
  paymentPostponed: (props, options) => {
    mixpanel.track('payment_postponed', normalizeAgency(props), options)
  },
  leaveCart: (props) => {
    mixpanelActions.paymentPostponed(
      { ...normalizeAgency(props), reason: 'leave' },
      { transport: 'sendBeacon' },
    )
  },
  navigateFromCart: (props) => {
    mixpanelActions.paymentPostponed({
      ...normalizeAgency(props),
      reason: 'navigate',
    })
  },
  paymentRefused: (props, options) => {
    mixpanel.track('payment_refused', normalizeAgency(props), options)
  },
  cartExpired: (props) => {
    mixpanel.track('cart_expired', normalizeAgency(props))
  },
  signOutWithCart: (props) => {
    mixpanelActions.paymentRefused(
      { ...normalizeAgency(props), reason: 'sign_out' },
      { transport: 'sendBeacon' },
    )
  },
  abandonCart: (props) => {
    mixpanelActions.paymentRefused({
      ...normalizeAgency(props),
      reason: 'cart_abandoned',
    })
  },
  makePayment: () => mixpanel.track('make_payment'),
  completePayment: (props) => {
    mixpanel.track('complete_payment', normalizeAgency(props))
  },
  failedPayment: (props) => {
    mixpanel.track('failed_payment', normalizeAgency(props))
  },
  clickSignIn: () => mixpanel.track('sign_in_initiated'),
  changeMapCoordinatesAndUpdate: () =>
    mixpanel.track('change_map_coordinates_and_update'),
  successfulSignIn: (emailAddress) => {
    mixpanel.identify(emailAddress)
    mixpanel.track('Login')
  },
  successfulCreateAcct: (emailAddress, address) => {
    mixpanel.identify(emailAddress)
    mixpanel.people.set({
      Email: emailAddress,
      City: address.city,
      State: address.stateProvinceCode,
      ZIP: address.zipCode,
    })
    mixpanel.track('create_account_submission', {
      Email: emailAddress,
      City: address.city,
      State: address.stateProvinceCode,
      ZIP: address.zipCode,
    })
  },
  facilityPageLoad: (props) => {
    mixpanel.track('open_facility_page', normalizeAgency(props))
  },
  nearbyFacilityOnClick: (props) => {
    mixpanel.track('nearby_park_click', normalizeAgency(props))
  },
  facilityExtGetDetailsClick: (props) => {
    mixpanel.track(
      'facility_ext_check_availability_click',
      normalizeAgency(props),
    )
  },
  searchInSearchBar: (searchString, searchTerm, pageName) => {
    mixpanel.track('search_in_searchbar', {
      searchString,
      searchTerm,
      pageName,
    })
  },
  addonsDisplayed: (componentTrackId, props) => {
    mixpanel.track(componentTrackId, normalizeAgency(props))
  },
  addonsClosed: (componentTrackId, props) => {
    mixpanel.track(componentTrackId, normalizeAgency(props))
  },
  offerPresented: (props) => {
    mixpanel.track('Offer_presented', normalizeAgency(props))
  },
  offerPresentingFailed: (props) => {
    mixpanel.track('Offer_presenting_failed', normalizeAgency(props))
  },
  offerAdded: (props) => {
    mixpanel.track('Added_Offer', normalizeAgency(props))
  },
  offerRemoved: (props) => {
    mixpanel.track('Removed_Offer', normalizeAgency(props))
  },
  viewSitePhotos: (props) => {
    mixpanel.track('view_site_photos', normalizeAgency(props))
  },
  viewFacilityPhotos: (props) => {
    mixpanel.track('view_facility_photos', normalizeAgency(props))
  },
  accordionExpanded: (props) => {
    mixpanel.track('accordion_expanded', normalizeAgency(props))
  },
  accordionCollapsed: (props) => {
    mixpanel.track('accordion_collapsed', normalizeAgency(props))
  },
  signInInitiated: (data) => {
    mixpanel.track('sign_in_initiated', normalizeAgency(data))
  },
  createAccountSelected: (data) => {
    mixpanel.track('create_account_selected', normalizeAgency(data))
  },
  forgotPassword: (data) => {
    mixpanel.track('forgot_password', normalizeAgency(data))
  },
  campgroundMapView: (data) => {
    mixpanel.track('campground_map_view', normalizeAgency(data))
  },
  categorySiteSearchAvailability: (data) => {
    mixpanel.track('category_site_search_availability', normalizeAgency(data))
  },
  searchResultsView: (data) => {
    mixpanel.track('search_results_view', { searchTerm: data })
  },
  mainNavigationArticles: () => {
    mixpanel.track('main_nav_articles')
  },
  paymentFallback: (cartContracts) => {
    const contracts = cartContracts.map(
      (data) => data.cartOrderViews[0]?.contractCode,
    )
    const agencyNames = cartContracts.map(
      (data) => data.cartOrderViews[0]?.agencyName,
    )
    const param = {
      contracts,
      agencyNames,
    }
    mixpanel.track('payment_fallback', param)
  },
}

const mixpanelActions = wrapTrackingActions(trackingActions)

export default mixpanelActions
