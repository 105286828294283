import { combineReducers } from 'redux'

import { default as application } from './Application'
import { default as backend } from './Backend'
import { default as cardvault } from './CardVault'
import { default as chubAvailabilityForm } from './ChubAvailabilityForm'

const rootReducer = combineReducers({
  application,
  backend,
  cardvault,
  chubAvailabilityForm,
})

export default rootReducer
