import { array, bool, func, number, object, string } from 'prop-types'
import React from 'react'

import { isEmpty, isUndefined } from '../../../external/utilities/GeneralUtils'
import { getSearchResultName } from '../../../utilities/SearchUtils'
import Attribution from '../legend/Attribution'

const SearchResults = ({
  intl,
  onSearchResultItemSelectRequest,
  allQuickSearchResults,
  allQuickSearchResultsAttribs,
  selectedIndex,
  show,
  cmpRef,
  searchString,
  isSearching,
}) => {
  const handleItemSelect = function (searchResult) {
    searchResult.searchString = searchString
    onSearchResultItemSelectRequest(searchResult)
  }

  const insertAttribAtEnd = false

  const getAttribUI = function (currIndex, isInsertAtEnd = true) {
    let attribUI = null
    let attrib = null
    let shouldInsert = false
    if (
      isUndefined(allQuickSearchResultsAttribs) ||
      isEmpty(allQuickSearchResultsAttribs)
    ) {
      return attribUI
    }

    const currSearchResult = allQuickSearchResults[currIndex]
    const nextSearchResult =
      currIndex + 1 === allQuickSearchResults.length
        ? null
        : allQuickSearchResults[currIndex + 1]
    const previousSearchResult =
      currIndex === 0 ? null : allQuickSearchResults[currIndex - 1]

    if (isInsertAtEnd) {
      if (nextSearchResult === null) {
        shouldInsert = true
      } else if (
        nextSearchResult !== null &&
        currSearchResult.type !== nextSearchResult.type
      ) {
        shouldInsert = true
      }
    } else if (previousSearchResult === null) {
      shouldInsert = true
    } else if (
      previousSearchResult !== null &&
      currSearchResult.type !== previousSearchResult.type
    ) {
      shouldInsert = true
    }

    if (shouldInsert) {
      for (const currAttrib of allQuickSearchResultsAttribs) {
        if (currSearchResult.type === currAttrib.type) {
          attrib = currAttrib
          break
        }
      }
    }

    if (shouldInsert && attrib !== null) {
      attribUI = (
        <div className={'search-result-attrib'}>
          <Attribution intl={intl} attrib={attrib} />
        </div>
      )
    }

    return attribUI
  }

  let searchResultsList = ''

  let currentType

  if (!isEmpty(allQuickSearchResults)) {
    searchResultsList = allQuickSearchResults.map((searchResult, i) => {
      let newType

      const resultName = getSearchResultName(searchResult)
      let styleName = `searchResult ${searchResult.type}`

      if (i === selectedIndex) {
        styleName = `searchResult active ${searchResult.type}`
      }
      if (!currentType || currentType !== searchResult.type) {
        currentType = searchResult.type
        newType = true
      } else {
        newType = false
      }

      let attribUITop = null
      let attribUIBottom = null
      if (insertAttribAtEnd) {
        attribUIBottom = getAttribUI(i, insertAttribAtEnd)
      } else {
        attribUITop = getAttribUI(i, insertAttribAtEnd)
      }

      if (newType) {
        return (
          <div key={i}>
            {/* <div className={'search-result-section--' + searchResult.type} /> */}
            {attribUITop}
            <div
              className={styleName}
              onClick={() => handleItemSelect(searchResult)}
              data-ref={`${cmpRef}-${i}`}
            >
              {resultName}
            </div>
            {attribUIBottom}
          </div>
        )
      }

      return (
        <div key={i}>
          {attribUITop}
          <div
            className={styleName}
            onClick={() => handleItemSelect(searchResult)}
            data-ref={`${cmpRef}-${i}`}
          >
            {resultName}
          </div>
          {attribUIBottom}
        </div>
      )
    })
  }

  const styleClass = isEmpty(allQuickSearchResults)
    ? 'searchbar__results'
    : 'searchbar__results searchbar__results--active'

  if (show) {
    return (
      <div className={styleClass}>
        <div className="searchResults">{searchResultsList}</div>
      </div>
    )
  }

  return <div />
}

SearchResults.propTypes = {
  intl: object.isRequired,
  onSearchResultItemSelectRequest: func.isRequired,
  allQuickSearchResults: array.isRequired,
  allQuickSearchResultsAttribs: array.isRequired,
  selectedIndex: number.isRequired,
  show: bool.isRequired,
  cmpRef: string,
  isSearching: bool,
  searchString: string,
}

export default SearchResults
