import { func, number } from 'prop-types'
import React, { Component } from 'react'

// Generic Countdown Timer UI component
//
// https://github.com/uken/react-countdown-timer
//
// props:
//   - initialTimeRemaining: Number
//       The time remaining for the countdown (in ms).
//
//   - interval: Number (optional -- default: 1000ms)
//       The time between timer ticks (in ms).
//
//   - formatFunc(timeRemaining): Function (optional)
//       A function that formats the timeRemaining.
//
//   - tickCallback(timeRemaining): Function (optional)
//       A function to call each tick.
//
//   - completeCallback(): Function (optional)
//       A function to call when the countdown completes.
//

class CountdownTimer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      timeRemaining: props.initialTimeRemaining,
      timeoutId: null,
      prevTime: null,
      txnTimeStamp: props.txnTimeStamp,
      mounted: false,
    }
  }

  getInitialState() {
    // Normally an anti-pattern to use this.props in getInitialState,
    // but these are all initializations (not an anti-pattern).
    return {
      timeRemaining: this.props.initialTimeRemaining,
      timeoutId: null,
      prevTime: null,
      txnTimeStamp: this.props.txnTimeStamp,
    }
  }

  componentDidMount() {
    this.setState({
      mounted: true,
    })
    this.tick()
  }

  componentWillUnmount() {
    clearTimeout(this.state.timeoutId)
  }

  componentDidUpdate(prevProps) {
    const { txnTimeStamp, initialTimeRemaining } = this.props
    if (
      !this.state.prevTime &&
      this.state.timeRemaining > 0 &&
      this.state.mounted
    ) {
      this.tick()
    }

    if (
      txnTimeStamp !== prevProps.txnTimeStamp &&
      txnTimeStamp !== this.state.txnTimeStamp
    ) {
      if (this.state.timeoutId) {
        clearTimeout(this.state.timeoutId)
      }
      this.setState({
        prevTime: null,
        timeRemaining: initialTimeRemaining,
        txnTimeStamp,
      })
    }
  }

  tick() {
    const currentTime = Date.now()
    const dt = this.state.prevTime ? currentTime - this.state.prevTime : 0
    const interval = this.props.interval

    const timeRemaining = Math.max(this.state.timeRemaining - dt, 0)
    const countdownComplete = this.state.prevTime && timeRemaining <= 0

    if (this.state.mounted) {
      if (this.state.timeoutId) {
        clearTimeout(this.state.timeoutId)
      }

      this.setState({
        ...this.state,
        timeoutId: countdownComplete
          ? null
          : setTimeout(this.tick.bind(this), interval),
        prevTime: currentTime,
        timeRemaining,
      })
    }

    if (countdownComplete) {
      if (this.props.completeCallback) {
        this.props.completeCallback()
      }

      return
    }

    if (this.props.tickCallback) {
      this.props.tickCallback(timeRemaining, this.props.txnTimeStamp)
    }
  }

  getFormattedTime(milliseconds) {
    if (this.props.formatFunc) {
      return this.props.formatFunc(milliseconds)
    }

    const totalSeconds = Math.round(milliseconds / 1000)

    let seconds = parseInt(totalSeconds % 60, 10)
    let minutes = parseInt(totalSeconds / 60, 10) % 60
    let hours = parseInt(totalSeconds / 3600, 10)

    seconds = seconds < 10 ? `0${seconds}` : seconds
    minutes = minutes < 10 ? `0${minutes}` : minutes
    hours = hours < 10 ? `0${hours}` : hours

    return `${hours}:${minutes}:${seconds}`
  }

  render() {
    const timeRemaining = this.state.timeRemaining

    return <div className="timer">{this.getFormattedTime(timeRemaining)}</div>
  }

  static propTypes = {
    initialTimeRemaining: number.isRequired,
    interval: number,
    formatFunc: func,
    tickCallback: func,
    completeCallback: func,
    txnTimeStamp: number,
  }

  static defaultProps = {
    interval: 1000,
    formatFunc: null,
    tickCallback: null,
    completeCallback: null,
  }
}

export default CountdownTimer
