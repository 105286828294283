import { useCallback, useEffect, useRef } from 'react'

export function useClickOutside({ onClickOutside }) {
  const handlerRef = useRef()
  const containerRef = useRef()

  handlerRef.current = onClickOutside

  const onWindowClick = useCallback(({ target }) => {
    if (
      containerRef.current?.contains(target) ||
      target === containerRef.current
    )
      return

    handlerRef.current?.()
  }, [])

  useEffect(() => {
    window.addEventListener('click', onWindowClick)

    return () => {
      window.removeEventListener('click', onWindowClick)
    }
  }, [onWindowClick])

  return { containerRef }
}
