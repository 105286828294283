import { getShowLegacySiteLink } from '../utilities/ConfigUtils'
import {
  getIsWebSearchProviderSourceInternal,
  getMatchingContracts,
} from '../utilities/SearchUtils'

export const selectBackendConfig = (state) => state.backend.config

export const selectContractConfig = (state) =>
  selectBackendConfig(state)?.contract

export const selectCountries = (state) =>
  selectBackendConfig(state)?.countryStateList.countryList || []

export const selectCountryData = (state, countryCode) => {
  return selectCountries(state).find(
    (country) => country.countryCode === countryCode,
  )
}

export const selectCountryStates = (state, countryCode) => {
  const country = selectCountryData(state, countryCode)

  return (country && country.states) || []
}

export const selectCountryTitle = (state, countryCode) => {
  const country = selectCountryData(state, countryCode)

  return country && country.countryName
}

export const selectStateTitle = (state, countryCode, stateCode) => {
  const country = selectCountryData(state, countryCode)

  const states = country && country.states

  if (!states) return stateCode

  const countryState = states.find((state) => state.stateCode === stateCode)

  return countryState && countryState.stateName
}

export const selectHasCountries = (state) => selectCountries(state).length > 0

export const selectBrandConfig = (state) => selectBackendConfig(state)?.brand

export const selectBrandStates = (state) =>
  selectBrandConfig(state)?.states || []

export const selectBrandContractsByTerm = (state, searchTerm) => {
  const states = selectBrandStates(state)

  return getMatchingContracts(searchTerm, states)
}

export const selectBrandSuggestions = (state) =>
  selectBrandConfig(state)?.suggestionsCfg

export const selectCountriesSuggestions = (state) =>
  selectBrandSuggestions(state)?.countries

export const selectMainConfig = (state) => selectBackendConfig(state)?.main

export const selectMainSearchConfig = (state) =>
  selectMainConfig(state)?.mainSearch

export const selectIsWebSearchProviderSourceInternal = (state) => {
  const mainSearchConfig = selectMainSearchConfig(state)

  return getIsWebSearchProviderSourceInternal(mainSearchConfig)
}

export const selectConfirmationFeedbackDelay = (state) => {
  return selectMainConfig(state)?.cart?.confirmationFeedbackDelay
}

export const selectConfirmationPostConfig = (state) => {
  return selectMainConfig(state)?.cart?.confirmationPostConfig
}

export const selectComponentCloseTrackId = (state) => {
  return selectConfirmationPostConfig(state)?.componentCloseTrackId
}

export const selectComponentTrackId = (state) => {
  return selectConfirmationPostConfig(state)?.componentTrackId
}

export const selectAddonsBackdropMode = (state) => {
  return (
    selectConfirmationPostConfig(state)?.addonsAllowCloseBGClick || 'static'
  )
}

export const selectAddonsCloseAllowed = (state) => {
  return selectConfirmationPostConfig(state)?.addonsAllowClose
}

export const selectAddonsDefaultHeight = (state) => {
  return selectConfirmationPostConfig(state)?.addonsDefaultHeight ?? 300
}

export const selectAddonsVisible = (state) => {
  return !!selectConfirmationPostConfig(state)
}

export const selectAddonsMinDays = (state) => {
  return selectConfirmationPostConfig(state)?.weatherInsuranceMinWindow || 0
}

export const selectLegacyLinkShown = (state) =>
  getShowLegacySiteLink(selectMainConfig(state))

export const selectHomeConfig = (state) => selectBackendConfig(state)?.home

export const selectHomeSubMenuItems = (state) =>
  selectHomeConfig(state)?.mainSubMenuOptions?.items || []

export const selectHomeMobileSubMenuItems = (state) =>
  selectHomeSubMenuItems(state).filter(
    ({ showInMobileMenu }) => showInMobileMenu,
  )
