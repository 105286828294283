import PropTypes from 'prop-types'
import React from 'react'

/** Custom container with a header, body, footer and border

@example ../../../../docs/external/container/BoxContainer.md */

export const BACKGROUND_COLOR = {
  HIGHLIGHT_DANGER: 'highlight-danger',
  HIGHLIGHT_IMPORTANT: 'highlight-important',
  HIGHLIGHT_NEUTRAL: 'highlight-neutral',
  HIGHLIGHT_SUCCESS: 'highlight-success',
  HIGHLIGHT_WARNING: 'highlight-warning',
  HIGHLIGHT_NOTIFICATION: 'highlight-notification',
}

const BoxContainer = ({
  header,
  footer,
  borderColor = 'grey',
  children,
  titleBackgroundColor = BACKGROUND_COLOR.HIGHLIGHT_NOTIFICATION,
  footnoteBackgroundColor = BACKGROUND_COLOR.HIGHLIGHT_NOTIFICATION,
  encapsulateContent = true,
}) => {
  const getFooterContent = function () {
    let footerContent = null
    if (typeof footer !== 'undefined' && footer !== null) {
      footerContent = (
        <div
          className={`footnote content-box__border--${borderColor} ${footnoteBackgroundColor}`}
        >
          {footer}
        </div>
      )
    }

    return footerContent
  }
  const getHeaderContent = function () {
    let headerContent = null
    if (typeof header !== 'undefined') {
      headerContent = (
        <div
          className={`title content-box__border--${borderColor} ${titleBackgroundColor}`}
        >
          {header}
        </div>
      )
    }

    return headerContent
  }

  const encapsulateStyle = encapsulateContent ? 'encapsulate' : ''

  return (
    <div className={'content-box'}>
      {getHeaderContent()}
      <div
        className={`content content-box__border--${borderColor} ${encapsulateStyle}`}
      >
        {children}
      </div>
      {getFooterContent()}
    </div>
  )
}

BoxContainer.propTypes = {
  /** header for container */
  header: PropTypes.element,
  /** footer for container */
  footer: PropTypes.element,
  /** border colour, default is grey */
  borderColor: PropTypes.oneOf(['grey', 'grey-light', 'grey-medium']),
  /** body of the container */
  children: PropTypes.node,
  /** header background colour, default is #f5f5f5 */
  titleBackgroundColor: PropTypes.oneOf(
    Object.keys(BACKGROUND_COLOR).map((key) => BACKGROUND_COLOR[key]),
  ),
  /** footer background colour, default is #f5f5f5 */
  footnoteBackgroundColor: PropTypes.oneOf(
    Object.keys(BACKGROUND_COLOR).map((key) => BACKGROUND_COLOR[key]),
  ),
  /** prevent main content from overflowing - false when using widgets like date picker as they need to be visible outside box */
  encapsulateContent: PropTypes.bool,
}

export default BoxContainer
