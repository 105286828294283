/**
 * @file   : SimpleContainer.js
 * @author : Amarjeet Lamba (Amarjeet.Lamba@AspiraConnect.com)
 * @date   : 10/26/2018, 3:22:03 PM
 */
import PropTypes from 'prop-types'
import React from 'react'

export const STYLE_DEFAULT = 'simple-container-default'
export const STYLE_PRIMARY = 'simple-container-primary'
export const STYLES = [STYLE_DEFAULT, STYLE_PRIMARY]

// eslint-disable-next-line one-var
const SimpleContainer = ({ children, style = STYLE_DEFAULT }) => {
  const styleClass = `simple-container ${style}`

  return <div className={styleClass}>{children}</div>
}

SimpleContainer.propTypes = {
  children: PropTypes.element,
  style: PropTypes.oneOf(STYLES),
}

export default SimpleContainer
