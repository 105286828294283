import PropTypes from 'prop-types'
import React from 'react'

import ABaseLink from '../../link/ABaseLink'

/** MenuLink - can't have a submenu.  Used for simple menu redirects

@example  ../../../../../docs/external/navigation/menu/MenuLink.md */

const MenuLink = ({
  className = 'menu-link__container',
  iconClassName = 'menu-link__icon',
  labelClassName = 'menu-link__text',
  onClick,
  icon,
  label,
  to,
  newWin,
  internal,
  cmpRel = null,
}) => {
  let localIcon = null
  if (typeof icon !== 'undefined') {
    localIcon = <div className={iconClassName}>{icon}</div>
  }

  return (
    <ABaseLink
      cmpRel={cmpRel}
      onClick={onClick}
      to={to}
      internal={internal}
      newWin={newWin}
    >
      <div className={className}>
        {localIcon}
        <div className={labelClassName}>{label}</div>
      </div>
    </ABaseLink>
  )
}

MenuLink.propTypes = {
  /** wrapper classname for component for custom styling */
  className: PropTypes.string,
  /** wrapper classname for label for custom styling */
  labelClassName: PropTypes.string,
  /** link label */
  label: PropTypes.string,
  /** wrapper classname for icon for custom styling */
  iconClassName: PropTypes.string,
  /** icon placed to the left of the label */
  icon: PropTypes.object,
  /** function to run when link is clicked */
  onClick: PropTypes.func,
  to: PropTypes.string,
  newWin: PropTypes.bool,
  internal: PropTypes.bool,
  /** rel attribute */
  cmpRel: PropTypes.arrayOf(PropTypes.string),
}

export default MenuLink
