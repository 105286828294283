import PropTypes from 'prop-types'
import React from 'react'

import { isUndefined } from '../../utilities/GeneralUtils'
import Cont from '../layout/Cont'
import ABaseLink from './ABaseLink'

/** Component used for text that has an orange bar to the left of it when hoevered or selected.  Can also be clickable/a link

@example ../../../../docs/external/link/TextHoverable.md*/

export const BACKGROUND_COLOR = {
  NONE: 'none',
  BLUELIGHT: 'bluelight',
  BLUEMUTED: 'bluemuted',
  BLUE80: 'blue80',
  BLUE90: 'blue90',
  BLUEDARK: 'bluedark',
}

const TextHoverable = ({
  backgroundColor = 'none',
  fontSizeType,
  fontFamily = 'Georgia',
  fontColor = 'blue-dark',
  fontWeight = 400,
  lineClamp,
  className = '',
  selected,
  onClick,
  children,
  alignText = false,
  asSpan = false,

  to,
  newWin,
  internal,
  cmpLabel,
  cmpTitle,
  tabIndex,
  cmpRel,
  stopClickEventPropogation,
  setNoOpener = true,
  setNoReferrer = true,
}) => {
  let localClassName = `hoverable-text ${className}`
  localClassName += ` hoverable-text--font-size-${fontSizeType}`
  localClassName += ` font-family-${fontFamily}`
  localClassName += ` ${fontColor}-text`
  localClassName += ` font-weight-${fontWeight}`
  localClassName += selected ? ' hoverable-text--selected' : ''
  localClassName +=
    typeof onClick === 'undefined' && typeof to === 'undefined'
      ? ' hoverable-text--default-cursor'
      : ''
  localClassName += alignText ? ' hoverable-text--align-text' : ''
  localClassName += ` hoverable-bgcolor-${backgroundColor}`
  localClassName += backgroundColor === 'none' ? '' : ' hoverable-bgstyle'
  localClassName += lineClamp
    ? ` hoverable-text--ellipsis hoverable-text--line-clamp-${lineClamp}`
    : ''

  const childDiv = (
    <Cont className={localClassName} asSpan={asSpan}>
      {children}
    </Cont>
  )

  if (!isUndefined(onClick) || !isUndefined(to)) {
    return (
      <ABaseLink
        onClick={onClick}
        to={to}
        newWin={newWin}
        internal={internal}
        cmpLabel={cmpLabel}
        cmpTitle={cmpTitle}
        tabIndex={tabIndex}
        cmpRel={cmpRel}
        stopClickEventPropogation={stopClickEventPropogation}
        setNoOpener={setNoOpener}
        setNoReferrer={setNoReferrer}
      >
        {childDiv}
      </ABaseLink>
    )
  }

  return childDiv
}

TextHoverable.propTypes = {
  /** number of lines to clamp the text to */
  lineClamp: PropTypes.oneOf([1, 2]),
  /** see variables.less for different sizes */
  fontSizeType: PropTypes.oneOf([1, 2, 3, 4]).isRequired,
  /** background color to use for text, default is none */
  backgroundColor: PropTypes.oneOf(
    Object.keys(BACKGROUND_COLOR).map((key) => BACKGROUND_COLOR[key]),
  ),
  /** font family to use for text, default is Georgia */
  fontFamily: PropTypes.oneOf(['Georgia', 'Figtree']),
  /** font colour to use for text, default is blue-dark */
  fontColor: PropTypes.oneOf([
    'blue-dark',
    'white',
    'orange',
    'grey',
    'green',
    'cerulean',
  ]),
  /** font weight to use for text, default is 400 */
  fontWeight: PropTypes.oneOf([
    '100',
    '200',
    '300',
    '400',
    '500',
    '600',
    '700',
    '800',
    '900',
  ]),
  /** wrapper classname for text for custom styles */
  className: PropTypes.string,
  /** if selectted, orange bar will always appear to the left */
  selected: PropTypes.bool,
  /** function to run when text is clicked.  If nothing is passed in, text is not a link */
  onClick: PropTypes.func,
  /** text/react element(s) to display */
  children: PropTypes.node,
  /** if true, adds a negative left margin so that the text lines up with the text below it and the orange bar is in the gutter, if false, orange bar lines up with the text below it */
  alignText: PropTypes.bool,
  /** use a span instead of a div to wrap the children */
  asSpan: PropTypes.bool,

  /** url for the link */
  to: PropTypes.string,
  /** should the link open in a new window - overrides cmpTarget */
  newWin: PropTypes.bool,
  /** is link internal - if yes then no server request will be sent - link will be opened by single page app */
  internal: PropTypes.bool,
  /** alt text for link */
  cmpLabel: PropTypes.string,
  /** title for link */
  cmpTitle: PropTypes.string,
  /** tab index for contents */
  tabIndex: PropTypes.number,
  /** rel attribute */
  cmpRel: PropTypes.arrayOf(PropTypes.string),
  /** should click event propogtion be stopped */
  stopClickEventPropogation: PropTypes.bool,
  /** hide 'noopener' rel attribute */
  setNoOpener: PropTypes.bool,
  /** hide 'noreferrer' rel attribute */
  setNoReferrer: PropTypes.bool,
}

export default TextHoverable
