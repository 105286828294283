/* eslint-disable no-negated-condition */
import React from 'react'

import Cont from '../../../external/components/layout/Cont'
import ABaseLink from '../../../external/components/link/ABaseLink'
import { isEmpty } from '../../../external/utilities/GeneralUtils'
import { simpleTranslate } from '../../../external/utilities/TranslationUtils'

export const getTranslation = function (intl, token, defaultMsg = '') {
  let trans = defaultMsg
  if (!isEmpty(intl)) {
    trans = simpleTranslate(intl, token, defaultMsg)
  }

  return trans
}

export const shouldUseHouseAd = function (adObj = null, pageMetaData = null) {
  let shouldUse = false
  if (!isEmpty(adObj) && !isEmpty(adObj.houseAd) && adObj.houseAd.active) {
    if (!isEmpty(adObj.houseAd.targeting)) {
      const { targeting } = adObj.houseAd
      if (!isEmpty(pageMetaData)) {
        let notMatch = 0
        for (const [key] of Object.entries(targeting)) {
          // eslint-disable-next-line max-depth
          if (targeting[key] !== pageMetaData[key]) {
            notMatch += 1
          }
        }
        if (notMatch === 0) {
          shouldUse = true
        }
      }
    } else {
      shouldUse = true
    }
  }

  return shouldUse
}

export const getAdHideConfig = function (adSize, adObj) {
  const hideObj = {
    LG: false,
    MD: false,
    SM: false,
    XS: false,
  }

  // return hideObj

  if (adSize === 'LG') {
    if (!adObj.houseAd.sizeMD.usePreviousSize) {
      hideObj.MD = true
      hideObj.SM = true
      hideObj.XS = true
    } else if (!adObj.houseAd.sizeSM.usePreviousSize) {
      hideObj.SM = true
      hideObj.XS = true
    } else if (!adObj.houseAd.sizeXS.usePreviousSize) {
      hideObj.XS = true
    }
  } else if (adSize === 'MD') {
    hideObj.LG = true
    if (!adObj.houseAd.sizeSM.usePreviousSize) {
      hideObj.SM = true
      hideObj.XS = true
    } else if (!adObj.houseAd.sizeXS.usePreviousSize) {
      hideObj.XS = true
    }
  } else if (adSize === 'SM') {
    hideObj.LG = true
    hideObj.MD = true
    if (!adObj.houseAd.sizeXS.usePreviousSize) {
      hideObj.XS = true
    }
  } else if (adSize === 'XS') {
    hideObj.LG = true
    hideObj.MD = true
    hideObj.SM = true
  }

  //   console.log('getHideConfig', adSize, hideObj)

  return hideObj
}

export const getHouseAd = function (adObj, intl) {
  const houseAdConfig = adObj.houseAd
  const center = {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  }
  const altText = getTranslation(
    intl,
    houseAdConfig.altText.token,
    houseAdConfig.altText.default,
  )

  let lgUI = null
  let mdUI = null
  let smUI = null
  let xsUI = null
  let currHideConfig = null
  let centerStyle = null

  currHideConfig = getAdHideConfig('LG', adObj)
  centerStyle = houseAdConfig.sizeLG.center ? center : null
  lgUI = (
    <Cont
      hideLG={currHideConfig.LG}
      hideMD={currHideConfig.MD}
      hideSM={currHideConfig.SM}
      hideXS={currHideConfig.XS}
    >
      <img
        style={centerStyle}
        src={houseAdConfig.sizeLG.imageURL}
        title={altText}
        alt={altText}
        width={houseAdConfig.sizeLG.dimen[0]}
        height={houseAdConfig.sizeLG.dimen[1]}
      ></img>
    </Cont>
  )

  if (!houseAdConfig.sizeMD.usePreviousSize) {
    currHideConfig = getAdHideConfig('MD', adObj)
    centerStyle = houseAdConfig.sizeMD.center ? center : null
    mdUI = (
      <Cont
        hideLG={currHideConfig.LG}
        hideMD={currHideConfig.MD}
        hideSM={currHideConfig.SM}
        hideXS={currHideConfig.XS}
      >
        <img
          style={centerStyle}
          src={houseAdConfig.sizeMD.imageURL}
          title={altText}
          alt={altText}
          width={houseAdConfig.sizeMD.dimen[0]}
          height={houseAdConfig.sizeMD.dimen[1]}
        ></img>
      </Cont>
    )
  }

  if (!houseAdConfig.sizeSM.usePreviousSize) {
    currHideConfig = getAdHideConfig('SM', adObj)
    centerStyle = houseAdConfig.sizeSM.center ? center : null
    smUI = (
      <Cont
        hideLG={currHideConfig.LG}
        hideMD={currHideConfig.MD}
        hideSM={currHideConfig.SM}
        hideXS={currHideConfig.XS}
      >
        <img
          style={centerStyle}
          src={houseAdConfig.sizeSM.imageURL}
          title={altText}
          alt={altText}
          width={houseAdConfig.sizeSM.dimen[0]}
          height={houseAdConfig.sizeSM.dimen[1]}
        ></img>
      </Cont>
    )
  }

  if (!houseAdConfig.sizeXS.usePreviousSize) {
    currHideConfig = getAdHideConfig('XS', adObj)
    centerStyle = houseAdConfig.sizeXS.center ? center : null
    xsUI = (
      <Cont
        hideLG={currHideConfig.LG}
        hideMD={currHideConfig.MD}
        hideSM={currHideConfig.SM}
        hideXS={currHideConfig.XS}
      >
        <img
          style={centerStyle}
          src={houseAdConfig.sizeXS.imageURL}
          title={altText}
          alt={altText}
          width={houseAdConfig.sizeXS.dimen[0]}
          height={houseAdConfig.sizeXS.dimen[1]}
        ></img>
      </Cont>
    )
  }

  return (
    <Cont>
      <ABaseLink
        to={houseAdConfig.link.url}
        newWin={houseAdConfig.link.newWindow}
        internal={houseAdConfig.link.linkInternal}
      >
        {lgUI}
        {mdUI}
        {smUI}
        {xsUI}
      </ABaseLink>
    </Cont>
  )
}
