import axios from 'axios'

import {
  ABANDON_CART,
  CANCEL_CHANGE_RESERVATION,
  CHUB_CHECKOUT,
  CHUB_REMOVE_ITEM_FROM_CART,
  CONFIRM_CHANGE_RESERVATION,
  PAY_RESERVATION,
  PROCESS_CART,
} from '../../../actions/ActionTypes'
import { isString } from '../../../external/utilities/GeneralUtils'
import { selectCartMixpanelPayload } from '../../../selectors/cart'
import braze from '../../../thirdParty/braze'
import mixpanel from '../../../thirdParty/mixpanel'
import {
  getAxiosConfig,
  getErrorMessage,
  getErrorMessageToUser,
  receiveData,
  receiveError,
  requestData,
  root,
  rootChub,
  sendToError,
} from '../../../utilities/BackendUtils'
import { ACTIONS } from '../../../utilities/HumanVerificationActions'
import { CheckoutResponseHandler } from './CheckoutResponseHandler'

export function chubCheckout(
  cartId,
  { currentCart, chubCart, availableSWQuotes, ...data },
  humanVerificationToken = '',
  humanVerificationTokenV3 = '',
  humanVerificationActionV3 = ACTIONS.PROCESS_CART,
) {
  return async (dispatch) => {
    dispatch(requestData(CHUB_CHECKOUT))

    CheckoutResponseHandler.inject({
      dispatch,
      availableSWQuotes,
      currentCart,
      chubCart,
    })

    try {
      const { data: response } = await axios(
        getAxiosConfig(
          'post',
          `${rootChub}/cart/${cartId}/checkout`,
          {},
          data,
          humanVerificationToken,
          humanVerificationTokenV3,
          humanVerificationActionV3,
        ),
      )

      CheckoutResponseHandler.handle(response)
    } catch (err) {
      let uiError = err

      const body =
        err?.response?.data?.data?.[0]?.paymentResponse?.api_response?.body
      if (isString(body)) {
        const jsonBody = JSON.parse(body)
        uiError = jsonBody
      }

      CheckoutResponseHandler.handleUnknownFailure(uiError)
    }
  }
}

export function chubCancelReservation({ reservation, ...data }) {
  return async (dispatch) => {
    dispatch(requestData(CHUB_CHECKOUT))
    const { id, stateCode, type } = reservation
    try {
      const { data: response } = await axios(
        getAxiosConfig(
          'delete',
          `${rootChub}/partner/reservation/a1/${id}/cancel/confirm?rtp=${type}&contractCode=${stateCode}`,
          {},
          data,
        ),
      )
      dispatch(receiveData([response], CANCEL_CHANGE_RESERVATION))
    } catch (err) {
      const status = err?.status
      const reason = getErrorMessage(err)
      const errorFaults = getErrorMessageToUser(err)
      mixpanel.failedPayment({ reason, status, errorFaults })
      dispatch(receiveError(err.data))
    }
  }
}

export function chubPayReservation({ reservation, ...data }) {
  return async (dispatch) => {
    dispatch(requestData(CHUB_CHECKOUT))

    const { id, stateCode, type } = reservation

    try {
      const { data: response } = await axios(
        getAxiosConfig(
          'post',
          `${rootChub}/partner/reservation/a1/${id}/paybalance/confirm?rtp=${type}&contractCode=${stateCode}`,
          {},
          data,
        ),
      )

      dispatch(receiveData([response], PAY_RESERVATION))
    } catch (err) {
      const status = err?.status
      const reason = getErrorMessage(err)
      const errorFaults = getErrorMessageToUser(err)

      mixpanel.failedPayment({ reason, status, errorFaults })
      dispatch(receiveError(err.data))
    }
  }
}

export function chubUpdateReservation({ reservation, ...data }) {
  return async (dispatch) => {
    dispatch(requestData(CHUB_CHECKOUT))

    const { id, stateCode, type } = reservation

    try {
      const { data: response } = await axios(
        getAxiosConfig(
          'post',
          `${rootChub}/partner/reservation/a1/${id}/change/confirm?rtp=${type}&contractCode=${stateCode}`,
          {},
          data,
        ),
      )

      dispatch(receiveData([response], CONFIRM_CHANGE_RESERVATION))
    } catch (err) {
      const status = err?.status
      const reason = getErrorMessage(err)
      const errorFaults = getErrorMessageToUser(err)

      mixpanel.failedPayment({ reason, status, errorFaults })
      dispatch(receiveError(err.data))
    }
  }
}

export function processCart(
  paymentFormObject,
  isResolving = false,
  userChallengeVerificationToken = null,
  humanVerificationTokenV3 = '',
  humanVerificationActionV3 = '',
) {
  return async (dispatch, getState) => {
    dispatch(requestData(PROCESS_CART))

    const eventPayload = selectCartMixpanelPayload(getState())

    try {
      const { data } = await axios(
        getAxiosConfig(
          'post',
          `${root}/shoppingcart/0/process`,
          null,
          paymentFormObject,
          userChallengeVerificationToken,
          humanVerificationTokenV3,
          humanVerificationActionV3,
        ),
      )

      mixpanel.completePayment(eventPayload)
      dispatch(receiveData([data], PROCESS_CART))
    } catch (err) {
      console.error(err)
      const status = err?.status
      const reason = getErrorMessage(err)
      const errorFaults = getErrorMessageToUser(err)

      mixpanel.failedPayment({ ...eventPayload, reason, status, errorFaults })
      dispatch(receiveError(err.data))

      if (isResolving) {
        sendToError(err)
      }
    }
  }
}

export function chubRemoveCart(cartId, cartItems = null) {
  return async (dispatch, getState) => {
    dispatch(requestData(CHUB_REMOVE_ITEM_FROM_CART))

    try {
      const removeCartResponse = await axios({
        url: `${rootChub}/cart/${cartId}`,
        method: 'delete',
        headers: {
          'Content-Type': 'application/json',
        },
      })

      const state = getState()

      const eventPayload = selectCartMixpanelPayload(state)

      mixpanel.abandonCart(eventPayload)
      if (cartItems && cartItems.lenght > 0) {
        const name = eventPayload.parkName.replaceAll(' ', '-').toLowerCase()

        const url = `https://www.reserveamerica.com/explore/${name}/${eventPayload.contractCode}/${eventPayload.parkId}/overview`

        const brazeEvent = { ...eventPayload, url }
        braze.abandonCart(brazeEvent)
      }

      dispatch(
        receiveData([removeCartResponse.data], CHUB_REMOVE_ITEM_FROM_CART),
      )
    } catch (err) {
      console.error(err)
      dispatch(receiveError(err.data))
    }
  }
}

export function abandonCart(cartId) {
  return async (dispatch, getState) => {
    dispatch(requestData(ABANDON_CART))

    const state = getState()
    const eventPayload = selectCartMixpanelPayload(state)

    try {
      const removeCartResponse = await axios(
        getAxiosConfig(
          'delete',
          `${root}/shoppingcart/${cartId}/abandon`,
          null,
        ),
      )

      mixpanel.abandonCart(eventPayload)
      const name = eventPayload.parkName.replaceAll(' ', '-').toLowerCase()

      const url = `https://www.reserveamerica.com/explore/${name}/${eventPayload.contractCode}/${eventPayload.parkId}/overview`

      const brazeEvent = { ...eventPayload, url }
      braze.abandonCart(brazeEvent)

      dispatch(receiveData([removeCartResponse.data], ABANDON_CART))
    } catch (err) {
      console.error(err)
      dispatch(receiveError(err.data))
    }
  }
}
