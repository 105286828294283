import axios from 'axios'

import BackendCache, { TTL_LONG_TERM_EXTREME } from '../utilities/BackendCache'

export async function fetchWithCache(
  requestConfig,
  shouldCache,
  ttl = TTL_LONG_TERM_EXTREME,
) {
  const cache = BackendCache.get()

  if (shouldCache) {
    const cached = cache.getData({ data: requestConfig })

    if (cached) return cached
  }

  const response = await axios(requestConfig)

  if (shouldCache) {
    cache.addData(requestConfig, response.data, ttl)
  }

  return response
}
