import PropTypes from 'prop-types'
import React from 'react'

import { isEmpty } from '../../utilities/GeneralUtils'
import TextStyler, { DEFAULT_HEADER, FONT_SIZE } from '../text/TextStyler'
import Legend from './Legend'

/**
 * @author Angelo  Natal <Angelo .Natal@activenetwork.com>
 *
 * Chart description
 * @returns {element} Chart
 *
 */

const Charts = ({
  data,
  colors,
  labels,
  series,
  values,
  chartType,
  height = 300,
  opaque = true,
  showLegend = false,
  showBackground = false,
  showPercentage = false,
  size = '1x',
}) => {
  let max = 0
  const compareNumbers = function (num1, num2) {
    return num1 - num2
  }

  const getColors = function () {
    const randomColor = []

    for (let i = 0; i < 5; i++) {
      if (chartType === 'horizontal' && isEmpty(colors)) {
        randomColor.push('#ed6e17')
      }
    }

    return randomColor
  }

  const isColor = isEmpty(colors) ? getColors() : colors

  const getLegend = function () {
    if (!showLegend) {
      return null
    }

    return <Legend labels={labels} colors={isColor} />
  }

  const getChart = function () {
    for (let i = data.length; i--; ) {
      for (let j = data[i].length; j--; ) {
        if (data[i][j] > max) {
          max = data[i][j]
        }
      }
    }

    return (
      <div className={`Charts ${chartType}`}>
        {data.map((serie, serieIndex) => {
          const sortedSerie = serie.slice(0)
          const horizontalHeight = { height: 'auto' }
          const verticalHeight = height
          const chartHeight =
            chartType === 'horizontal' ? horizontalHeight : verticalHeight
          const sum = serie.reduce((carry, current) => carry + current, 0)

          sortedSerie.sort(compareNumbers)

          return (
            <div
              className={`Charts--serie ${chartType}`}
              key={serieIndex}
              style={{ height: chartHeight }}
            >
              <TextStyler
                styleObj={{
                  ...DEFAULT_HEADER,
                  fontSize: FONT_SIZE.X3,
                }}
              >
                <label>{series[serieIndex]}</label>
              </TextStyler>
              {serie.map((item, itemIndex) => {
                const color = isEmpty(colors)
                  ? isColor[itemIndex]
                  : colors[itemIndex]
                const dimension =
                  (item / (chartType === 'stacked' ? sum : max)) * 100
                const value = item
                const percent = (item / sum) * 100
                const style = {
                  backgroundColor: color,
                  opacity: opaque ? 1 : item / max + 0.25,
                  zIndex: item,
                }
                const bgstyle = { backgroundColor: 'none' }
                const thickness = `size--${size} `
                const percentage = `${percent}%`
                const actual = `${dimension}%`

                let displayChart = null

                if (chartType === 'horizontal') {
                  style.width = showPercentage ? percentage : actual
                } else {
                  style.height = showPercentage ? percentage : actual
                }

                if (chartType === 'layered') {
                  style.right = `${
                    (sortedSerie.indexOf(item) / (serie.length + 1)) * 100
                  }%`
                }

                if (showBackground) {
                  bgstyle.backgroundColor = '#f2f2f2'
                }

                if (chartType === 'horizontal') {
                  displayChart = (
                    <div className={`Charts--wrapper ${thickness}`}>
                      <div className={'Charts--label'}>
                        <TextStyler
                          styleObj={{
                            ...DEFAULT_HEADER,
                            fontSize: FONT_SIZE.X1,
                          }}
                        >
                          {labels[itemIndex]}
                        </TextStyler>
                      </div>
                      <div
                        className={`Charts--background ${thickness}`}
                        style={bgstyle}
                      >
                        <div
                          className={`Charts--item ${thickness}`}
                          style={style}
                        ></div>
                      </div>
                      <div className={'Charts--value'}>
                        <TextStyler
                          styleObj={{
                            ...DEFAULT_HEADER,
                            fontSize: FONT_SIZE.X1,
                          }}
                        >
                          {isEmpty(values) ? value : values[itemIndex]}
                        </TextStyler>
                      </div>
                    </div>
                  )
                } else {
                  displayChart = (
                    <div className={`Charts--wrapper ${thickness}`}>
                      <div
                        className={`Charts--background ${thickness}`}
                        style={
                          chartType === 'layered' || chartType === 'stacked'
                            ? null
                            : bgstyle
                        }
                      >
                        <div
                          className={`Charts--item ${thickness}`}
                          style={chartType === 'vertical' ? style : null}
                        >
                          <div className={'Charts--value'}>
                            <TextStyler
                              styleObj={{
                                ...DEFAULT_HEADER,
                                fontSize: FONT_SIZE.X1,
                              }}
                            >
                              {isEmpty(values) ? value : values[itemIndex]}
                            </TextStyler>
                          </div>
                        </div>
                      </div>
                      <div className={'Charts--label'}>
                        <TextStyler
                          styleObj={{
                            ...DEFAULT_HEADER,
                            fontSize: FONT_SIZE.X1,
                          }}
                        >
                          {labels[itemIndex]}
                        </TextStyler>
                      </div>
                    </div>
                  )
                }

                return (
                  <div
                    key={itemIndex}
                    className="Charts--inner"
                    style={
                      chartType === 'layered' || chartType === 'stacked'
                        ? style
                        : null
                    }
                  >
                    {displayChart}
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div>
      {getChart()}
      {getLegend()}
    </div>
  )
}

Charts.propTypes = {
  data: PropTypes.arrayOf(PropTypes.array).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  series: PropTypes.arrayOf(PropTypes.string).isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  size: PropTypes.oneOf(['1x', '2x', '3x', '4x']),
  colors: PropTypes.arrayOf(PropTypes.string),
  chartType: PropTypes.string.isRequired,
  height: PropTypes.number,
  opaque: PropTypes.bool,
  showLegend: PropTypes.bool,
  showPercentage: PropTypes.bool,
  showBackground: PropTypes.bool,
}

export default Charts
