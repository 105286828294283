import PropTypes from 'prop-types'
import React from 'react'

/** Container class for menus.  Children can be anything but should be any combination of AMenuItem, MenuLink, SubMenu, and SubMenuItem */

const MenuContainer = ({ className = '', children }) => {
  let localClassName = 'menu-items__container'
  if (className !== '') {
    localClassName += ` ${className}`
  }

  return <div className={localClassName}>{children}</div>
}

MenuContainer.propTypes = {
  /** wrapper classname for custom styling */
  className: PropTypes.string,
  /** menu elements */
  children: PropTypes.node,
}

export default MenuContainer
