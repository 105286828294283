import PropTypes from 'prop-types'
import React from 'react'

import Translation from '../Translation'
import ErrorGuidance from '../error/ErrorGuidance'
import ErrorBlock from '../shared/ErrorBlock'
import HackHTag from '../shared/text/HackHTag'

const NotFound = ({ errors, intl, theme }) => (
  <div className="with-search">
    <div className="container container--errors">
      <HackHTag h1>
        <Translation intl={intl} mkey={'PAGE_NOT_FOUND'} />
      </HackHTag>
      <ErrorBlock errors={errors} intl={intl} />
      <ErrorGuidance intl={intl} theme={theme} mode={'notFound'} />
    </div>
  </div>
)

NotFound.propTypes = {
  theme: PropTypes.string.isRequired,
  errors: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
}

export default NotFound
