/**
 * @file   : PanelContentWrapper.js
 * @author : Amarjeet Lamba (Amarjeet.Lamba@AspiraConnect.com)
 * @date   : 4/10/2019, 6:27:18 PM
 */
import PropTypes from 'prop-types'
import React from 'react'

import Cont from '../../../external/components/layout/Cont'
import { isEmpty } from '../../../external/utilities/GeneralUtils'

const HackMarketingContentWrapper = ({ children, style = '' }) => {
  let customStyle = ''
  if (!isEmpty(style)) {
    customStyle = ` ${style}`
  }

  return (
    <Cont className={`hack-marketing-content-wrapper${customStyle}`}>
      {children}
    </Cont>
  )
}

HackMarketingContentWrapper.propTypes = {
  style: PropTypes.string,
  children: PropTypes.node,
}

export default HackMarketingContentWrapper
