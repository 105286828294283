import { array, bool, func, number, object, string } from 'prop-types'
import React, { Component } from 'react'

import Cont from '../../../external/components/layout/Cont'
import { isEmpty } from '../../../external/utilities/GeneralUtils'
import ClientHeader from './ClientHeader'
import HeaderMessageBar from './HeaderMessageBar'
import MainHeader from './MainHeader'

class Header extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      clientHeaderStyle: 'topbar-navigation__row-1',
      messageOpen: true,
      messageToggled: true,
    }
    this.toggleMessage = this.toggleMessage.bind(this)
    this.setMessageState = this.setMessageState.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { inlineSearch } = this.props

    if (!this.state.messageToggled && inlineSearch !== prevProps.inlineSearch) {
      this.setState({ messageOpen: !inlineSearch })
    }
  }

  setMessageState(toggled) {
    this.setState({ messageOpen: !this.props.inlineSearch && toggled })
  }

  toggleMessage() {
    const currentState = this.state.messageToggled
    this.setMessageState(this.state.messageToggled)
    this.setState({ messageToggled: !currentState })
  }

  render() {
    const {
      intl,
      inlineSearch,
      inlineSearchEnabled,
      isOverlay,
      getLocale,
      userProfile,
      allQuickSearchResults,
      allQuickSearchResultsAttribs,
      mainSearchConfig,
      brndConf,
      homeConfig,
      mainConfig,
      onSearchTrigger,
      onSearchTermChange,
      onSearchResultItemSelectRequest,
      displayMenu,
      cartTimeToExpiry,
      cartItemCount,
      currSearch,
      location,
      addToFavoritesCtr,
      removeFromFavoritesCtr,
      onSignOutRequest,
      headerTraceIdEnabled,
    } = this.props
    const broadcastMessage = homeConfig.systemBroadcast
    const broadCastMessageStyle =
      !inlineSearch &&
      !isEmpty(homeConfig.systemBroadcast) &&
      this.state.messageOpen
        ? ' broadcast-message'
        : ''
    const clientHeaderStyle = 'topbar-navigation__row-1'
    let mainHeaderStyle = 'main-header--overlay'

    if (this.props.fixed) {
      mainHeaderStyle += ' main-header--inline-search'
    } else {
      mainHeaderStyle += ' main-header--detached-search'
    }

    return (
      <div className="no-print">
        <div className={mainHeaderStyle + broadCastMessageStyle}>
          <HeaderMessageBar
            toggleMessage={this.toggleMessage}
            display={!inlineSearch}
            message={broadcastMessage}
            open={this.state.messageOpen}
          />
          <Cont className={clientHeaderStyle} hideXS>
            <ClientHeader
              intl={intl}
              displayMenu={displayMenu}
              userProfile={userProfile}
              getLocale={getLocale}
              cartTimeToExpiry={cartTimeToExpiry}
              cartItemCount={cartItemCount}
              location={location}
              mainConfig={mainConfig}
              homeConfig={homeConfig}
              headerTraceIdEnabled={headerTraceIdEnabled}
            />
          </Cont>
          <MainHeader
            intl={intl}
            inlineSearch={this.props.inlineSearch}
            inlineSearchEnabled={inlineSearchEnabled}
            displayMenu={displayMenu}
            isOverlay={isOverlay}
            userProfile={userProfile}
            allQuickSearchResults={allQuickSearchResults}
            allQuickSearchResultsAttribs={allQuickSearchResultsAttribs}
            mainSearchConfig={mainSearchConfig}
            brndConf={brndConf}
            onSearchTrigger={onSearchTrigger}
            onSearchTermChange={onSearchTermChange}
            onSearchResultItemSelectRequest={onSearchResultItemSelectRequest}
            currSearch={currSearch}
            mainConfig={mainConfig}
            brandConfig={brndConf}
            addToFavoritesCtr={addToFavoritesCtr}
            removeFromFavoritesCtr={removeFromFavoritesCtr}
            cartTimeToExpiry={cartTimeToExpiry}
            cartItemCount={cartItemCount}
            location={location}
            onSignOutRequest={onSignOutRequest}
            homeConfig={homeConfig}
          />
        </div>
      </div>
    )
  }

  static propTypes = {
    fixed: bool.isRequired,
    theme: string.isRequired,
    intl: object.isRequired,
    inlineSearch: bool.isRequired,
    inlineSearchEnabled: bool.isRequired,
    displayMenu: bool.isRequired,
    isOverlay: bool.isRequired,
    getLocale: func.isRequired,
    userProfile: object.isRequired,
    allQuickSearchResults: array.isRequired,
    allQuickSearchResultsAttribs: array.isRequired,
    mainSearchConfig: object.isRequired,
    brndConf: object.isRequired,
    homeConfig: object.isRequired,
    mainConfig: object.isRequired,
    onSearchTrigger: func.isRequired,
    onSearchTermChange: func.isRequired,
    onSearchResultItemSelectRequest: func.isRequired,
    cartTimeToExpiry: object.isRequired,
    cartItemCount: number.isRequired,
    currSearch: object,
    location: object,
    addToFavoritesCtr: number.isRequired,
    removeFromFavoritesCtr: number.isRequired,
    onSignOutRequest: func.isRequired,
    headerTraceIdEnabled: bool.isRequired,
  }
}

export default Header
