import { getAxiosConfig, root } from '../utilities/BackendUtils'
import { getTempFacilityDetailsOnlyObj } from '../utilities/QueryParamUtils'
import { fetchWithCache } from './withCache'

export function fetchFacility(contractCode, facilityId, shouldCache, ttl) {
  const requestConfig = getAxiosConfig(
    'get',
    `${root}/facilities/${contractCode}/${facilityId}`,
    getTempFacilityDetailsOnlyObj(false),
  )

  return fetchWithCache(requestConfig, shouldCache, ttl)
}

export function fetchContractAndFacilityConfig(
  contractCode,
  facilityId,
  shouldCache,
  ttl,
) {
  const requestConfig = getAxiosConfig(
    'get',
    `${root}/config/contractandfacility`,
    {
      contractCode,
      facilityID: facilityId,
    },
  )

  return fetchWithCache(requestConfig, shouldCache, ttl)
}
