import PropTypes from 'prop-types'
import React from 'react'

import ABaseLink from './ABaseLink'

export const ICON_POS_LEFT = 'left'
export const ICON_POS_RIGHT = 'right'

/** Component used to combine a font awesome icon and text as a link

@example ../../../../docs/external/link/AIconAction.md*/

const AIconAction = ({
  faStyle,
  onClick,
  isShowAsLink = false,
  label = '',
  divClass = '',
  iconPos = 'left',

  to,
  newWin,
  internal,
  cmpLabel,
  cmpTitle,
  tabIndex,
  cmpRel = null,
  stopClickEventPropogation,
  asSpan,
  cmpControlsId = null,
  cmpExpanded,
}) => {
  let iconClass = `icon-action${divClass === '' ? '' : ` ${divClass}`}`
  let textClass = ''

  const linkLabel = label
  const styleClass = `fa ${faStyle}`
  const Tag = asSpan ? 'span' : 'div'

  if (isShowAsLink) {
    iconClass += ' action-link'
    textClass = 'action-link'
  }

  return (
    <ABaseLink
      onClick={onClick}
      to={to}
      newWin={newWin}
      internal={internal}
      cmpLabel={cmpLabel}
      cmpTitle={cmpTitle}
      tabIndex={tabIndex}
      cmpRel={cmpRel}
      asSpan={asSpan}
      cmpControlsId={cmpControlsId}
      cmpExpanded={cmpExpanded}
      stopClickEventPropogation={stopClickEventPropogation}
    >
      <Tag className={`${iconClass} ${iconPos}`}>
        {iconPos === 'left' ? (
          <i className={styleClass} aria-hidden={'true'} />
        ) : (
          ''
        )}
        <span className={textClass}>{linkLabel}</span>
        {iconPos === 'right' ? (
          <i className={styleClass} aria-hidden={'true'} />
        ) : (
          ''
        )}
      </Tag>
    </ABaseLink>
  )
}

AIconAction.propTypes = {
  /** font awesome icon class */
  faStyle: PropTypes.string.isRequired,
  /** function to run when link is clicked */
  onClick: PropTypes.func,
  /** if true, link styles are applied to link */
  isShowAsLink: PropTypes.bool,
  /** link label */
  label: PropTypes.string,
  /** wrapper class for custom styles */
  divClass: PropTypes.string,
  /** position of icon in relation to label */
  iconPos: PropTypes.oneOf([ICON_POS_LEFT, ICON_POS_RIGHT]),

  /** url for the link */
  to: PropTypes.string,
  /** should the link open in a new window - overrides cmpTarget */
  newWin: PropTypes.bool,
  /** is link internal - if yes then no server request will be sent - link will be opened by single page app */
  internal: PropTypes.bool,
  /** alt text for link */
  cmpLabel: PropTypes.string,
  /** title for link */
  cmpTitle: PropTypes.string,
  /** tab index for contents */
  tabIndex: PropTypes.number,
  /** rel attribute */
  cmpRel: PropTypes.arrayOf(PropTypes.string),
  /** should click event propogtion be stopped */
  stopClickEventPropogation: PropTypes.bool,
  /** use a span instead of a div to wrap the contents */
  asSpan: PropTypes.bool,
  /** aria-expanded for link */
  cmpExpanded: PropTypes.bool,
  /** aria-controls id for link */
  cmpControlsId: PropTypes.string,
}

export default AIconAction
