import PropTypes, { bool } from 'prop-types'
import React from 'react'

import HackHTag from '../../../../components/shared/text/HackHTag'
import { isEmpty } from '../../../utilities/GeneralUtils'
import AButton from '../../button/AButton'
import AModal from './AModal'
import AModalBody from './AModalBody'
import AModalHeader from './AModalHeader'

/** Overlay with a positve and negative action (e.g. okay and cancel) */

const SimpleModal = ({
  show,
  header,
  body,
  onPositiveClick,
  onNegativeClick,
  onHide,
  positiveLabel,
  negativeLabel,
  closeButton,
  isNegativeDisabled,
  isPositiveDisabled,
}) => {
  const onPositiveClickInternal = function () {
    if (typeof onPositiveClick !== 'undefined') {
      onPositiveClick()
    }
  }

  const onNegativeClickInternal = function () {
    if (typeof onNegativeClick !== 'undefined') {
      onNegativeClick()
    }
  }

  const onHideInternal = function () {
    if (typeof onHide !== 'undefined') {
      onHide()
    }
  }

  const getHeader = function () {
    const isCloseButton =
      typeof closeButton === 'undefined' ? true : closeButton
    if (!isEmpty(header)) {
      return (
        <AModalHeader closeButton={isCloseButton}>
          <HackHTag h4>{header}</HackHTag>
        </AModalHeader>
      )
    }

    return ''
  }

  const getBody = function () {
    if (!isEmpty(body)) {
      return body
    }

    return ''
  }

  const getFooter = function () {
    if (isEmpty(positiveLabel) && isEmpty(negativeLabel)) {
      return ''
    }

    let buttonCount = 0
    let negativeButton = null
    let positiveButton = null
    if (!isEmpty(negativeLabel)) {
      buttonCount += 1
      negativeButton = (
        <AButton
          className="btn btn-info"
          onClick={onNegativeClickInternal}
          cmpRef={`modal-${negativeLabel}-button`}
          disabled={isNegativeDisabled}
        >
          {negativeLabel}
        </AButton>
      )
    }
    if (!isEmpty(positiveLabel)) {
      buttonCount += 1
      positiveButton = (
        <AButton
          className="btn btn-success"
          onClick={onPositiveClickInternal}
          cmpRef={`modal-${positiveLabel}-button`}
          disabled={isPositiveDisabled}
        >
          {positiveLabel}
        </AButton>
      )
    }
    // console.log('buttonCount ' + buttonCount)
    if (buttonCount === 0) {
      return null
    } else if (buttonCount === 1) {
      let liveButton = null
      if (negativeButton === null) {
        liveButton = positiveButton
      } else {
        liveButton = negativeButton
      }

      return (
        <div className="feedback-footer row">
          <div className="col-6"></div>
          <div className="col-6">{liveButton}</div>
          <div className="clear-fix"></div>
        </div>
      )
    }

    return (
      <div className="feedback-footer row">
        <div className="col-6">{negativeButton}</div>
        <div className="col-6">{positiveButton}</div>
        <div className="clear-fix"></div>
      </div>
    )
  }

  return (
    <AModal show={show} onHide={onHideInternal}>
      {getHeader()}
      <AModalBody>
        {getBody()}
        {getFooter()}
      </AModalBody>
    </AModal>
  )
}

SimpleModal.propTypes = {
  /** When true The modal will show itself. */
  show: PropTypes.bool.isRequired,
  /** Modal title */
  header: PropTypes.string,
  /** Modal body */
  body: PropTypes.element,
  /** Label for positive action */
  positiveLabel: PropTypes.string,
  /** function to run when positive button is clicked */
  onPositiveClick: PropTypes.func,
  /** Label for negative action */
  negativeLabel: PropTypes.string,
  /** function to run when negative button is clicked */
  onNegativeClick: PropTypes.func,
  /** A callback fired when the header closeButton or non-static backdrop is clicked. Required if either are specified. */
  onHide: PropTypes.func,
  /** Specify whether the Component should contain a close button */
  closeButton: PropTypes.bool,

  isNegativeDisabled: bool,
  isPositiveDisabled: bool,
}

export default SimpleModal
