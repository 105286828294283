/**
 * @file   : SRLoadAnnouncer.js
 * @author : Amarjeet Lamba (Amarjeet.Lamba@AspiraConnect.com)
 * @date   : 8/16/2018, 7:02:34 PM
 */
import PropTypes from 'prop-types'
import React from 'react'

import { LANDMARKS } from '../../utilities/accessibility/Definitions'
import Cont from '../layout/Cont'

const SRLoadAnnouncer = ({
  isLoading = false,
  loadingMsg = 'Loading',
  loadCompleteMsg = 'Loading completed',
  children,
}) => {
  if (isLoading) {
    return (
      <div role="status" aria-live="polite" aria-atomic="true">
        <span className="sr-only">{loadingMsg}</span>
        {children}
      </div>
    )
  }

  return (
    <div role="status" aria-live="polite" aria-atomic="true">
      <span className="sr-only">{loadCompleteMsg}</span>
      {children}
    </div>
  )
}

SRLoadAnnouncer.propTypes = {
  isLoading: PropTypes.bool,
  loadingMsg: PropTypes.string,
  loadCompleteMsg: PropTypes.string,
  children: PropTypes.node,
}

export default SRLoadAnnouncer
