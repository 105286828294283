import { bool, func, number, object } from 'prop-types'
import React from 'react'
import root from 'window-or-global'

import Cont, {
  ALIGN_LEFT2,
  ALIGN_RIGHT,
} from '../../../external/components/layout/Cont'
import ABaseLink, {
  REL_NOFOLLOW,
} from '../../../external/components/link/ABaseLink'
import { isEmpty, isUndefined } from '../../../external/utilities/GeneralUtils'
import { getLeaveBetaRoute } from '../../../router/ExtRouteManager'
import { getShowLegacySiteLink } from '../../../utilities/ConfigUtils'
import Translation from '../../Translation'

const ClientHeader = ({
  intl,
  mainConfig,
  homeConfig,
  headerTraceIdEnabled,
}) => {
  let legacySiteLink = null
  if (getShowLegacySiteLink(mainConfig)) {
    legacySiteLink = (
      <ABaseLink
        cmpRel={[REL_NOFOLLOW]}
        to={getLeaveBetaRoute()}
        internal={false}
        style="home-return-link"
      >
        <Translation mkey="labels_return_to_legacy_site" intl={intl} />
      </ABaseLink>
    )
  }

  const getSubMenuOptions = function () {
    let subMenuOptions = null
    let count = 0
    if (!isEmpty(homeConfig) && !isUndefined(homeConfig.mainSubMenuOptions)) {
      subMenuOptions = homeConfig.mainSubMenuOptions.items.map((item, i) => {
        if (!item.showItem) {
          return null
        }

        count += 1

        return (
          <div key={i}>
            {count > 1 ? <div className="header-v-separator" /> : ''}
            <a href={item.link} target="_blank" rel="noreferrer">
              <Translation
                intl={intl}
                defaultMessage={item.label.default}
                mkey={item.label.token}
              />
            </a>
          </div>
        )
      })
    }

    return subMenuOptions
  }

  const getTraceId = function () {
    if (headerTraceIdEnabled) {
      return (
        <Cont align={ALIGN_LEFT2}>
          <span style={{ color: 'white', display: 'none' }}>
            {root.traceId || ''}
          </span>
        </Cont>
      )
    }

    return null
  }

  return (
    <Cont
      className="topbar-navigation"
      style={{ display: 'flex', alignItems: 'center' }}
    >
      {legacySiteLink}
      {getTraceId()}
      <Cont align={ALIGN_RIGHT} className={'header-right-links'}>
        {getSubMenuOptions()}
      </Cont>
    </Cont>
  )
}

ClientHeader.propTypes = {
  displayMenu: bool.isRequired,
  getLocale: func.isRequired,
  children: object,
  intl: object.isRequired,
  userProfile: object.isRequired,
  cartTimeToExpiry: object.isRequired,
  cartItemCount: number.isRequired,
  location: object,
  mainConfig: object.isRequired,
  homeConfig: object.isRequired,
  headerTraceIdEnabled: bool.isRequired,
}

export default ClientHeader
