export const VIEW_FAVORITES = 'viewFavorites'
export const SUB_VIEW_FAVORITES_ALL = 'subViewFavoritesAll'
// export const SUB_VIEW_FAVORITES_MAP = 'subViewFavoritesMap'
export const SUB_VIEW_FAVORITES_ADD_TO_LIST = 'subViewFavoritesAddToList'
export const VIEW_LISTS = 'viewLists'
export const SUB_VIEW_LISTS_ALL = 'subViewListsAll'
export const SUB_VIEW_LIST_DETAILS = 'subViewListDetails'
export const SUB_VIEW_LIST_ADD_FROM_FAVORITES = 'subViewListAddFromFavorites'
export const SUB_VIEW_LIST_ADD_ADDRESS = 'subViewListAddAddress'
// export const SUB_VIEW_LISTS_MAP = 'subViewListsMap'
export const VIEW_MAP = 'viewMap'

export const TOGGLE_PANEL_VIEW_MAIN_PANEL = 'togglePanelViewMainPanel'
export const TOGGLE_PANEL_VIEW_MAP = 'togglePanelViewMap'

export const views = {}
views[VIEW_FAVORITES] = { name: VIEW_FAVORITES }
views[VIEW_FAVORITES][SUB_VIEW_FAVORITES_ALL] = { name: SUB_VIEW_FAVORITES_ALL }
views[VIEW_FAVORITES][SUB_VIEW_FAVORITES_ADD_TO_LIST] = {
  name: SUB_VIEW_FAVORITES_ADD_TO_LIST,
}
views[VIEW_LISTS] = { name: VIEW_LISTS }
views[VIEW_LISTS][SUB_VIEW_LISTS_ALL] = { name: SUB_VIEW_LISTS_ALL }
views[VIEW_LISTS][SUB_VIEW_LIST_DETAILS] = { name: SUB_VIEW_LIST_DETAILS }
views[VIEW_LISTS][SUB_VIEW_LIST_ADD_FROM_FAVORITES] = {
  name: SUB_VIEW_LIST_ADD_FROM_FAVORITES,
}
views[VIEW_LISTS][SUB_VIEW_LIST_ADD_ADDRESS] = {
  name: SUB_VIEW_LIST_ADD_ADDRESS,
}
views[VIEW_MAP] = { name: VIEW_MAP }

export function getViewObject(viewState) {
  return views[viewState]
}

export function getDefaultView() {
  return views[VIEW_FAVORITES]
}

export function getDefaultSubView(selectedView) {
  if (selectedView.name === VIEW_FAVORITES) {
    return views[VIEW_FAVORITES][SUB_VIEW_FAVORITES_ALL]
  }

  return views[VIEW_LISTS][SUB_VIEW_LISTS_ALL]
}

export function getDefaultListsSubView() {
  return views[VIEW_LISTS][SUB_VIEW_LISTS_ALL]
}

export function getDefaultPanelToggle() {
  return TOGGLE_PANEL_VIEW_MAIN_PANEL
}

export function isPanelToggleMap(panelToggle) {
  if (panelToggle === TOGGLE_PANEL_VIEW_MAP) {
    return true
  }

  return false
}

export function isPanelToggleMainPanel(panelToggle) {
  if (panelToggle === TOGGLE_PANEL_VIEW_MAIN_PANEL) {
    return true
  }

  return false
}

export function isViewFavorites(viewState) {
  if (viewState.name === VIEW_FAVORITES) {
    return true
  }

  return false
}

export function isSubViewFavouritesList(subViewState) {
  if (subViewState.name === SUB_VIEW_FAVORITES_ALL) {
    return true
  }

  return false
}

export function isSubViewFavoriteAddToList(subViewState) {
  if (subViewState.name === SUB_VIEW_FAVORITES_ADD_TO_LIST) {
    return true
  }

  return false
}

export function isSubViewListsAll(subViewState) {
  if (subViewState.name === SUB_VIEW_LISTS_ALL) {
    return true
  }

  return false
}

export function isSubViewListDetails(subViewState) {
  if (subViewState.name === SUB_VIEW_LIST_DETAILS) {
    return true
  }

  return false
}

export function isSubViewListAddFromFavorites(subViewState) {
  if (subViewState.name === SUB_VIEW_LIST_ADD_FROM_FAVORITES) {
    return true
  }

  return false
}

export function isSubViewListAddAddress(subViewState) {
  if (subViewState.name === SUB_VIEW_LIST_ADD_ADDRESS) {
    return true
  }

  return false
}

export function getPanelToggleMain() {
  return TOGGLE_PANEL_VIEW_MAIN_PANEL
}

export function getPanelToggleMap() {
  return TOGGLE_PANEL_VIEW_MAP
}

export function getViewFavorites() {
  return views[VIEW_FAVORITES]
}

export function getViewLists() {
  return views[VIEW_LISTS]
}

export function getViewMap() {
  return views[VIEW_MAP]
}

export function getSubViewFavoritesList() {
  return views[VIEW_FAVORITES][SUB_VIEW_FAVORITES_ALL]
}

export function getSubViewFavoritesAddToList() {
  return views[VIEW_FAVORITES][SUB_VIEW_FAVORITES_ADD_TO_LIST]
}

export function getSubViewListsAll() {
  return views[VIEW_LISTS][SUB_VIEW_LISTS_ALL]
}

export function getSubViewListDetails() {
  return views[VIEW_LISTS][SUB_VIEW_LIST_DETAILS]
}

export function getSubViewListAddFromFavorites() {
  return views[VIEW_LISTS][SUB_VIEW_LIST_ADD_FROM_FAVORITES]
}

export function getSubViewListAddAddress() {
  return views[VIEW_LISTS][SUB_VIEW_LIST_ADD_ADDRESS]
}

/** ************* HACKS FOR HEIGHTS & WIDTHS *************************/
/*
 *    The constant here have been hijacked by other pages that has nothing
 *    to do with Favorites. This constant should probably moved to some
 *    common folder later.
 */
// TODO - refactor as above.
export const MOBILE_SWITCH_BREAK_POINT = 992
export const TOP_HEADER_MAIN_HEIGHT = 85
export const TOP_HEADER_MAIN_HEIGHT_MOBILE = 95
export const TOP_HEADER_SEARCHBAR_HEIGHT_MOBILE = 45
export const SEARCHBAR_MARGIN_TOP = 250
export const MAIN_PANEL_HEADER_MAIN_HEIGHT = 104
export const MAP_VIEW_TOP_HEADER_MOBILE = 43
export const MAIN_PANEL_WIDTH = 536
export const MAIN_PANEL_MAIN_MENU_HEIGHT = 83
export const MAIN_PANEL_MAIN_MENU_HEIGHT_MOBILE = 43
export const MIN_SCROLLER_VIEW_HEIGHT = 40

export function isMobileView(width) {
  let ret = true
  if (width > MOBILE_SWITCH_BREAK_POINT) {
    ret = false
  }
  // console.log('isMobileView: ' + ret)
  return ret
}

export function getMainPanelWidth(width) {
  let ret = MAIN_PANEL_WIDTH
  if (isMobileView(width)) {
    ret = width
  }
  // console.log('getMainPanelWidth ' + ret)
  return ret
}

export function getMapPanelWidth(width) {
  let ret = width
  if (!isMobileView(width)) {
    ret = width - getMainPanelWidth(width)
  }
  // console.log('getMapPanelWidth: ' + ret)
  return ret
}

export function getMapPanelHeight(height, width) {
  let topMainHeaderHeight = TOP_HEADER_MAIN_HEIGHT

  let mainMenuHeight = 0

  let mobileHeaderHeight = 0

  if (isMobileView(width)) {
    topMainHeaderHeight = TOP_HEADER_MAIN_HEIGHT_MOBILE
    mainMenuHeight = MAIN_PANEL_MAIN_MENU_HEIGHT_MOBILE
    mobileHeaderHeight = MAP_VIEW_TOP_HEADER_MOBILE
  }

  const ht =
    height - (topMainHeaderHeight + mainMenuHeight + mobileHeaderHeight)

  return ht
}

// export function getMapPanelHeight (height, width) {
//   let ret = getPanelHeight(height, width)
//   if (isMobileView(width)) {
//     ret = ret - MAP_VIEW_TOP_HEADER_MOBILE
//   }
//   //console.log('getMapPanelHeight: ' + height + '/' + ret)
//   return ret
// }

// export function getPanelHeight (height, width) {
//   let ret = height - TOP_HEADER_MAIN_HEIGHT_MOBILE
//   if (!isMobileView(width)) {
//     ret = height - TOP_HEADER_MAIN_HEIGHT
//   }
//   //console.log('getPanelHeight: ' + ret)
//   return ret
// }

export function getMainPanelHeightStyle(
  height,
  width,
  componentHeaderHeight,
  componentFooterHeight,
) {
  // console.log('getMainPanelHeightStyle - height/width: ' + height + '/' + width)

  let topMainHeaderHeight = TOP_HEADER_MAIN_HEIGHT

  let mainMenuHeight = MAIN_PANEL_MAIN_MENU_HEIGHT

  let mobileHeaderHeight = 0
  if (isMobileView(width)) {
    topMainHeaderHeight = TOP_HEADER_MAIN_HEIGHT_MOBILE
    mainMenuHeight = MAIN_PANEL_MAIN_MENU_HEIGHT_MOBILE
    mobileHeaderHeight = MAP_VIEW_TOP_HEADER_MOBILE
  }
  // console.log('getMainPanelHeightStyle - mobileHeaderHeight: ' + mobileHeaderHeight)

  if (componentHeaderHeight === null) {
    componentHeaderHeight = 0
  }
  // console.log('getMainPanelHeightStyle - componentHeaderHeight: ' + componentHeaderHeight)

  if (componentFooterHeight === null) {
    componentFooterHeight = 0
  }
  // console.log('getMainPanelHeightStyle - componentFooterHeight: ' + componentFooterHeight)

  let ht = `${
    height -
    (topMainHeaderHeight +
      mainMenuHeight +
      mobileHeaderHeight +
      componentHeaderHeight +
      componentFooterHeight)
  }px`
  const wt = getMainPanelWidth(width)

  if (ht < MIN_SCROLLER_VIEW_HEIGHT) {
    ht = MIN_SCROLLER_VIEW_HEIGHT
  }

  const leftPanelStyle = {
    height: ht,
    width: wt,
  }
  // console.log('getMainPanelHeightStyle - scroller height/scroller width: ' + ht + '/' + wt)
  return leftPanelStyle
}
