import root from 'window-or-global'

export const ROOT = root

export const isEnvServer = function () {
  if (
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  ) {
    return false
  }

  return true
}

export const getRoot = function () {
  if (isEnvServer()) {
    return {}
  }

  return window
}
