import PropTypes from 'prop-types'
import React from 'react'

import Section, { MAIN } from '../../../external/components/layout/Section'
import Advert from '../../shared/ad/Advert'

/**
 * @author Amarjeet Lamba <Amarjeet.Lamba@activenetwork.com>
 *
 * ArticleAdvert description
 * @returns {element} ArticleAdvert
 *
 */
const ArticleAdvert = ({ id, adConfig }) => {
  const testFunc = function () {
    varOne = 'bar'
  }

  return (
    <Section style={MAIN}>
      <Advert id={id} adConfig={adConfig} />
    </Section>
  )
}

ArticleAdvert.propTypes = {
  id: PropTypes.string.isRequired,
  adConfig: PropTypes.object.isRequired,
}

export default ArticleAdvert
