/* Internal */
import root from 'window-or-global'

import * as RouteNames from '../constants/Routes'
import { isEnvServer } from '../external/utilities/GeneralServerUtils'
import {
  contains,
  isEmpty,
  uriFriendlyString,
} from '../external/utilities/GeneralUtils'
import {
  addObjToQueryString,
  getQueryStringFromObj,
  removeAddItemToCartObj,
  removeSignInTrackingParams,
} from '../utilities/QueryParamUtils'
import {
  getPath,
  getPathWithoutQuery,
  getQuery,
  isPathMatch,
} from '../utilities/ServerUtils'
import {
  forwardToForgotPassword,
  forwardToSignIn,
  forwardToSignUp,
} from './ExtRouteManager'
import History from './history'

export const PROTOCOL = {
  email: 'mailto:',
  sms: 'sms:',
  phone: 'tel:',
}

function hackReplaceRoute(route) {
  // Used to fix a delay the above replaceRoute introduced. Should not be used elsewhere.
  window.history.replaceState({}, '', route)
}

export function pushRoute(route) {
  // console.log('pushing to :' + route)
  // if (useHashBangs) {
  //   History.push(route)
  // } else {
  History.push(route)
  // }
}

export function getCurrentRouteWithQuery(location) {
  return location.pathname + getQueryStringFromObj(location.query)
}

export function getHackCurrentFullPath() {
  // TODO - This is a hack for now - wont work on Isomorphic app and
  // is using bad practices - fix
  return root.location.href
}

export function getHackPathWithoutProtocol(url) {
  // return url.replace(/(^\w+:|^)\/\//, '')

  url = url.replace('https://', '')
  url = url.replace('http://', '')

  return url
}

export function isCurrentRouteName(route, routeName) {
  if (route.name === routeName) {
    return true
  }

  return false
}

function getBaseFacilityRoute(splat, contractId, facilityId) {
  // return '/' + RouteNames.LOB_CAMP + '/' + splat + '/' + contractId + '/' + facilityId + '/'
  return `/${uriFriendlyString(splat)}/${contractId}/${facilityId}/`
}

/* Compose Routes */
export function getHomeRoute(query = null) {
  return getPath(`/${getQueryStringFromObj(query)}`)
}

export function getErrorRoute(query = null) {
  return getPath(RouteNames.ROUTE_ERROR + getQueryStringFromObj(query))
}

export function getNotLoggedInRoute(query = null) {
  return getPath(RouteNames.ROUTE_NOT_LOGGED_IN + getQueryStringFromObj(query))
}

export function getFacilityOverviewRoute(
  splat,
  contractId,
  facilityId,
  query = null,
) {
  return getPath(
    getBaseFacilityRoute(splat, contractId, facilityId) +
      RouteNames.OVERVIEW +
      getQueryStringFromObj(query),
  )
}

export function getFacilityOverviewChubCartRoute(
  splat,
  contractId,
  facilityId,
  query = null,
) {
  return getPath(
    getBaseFacilityRoute(splat, contractId, facilityId) +
      RouteNames.CHUBCART +
      getQueryStringFromObj(query),
  )
}

export function getFacilityOverviewChubCartConfirmationRoute(
  splat,
  contractId,
  facilityId,
) {
  return getPath(
    getBaseFacilityRoute(splat, contractId, facilityId) +
      RouteNames.CHUBCART_CONFIRMATION,
  )
}

export function getFacilitySiteListRoute(
  splat,
  contractId,
  facilityId,
  query = null,
) {
  return getPath(
    getBaseFacilityRoute(splat, contractId, facilityId) +
      RouteNames.SITE_LIST +
      getQueryStringFromObj(query),
  )
}

export function getFacilitySitesCalendarRoute(
  splat,
  contractId,
  facilityId,
  query = null,
) {
  return getPath(
    getBaseFacilityRoute(splat, contractId, facilityId) +
      RouteNames.SITES_CALENDAR +
      getQueryStringFromObj(query),
  )
}

export function getFacilityDayPassesRoute(
  splat,
  contractId,
  facilityId,
  query = null,
) {
  return getPath(
    getBaseFacilityRoute(splat, contractId, facilityId) +
      RouteNames.DAY_PASSES +
      getQueryStringFromObj(query),
  )
}

export function getFacilityHourlySiteListRoute(
  splat,
  contractId,
  facilityId,
  query = null,
) {
  return getPath(
    getBaseFacilityRoute(splat, contractId, facilityId) +
      RouteNames.HOURLY_SITE_LIST +
      getQueryStringFromObj(query),
  )
}

export function getFacilityHourlySitesCalendarRoute(
  splat,
  contractId,
  facilityId,
  query = null,
) {
  return getPath(
    getBaseFacilityRoute(splat, contractId, facilityId) +
      RouteNames.HOURLY_SITES_CALENDAR +
      getQueryStringFromObj(query),
  )
}

export function getSiteAndBookingDetailsRoute(
  splat,
  contractId,
  facilityId,
  productId,
  query = null,
) {
  return getPath(
    `${getBaseFacilityRoute(splat, contractId, facilityId) + productId}/${
      RouteNames.SITE_AND_BOOKING_DETAILS
    }${getQueryStringFromObj(query)}`,
  )
}

export function getDaypassAndBookingDetailsRoute(
  splat,
  contractId,
  facilityId,
  productId,
  query = null,
) {
  return getPath(
    `${getBaseFacilityRoute(splat, contractId, facilityId) + productId}/${
      RouteNames.DAY_PASSES_AND_BOOKING_DETAILS
    }${getQueryStringFromObj(query)}`,
  )
}

export function getHourlySiteAndBookingDetailsRoute(
  splat,
  contractId,
  facilityId,
  productId,
  query = null,
) {
  return getPath(
    `${getBaseFacilityRoute(splat, contractId, facilityId) + productId}/${
      RouteNames.HOURLY_SITE_AND_BOOKING_DETAILS
    }${getQueryStringFromObj(query)}`,
  )
}

export function getProductNotificationRoute(
  splat,
  contractId,
  facilityId,
  productId,
  query = null,
) {
  return getPath(
    `${getBaseFacilityRoute(splat, contractId, facilityId) + productId}/${
      RouteNames.PRODUCT_NOTIFICATION
    }${getQueryStringFromObj(query)}`,
  )
}

export function getProductBookingDetailsRoute(query = null) {
  return getPath(
    RouteNames.ROUTE_PRODUCT_BOOKING_DETAILS + getQueryStringFromObj(query),
  )
}

export function getProductBookingSupplementDetailsRoute(query = null) {
  return getPath(
    RouteNames.ROUTE_PRODUCT_BOOKING_SUPPLEMENT_DETAILS +
      getQueryStringFromObj(query),
  )
}

export function getSearchResultsRoute(query = null) {
  return getPath(RouteNames.ROUTE_SEARCH_RESULTS + getQueryStringFromObj(query))
}

export function getCartViewRoute(query = null) {
  return getPath(RouteNames.ROUTE_CART_VIEW + getQueryStringFromObj(query))
}

export function getCartItemRoute(query = null) {
  return getPath(RouteNames.ROUTE_CART_ITEM + getQueryStringFromObj(query))
}

export function getCartCheckoutRoute(query = null) {
  return getPath(RouteNames.ROUTE_CART_CHECKOUT + getQueryStringFromObj(query))
}

export function getCartConfirmationRoute(query = null) {
  return getPath(
    RouteNames.ROUTE_CART_CONFIRMATION + getQueryStringFromObj(query),
  )
}

export function getOfferRoute(query = null) {
  return getPath(RouteNames.ROUTE_OFFER + getQueryStringFromObj(query))
}

export function getFavoritesRoute(query = null) {
  return getPath(RouteNames.ROUTE_FAVORITES + getQueryStringFromObj(query))
}

export const getMyAccountDashboardRoute = function (query = null) {
  return getPath(RouteNames.ROUTE_DASHBOARD + getQueryStringFromObj(query))
}

export const getMyAccountReservationsRoute = function (query = null) {
  return getPath(RouteNames.ROUTE_RESERVATIONS + getQueryStringFromObj(query))
}

export const getMyAccountReservationDetailsRoute = function (query = null) {
  return getPath(
    RouteNames.ROUTE_RESERVATION_DETAILS + getQueryStringFromObj(query),
  )
}

export const getMyAccountReservationCancelRoute = function (query = null) {
  return getPath(
    RouteNames.ROUTE_RESERVATION_CANCEL + getQueryStringFromObj(query),
  )
}

export const getMyAccountReservationPreCheckinRoute = function (query = null) {
  return getPath(
    RouteNames.ROUTE_RESERVATION_PRE_CHECKIN + getQueryStringFromObj(query),
  )
}

export const getMyAccountReservationPaymentRoute = function (query = null) {
  return getPath(
    RouteNames.ROUTE_RESERVATION_PAYMENT + getQueryStringFromObj(query),
  )
}

export const getMyAccountReservationChangeRoute = function (query = null) {
  return getPath(
    RouteNames.ROUTE_RESERVATION_CHANGE + getQueryStringFromObj(query),
  )
}

export const getMyAccountReservationChangeSupplementDetailsRoute = function (
  query = null,
) {
  return getPath(
    RouteNames.ROUTE_RESERVATION_CHANGE_SUPPLEMENT_DETAILS +
      getQueryStringFromObj(query),
  )
}

export const getMyAccountReservationChangeConfirmationRoute = function (
  query = null,
) {
  return getPath(
    RouteNames.ROUTE_RESERVATION_CHANGE_CONFIRMATION +
      getQueryStringFromObj(query),
  )
}

export const getMyAccountNotificationsRoute = function (query = null) {
  return getPath(RouteNames.ROUTE_NOTIFICATIONS + getQueryStringFromObj(query))
}

export const getMyAccountApplicationsRoute = function (query = null) {
  return getPath(RouteNames.ROUTE_APPLICATIONS + getQueryStringFromObj(query))
}

export const getMyAccountPassesRoute = function (query = null) {
  return getPath(RouteNames.ROUTE_PASSES + getQueryStringFromObj(query))
}

export const getMyAccountVouchersRoute = function (query = null) {
  return getPath(RouteNames.ROUTE_VOUCHERS + getQueryStringFromObj(query))
}

export const getMyAccountPointsRoute = function (query = null) {
  return getPath(RouteNames.ROUTE_POINTS + getQueryStringFromObj(query))
}

export const getMyAccountPointsDetailsRoute = function (query = null) {
  return getPath(RouteNames.ROUTE_POINTS_DETAILS + getQueryStringFromObj(query))
}

export const getMyAccountEquipmentRoute = function (query = null) {
  return getPath(RouteNames.ROUTE_EQUIPMENT + getQueryStringFromObj(query))
}

export const getMyAccountEquipmentDetailsRoute = function (query = null) {
  return getPath(
    RouteNames.ROUTE_EQUIPMENT_DETAILS + getQueryStringFromObj(query),
  )
}

export const getMyAccountPrintTicketsPermitsRoute = function (query = null) {
  return getPath(
    RouteNames.ROUTE_PRINT_TICKETS_PERMITS + getQueryStringFromObj(query),
  )
}

export const getMyAccountProfileRoute = function (query = null) {
  return getPath(RouteNames.ROUTE_PROFILE + getQueryStringFromObj(query))
}

export const getMyAccountCampClubProfileRoute = function (query = null) {
  return getPath(
    RouteNames.ROUTE_CAMP_CLUB_PROFILE + getQueryStringFromObj(query),
  )
}

export const getMyAccountRateReservationRoute = function (query = null) {
  return getPath(
    RouteNames.ROUTE_RATE_RESERVATION + getQueryStringFromObj(query),
  )
}

export const getPOSItemRoute = function (query = null) {
  return getPath(RouteNames.ROUTE_POS_ITEM + getQueryStringFromObj(query))
}

export const getContactUsRoute = function (query = null) {
  return getPath(RouteNames.ROUTE_CONTACT_US + getQueryStringFromObj(query))
}

export const getAdvertiseRoute = function (query = null) {
  return getPath(RouteNames.ROUTE_ADVERTISE + getQueryStringFromObj(query))
}

/* Forward to Routes */
export function forwardToHome(query = null) {
  const newRoute = getHomeRoute(query)
  pushRoute(newRoute)
}

export function forwardToError(query = null) {
  const newRoute = getErrorRoute(query)
  pushRoute(newRoute)
}

export function forwardToNotLoggedIn(query = null) {
  const newRoute = getNotLoggedInRoute(query)
  pushRoute(newRoute)
}

export function forwardToSearchResults(query = null) {
  const newRoute = getSearchResultsRoute(query)
  pushRoute(newRoute)
}

export function forwardToFacilityOverview(
  splat,
  contractId,
  facilityId,
  query = null,
) {
  const newRoute = getFacilityOverviewRoute(
    splat,
    contractId,
    facilityId,
    query,
  )
  pushRoute(newRoute)
}

export function forwardToFacilityOverviewChubCart({
  splat,
  contractId,
  facilityId,
  query,
  isAnonymous = false,
  chubcartConfig,
}) {
  const newRoute = getFacilityOverviewChubCartRoute(
    splat,
    contractId,
    facilityId,
    query,
  )

  if (chubcartConfig.default.chub_login) {
    pushRoute(newRoute)
  } else if (isAnonymous) {
    forwardToSignIn({ landing: encodeURIComponent(newRoute) })
  } else {
    pushRoute(newRoute)
  }
}

export function forwardToFacilityOverviewChubCartSingUp({
  splat,
  contractId,
  facilityId,
  query,
}) {
  const newRoute = getFacilityOverviewChubCartRoute(
    splat,
    contractId,
    facilityId,
    query,
  )

  forwardToSignUp({ landing: encodeURIComponent(newRoute) })
}

export function forwardToFacilityOverviewChubCartForgotPassword({
  splat,
  contractId,
  facilityId,
  query,
}) {
  const newRoute = getFacilityOverviewChubCartRoute(
    splat,
    contractId,
    facilityId,
    query,
  )

  forwardToForgotPassword({ landing: encodeURIComponent(newRoute) })
}

export function forwardToFacilityOverviewConfirmation({
  splat,
  contractId,
  facilityId,
}) {
  const newRoute = getFacilityOverviewChubCartConfirmationRoute(
    splat,
    contractId,
    facilityId,
  )
  pushRoute(newRoute)
}

export function forwardToFacilitySiteList(
  splat,
  contractId,
  facilityId,
  query = null,
) {
  const newRoute = getFacilitySiteListRoute(
    splat,
    contractId,
    facilityId,
    query,
  )
  pushRoute(newRoute)
}

export function forwardToFacilitySitesCalendar(
  splat,
  contractId,
  facilityId,
  query = null,
) {
  const newRoute = getFacilitySitesCalendarRoute(
    splat,
    contractId,
    facilityId,
    query,
  )
  pushRoute(newRoute)
}

export function forwardToFacilityDayPasses(
  splat,
  contractId,
  facilityId,
  query = null,
) {
  const newRoute = getFacilityDayPassesRoute(
    splat,
    contractId,
    facilityId,
    query,
  )
  pushRoute(newRoute)
}

export function forwardToFacilityHourlySiteList(
  splat,
  contractId,
  facilityId,
  query = null,
) {
  const newRoute = getFacilityHourlySiteListRoute(
    splat,
    contractId,
    facilityId,
    query,
  )
  pushRoute(newRoute)
}

export function forwardToFacilityHourlySitesCalendar(
  splat,
  contractId,
  facilityId,
  query = null,
) {
  const newRoute = getFacilityHourlySitesCalendarRoute(
    splat,
    contractId,
    facilityId,
    query,
  )
  pushRoute(newRoute)
}

export function forwardToSiteAndBookingDetails(
  splat,
  contractId,
  facilityId,
  productId,
  query = null,
  removeAddItemToCartParam = true,
) {
  if (removeAddItemToCartParam && !isEmpty(query)) {
    query = removeAddItemToCartObj(query)
  }

  const newRoute = getSiteAndBookingDetailsRoute(
    splat,
    contractId,
    facilityId,
    productId,
    query,
  )
  pushRoute(newRoute)
}

export function forwardToDaypassAndBookingDetails(
  splat,
  contractId,
  facilityId,
  productId,
  query = null,
  removeAddItemToCartParam = true,
) {
  if (removeAddItemToCartParam && !isEmpty(query)) {
    query = removeAddItemToCartObj(query)
  }

  const newRoute = getDaypassAndBookingDetailsRoute(
    splat,
    contractId,
    facilityId,
    productId,
    query,
  )
  pushRoute(newRoute)
}

export function forwardToHourlySiteAndBookingDetails(
  splat,
  contractId,
  facilityId,
  productId,
  query = null,
  removeAddItemToCartParam = true,
) {
  if (removeAddItemToCartParam && !isEmpty(query)) {
    query = removeAddItemToCartObj(query)
  }

  const newRoute = getHourlySiteAndBookingDetailsRoute(
    splat,
    contractId,
    facilityId,
    productId,
    query,
  )
  pushRoute(newRoute)
}

export function forwardToProductNotification(
  splat,
  contractId,
  facilityId,
  productId,
  query = null,
) {
  const newRoute = getProductNotificationRoute(
    splat,
    contractId,
    facilityId,
    productId,
    query,
  )
  pushRoute(newRoute)
}

export function forwardToProductBookingDetails(query = null) {
  const newRoute = getProductBookingDetailsRoute(query)
  pushRoute(newRoute)
}

export function forwardToProductBookingSupplementDetails(query = null) {
  const newRoute = getProductBookingSupplementDetailsRoute(query)
  pushRoute(newRoute)
}

export function forwardToCartView(query = null) {
  const route = getCartViewRoute(query)
  pushRoute(route)
}

export function forwardToCartItem(query = null) {
  const route = getCartItemRoute(query)
  pushRoute(route)
}

export function forwardToCartCheckout(query = null) {
  const route = getCartCheckoutRoute(query)
  pushRoute(route)
}

export function forwardToCartConfirmation(query = null) {
  const route = getCartConfirmationRoute(query)
  pushRoute(route)
}

export function forwardToOffer(query = null) {
  const route = getOfferRoute(query)
  pushRoute(route)
}

export function forwardToFavorites(query = null) {
  const route = getFavoritesRoute(query)
  pushRoute(route)
}

export const forwardToMyAccountDashboard = function (query = null) {
  const route = getMyAccountDashboardRoute(query)
  pushRoute(route)
}

export const forwardToMyAccountReservations = function (query = null) {
  const route = getMyAccountReservationsRoute(query)
  pushRoute(route)
}

export const forwardToMyAccountReservationDetails = function (query = null) {
  const route = getMyAccountReservationDetailsRoute(query)
  pushRoute(route)
}

export const forwardToMyAccountReservationCancel = function (query = null) {
  const route = getMyAccountReservationCancelRoute(query)
  pushRoute(route)
}

export const forwardToMyAccountReservationPreCheckin = function (query = null) {
  const route = getMyAccountReservationPreCheckinRoute(query)
  pushRoute(route)
}

export const forwardToMyAccountReservationPayment = function (query = null) {
  const route = getMyAccountReservationPaymentRoute(query)
  pushRoute(route)
}

export const forwardToMyAccountReservationChange = function (query = null) {
  const route = getMyAccountReservationChangeRoute(query)
  pushRoute(route)
}

export const forwardToMyAccountReservationChangeSupplementDetails = function (
  query = null,
) {
  const route = getMyAccountReservationChangeSupplementDetailsRoute(query)
  pushRoute(route)
}

export const forwardToMyAccountReservationChangeConfirmation = function (
  query = null,
) {
  const route = getMyAccountReservationChangeConfirmationRoute(query)
  pushRoute(route)
}

export const forwardToMyAccountNotifications = function (query = null) {
  const route = getMyAccountNotificationsRoute(query)
  pushRoute(route)
}

export const forwardToMyAccountApplications = function (query = null) {
  const route = getMyAccountApplicationsRoute(query)
  pushRoute(route)
}

export const forwardToMyAccountPasses = function (query = null) {
  const route = getMyAccountPassesRoute(query)
  pushRoute(route)
}

export const forwardToMyAccountVouchers = function (query = null) {
  const route = getMyAccountVouchersRoute(query)
  pushRoute(route)
}

export const forwardToMyAccountPoints = function (query = null) {
  const route = getMyAccountPointsRoute(query)
  pushRoute(route)
}

export const forwardToMyAccountPointsDetails = function (query = null) {
  const route = getMyAccountPointsDetailsRoute(query)
  pushRoute(route)
}

export const forwardToMyAccountEquipment = function (query = null) {
  const route = getMyAccountEquipmentRoute(query)
  pushRoute(route)
}

export const forwardToMyAccountEquipmentDetails = function (query = null) {
  const route = getMyAccountEquipmentDetailsRoute(query)
  pushRoute(route)
}

export const forwardToMyAccountPrintTicketsPermits = function (query = null) {
  const route = getMyAccountPrintTicketsPermitsRoute(query)
  pushRoute(route)
}

export const forwardToMyAccountProfile = function (query = null) {
  const route = getMyAccountProfileRoute(query)
  pushRoute(route)
}

export const forwardToMyAccountCampClubProfile = function (query = null) {
  const route = getMyAccountCampClubProfileRoute(query)
  pushRoute(route)
}

export const forwardToMyAccountRateReservation = function (query = null) {
  const route = getMyAccountRateReservationRoute(query)
  pushRoute(route)
}

export const forwardToPOSItem = function (query = null) {
  const route = getPOSItemRoute(query)
  pushRoute(route)
}

export const forwardToContactUs = function (query = null) {
  const route = getContactUsRoute(query)
  pushRoute(route)
}

export const forwardToAdvertise = function (query = null) {
  const route = getAdvertiseRoute(query)
  pushRoute(route)
}

// Util Methods
/**
 * @param {string} route - current route
 * @returns {boolean} - true if route is facility site list
 * @deprecated
 */
export function isFacilitySiteList(route) {
  return isCurrentRouteName(route, RouteNames.SITE_LIST)
}

/**
 * @param {string} route - current route
 * @returns {boolean} - true if route is facility site calendar
 * @deprecated
 */
export function isFacilitySitesCalendar(route) {
  return isCurrentRouteName(route, RouteNames.SITES_CALENDAR)
}

/**
 * @param {string} route - current route
 * @returns {boolean} - true if route is facility overview
 * @deprecated
 */
export function isFacilityOverview(route) {
  return isCurrentRouteName(route, RouteNames.OVERVIEW)
}

// TODO - SSR - change all above deprecated methods to match the one below.
export function isRouteFacilitySiteList(route) {
  return isPathMatch(route, RouteNames.ROUTE_SITE_LIST)
}

export function isRouteFacilitySitesCalendar(route) {
  return isPathMatch(route, RouteNames.ROUTE_SITES_CALENDAR)
}

export function isRouteFacilityHourlySiteList(route) {
  return isPathMatch(route, RouteNames.ROUTE_HOURLY_SITE_LIST)
}

export function isRouteFacilityHourlySitesCalendar(route) {
  return isPathMatch(route, RouteNames.ROUTE_HOURLY_SITES_CALENDAR)
}

export function isRouteFacilityDayPasses(route) {
  return isPathMatch(route, RouteNames.ROUTE_DAY_PASSES)
}

export function isRouteFacilityOverview(route) {
  return isPathMatch(route, RouteNames.ROUTE_OVERVIEW)
}

export function isRouteFacilityCheckout(route) {
  return isPathMatch(route, RouteNames.ROUTE_OVERVIEW_CHECKOUT)
}

export function isRouteFacilityChubOverview(route) {
  return (
    isPathMatch(route, RouteNames.ROUTE_OVERVIEW) ||
    isPathMatch(route, RouteNames.ROUTE_OVERVIEW_CHECKOUT) ||
    isPathMatch(route, RouteNames.ROUTE_OVERVIEW_CONFIRMATION)
  )
}

export function isRouteFacilityChubCart(route) {
  return (
    isPathMatch(route, RouteNames.ROUTE_OVERVIEW_CHECKOUT) ||
    isPathMatch(route, RouteNames.ROUTE_OVERVIEW_CONFIRMATION)
  )
}

export function getRouteObject(props) {
  const ro = {
    params: props.params,
    query: props.location.query,
  }

  return ro
}

export function isVisibleForRoute(hideOnRoutes, location) {
  const dex = location.lastIndexOf('/')
  const routeName = location.substring(dex + 1, location.length)

  return !contains(hideOnRoutes, routeName)
}

export function getCurrentPageRoute(location) {
  const dex = location.lastIndexOf('/')

  return location.substring(dex + 1, location.length)
}

// External Links
export function getCurrentPathFromProps(
  props,
  newQueryObj = {},
  withQuery = true,
) {
  let newQueryStr = addObjToQueryString(newQueryObj, props.location.search)
  if (!withQuery) {
    newQueryStr = ''
  }

  return props.location.pathname + newQueryStr
}

export function getCurrentPathFromLocation(
  location,
  newQueryObj = {},
  withQuery = true,
) {
  let newQueryStr = addObjToQueryString(newQueryObj, location.search)
  if (!withQuery) {
    newQueryStr = ''
  }

  return location.pathname + newQueryStr
}

export function getFullAppPath() {
  // TODO - This is a hack for now - wont work on Isomorphic app and
  // is using bad practices - fix
  // const base = '/owp-webclient.do'

  if (isEnvServer()) return ''

  const base = getPath(RouteNames.ROUTE_ROOT)
  let port = root.location.port || ''

  if (port) {
    port = `:${port}`
  }

  return `${root.location.protocol}//${root.location.hostname}${port}${base}`
}

export function getFullPath(path) {
  // TODO - This is a hack for now - wont work on Isomorphic app and
  // is using bad practices - fix
  // const base = 'owp-webclient.do#'
  const base = ''
  const returnPath = encodeURIComponent(base + path)

  return returnPath
}

export function getFullLoginAndReturnPath(path) {
  let queryString = getQuery(path)
  if (!isEmpty(queryString)) {
    queryString = `?${queryString}`
  }

  const pathWithoutQuery = getPathWithoutQuery(path)
  const query = {}

  const newQueryStr = addObjToQueryString(query, queryString)
  const pt = `/signin?landing=${encodeURIComponent(
    pathWithoutQuery,
  )}${encodeURIComponent(newQueryStr)}`

  return pt
}

export function forwardToLoginWithReturnPath(path) {
  // TODO - This is a hack for now - wont work on Isomorphic app and
  // is using bad practices - fix

  const newRoute = getFullLoginAndReturnPath(path)
  /*
   Pedro Nobrega:
        reference: Jira ticket NEWRA-92
  */
  sendToExternalPath(newRoute)

  // const dest = 'https://newra-dev.auth.us-east-1.amazoncognito.com/login?client_id=nqq8nj0iu19b98c3ifepbg60h&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://c8wn55n6mb.us-east-1.awsapprunner.com/'
  // sendToExternalPath(dest)
}

export function sendToExternalPath(path, newWindow = false, noOpener = false) {
  if (newWindow) {
    if (noOpener) {
      root.open(path, '_blank', 'noopener')
    } else {
      root.open(path, '_blank')
    }
  } else {
    root.location.href = path
  }
}

export function sendToInternalPath(path) {
  pushRoute(path)
}

// Used for mailto:, sms: and other special links
export function openSpecialProtocolPath(protocol, path) {
  root.open(protocol + path, '_self')
}

export function encodeURL(url) {
  return encodeURIComponent(url)
}

export function forwardToFacilityOverviewExternal(
  splat,
  contractId,
  facilityId,
  query = null,
) {
  const newRoute = getFacilityOverviewRoute(
    splat,
    contractId,
    facilityId,
    query,
  )
  sendToExternalPath(newRoute, true, true)
}

export function forwardToFacilitySiteListExternal(
  splat,
  contractId,
  facilityId,
  query = null,
) {
  const newRoute = getFacilitySiteListRoute(
    splat,
    contractId,
    facilityId,
    query,
  )
  sendToExternalPath(newRoute, true, true)
}

export function forwardToFacilitySitesCalendarExternal(
  splat,
  contractId,
  facilityId,
  query = null,
) {
  const newRoute = getFacilitySitesCalendarRoute(
    splat,
    contractId,
    facilityId,
    query,
  )
  sendToExternalPath(newRoute, true, true)
}

export function forwardToExternalOrderDetails(cartItemId) {
  const pt = `/reservationDetails.do${getQueryStringFromObj({
    currentItemId: cartItemId,
  })}`
  sendToExternalPath(pt)
}

export function forwardToExternalCartView() {
  const pt = '/viewShoppingCart.do'
  sendToExternalPath(pt)
}

export function forwardToExternalMyAccount() {
  const pt = '/memberAccountOverview.do'
  sendToExternalPath(pt)
}

export function forwardToExternalWelcome() {
  const pt = '/owpSwitch.do'
  sendToExternalPath(pt)
}

export function forwardToExternalCurrentReservations() {
  const pt = '/reservation.do?mode=current'
  sendToExternalPath(pt)
}

export function forwardToExternalViewOrder(contractCode, orderNum) {
  const pt = `/reservationDetail.do?contractCode=${contractCode}&reservationNumber=${orderNum}&mode=current`
  sendToExternalPath(pt)
}

export function getExternalListCampgroundURL() {
  const pt = 'http://www.aspiraconnect.com/Astra'

  return pt
}

export function replaceSignInTrackingParams(pathName, queryString) {
  const qs = removeSignInTrackingParams(queryString)

  hackReplaceRoute(pathName + qs)
}
