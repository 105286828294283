import { getClientTodayYear } from '../external/utilities/DateUtils'
import {
  findObjectInArray,
  isEmpty,
  isLocalStorage,
  isUndefined,
} from '../external/utilities/GeneralUtils'

const UC_ACTION_INVENTORY_ANONYMOUS_LOCK = 'inventoryAnonymousLock'
const UC_ACTION_CHECKOUT = 'checkout'
const UC_ACTION_SIGN_UP = 'signUp'

const KEY_ID_GOOGLE_RECAPTCHA = 'googleRecaptcha'
const KEY_ID_GOOGLE_RECAPTCHA_V3 = 'googleRecaptchaV3'
const KEY_ID_GOOGLE_ANALYTICS = 'googleAnalytics'
const KEY_ID_GOOGLE_ANALYTICS4 = 'googleAnalytics4'
const KEY_ID_GOOGLE_MAPS = 'googleMaps'
const KEY_ID_MIXPANEL = 'mixpanel'
const KEY_ID_BRAZE_APP_ID = 'brazeAppKey'
const KEY_ID_BRAZE_SDK_HOST = 'brazeSdkHost'

const SWITCH_ID_USE_NEW_CHECKOUT_WORKFLOW = 'useNewCheckoutWorkflow'
const SWITCH_ID_USE_NEW_FAVORITES_WORKFLOW = 'useNewFavoritesWorkflow'
const SWITCH_ID_USE_NEW_MY_ACCOUNT_WORKFLOW = 'useNewMyAccountWorkflow'
const SWITCH_ID_SHOW_LEGACY_SITE_LINK = 'showLegacySiteLink'
const BRAND_ATTRIBUTE_FAVORITES = 'favoritesEnabled'

const REDIRECT_ID_BOOKING_REDIRECT = 'bookingRedirect'
const REDIRECT_ID_FACILITY_REDIRECT = 'facilityRedirect'
const REDIRECT_ID_ORDER_REDIRECT = 'orderRedirect'
const REDIRECT_ID_AVAILABILITY_NOTOFICATION_DETAIL_REDIRECT =
  'availabilityNotificationDetailRedirect'
const REDIRECT_ID_LOYALTY_POINTS_DETAIL_REDIRECT = 'loyaltyPointsDetailRedirect'

const REDIRECT_ID_FACILITY_EMBEDDED_FRAME = 'facilityEmbeddedFrame'

/* ------- App Config ------- */
export function getAppConsumerProductName(mainConfig, contractCode) {
  return mainConfig.app.consumerProductName
}

/* ------- Redirect Configs ------- */
export function getRedirectConfig(mainConfig, redirectConfigId) {
  let redirectConfig = null
  if (!isUndefined(mainConfig) && !isEmpty(mainConfig)) {
    if (!isEmpty(mainConfig.redirects) && mainConfig.redirects.length > 0) {
      for (const configItem of mainConfig.redirects) {
        if (configItem.id === redirectConfigId) {
          redirectConfig = configItem
          break
        }
      }
    }
  }

  return redirectConfig
}

export function getRedirectConfigForContract(redirectConfig, contractCode) {
  let redirectConfigForContract = {}
  if (!isUndefined(redirectConfig) && !isEmpty(redirectConfig)) {
    if (
      !isEmpty(redirectConfig.contracts) &&
      redirectConfig.contracts.length > 0
    ) {
      for (const redirectConfigItem of redirectConfig.contracts) {
        if (redirectConfigItem.contractCode === contractCode) {
          redirectConfigForContract = redirectConfigItem
          break
        }
      }
    }
  }

  return redirectConfigForContract
}

export function getBookingRedirectConfig(mainConfig, contractCode) {
  const redirectConfig = getRedirectConfig(
    mainConfig,
    REDIRECT_ID_BOOKING_REDIRECT,
  )

  return getRedirectConfigForContract(redirectConfig, contractCode)
}

export function getFacilityRedirectConfig(mainConfig, contractCode) {
  const redirectConfig = getRedirectConfig(
    mainConfig,
    REDIRECT_ID_FACILITY_REDIRECT,
  )

  return getRedirectConfigForContract(redirectConfig, contractCode)
}

export function getOrderRedirectConfig(mainConfig, contractCode) {
  const redirectConfig = getRedirectConfig(
    mainConfig,
    REDIRECT_ID_ORDER_REDIRECT,
  )

  return getRedirectConfigForContract(redirectConfig, contractCode)
}

export function getAvailabilityNotificationRedirectConfig(
  mainConfig,
  contractCode,
) {
  const redirectConfig = getRedirectConfig(
    mainConfig,
    REDIRECT_ID_AVAILABILITY_NOTOFICATION_DETAIL_REDIRECT,
  )

  return getRedirectConfigForContract(redirectConfig, contractCode)
}

export function getLoyaltyPointsRedirectConfig(mainConfig, contractCode) {
  const redirectConfig = getRedirectConfig(
    mainConfig,
    REDIRECT_ID_LOYALTY_POINTS_DETAIL_REDIRECT,
  )

  return getRedirectConfigForContract(redirectConfig, contractCode)
}

export function getFacilityEmbeddedFrameConfig(mainConfig, contractCode) {
  const redirectConfig = getRedirectConfig(
    mainConfig,
    REDIRECT_ID_FACILITY_EMBEDDED_FRAME,
  )

  return getRedirectConfigForContract(redirectConfig, contractCode)
}

export function getFacilityEmbeddedFrameConfigForAgency(
  embedConfig,
  agencyName,
) {
  if (!isEmpty(embedConfig)) {
    for (const agency of embedConfig.agencies) {
      if (agencyName === agency.agencyName) {
        return agency
      }
    }
  }

  return null
}

export function getEmbeddedFrameURLForEnv(agencyEmbedConfig, env = 'default') {
  if (!isEmpty(agencyEmbedConfig)) {
    for (const embedURL of agencyEmbedConfig.embedURL) {
      if (embedURL.env === env) {
        return embedURL.link
      }
    }

    // Nothing found for the env. Return value for default, if it exists
    for (const embedURL of agencyEmbedConfig.embedURL) {
      if (embedURL.env === 'default') {
        return embedURL.link
      }
    }
  }

  return ''
}

/* ------- Keys ------- */
export function getKeyForIdAndParam(keyId, mainConfig, param = 'default') {
  let key = ''
  if (!isEmpty(mainConfig.keys) && mainConfig.keys.length > 0) {
    for (const configKey of mainConfig.keys) {
      if (configKey.id === keyId) {
        for (const paramObj of configKey.values) {
          if (paramObj.param === param) {
            key = paramObj.value
            break
          }
        }
        break
      }
    }
  }

  return key
}

export function getGoogleRecaptchaKey(mainConfig) {
  return getKeyForIdAndParam(KEY_ID_GOOGLE_RECAPTCHA, mainConfig)
}

export function getGoogleRecaptchaV3Key(mainConfig, env = 'default') {
  let googleRecaptchaV3Key = getKeyForIdAndParam(
    KEY_ID_GOOGLE_RECAPTCHA_V3,
    mainConfig,
    env,
  )

  // No key for env so get default key
  if (googleRecaptchaV3Key === '') {
    googleRecaptchaV3Key = getKeyForIdAndParam(
      KEY_ID_GOOGLE_RECAPTCHA_V3,
      mainConfig,
      'default',
    )
  }

  return googleRecaptchaV3Key
}

export function getGoogleAnalyticsKey(mainConfig, env = 'default') {
  let googleAnalyticsKey = getKeyForIdAndParam(
    KEY_ID_GOOGLE_ANALYTICS,
    mainConfig,
    env,
  )

  // No key for env so get default key
  if (googleAnalyticsKey === '') {
    googleAnalyticsKey = getKeyForIdAndParam(
      KEY_ID_GOOGLE_ANALYTICS,
      mainConfig,
      'default',
    )
  }

  return googleAnalyticsKey
}

export function getGoogleAnalytics4Key(mainConfig, env = 'default') {
  let googleAnalyticsKey = getKeyForIdAndParam(
    KEY_ID_GOOGLE_ANALYTICS4,
    mainConfig,
    env,
  )

  // No key for env so get default key
  if (googleAnalyticsKey === '') {
    googleAnalyticsKey = getKeyForIdAndParam(
      KEY_ID_GOOGLE_ANALYTICS4,
      mainConfig,
      'default',
    )
  }

  return googleAnalyticsKey
}

export function getGoogleMapsKey(mainConfig, env = 'default') {
  let googleMapsKey = getKeyForIdAndParam(KEY_ID_GOOGLE_MAPS, mainConfig, env)

  // No key for env so get default key
  if (googleMapsKey === '') {
    googleMapsKey = getKeyForIdAndParam(
      KEY_ID_GOOGLE_MAPS,
      mainConfig,
      'default',
    )
  }

  return googleMapsKey
}

export function getMixpanelKey(mainConfig, env = 'default') {
  let mixpanelKey = getKeyForIdAndParam(KEY_ID_MIXPANEL, mainConfig, env)

  // No key for env so get default key
  if (mixpanelKey === '') {
    mixpanelKey = getKeyForIdAndParam(KEY_ID_MIXPANEL, mainConfig, 'default')
  }

  if (isLocalStorage()) {
    if (mixpanelKey === '') {
      mixpanelKey = localStorage.getItem('ra.com.mixpanel.key')
    } else {
      localStorage.setItem('ra.com.mixpanel.key', mixpanelKey)
    }
  }

  return mixpanelKey
}

export function getBrazeConfig(mainConfig, env = 'default', configId = '') {
  let brazeConfig = getKeyForIdAndParam(configId, mainConfig, env)
  // No key for env so get default key
  if (brazeConfig === '') {
    brazeConfig = getKeyForIdAndParam(configId, mainConfig, 'default')
  }

  return brazeConfig
}

export function getBrazeAppKey(mainConfig, env = 'default') {
  return getBrazeConfig(mainConfig, env, KEY_ID_BRAZE_APP_ID)
}

export function getBrazeSdkHost(mainConfig, env = 'default') {
  return getBrazeConfig(mainConfig, env, KEY_ID_BRAZE_SDK_HOST)
}

/* ------- User Challenges ------- */
export function isUserChallengeAnonymousInventoryLock(
  mainConfig,
  isConfigured = false,
) {
  return isUserChallengeForAction(
    UC_ACTION_INVENTORY_ANONYMOUS_LOCK,
    mainConfig,
    isConfigured,
  )
}

export function isUserChallengeCheckout(mainConfig, isConfigured = false) {
  return isUserChallengeForAction(UC_ACTION_CHECKOUT, mainConfig, isConfigured)
}

export function isUserChallengeForAction(
  action,
  mainConfig,
  isConfigured = false,
) {
  let show = false
  if (
    !isEmpty(mainConfig.userChallenge) &&
    !isEmpty(mainConfig.userChallenge.actions) &&
    mainConfig.userChallenge.actions.length > 0
  ) {
    for (const configAction of mainConfig.userChallenge.actions) {
      if (configAction.action === action) {
        if (configAction.show === 'always') {
          show = true
        } else if (configAction.show === 'ifConfigured') {
          show = isConfigured
        }
        break
      }
    }
  }

  return show
}

/* ------- Switches ------- */
export function getSwitchUseNewCheckoutWorkflow(mainConfig) {
  return getSwitch(SWITCH_ID_USE_NEW_CHECKOUT_WORKFLOW, mainConfig)
}

export function getSwitchUseNewFavoritesWorkflow(mainConfig) {
  return getSwitch(SWITCH_ID_USE_NEW_FAVORITES_WORKFLOW, mainConfig)
}

export function getSwitchUseNewMyAccountWorkflow(mainConfig) {
  return getSwitch(SWITCH_ID_USE_NEW_MY_ACCOUNT_WORKFLOW, mainConfig)
}

export function getShowLegacySiteLink(mainConfig) {
  return getSwitch(SWITCH_ID_SHOW_LEGACY_SITE_LINK, mainConfig)
}

export function getSwitch(switchId, mainConfig) {
  let value = null
  if (!isEmpty(mainConfig.switches) && mainConfig.switches.length > 0) {
    for (const currSwitch of mainConfig.switches) {
      if (currSwitch.id === switchId) {
        value = currSwitch.value
        break
      }
    }
  }

  return value
}

export function getBrandConfigRootValue(brandConfig, attribute) {
  let value = null
  if (!isEmpty(brandConfig)) {
    value = brandConfig[attribute]
  }

  return value
}

/* ------- Cart ------- */
export function getMissingOrderDetailsRedirectURL(mainConfig) {
  let value = null
  if (!isEmpty(mainConfig)) {
    value = mainConfig.cart.missingOrderDetailsRedirectURL
  }

  return value
}

export function getCartTimerColorLevels(mainConfig) {
  let value = null
  if (!isEmpty(mainConfig) && !isEmpty(mainConfig.cart)) {
    value = mainConfig.cart.timerColorLevels
  }

  return value
}

export function getCartTimerAlerts(mainConfig) {
  let value = null
  if (!isEmpty(mainConfig) && !isEmpty(mainConfig.cart)) {
    value = mainConfig.cart.timerAlerts
  }

  return value
}

export function getConfirmationFeedbackDelay(mainConfig) {
  let value = null
  if (!isEmpty(mainConfig) && !isEmpty(mainConfig.cart)) {
    value = mainConfig.cart.confirmationFeedbackDelay
  }

  return value
}

export function getConfirmationPostConfig(mainConfig) {
  let value = null
  if (!isEmpty(mainConfig) && !isEmpty(mainConfig.cart)) {
    value = mainConfig.cart.confirmationPostConfig
  }

  return value
}

/* ------- Payment Config ------- */
export function getCreditCardDisplayYears(mainConfig) {
  let years = 10
  if (!isEmpty(mainConfig)) {
    years = mainConfig.paymentConfig.creditCard.displayYears
  }

  return years
}

export function getCreditCardMonthsList(mainConfig) {
  let monthsList = []
  if (!isEmpty(mainConfig)) {
    monthsList = mainConfig.paymentConfig.creditCard.monthsList
  }

  return monthsList
}

export function getSiteSecurityLogos(mainConfig) {
  let logoList = []
  if (!isEmpty(mainConfig)) {
    logoList = mainConfig.paymentConfig.siteSecurityLogos
  }

  return logoList
}

export function getVoucherPaymentAmountOptions(mainConfig) {
  let options = []
  if (!isEmpty(mainConfig)) {
    options = mainConfig.paymentConfig.voucherConfig.allowedPaymentAmountTypes
  }

  return options
}

export function getGiftCardPaymentAmountOptions(mainConfig) {
  let options = []
  if (!isEmpty(mainConfig)) {
    options = mainConfig.paymentConfig.giftCardConfig.allowedPaymentAmountTypes
  }

  return options
}

// TODO - Get date from server - client date is unreliable
export function getCurrentYear(mainConfig) {
  return getClientTodayYear()
}

/* ------- Other Config ------- */
/* ------- Move out later? ------- */
export function isAllowFavorites(mainConfig, brandConfig) {
  if (
    getSwitchUseNewFavoritesWorkflow(mainConfig) &&
    getBrandConfigRootValue(brandConfig, BRAND_ATTRIBUTE_FAVORITES)
  ) {
    return true
  }

  return false
}

/** ------- Facility Photo File Upload ------- */
export function getPhotoUploadFileTypes(mainConfig) {
  let value = null
  if (!isEmpty(mainConfig) && !isEmpty(mainConfig.photoUpload)) {
    value = mainConfig.photoUpload.validFileTypes
  }

  return value
}

export function getPhotoUploadMaxSize(mainConfig) {
  let value = null
  if (!isEmpty(mainConfig) && !isEmpty(mainConfig.photoUpload)) {
    value = mainConfig.photoUpload.maxSize
  }

  return value
}

/** ------- Rating ------- */
export function getRatingLegend(mainConfig) {
  let legend = null
  if (!isEmpty(mainConfig) && !isEmpty(mainConfig.rating)) {
    legend = mainConfig.rating.valueLegend
  }

  return legend
}

export function getRatingDescription(legend, value) {
  let description = ''
  if (!isEmpty(legend)) {
    const descriptionObj = findObjectInArray(legend, 'value', value)
    if (descriptionObj !== null) {
      description = descriptionObj.description
    }
  }

  return description
}

/* ------- Ads Config ------- */
export function getAdConfig(mainConfig) {
  let adConfig = null
  if (!isEmpty(mainConfig)) {
    adConfig = mainConfig.ads
  }

  return adConfig
}

/* ------- Marketing Spots Config ------- */
export function getMarketingSpotsConfig(mainConfig) {
  let marketingSpotsConfig = null
  if (!isEmpty(mainConfig)) {
    marketingSpotsConfig = mainConfig.marketingSpots
  }

  return marketingSpotsConfig
}

/* ------- Map Config ------- */
export function getMapConfig(mainConfig) {
  let mapConfig = null
  if (!isEmpty(mainConfig)) {
    mapConfig = mainConfig.map
  }

  return mapConfig
}

/* ------- Trackers Config ------- */
export function getTrackersConfig(mainConfig) {
  let trackersConfig = null
  if (!isEmpty(mainConfig)) {
    trackersConfig = mainConfig.trackers
  }

  return trackersConfig
}

/* ------- Client Logo Source ------- */
export function getClientLogoSource(mainConfig, contractCode) {
  let logoSource = null
  if (!isEmpty(mainConfig) && !isEmpty(contractCode)) {
    const { configs, baseImageLocation } = mainConfig.clientLogos
    for (const config of configs) {
      if (config.contractCode === contractCode) {
        logoSource = baseImageLocation + config.mainImage
        break
      }
    }
  }

  return logoSource
}

/* ------- UI Config ------- */
export function getPageUIConfig(mainConfig, pageId) {
  let pageUIConfig = null
  if (!isEmpty(mainConfig) && !isUndefined(mainConfig.uiConfig)) {
    for (const currPageUIConfig of mainConfig.uiConfig.pages) {
      if (currPageUIConfig.pageId === pageId) {
        pageUIConfig = currPageUIConfig
        break
      }
    }
  }

  return pageUIConfig
}

/* ------- UI Analytics Config ------- */
export function getUIAnalyticsConfig(mainConfig, analyticsId) {
  let uiAnalyticsConfig = null
  if (
    !isEmpty(mainConfig) &&
    !isUndefined(mainConfig.uiAnalytics) &&
    mainConfig.uiAnalytics.active
  ) {
    for (const currUIAnalyticsConfig of mainConfig.uiAnalytics.configs) {
      if (currUIAnalyticsConfig.id === analyticsId) {
        uiAnalyticsConfig = currUIAnalyticsConfig
        break
      }
    }
  }

  return uiAnalyticsConfig
}

/* ------- LOB Support ------- */
export function isLOBSupported(mainConfig, lob) {
  let supported = false
  if (
    !isEmpty(mainConfig) &&
    !isUndefined(mainConfig.LOBSupport) &&
    !isUndefined(mainConfig.LOBSupport.available)
  ) {
    for (const currLOB of mainConfig.LOBSupport.available) {
      if (currLOB === lob) {
        supported = true
        break
      }
    }
  }

  return supported
}
