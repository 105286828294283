import { object, string } from 'prop-types'
import React from 'react'

import AImgLink from '../../../external/components/link/AImgLink'
import { getHomeRoute } from '../../../router/RouteManager'
import { simpleTranslate } from '../../Translation'

const Brand = ({ intl }) => (
  <div className="site-navigation__brand">
    <AImgLink
      to={getHomeRoute()}
      linkAlt={simpleTranslate(intl, 'labels_navigation_logo_link_alt')}
      linkTitle={simpleTranslate(intl, 'labels_navigation_logo_link_title')}
      imgURL={'/newra/assets/images/ra/ra-logo.svg'}
      imgAlt={simpleTranslate(intl, 'labels_navigation_logo_img_alt')}
    />
  </div>
)

Brand.propTypes = {
  theme: string,
  intl: object.isRequired,
}

export default Brand
