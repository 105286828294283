import { startOfToday, startOfTomorrow } from 'date-fns'

import * as types from '../actions/ActionTypes'

export const initialState = {
  reservationDates: {
    selectedMode: 'arrival',
    selectedDates: { from: startOfToday(), to: startOfTomorrow() },
    excludedDates: { arrival: [], departure: [] },
    dateBoundaries: { maxDate: undefined, minDate: undefined },
  },
}

export function setChubFormReservationDates(state = initialState, action) {
  return {
    ...state,
    reservationDates: {
      ...state.reservationDates,
      selectedDates: action.data,
    },
  }
}

export function setChubFormExcludedReservationDates(
  state = initialState,
  action,
) {
  return {
    ...state,
    reservationDates: {
      ...state.reservationDates,
      excludedDates: action.data,
    },
  }
}

export function setChubFormReservationDateMode(state = initialState, action) {
  return {
    ...state,
    reservationDates: {
      ...state.reservationDates,
      selectedMode: action.data,
    },
  }
}

export function setChubFormReservationDateBoundaries(
  state = initialState,
  action,
) {
  return {
    ...state,
    reservationDates: {
      ...state.reservationDates,
      dateBoundaries: action.data,
    },
  }
}

export default function chubForm(state = initialState, action) {
  switch (action.type) {
    case types.CHUB_SET_SELECTED_RESERVATION_DATES:
      return setChubFormReservationDates(state, action)
    case types.CHUB_SET_EXCLUDED_RESERVATION_DATES:
      return setChubFormExcludedReservationDates(state, action)
    case types.CHUB_SET_SELECTED_RESERVATION_DATE_MODE:
      return setChubFormReservationDateMode(state, action)
    case types.CHUB_SET_RESERVATION_DATE_BOUNDARIES:
      return setChubFormReservationDateBoundaries(state, action)
    default:
      return state
  }
}
