import PropTypes from 'prop-types'
import React from 'react'
import scrollToElement from 'scroll-to-element'

/** Component used to scroll to a certain point on a page.  Usually to take the user to a field that has an error when filling out a form */

const ScrollPoint = ({ name, children }) => <div id={name}>{children}</div>

ScrollPoint.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.object,
}

export default ScrollPoint

/*
in-quad
out-quad
in-out-quad
in-cube
out-cube
in-out-cube
in-quart
out-quart
in-out-quart
in-quint
out-quint
in-out-quint
in-sine
out-sine
in-out-sine
in-expo
out-expo
in-out-expo
in-circ
out-circ
in-out-circ
in-back
out-back
in-out-back
in-bounce
out-bounce
in-out-bounce
in-elastic
out-elastic
in-out-elastic
*/

export function scrollToPoint(
  name,
  duration = 200,
  easeFunction = 'in-out-cube',
  offset = -120,
) {
  scrollToElement(`#${name}`, {
    offset,
    ease: easeFunction,
    duration,
  })
}
