import PropTypes from 'prop-types'
import React from 'react'
import ModalBody from 'react-bootstrap/ModalBody'

/** Wrapper class for react-bootstrap modal body.  https://react-bootstrap.github.io/components.html#modals-props-modal-body */

const AModalBody = ({ bsPrefix, children }) => (
  <ModalBody bsPrefix={bsPrefix}>{children}</ModalBody>
)

AModalBody.propTypes = {
  /** Base CSS class and prefix for the component. Generally one should only change bsPrefix to provide new, non-Bootstrap, CSS styles for a component. */
  bsPrefix: PropTypes.string,
  /** Contents of modal body */
  children: PropTypes.node,
}
export default AModalBody
