import PropTypes from 'prop-types'
import React from 'react'

import { isEmpty, isUndefined } from '../../../utilities/GeneralUtils'
import ABaseLink from '../../link/ABaseLink'
import AIconAction from '../../link/AIconAction'
import HTag, { HEADER_TYPES } from '../../text/HTag'

/** Used in place of tabs.  Each tab can be a label, an icon, or a label combined with an icon

@example  ../../../../../docs/external/navigation/selector/SimpleSelector.md */

const SimpleSelector = ({
  itemLabels = [],
  itemIcons = [],
  selectedIndex = 0,
  onItemSelectRequest,
  getItemLink,
  useMargin = false,
  hasDivider = true,
  isUpperCase = true,
  selectedItemHeaderType,
}) => {
  let itemList = null

  const onItemClick = function (index) {
    if (selectedIndex !== index) {
      onItemSelectRequest(index)
    }
  }

  const getItemLinkLocal = function (index) {
    if (!isUndefined(getItemLink) && selectedIndex !== index) {
      return getItemLink(index)
    }

    return null
  }

  const getCurrentItemSimpleLink = function (item, index, styleClass) {
    if (!isUndefined(onItemSelectRequest)) {
      return (
        <ABaseLink
          to={getItemLinkLocal(index)}
          onClick={() => onItemClick(index)}
        >
          <span className={styleClass}>{item}</span>
        </ABaseLink>
      )
    }

    return (
      <ABaseLink to={getItemLinkLocal(index)}>
        <span className={styleClass}>{item}</span>
      </ABaseLink>
    )
  }

  const getCurrentItemIconLink = function (item, index, label) {
    if (!isUndefined(onItemSelectRequest)) {
      return (
        <AIconAction
          to={getItemLinkLocal(index)}
          faStyle={item}
          onClick={() => {
            onItemClick(index)
          }}
          label={label}
          asSpan
        />
      )
    }

    return (
      <AIconAction
        to={getItemLinkLocal(index)}
        faStyle={item}
        label={label}
        asSpan
      />
    )
  }

  const baseStyle = isUpperCase ? ' simple-selector__item--uppercase' : ''
  if (!isEmpty(itemIcons)) {
    itemList = itemIcons.map((item, index) => {
      const selected = selectedIndex === index
      let label = ''
      let styleClass =
        baseStyle +
        (useMargin
          ? ' simple-selector__items--margin '
          : ` simple-selector__items--options-${itemIcons.length}`)
      styleClass += selected
        ? ' simple-selector__item--selected'
        : ' simple-selector__item'
      if (!isEmpty(itemLabels)) {
        label = itemLabels[index]
      }

      // eslint-disable-next-line one-var
      const content = (
        <span className={styleClass} key={index}>
          {getCurrentItemIconLink(item, index, label)}
        </span>
      )

      if (
        selected &&
        !isUndefined(selectedItemHeaderType) &&
        !isEmpty(selectedItemHeaderType)
      ) {
        return <HTag type={selectedItemHeaderType}>{content}</HTag>
      }

      return content
    })
  } else if (!isEmpty(itemLabels)) {
    itemList = itemLabels.map((item, index) => {
      const selected = selectedIndex === index
      const styleClass =
        baseStyle +
        (selected
          ? ' simple-selector__item--selected'
          : ' simple-selector__item')

      // eslint-disable-next-line one-var
      const content = (
        <span
          className={`simple-selector__items${
            useMargin
              ? ' simple-selector__items--margin '
              : ` simple-selector__items--options-${itemLabels.length}`
          }`}
          key={index}
        >
          {getCurrentItemSimpleLink(item, index, styleClass)}
        </span>
      )
      if (
        selected &&
        !isUndefined(selectedItemHeaderType) &&
        !isEmpty(selectedItemHeaderType)
      ) {
        return (
          <HTag type={selectedItemHeaderType} key={index}>
            {content}
          </HTag>
        )
      }

      return content
    })
  }

  return (
    <div
      className={`simple-selector${
        useMargin ? ' simple-selector--margin' : ''
      }${hasDivider ? ' simple-selector--border' : ''}`}
    >
      {itemList}
    </div>
  )
}

SimpleSelector.propTypes = {
  /** array of labels for the tabs */
  itemLabels: PropTypes.array,
  /** array of icons for the tabs */
  itemIcons: PropTypes.array,
  /** which tab is currently selected */
  selectedIndex: PropTypes.number.isRequired,
  /** function to run when selecting a tab */
  onItemSelectRequest: PropTypes.func,
  /** function to get item link */
  getItemLink: PropTypes.func,
  /** if true, items will be separated by a small margin, otherwise items will be a % of their container */
  useMargin: PropTypes.bool,
  /** if true, there will be a divider below the selectors */
  hasDivider: PropTypes.bool,
  /** if true, uppercase the labels, default is true */
  isUpperCase: PropTypes.bool,
  /** set selected label as header h1 - h6 */
  selectedItemHeaderType: PropTypes.oneOf(HEADER_TYPES),
}

export default SimpleSelector
