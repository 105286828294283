import { array, func, object, string } from 'prop-types'
import React from 'react'

import Cont from '../../../external/components/layout/Cont'
import Section, {
  MAIN_INLINE,
  SUB_INLINE,
} from '../../../external/components/layout/Section'
import TextHoverable from '../../../external/components/link/TextHoverable'
import { simpleTranslate } from '../../Translation'

const ArticleFeature = ({
  intl,
  getArticleSelectLinkObj,
  featuredArticles,
  onArticleSelectRequest,
}) => {
  const getArticles = function () {
    const ui = featuredArticles.map((article, i) => (
      <Section key={i} style={MAIN_INLINE}>
        <Section
          className={'home-featured-article__category'}
          style={SUB_INLINE}
        >
          {article.categoryDisplayName}
        </Section>
        <Cont className={'home-featured-article__description'}>
          <TextHoverable
            fontSizeType={4}
            fontFamily={'Georgia'}
            fontColor={'blue-dark'}
            {...getArticleSelectLinkObj(article)}
            onClick={() => {
              onArticleSelectRequest(article)
            }}
          >
            {article.title}
          </TextHoverable>
        </Cont>
      </Section>
    ))

    return ui
  }

  return (
    <Cont className="home-featured-articles">
      <Section className={'home-featured-articles__title'} style={MAIN_INLINE}>
        {simpleTranslate(intl, 'labels_home_featured_articles_title')}
      </Section>
      {getArticles()}
    </Cont>
  )
}

ArticleFeature.propTypes = {
  theme: string.isRequired,
  intl: object.isRequired,
  getArticleSelectLinkObj: func.isRequired,
  featuredArticles: array.isRequired,
  onArticleSelectRequest: func.isRequired,
}

export default ArticleFeature
