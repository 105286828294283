import { bool, func, object } from 'prop-types'
import React from 'react'
import root from 'window-or-global'

import ACol from '../../../external/components/layout/ACol'
import ARow from '../../../external/components/layout/ARow'
import Cont, { ALIGN_CENTER } from '../../../external/components/layout/Cont'
import Section, { SUB } from '../../../external/components/layout/Section'
import ABaseLink from '../../../external/components/link/ABaseLink'
import { createSimpleLinkProps } from '../../../external/components/link/LinkUtils'
import AIcon, { ICONS } from '../../../external/components/media/icon/AIcon'
import * as ExtRouteManager from '../../../router/ExtRouteManager'
import {
  getAdvertiseRoute,
  getContactUsRoute,
} from '../../../router/RouteManager'
import { getCurrentYear, getShowLegacySiteLink } from '../../../utilities/ConfigUtils'
import Translation, { simpleTranslate } from '../../Translation'
import Advert from '../ad/Advert'
import HackHTag from '../text/HackHTag'

const MainFooter = ({
  intl,
  isLoading,
  adConfig,
  mainConfig,
  onFeedbackRequest,
}) => {
  let legacySiteLink = null
  if (isLoading) {
    return <div />
  }

  if (getShowLegacySiteLink(mainConfig)) {
    legacySiteLink = (
      <li>
        <a rel="nofollow" href={ExtRouteManager.getLeaveBetaRoute()}>
          <Translation
            ignoreContext={true}
            mkey="label_footer_beta"
            intl={intl}
          />
        </a>
      </li>
    )
  }

  return (
    <div className="footer">
      <div className="container">
        <ARow>
          <ACol xs={12}>
            <div className="footer__links row">
              <ACol sm={12} md={4}>
                <HackHTag h5>
                  <Cont className="footer__title">
                    {simpleTranslate(intl, 'label_footer_social_connect')}
                  </Cont>
                </HackHTag>
                <ARow>
                  <ACol xs={6}>
                    <ul>
                      <li>
                        <a
                          href={ExtRouteManager.getFacebookRoute()}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <AIcon icon={ICONS.facebook} />
                          {simpleTranslate(intl, 'label_facebook')}
                        </a>
                      </li>
                      <li>
                        <a
                          href={ExtRouteManager.getTwitterRoute()}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <AIcon icon={ICONS.twitter} />
                          {simpleTranslate(intl, 'label_twitter')}
                        </a>
                      </li>
                    </ul>
                  </ACol>
                  <ACol xs={6}>
                    <ul>
                      <li>
                        <a
                          href={ExtRouteManager.getInstagramRoute()}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <AIcon icon={ICONS.instagram} />
                          {simpleTranslate(intl, 'label_instagram')}
                        </a>
                      </li>
                      <li>
                        <a
                          href={ExtRouteManager.getPinterestRoute()}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <AIcon icon={ICONS.pinterest} />
                          {simpleTranslate(intl, 'label_pinterest')}
                        </a>
                      </li>
                    </ul>
                  </ACol>
                </ARow>
              </ACol>
              <ACol sm={12} md={4}>
                <HackHTag h5>
                  <Cont className="footer__title">
                    {simpleTranslate(intl, 'label_footer_your_outdoor_guide')}
                  </Cont>
                </HackHTag>
                <ARow>
                  <ACol xs={6}>
                    <ul>
                      <li>
                        <a href={ExtRouteManager.getArticlesRoute()}>
                          <Translation
                            ignoreContext={true}
                            mkey="label_footer_camping_guide"
                            intl={intl}
                          />
                        </a>
                      </li>
                      <li>
                        <a href={ExtRouteManager.getHuntingAndFishingRoute()}>
                          <Translation
                            mkey="label_footer_hunting"
                            intl={intl}
                          />
                        </a>
                      </li>
                      <li>
                        <a href={ExtRouteManager.getDestinationsRoute()}>
                          <Translation
                            mkey="label_footer_directory"
                            intl={intl}
                          />
                        </a>
                      </li>
                    </ul>
                  </ACol>
                  <ACol xs={6}>
                    <ul>
                      <li>
                        <a
                          href={ExtRouteManager.getHelpCenterRoute()}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Translation
                            ignoreContext={true}
                            mkey="label_footer_help"
                            intl={intl}
                          />
                        </a>
                      </li>
                      <li>
                        <ABaseLink onClick={onFeedbackRequest}>
                          <Translation
                            ignoreContext={true}
                            mkey="label_footer_feedback"
                            intl={intl}
                          />
                        </ABaseLink>
                      </li>
                    </ul>
                  </ACol>
                </ARow>
              </ACol>
              <ACol sm={12} md={4}>
                <ARow>
                  <ACol xs={6}>
                    <Cont cmpSRonly>
                      <Translation
                        mkey="label_footer_checkout_outdoors_apps"
                        intl={intl}
                      />
                    </Cont>
                    <Cont className="footer__mobile-apps-links">
                      <a
                        href={ExtRouteManager.getAppStoreRoute()}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          alt={simpleTranslate(
                            intl,
                            'label_footer_app_store_img_alt',
                            '',
                          )}
                          src="/newra/assets/images/ra/app_store.png"
                        />
                      </a>
                      <Section style={SUB} />
                      <a
                        href={ExtRouteManager.getPlayStoreRoute()}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          alt={simpleTranslate(
                            intl,
                            'label_footer_play_store_img_alt',
                            '',
                          )}
                          src="/newra/assets/images/ra/play_store.png"
                        />
                      </a>
                    </Cont>
                  </ACol>
                  <ACol xs={6}>
                    <Cont hideMD hideLG>
                      <Section />
                    </Cont>
                    <img
                      alt={simpleTranslate(
                        intl,
                        'label_footer_phone_img_alt',
                        '',
                      )}
                      src="/newra/assets/images/ra/ra_camping_mobile_app.png"
                    />
                  </ACol>
                </ARow>
              </ACol>
            </div>
          </ACol>
        </ARow>
        <ARow>
          <ACol xs={12}>
            <Section />
            <div className="footer__base-links">
              <ul className="clearfix">
                <li>
                  <ABaseLink {...createSimpleLinkProps(getContactUsRoute())}>
                    <Translation
                      ignoreContext={true}
                      mkey="label_footer_contact"
                      intl={intl}
                    />
                  </ABaseLink>
                </li>
                {/* <li><a href={ExtRouteManager.getContactUsRoute()} target="_blank" rel="noopener noreferrer"><Translation ignoreContext={true} mkey="label_footer_contact" intl={intl} /></a></li> */}
                {/* <li><a href={ExtRouteManager.getAdvertisingRoute()}><Translation ignoreContext={true} mkey="label_footer_ads" intl={intl} /></a></li> */}
                <li>
                  <a href={ExtRouteManager.getAboutUsRoute()}>
                    <Translation
                      ignoreContext={true}
                      mkey="label_footer_about"
                      intl={intl}
                    />
                  </a>
                </li>
                {/* <li><a href={ExtRouteManager.getProductsServicesRoute()}><Translation ignoreContext={true} mkey="label_footer_products" intl={intl} /></a></li> */}
                <li>
                  <a
                    href={ExtRouteManager.getPrivacyPolicyRoute()}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Translation
                      ignoreContext={true}
                      mkey="label_footer_copyright_privacy"
                      intl={intl}
                    />
                  </a>
                </li>
                <li>
                  <button className="base-link" id="pmLink">
                    <Translation
                      mkey="labels_text_privacy_manager"
                      intl={intl}
                    />
                  </button>
                </li>
                <li>
                  <a
                    href={ExtRouteManager.getCopyrightPolicyRoute()}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Translation
                      ignoreContext={true}
                      mkey="label_footer_copyright_policy"
                      intl={intl}
                    />
                  </a>
                </li>
                <li>
                  <a
                    href={ExtRouteManager.getTermsOfUseRoute()}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Translation
                      ignoreContext={true}
                      mkey="label_footer_terms"
                      intl={intl}
                    />
                  </a>
                </li>
                <li>
                  <a
                    href={ExtRouteManager.getCookiePolicyRoute()}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Translation
                      ignoreContext={true}
                      mkey="label_footer_cookies"
                      intl={intl}
                    />
                  </a>
                </li>
                <li>
                  <a href={ExtRouteManager.getMyAccountOverviewRoute()}>
                    <Translation
                      ignoreContext={true}
                      mkey="label_footer_account"
                      intl={intl}
                    />
                  </a>
                </li>
                <li>
                  <a
                    href={ExtRouteManager.getCareersRoute()}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Translation
                      ignoreContext={true}
                      mkey="label_footer_careers"
                      intl={intl}
                    />
                  </a>
                </li>
                <li>
                  <ABaseLink {...createSimpleLinkProps(getAdvertiseRoute())}>
                    <Translation
                      ignoreContext={true}
                      mkey="label_footer_ads"
                      intl={intl}
                    />
                  </ABaseLink>
                </li>
                {legacySiteLink}
              </ul>
              <div className="clearfix footer__copyright">
                <Translation
                  intl={intl}
                  ignoreContext={true}
                  mkey="label_footer_copyright_statement"
                  literalValues={{ year: getCurrentYear() }}
                />
                {/* {' '} */}
                {/* <span className="footer__traceId">{root.traceId}</span>{' '} */}
              </div>
              <div className="clearfix footer__note">
                <Translation
                  ignoreContext={true}
                  mkey="label_footer_note"
                  intl={intl}
                />
              </div>
            </div>
          </ACol>
        </ARow>
        <div className="clearfix"></div>
      </div>
      <Section />
      <Cont hideSM hideMD hideLG align={ALIGN_CENTER}>
        <Advert id={'HPM7'} adConfig={adConfig} />
      </Cont>
    </div>
  )
}

MainFooter.propTypes = {
  intl: object.isRequired,
  selectedSearchObj: object.isRequired,
  isLoading: bool.isRequired,
  adConfig: object,
  mainConfig: object.isRequired,
  onFeedbackRequest: func.isRequired,
}

export default MainFooter
