export const createSimpleLinkProps = function (
  to,
  internal = true,
  newWin = false,
  onClick = null,
) {
  return {
    to,
    internal,
    newWin,
    onClick,
  }
}
