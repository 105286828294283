import { differenceInMinutes, millisecondsInMinute } from 'date-fns'

import { getClientToday } from '../external/utilities/DateUtils'
import {
  isEmpty,
  isLocalStorage,
  isUndefined,
  trimArray,
} from '../external/utilities/GeneralUtils'
import { getFacilitySummaryFromFacility } from './FacilityUtils'

const RECENTLY_VIEWED_FACILITY_DATA = 'recentlyViewedFacilityData'
const SEARCH_AVAILABILITY_DATA = 'searchAvailabilityData'

function getNowTimeStamp() {
  return getClientToday()
}

function isCacheTimeExpired(cacheLastUpdate, maxCacheTimeMinutes = 0) {
  const now = getClientToday()
  const elapsedTime = differenceInMinutes(
    now,
    cacheLastUpdate * millisecondsInMinute,
  )

  return maxCacheTimeMinutes > 0 && elapsedTime > maxCacheTimeMinutes
}

export function saveApplicationLocalState(state) {
  if (isLocalStorage()) {
    /* Testing */
    // Theme will not be saved in localStorage
    localStorage.setItem('theme', state.theme)
    // localStorage.clear()
  }

  return state
}

export function savePendingPOSItemSelectionData(data) {
  if (isLocalStorage()) {
    localStorage.setItem('pendingPOSItemSelectionData', JSON.stringify(data))
  }
}

export function saveBackendLocalState(state) {
  if (isLocalStorage()) {
    localStorage.setItem('locale', state.intl.locale)
    savePendingPOSItemSelectionData()
    // localStorage.clear()
  }

  return state
}

export function getApplicationLocalState(state) {
  if (isLocalStorage()) {
    const theme = localStorage.getItem('theme')
    if (!isEmpty(theme)) {
      return {
        ...state,
        theme,
      }
    }

    return state
  }

  return state
}

export function getBackendLocalState(state) {
  if (isLocalStorage()) {
    const locale = localStorage.getItem('locale')
    const pendingPOSItemSelectionData = localStorage.getItem(
      'pendingPOSItemSelectionData',
    )

    return {
      ...state,
      intl: {
        ...state.intl,
        locale:
          !isUndefined(locale) && isEmpty(locale) ? locale : state.intl.locale,
      },
      pos: {
        ...state.pos,
        pendingPOSItemSelectionData:
          !isUndefined(pendingPOSItemSelectionData) &&
          !isEmpty(pendingPOSItemSelectionData)
            ? JSON.parse(pendingPOSItemSelectionData)
            : state.pos.pendingPOSItemSelectionData,
      },
    }
  }

  return state
}

export function createRecentlyViewedFacilityDataObj() {
  const recentlyViewedFacilityData = {}
  recentlyViewedFacilityData.lastUpdate = getNowTimeStamp()
  recentlyViewedFacilityData.list = []

  return recentlyViewedFacilityData
}

export function getRecentlyViewedFacilityData(maxCacheTimeMinutes = 0) {
  let recentlyViewedFacilityData = null
  if (isLocalStorage()) {
    const lsRecentlyViewedFacilityData = localStorage.getItem(
      RECENTLY_VIEWED_FACILITY_DATA,
    )

    if (
      !isUndefined(lsRecentlyViewedFacilityData) &&
      !isEmpty(lsRecentlyViewedFacilityData)
    ) {
      recentlyViewedFacilityData = JSON.parse(lsRecentlyViewedFacilityData)

      if (
        isCacheTimeExpired(
          recentlyViewedFacilityData.lastUpdate,
          maxCacheTimeMinutes,
        )
      ) {
        recentlyViewedFacilityData = createRecentlyViewedFacilityDataObj()
      }
    }
  }

  return recentlyViewedFacilityData
}

export function addFacilityToRecentlyViewedList(
  facility,
  maxFacilitesToCache = 4,
  maxCacheTimeMinutes = 0,
) {
  if (isLocalStorage()) {
    const facilitySummary = getFacilitySummaryFromFacility(facility)
    let recentlyViewedFacilityData =
      getRecentlyViewedFacilityData(maxCacheTimeMinutes)
    let alreadyAdded = false
    if (
      isUndefined(recentlyViewedFacilityData) ||
      isEmpty(recentlyViewedFacilityData)
    ) {
      recentlyViewedFacilityData = createRecentlyViewedFacilityDataObj()
    }

    for (const currFacility of recentlyViewedFacilityData.list) {
      if (currFacility.uniqueId === facilitySummary.uniqueId) {
        alreadyAdded = true
        break
      }
    }

    if (!alreadyAdded) {
      recentlyViewedFacilityData.list.unshift(facilitySummary)
      trimArray(recentlyViewedFacilityData.list, maxFacilitesToCache, false)
    }

    localStorage.setItem(
      RECENTLY_VIEWED_FACILITY_DATA,
      JSON.stringify(recentlyViewedFacilityData),
    )
  }
}

export function clearRecentlyViewedFacilityData() {
  if (isLocalStorage()) {
    const recentlyViewedFacilityData = getRecentlyViewedFacilityData()

    if (
      !isUndefined(recentlyViewedFacilityData) &&
      !isEmpty(recentlyViewedFacilityData)
    ) {
      recentlyViewedFacilityData.list = []
      recentlyViewedFacilityData.lastUpdate = getNowTimeStamp()
      localStorage.setItem(
        RECENTLY_VIEWED_FACILITY_DATA,
        JSON.stringify(recentlyViewedFacilityData),
      )
    }
  }
}

export function saveSearchAvailabilityData(data) {
  if (isLocalStorage()) {
    const initialData = getSearchAvailabilityData()

    const persist = initialData ?? {}

    if (data.arrivaldate) persist.fromDate = data.arrivaldate

    if (data.departuredate) persist.toDate = data.departuredate

    if (data.from) persist.fromDate = data.from

    if (data.to) persist.toDate = data.to

    if (data.guests) persist.guests = data.guests

    if (data.unitType) persist.unitType = data.unitType

    if (data.siteType) persist.siteType = data.siteType

    if (data.vehicle) persist.vehicle = data.vehicle

    localStorage.setItem(SEARCH_AVAILABILITY_DATA, JSON.stringify(persist))
  }
}

export function getSearchAvailabilityData() {
  if (isLocalStorage()) {
    const searchAvailabilityData = localStorage.getItem(
      SEARCH_AVAILABILITY_DATA,
    )

    if (!isUndefined(searchAvailabilityData)) {
      return JSON.parse(searchAvailabilityData)
    }
  }

  return null
}

export function getObjFromStorage(source, key) {
  if (source === 'localStorage') {
    return getObjFromLocalStorage(key)
  }

  return null
}

export function getObjFromLocalStorage(key) {
  if (isLocalStorage()) {
    const data = localStorage.getItem(key)

    if (!isUndefined(data)) return JSON.parse(data)
  }

  return null
}
