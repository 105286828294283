import { isEnvServer } from '../external/utilities/GeneralServerUtils'

const { AutocompleteService, PlacesService } = isEnvServer()
  ? {}
  : window.google.maps.places

export const webSearchService = AutocompleteService && new AutocompleteService()
export const webPlacesService =
  PlacesService &&
  // HACK! This is just a div element. We need to investigate and fix. May not work when server rendered etc.
  new PlacesService(document.getElementById('html_attributions'))

export function fetchGooglePlacesPredictions({ input, country, types = [] }) {
  return new Promise((res, rej) => {
    webSearchService.getPredictions(
      {
        input,
        componentRestrictions: { country },
        types,
      },
      (predictions, status) => {
        switch (status) {
          case 'OK':
            res(predictions)
            break
          case 'NOT_FOUND':
          case 'ZERO_RESULTS':
            res([])
            break
          default:
            rej(new Error(status))
        }
      },
    )
  })
}

export function fetchGooglePlacesDetails(placeId) {
  return new Promise((res, rej) => {
    webPlacesService.getDetails({ placeId }, (result, status) => {
      switch (result) {
        case 'OK':
          res(result)
          break
        case 'NOT_FOUND':
        case 'ZERO_RESULTS':
          res(null)
          break
        default:
          rej(new Error(status))
      }
    })
  })
}

export async function fetchGooglePlacesCoordinates(placeId) {
  const details = await fetchGooglePlacesDetails(placeId)

  const { location } = details?.geometry || {}

  if (!location) return null

  return {
    latitude: location.lat(),
    longitude: location.lng(),
  }
}
