/**
 * @file   : HeaderMessageBarSecondary.js
 * @author : Amarjeet Lamba (amarjeet.lamba@aspiraconnect.com)
 * @date   : 3/24/2020, 8:23:32 PM
 */
import PropTypes from 'prop-types'
import React from 'react'

import HighlightContainer, {
  HIGHLIGHT_IMPORTANT,
} from '../../../external/components/container/HighlightContainer'
import Cont from '../../../external/components/layout/Cont'
import TextBlock from '../../../external/components/text/TextBlock'
import { isEmpty } from '../../../external/utilities/GeneralUtils'

const HeaderMessageBarSecondary = ({ message }) => {
  if (isEmpty(message)) {
    return null
  }

  return (
    <Cont className={'header-message-bar-secondary-pos'}>
      <HighlightContainer closeable style={HIGHLIGHT_IMPORTANT}>
        <Cont className={'header-message-bar-secondary-txt'}>
          <TextBlock isHTML content={message} />
        </Cont>
      </HighlightContainer>
    </Cont>
  )
}

HeaderMessageBarSecondary.propTypes = { message: PropTypes.string }

export default HeaderMessageBarSecondary
