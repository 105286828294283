import PropTypes from 'prop-types'
import React from 'react'

import ABaseLink from './ABaseLink'

/** Component used for images that are also links

@example ../../../../docs/external/link/AImgLink.md*/

const AImgLink = ({
  imgURL,
  imgResponsive = true,
  imgClassName = '',
  imgAlt,
  imgTitle,
  linkOnClick,
  linkClassName,
  linkAlt,
  linkTitle,
  imgWidth = '',
  imgHeight = '',

  to,
  newWin,
  internal,
  tabIndex,
  cmpRel,
  stopClickEventPropogation,
}) => {
  let localClassName = imgClassName
  if (imgResponsive) {
    localClassName += ' img-fluid'
  }

  const localStyle = {}
  if (imgWidth !== '') {
    localStyle.width = imgWidth
  }

  if (imgHeight !== '') {
    localStyle.height = imgHeight
  }

  return (
    <ABaseLink
      onClick={linkOnClick}
      style={linkClassName}
      cmpLabel={linkAlt}
      cmpTitle={linkTitle}
      to={to}
      newWin={newWin}
      internal={internal}
      tabIndex={tabIndex}
      cmpRel={cmpRel}
      stopClickEventPropogation={stopClickEventPropogation}
    >
      <img
        src={imgURL}
        className={localClassName}
        alt={imgAlt}
        title={imgTitle}
        style={localStyle}
      />
    </ABaseLink>
  )
}

AImgLink.propTypes = {
  /** the image url */
  imgURL: PropTypes.string.isRequired,
  /** whether or not the image should be responsive */
  imgResponsive: PropTypes.bool,
  /** wrapper class for image for custom styles */
  imgClassName: PropTypes.string,
  /** alt text for image */
  imgAlt: PropTypes.string,
  /** title for image */
  imgTitle: PropTypes.string,
  /** function to run when image is clicked */
  linkOnClick: PropTypes.func,
  /** wrapper class for link for custom styles */
  linkClassName: PropTypes.string,
  /** alt text for link */
  linkAlt: PropTypes.string,
  /** title text for link */
  linkTitle: PropTypes.string,
  /** css width property for the image e.g. '130px' */
  imgWidth: PropTypes.string,
  /** css height property for the image e.g. '130px' */
  imgHeight: PropTypes.string,

  /** url for the link */
  to: PropTypes.string,
  /** should the link open in a new window - overrides cmpTarget */
  newWin: PropTypes.bool,
  /** is link internal - if yes then no server request will be sent - link will be opened by single page app */
  internal: PropTypes.bool,
  /** tab index for contents */
  tabIndex: PropTypes.number,
  /** rel attribute */
  cmpRel: PropTypes.arrayOf(PropTypes.string),
  /** should click event propogtion be stopped */
  stopClickEventPropogation: PropTypes.bool,
}

export default AImgLink
