import PropTypes from 'prop-types'
import React from 'react'

/** Inserts a divider line between sections

@example ../../../../docs/external/layout/Divider.md */

const Divider = ({
  isDotted = false,
  hasTopMargin = false,
  hasBottomMargin = true,
  hasSmallBottomMargin = false,
}) => {
  let className = 'main-divider clearfix'
  className += isDotted ? ' main-divider--dotted' : ''
  className += hasTopMargin ? ' main-divider--top-margin' : ''
  className += hasBottomMargin ? ' main-divider--bottom-margin' : ''
  className += hasSmallBottomMargin ? ' main-divider--bottom-margin-small' : ''

  return <div className={className} />
}

Divider.propTypes = {
  /** set to true for a dotted line instead of a solid line */
  isDotted: PropTypes.bool,
  /** set to true to add a top margin before the divider */
  hasTopMargin: PropTypes.bool,
  /** set to true to add a bottom margin after the divider, default is true */
  hasBottomMargin: PropTypes.bool,
  /** set to true to add a small bottom margin after the divider, default is false */
  hasSmallBottomMargin: PropTypes.bool,
}

export default Divider
