import sha1 from 'crypto-js/sha1'
import md5 from 'js-md5'
import { sha256 } from 'js-sha256'
import { array, bool, func, number, object } from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import Cont, { ALIGN_RIGHT } from '../../../external/components/layout/Cont'
import * as AAlert from '../../../external/components/overlay/AAlert'
import { isEmpty, isUndefined } from '../../../external/utilities/GeneralUtils'
import { selectCartMixpanelPayload } from '../../../selectors/cart'
import braze from '../../../thirdParty/braze'
import mixpanel from '../../../thirdParty/mixpanel'
import { getCartTimerAlerts } from '../../../utilities/ConfigUtils'
import { simpleTranslate, translate } from '../../Translation'
import SearchBarCmp from '../search/SearchBarCmp'
import Brand from './Brand'
import { BurgerMenu } from './BurgerMenu'
import MainMenuItems from './MainMenuItems'

const MainHeader = ({
  intl,
  inlineSearch,
  inlineSearchEnabled,
  userProfile,
  allQuickSearchResults,
  allQuickSearchResultsAttribs,
  mainSearchConfig,
  brndConf,
  onSearchTrigger,
  onSearchTermChange,
  onSearchResultItemSelectRequest,
  currSearch,
  brandConfig,
  mainConfig,
  cartTimeToExpiry,
  cartItemCount,
  location,
  onSignOutRequest,
  homeConfig,
}) => {
  const cartMixpanelPayload = useSelector(selectCartMixpanelPayload)

  const freestarIdentitySet = useRef(false)

  useEffect(() => {
    const setFreestarIdentity = async () => {
      const email = userProfile?.loginName

      await waitForFreestar()

      if (!email) {
        if (
          window.freestar &&
          window.freestar.identity &&
          window.freestar.identity.clearIdentity
        ) {
          window.freestar.identity.clearIdentity()
        }
        freestarIdentitySet.current = false

        return
      }

      if (freestarIdentitySet.current) {
        return
      }

      let normalizedEmail = email.trim().toLowerCase()

      const [localPart, domain] = normalizedEmail.split('@')

      if (localPart.includes('.') || localPart.includes('+')) {
        const normalizedLocalPart = localPart.replace(/\./g, '').split('+')[0]
        normalizedEmail = `${normalizedLocalPart}@${domain}`
      }

      const hashedSha256 = sha256(normalizedEmail)
      const hashedMd5 = md5(email)
      const hashedSha1 = sha1(email).toString()

      window.freestar.queue.push(() => {
        window.freestar.identity.setIdentity({
          hashes: {
            sha256: hashedSha256,
            sha1: hashedSha1,
            md5: hashedMd5,
          },
        })
      })

      freestarIdentitySet.current = true
    }

    const waitForFreestar = () => {
      return new Promise((resolve) => {
        const checkFreestar = setInterval(() => {
          if (
            window.freestar &&
            window.freestar.identity &&
            window.freestar.identity.setIdentity
          ) {
            clearInterval(checkFreestar)
            resolve()
          }
        }, 200)
      })
    }

    setFreestarIdentity()
  }, [userProfile.loginName])

  const mainHeaderBrandClass = getStyle('main-header__brand')
  let searchStyle = getStyle('main-header__search')
  const mainHeaderLinksStyle = getStyle('main-header__links')
  const mainHeaderBurgerStyle = getStyle('main-header__burger')
  const delayConfig = {}

  if (!isUndefined(mainConfig) && !isEmpty(mainConfig)) {
    delayConfig.delayType = mainConfig.mainSearch.delay.type
    delayConfig.delayInputTimeMS = mainConfig.mainSearch.delay.delayInputTimeMS
    delayConfig.delayIntervalTimeMS =
      mainConfig.mainSearch.delay.delayIntervalTimeMS
    delayConfig.delayAfterMinChar =
      mainConfig.mainSearch.delay.delayAfterMinChar
  }
  if (!inlineSearch || !inlineSearchEnabled) {
    searchStyle += ' hidden'
  }

  function getSearchComponentUI(scrollPointName) {
    if (inlineSearch && inlineSearchEnabled) {
      return (
        <SearchBarCmp
          intl={intl}
          onSearchTrigger={onSearchTrigger}
          onSearchTermChange={onSearchTermChange}
          allQuickSearchResults={allQuickSearchResults}
          allQuickSearchResultsAttribs={allQuickSearchResultsAttribs}
          mainSearchConfig={mainSearchConfig.mainSearch}
          brndConf={brndConf}
          onSearchResultItemSelectRequest={onSearchResultItemSelectRequest}
          currSearch={currSearch}
          showHelper={false}
          delayType={delayConfig.delayType}
          delayInputTimeMS={delayConfig.delayInputTimeMS}
          delayIntervalTimeMS={delayConfig.delayIntervalTimeMS}
          delayAfterMinChar={delayConfig.delayAfterMinChar}
          scrollToTopOnMobile={false}
          scrollPointName={scrollPointName}
        />
      )
    }

    return null
  }

  function getStyle(styleClass) {
    return inlineSearch ? `${styleClass} inner-page` : `${styleClass} home-page`
  }

  const onCartCountdownTimerTick = function (milliseconds) {
    const totalSeconds = Math.round(milliseconds / 1000)
    const minutes = Math.round(totalSeconds / 60)
    const timerAlerts = getCartTimerAlerts(mainConfig)
    for (const i in timerAlerts) {
      if (totalSeconds === Math.round(timerAlerts[i].alertTime / 1000)) {
        const defaultAlertSettings = AAlert.getDefaultSettings()
        defaultAlertSettings.timeout = timerAlerts[i].displayLength
        const alertContent = (
          <div>
            <div>
              {simpleTranslate(
                intl,
                timerAlerts[i].translationTokenHeader,
                timerAlerts[i].defaultTokenHeader,
              )}
            </div>
            <div>
              {translate(
                intl,
                timerAlerts[i].translationTokenBody,
                { alertTime: minutes },
                null,
                null,
                timerAlerts[i].defaultTokenBody,
              )}
            </div>
          </div>
        )

        if (timerAlerts[i].displayType === 'error') {
          mixpanel.cartExpired(cartMixpanelPayload)
          const name = cartMixpanelPayload.parkName
            .replaceAll(' ', '-')
            .toLowerCase()

          const url = `https://www.reserveamerica.com/explore/${name}/${cartMixpanelPayload.contractCode}/${cartMixpanelPayload.parkId}/overview`

          const brazeEvent = { ...cartMixpanelPayload, url }
          braze.abandonCart(brazeEvent)
        }

        AAlert.showAlert(
          alertContent,
          defaultAlertSettings,
          timerAlerts[i].displayType,
        )
      }
    }
  }

  const mainMenuItems = (
    <MainMenuItems
      intl={intl}
      userProfile={userProfile}
      brandConfig={brandConfig}
      mainConfig={mainConfig}
      displayType={'vertical'}
      cartTimeToExpiry={cartTimeToExpiry}
      cartItemCount={cartItemCount}
      location={location}
      closeOnClickOutside
      onSignOutRequest={onSignOutRequest}
      menuType={'desktop'}
      onCartCountdownTimerTick={onCartCountdownTimerTick}
      homeConfig={homeConfig}
    />
  )

  const mainMenuItemsNoTickCallback = (
    <MainMenuItems
      intl={intl}
      userProfile={userProfile}
      brandConfig={brandConfig}
      mainConfig={mainConfig}
      displayType={'vertical'}
      cartTimeToExpiry={cartTimeToExpiry}
      cartItemCount={cartItemCount}
      location={location}
      closeOnClickOutside
      onSignOutRequest={onSignOutRequest}
      menuType={'desktop'}
      homeConfig={homeConfig}
    />
  )

  return (
    <div className="main-header-wrapper">
      <Cont hideXS className={'topbar-navigation__row-2'}>
        <div className={mainHeaderBrandClass}>
          <Brand intl={intl} />
        </div>
        <div className={searchStyle}>
          {getSearchComponentUI('searchBarScrollPoint')}
        </div>
        <div className={mainHeaderLinksStyle}>{mainMenuItems}</div>
      </Cont>
      <Cont hideSM hideMD hideLG>
        <div className={'topbar-navigation__row-1'}>
          <div className={mainHeaderBrandClass}>
            <Brand intl={intl} />
          </div>
          <Cont align={ALIGN_RIGHT}>{mainMenuItemsNoTickCallback}</Cont>
          <Cont hideSM hideMD hideLG className={mainHeaderBurgerStyle}>
            <BurgerMenu
              intl={intl}
              userProfile={userProfile}
              brandConfig={brandConfig}
              mainConfig={mainConfig}
              displayType={'horizontal'}
              cartTimeToExpiry={cartTimeToExpiry}
              cartItemCount={cartItemCount}
              location={location}
              onSignOutRequest={onSignOutRequest}
              homeConfig={homeConfig}
            />
          </Cont>
        </div>
        <div
          className={`topbar-navigation__row-2${
            !inlineSearch || !inlineSearchEnabled ? ' hidden' : ''
          }`}
        >
          <div className={searchStyle}>
            {getSearchComponentUI('searchBarScrollPointMobile')}
          </div>
        </div>
      </Cont>
    </div>
  )
}

MainHeader.propTypes = {
  intl: object.isRequired,
  displayMenu: bool.isRequired,
  inlineSearch: bool.isRequired,
  inlineSearchEnabled: bool.isRequired,
  userProfile: object.isRequired,
  allQuickSearchResults: array.isRequired,
  allQuickSearchResultsAttribs: array.isRequired,
  mainSearchConfig: object.isRequired,
  brndConf: object.isRequired,
  onSearchTrigger: func.isRequired,
  onSearchTermChange: func.isRequired,
  onSearchResultItemSelectRequest: func.isRequired,
  currSearch: object,
  brandConfig: object.isRequired,
  mainConfig: object.isRequired,
  addToFavoritesCtr: number.isRequired,
  removeFromFavoritesCtr: number.isRequired,
  cartTimeToExpiry: object.isRequired,
  cartItemCount: number.isRequired,
  location: object,
  onSignOutRequest: func.isRequired,
  homeConfig: object.isRequired,
}

export default MainHeader
