import { array, bool, element, func, object, string } from 'prop-types'
import React, { Component } from 'react'
import { Waypoint } from 'react-waypoint'

import { HOME_HERO } from '../../constants/Elements'
import AButton from '../../external/components/button/AButton'
import Cont, { ALIGN_CENTER } from '../../external/components/layout/Cont'
import Section from '../../external/components/layout/Section'
import ScrollPoint from '../../external/components/link/ScrollPoint'
import AIcon, { ICONS } from '../../external/components/media/icon/AIcon'
import HTag from '../../external/components/text/HTag'
import { isEmpty, isUndefined } from '../../external/utilities/GeneralUtils'
import { simpleTranslate } from '../../external/utilities/TranslationUtils'
import * as AAlert from '../../external/components/overlay/AAlert'
import {
  SEARCHBAR_MARGIN_TOP,
  TOP_HEADER_MAIN_HEIGHT,
  TOP_HEADER_MAIN_HEIGHT_MOBILE,
  TOP_HEADER_SEARCHBAR_HEIGHT_MOBILE,
} from '../favorites/shared/ViewUtil'
import SearchBarCmp from '../shared/search/SearchBarCmp'
import Video from '../shared/video/Video'

const SEARCH_BAR_FIXED_SCROLL_POS = 100

const getLocationPermissionStatus = async () => {
  if (!navigator) {
    return false;
  }

  return await navigator.permissions.query({ name: "geolocation" });
}

class HomeHeroCmp extends Component {
  constructor() {
    super()
    this.resetWayPoint = this.resetWayPoint.bind(this)
    this.state = {
      searchBarClass: '',
      isUserLocationEnabled: false,
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.resetWayPoint)
    if (window.pageYOffset > SEARCH_BAR_FIXED_SCROLL_POS) {
      this.setSearchClass(true)
    }

    const setLocationFlag = async () => {
      const permission = await getLocationPermissionStatus();

      this.setState({ isUserLocationEnabled: permission.state === 'granted' });
    }

    setLocationFlag();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resetWayPoint)
  }

  resetWayPoint() {
    if (window.pageYOffset > SEARCH_BAR_FIXED_SCROLL_POS) {
      this.setSearchClass(true)
    } else {
      this.setSearchClass(false)
    }
  }

  setSearchClass(inlineSearch) {
    this.setState({
      searchBarClass: inlineSearch ? 'searchbar--inline-search' : '',
    })
  }

  async handleNearbyParksClick() {
    const onLocationDenied = () => {
      AAlert.error(simpleTranslate(this.props.intl, 'labels_enable_browser_location', ''))
    }

    const askForLocationAccess = (permission) => {
      navigator.geolocation.getCurrentPosition(
        this.props.onSelectNearMeRequest.bind(this),
        onLocationDenied
      )

      permission.onchange = () => {
        this.setState({ isUserLocationEnabled: permission.state === "granted" })
      };
    }

    const permission = await getLocationPermissionStatus();

    switch(permission.state) {
      case "granted":
        this.props.onSelectNearMeRequest.call(this)
        break;
      case "prompt":
      case "denied":
        askForLocationAccess(permission);
        break;
      default:
        break;
    }
  }

  getNearMe() {
    const { intl, mainSearchConfig } = this.props
    let nearMe = null
    if (
      !isUndefined(mainSearchConfig) &&
      !isEmpty(mainSearchConfig) &&
      mainSearchConfig.mainSearch.nearMe.active &&
      this.props.doesNavigatorSupportsGeolocation
    ) {
      nearMe = (
        <div className={'homehero__search_options'}>
          <Cont align={ALIGN_CENTER}>
            <AButton
              style={'highlight-light'}
              cmpRef={'near-me-button'}
              onClick={this.handleNearbyParksClick.bind(this)}
            >
              <AIcon icon={ICONS.streetView} padded />
              {simpleTranslate(intl, 'search_near_me')}
            </AButton>
          </Cont>
        </div>
      )
    }

    return nearMe
  }

  isBroadcastMessageShowing() {
    const { brandConfig } = this.props
    let showing = false

    if (
      !isEmpty(brandConfig) &&
      !isUndefined(brandConfig.systemBroadcast) &&
      !isEmpty(brandConfig.systemBroadcast)
    ) {
      showing = true
    }

    return showing
  }

  render() {
    const {
      intl,
      onSearchTrigger,
      onSearchTermChange,
      allQuickSearchResults,
      allQuickSearchResultsAttribs,
      mainSearchConfig,
      brndConf,
      onSearchResultItemSelectRequest,
      children,
      brandConfig,
      currSearch,
    } = this.props
    const config = brandConfig
    let content
    const delayConfig = {}
    let scrollToTopOnMobile = false

    if (!isUndefined(mainSearchConfig) && !isEmpty(mainSearchConfig)) {
      delayConfig.delayType = mainSearchConfig.mainSearch.delay.type
      delayConfig.delayInputTimeMS =
        mainSearchConfig.mainSearch.delay.delayInputTimeMS
      delayConfig.delayIntervalTimeMS =
        mainSearchConfig.mainSearch.delay.delayIntervalTimeMS
      delayConfig.delayAfterMinChar =
        mainSearchConfig.mainSearch.delay.delayAfterMinChar
      scrollToTopOnMobile =
        mainSearchConfig.mainSearch.scrollToTopOnMobile.active
    }
    const searchBarComponent = (
      <div className={this.state.searchBarClass}>
        <SearchBarCmp
          intl={intl}
          onSearchTrigger={onSearchTrigger}
          onSearchTermChange={onSearchTermChange}
          allQuickSearchResults={allQuickSearchResults}
          allQuickSearchResultsAttribs={allQuickSearchResultsAttribs}
          mainSearchConfig={mainSearchConfig.mainSearch}
          brndConf={brndConf}
          onSearchResultItemSelectRequest={onSearchResultItemSelectRequest}
          currSearch={currSearch}
          showHelper={this.state.searchBarClass === ''}
          delayType={delayConfig.delayType}
          delayInputTimeMS={delayConfig.delayInputTimeMS}
          delayIntervalTimeMS={delayConfig.delayIntervalTimeMS}
          delayAfterMinChar={delayConfig.delayAfterMinChar}
          scrollToTopOnMobile={
            this.isBroadcastMessageShowing() ? false : scrollToTopOnMobile
          }
        />
      </div>
    )

    const desktopStyle = {
      backgroundImage: `url(${config.images.heroImageURL})`,
    }
    const mobileStyle = {
      backgroundImage: `url(${config.images.mobileImageURL})`,
    }

    // h1Headline = (
    //   <HTag h1>
    //     <TextHoverable asSpan fontSizeType={3} fontFamily={'Georgia'} fontColor={'white'} backgroundColor={BACKGROUND_COLOR.BLUE80} selected>
    //       {isEmpty(brandConfig.mainCTA.headline) ? null : brandConfig.mainCTA.headline}
    //     </TextHoverable>
    //   </HTag>
    // ),

    const h1Headline = (
      <HTag h1 style={'homehero__message__main-line'}>
        {isEmpty(brandConfig.mainCTA.headline)
          ? null
          : brandConfig.mainCTA.headline}
      </HTag>
    )

    const heroMessage = (
      <div>
        <div className="hero-transparency" />
        {children}
        <div className="homehero__message">
          <Cont>
            <Cont align={ALIGN_CENTER}>
              {isEmpty(brandConfig.mainCTA.headline) ? null : h1Headline}
            </Cont>
            <Cont
              textAlign={'center'}
              className={'homehero__message__tag-line-1'}
            >
              {brandConfig.mainCTA.tagline1}
              <br />
              {brandConfig.mainCTA.tagline2}
            </Cont>
          </Cont>
        </div>
      </div>
    )

    const heroMessageMobile = (
      <div>
        <div className="hero-transparency" />
        {children}
        <div className="homehero__message">
          <Cont>
            <Cont align={ALIGN_CENTER}>
              {isEmpty(brandConfig.mainCTA.headline) ? null : h1Headline}
            </Cont>
            <Cont
              align={ALIGN_CENTER}
              className={'homehero__message__tag-line-1'}
            >
              {brandConfig.mainCTA.tagline1}
              <br />
              {brandConfig.mainCTA.tagline2}
            </Cont>
          </Cont>
        </div>
      </div>
    )

    let wayPoints = null

    if (!this.isBroadcastMessageShowing()) {
      wayPoints = (
        <Cont>
          <Cont hideXS>
            <Waypoint
              onEnter={this.setSearchClass.bind(this, false)}
              onLeave={this.setSearchClass.bind(this, true)}
              topOffset={TOP_HEADER_MAIN_HEIGHT - SEARCHBAR_MARGIN_TOP}
            />
          </Cont>
          <Cont hideSM hideMD hideLG>
            <Waypoint
              onEnter={this.setSearchClass.bind(this, false)}
              onLeave={this.setSearchClass.bind(this, true)}
              topOffset={
                TOP_HEADER_MAIN_HEIGHT_MOBILE -
                TOP_HEADER_SEARCHBAR_HEIGHT_MOBILE -
                SEARCHBAR_MARGIN_TOP
              }
            />
          </Cont>
        </Cont>
      )
    }

    if (config.showVideos && !isEmpty(config.videos)) {
      content = (
        <div>
          {wayPoints}
          {searchBarComponent}
          {this.getNearMe()}
          <div className="hidden-xs">
            <Video sources={config.videos} random>
              {heroMessage}
            </Video>
          </div>
          <div className="hidden-sm hidden-md hidden-lg">
            <div className="video-container" style={mobileStyle}>
              <div className="transparency-overlay-top" />
              {heroMessageMobile}
            </div>
            <Section />
          </div>
        </div>
      )
    } else {
      content = (
        <div>
          {wayPoints}
          {searchBarComponent}
          {this.getNearMe()}
          <div className="hidden-xs">
            <div className="video-container" style={desktopStyle}>
              <div className="transparency-overlay-top" />
              {heroMessage}
            </div>
          </div>
          <div className="hidden-sm hidden-md hidden-lg">
            <div className="video-container" style={mobileStyle}>
              <div className="transparency-overlay-top" />
              {heroMessageMobile}
            </div>
          </div>
        </div>
      )
    }

    return <ScrollPoint name={HOME_HERO}>{content}</ScrollPoint>
  }

  static propTypes = {
    theme: string.isRequired,
    intl: object.isRequired,
    brandConfig: object.isRequired,
    onSearchTrigger: func.isRequired,
    onSearchTermChange: func.isRequired,
    allQuickSearchResults: array.isRequired,
    allQuickSearchResultsAttribs: array.isRequired,
    onSearchResultItemSelectRequest: func.isRequired,
    mainSearchConfig: object.isRequired,
    brndConf: object.isRequired,
    currSearch: object,
    doesNavigatorSupportsGeolocation: bool.isRequired,
    onSelectNearMeRequest: func.isRequired,
    children: element,
  }
}

export default HomeHeroCmp
