import PropTypes from 'prop-types'
import React from 'react'
import Container from 'react-bootstrap/Container'

/** Wrapper class for react-bootstrap grid.  https://react-bootstrap.github.io/components.html#grid-props-grid

@example ../../../../docs/external/layout/AGrid.md */

const AGrid = ({ as, fluid, children }) => (
  <Container as={as} fluid={fluid}>
    {children}
  </Container>
)

AGrid.propTypes = {
  as: PropTypes.string,
  fluid: PropTypes.bool,
  children: PropTypes.node,
}

export default AGrid
