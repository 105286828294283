import * as utils from './GeneralUtils'

/*
 * getIntlObj
 */
export function getIntlObj(intlObj, defaultIntlObj) {
  let ret = intlObj
  if (intlObj === undefined || utils.isEmpty(intlObj)) {
    ret = defaultIntlObj
  }

  return ret
}

export function _translateWorker(
  intlObj,
  path,
  literalValues,
  translateValues,
  defaultMessage,
  allowNull,
  baseMessage = null,
) {
  // let baseMessage = null

  if (!baseMessage) {
    baseMessage = _translate(intlObj, path, defaultMessage, allowNull)
  }

  if (!baseMessage) {
    return null
  }

  const substitutions = {}

  if (translateValues) {
    for (const tprop in translateValues) {
      substitutions[tprop] = _translate(intlObj, translateValues[tprop])
    }
  }

  if (literalValues) {
    for (const lprop in literalValues) {
      substitutions[lprop] = literalValues[lprop]
    }
  }

  if (substitutions) {
    for (const prop in substitutions) {
      const replaceKey = `{${prop}}`
      baseMessage = utils.replaceAll(
        baseMessage,
        replaceKey,
        substitutions[prop],
      )
    }
  }

  return baseMessage
}

/*
 * Translate with KeyQualifiers
 */
export function translateWithKeyQualifiers(
  intlObj,
  path,
  literalValues,
  translateValues,
  keyQualifiers,
  defaultMessage,
  allowNull,
) {
  let baseMessage = null
  const applicableQualifiers = keyQualifiers

  if (
    !utils.isUndefined(applicableQualifiers) &&
    !utils.isEmpty(applicableQualifiers)
  ) {
    const arrlength = applicableQualifiers.length
    for (let i = arrlength; i > 0; i--) {
      applicableQualifiers.length = i
      const translatedMessage = _translate(
        intlObj,
        `${path}_${applicableQualifiers.join('_')}`,
        defaultMessage,
        true,
      )
      if (!utils.isEmpty(translatedMessage)) {
        baseMessage = translatedMessage
        break
      }
    }
  }

  return _translateWorker(
    intlObj,
    path,
    literalValues,
    translateValues,
    defaultMessage,
    allowNull,
    baseMessage,
  )
}

/*
 * SimpleTrnaslate
 */
export function simpleTranslate(intlObj, path, defaultMessage) {
  return _translateWorker(intlObj, path, null, null, defaultMessage, false)
}

/*
 * Translate
 */
export function translate(
  intlObj,
  path,
  literalValues,
  translateValues,
  defaultMessage,
  allowNull,
) {
  return _translateWorker(
    intlObj,
    path,
    literalValues,
    translateValues,
    defaultMessage,
    allowNull,
  )
}

function _translate(intlObj, path, defaultMessage, allowNull) {
  const pathParts = [path]
  let message
  try {
    message = pathParts.reduce(
      (obj, pathPart) => obj[pathPart],
      intlObj.messages,
    )
  } finally {
    if (!message) {
      if (allowNull) {
        return null
      }
      if (defaultMessage) {
        message = defaultMessage // return the default...
      } else {
        // message = TRANSLATION_NOT_FOUND + ':' + path // return the not found message...
        message = ''
      }
    }
  }

  return message
}
