import { has } from 'lodash'

import { updateSearchFilterObj } from '../actions/Backend'
import {
  getQuickSearchClearAll,
  getQuickSearchContract,
  getQuickSearchInternal,
  getQuickSearchWeb,
  updateSearchObj,
} from '../actions/Search'
import process from '../environment.json'
import {
  isEmpty,
  isUndefined,
  isValue,
} from '../external/utilities/GeneralUtils'

export function getAllQuickSearchResults(quickSearch, mainSearch) {
  const allQSR = []

  if (!isEmpty(quickSearch) && !isEmpty(mainSearch)) {
    const maxContract = mainSearch.resultsDisplay.maxContract
    const maxInternalAndWeb = mainSearch.resultsDisplay.maxInternalAndWeb
    const maxInternal = maxInternalAndWeb / 2
    const maxWeb = maxInternal

    let internalNum = maxInternal
    let webNum = maxWeb
    const internalResultsNum = isEmpty(quickSearch.internal.items)
      ? 0
      : quickSearch.internal.items.length
    const webResultsNum = isEmpty(quickSearch.web) ? 0 : quickSearch.web.length
    if (internalResultsNum < maxInternal) {
      webNum = maxWeb + (maxInternal - internalResultsNum)
    }
    if (webResultsNum < maxWeb) {
      internalNum = maxInternal + (maxWeb - webResultsNum)
    }

    if (!isEmpty(quickSearch.contracts)) {
      for (
        let i = 0;
        i < quickSearch.contracts.length && i < maxContract;
        i++
      ) {
        const sr = getSearchResultObjFromContract(quickSearch.contracts[i])
        allQSR.push(sr)
      }
    }

    if (!isEmpty(quickSearch.internal)) {
      if (!isEmpty(quickSearch.internal.items)) {
        for (
          let i = 0;
          i < quickSearch.internal.items.length && i < internalNum;
          i++
        ) {
          if (quickSearch.internal.items[i].type === 'FACILITY') {
            const sr = {
              name: quickSearch.internal.items[i].facilityMatch.facilityName,
              contractCode:
                quickSearch.internal.items[i].facilityMatch.landmark
                  .contractCode,
              facilityId:
                quickSearch.internal.items[i].facilityMatch.landmark.facilityID,
              region:
                quickSearch.internal.items[i].facilityMatch.landmark.region,
              type: 'internal',
            }
            allQSR.push(sr)
          }
        }
      }
    }
    if (!isEmpty(quickSearch.web)) {
      for (let i = 0; i < quickSearch.web.length && i < webNum; i++) {
        const sr = getSearchResultObjFromWeb(
          quickSearch.web[i],
          getIsWebSearchProviderSourceInternal(mainSearch),
        )
        allQSR.push(sr)
      }
    }
  }

  return allQSR
}

export function getSearchProviderSource(mainSearch, type) {
  let providerSouce = null
  if (!isUndefined(mainSearch) && !isEmpty(mainSearch)) {
    for (const currProviderSouce of mainSearch.providerSource.sources) {
      if (currProviderSouce.type === type) {
        providerSouce = currProviderSouce
        break
      }
    }
  }

  return providerSouce
}

export function getIsWebSearchProviderSourceInternal(mainSearch) {
  if (isUndefined(mainSearch) || isEmpty(mainSearch)) return null

  const providerSouce = getSearchProviderSource(mainSearch, 'web')

  return !isEmpty(providerSouce) && providerSouce.provider === 'internal'
}

export function getAllSearchProviderSourceAttribs(mainSearch) {
  const allQuickSearchAttribs = []
  if (!isUndefined(mainSearch) && !isEmpty(mainSearch)) {
    for (const currProviderSouce of mainSearch.providerSource.sources) {
      if (!isEmpty(currProviderSouce.attrib)) {
        allQuickSearchAttribs.push(currProviderSouce.attrib)
      }
    }
  }

  return allQuickSearchAttribs
}

export function getWebSearchProviderAttrib(mainSearch) {
  let attrib = null
  const providerSouce = getSearchProviderSource(mainSearch, 'web')

  if (!isEmpty(providerSouce)) {
    attrib = providerSouce.attrib
  }

  return attrib
}

export function getSearchResultName(searchResult) {
  let srn = searchResult.name
  if (searchResult.type === 'internal') {
    srn += searchResult.region
  }

  return srn
}

export function getSearchResultObjFromContract(contractObj) {
  const sr = {
    name: contractObj.stateName,
    stateCode: contractObj.stateCode,
    type: 'contract',
  }

  return sr
}

export function getSearchResultObjFromWeb(webObj, isSourceProviderInternal) {
  const sr = {}
  if (isSourceProviderInternal) {
    sr.name = `${webObj.nameAscii}, ${webObj.stateName}, ${webObj.countryName}`
    sr.type = 'web'
    sr.lat = webObj.lat
    sr.lng = webObj.lng
  } else {
    sr.name = webObj.description
    sr.type = 'web'
    sr.placeId = webObj.place_id
  }
  // const sr = {
  //   name: webObj.description,
  //   type: 'web',
  //   placeId: webObj.place_id
  // }

  return sr
}

export function getSearchResultObjFromWebList(
  searchResults,
  isSourceProviderInternal,
) {
  const modifiedSearchResults = []

  if (!isUndefined(searchResults) && !isEmpty(searchResults)) {
    for (const currSearchResult of searchResults) {
      modifiedSearchResults.push(
        getSearchResultObjFromWeb(currSearchResult, isSourceProviderInternal),
      )
    }
  }

  return modifiedSearchResults
}

export function getMatchingContracts(searchTerm, contractList) {
  const matchingContracts = []
  if (!isEmpty(searchTerm) && !isEmpty(contractList)) {
    for (const contract of contractList) {
      if (
        contract.stateName.toLowerCase().startsWith(searchTerm.toLowerCase()) ||
        contract.stateCode.toLowerCase() === searchTerm.toLowerCase()
      ) {
        matchingContracts.push(contract)
      }
    }
  }

  return matchingContracts
}

export function getResultsFromCache(searchTerm, cache) {
  let dataFromCache = null
  if (!isEmpty(cache[searchTerm.toLowerCase()])) {
    dataFromCache = cache[searchTerm.toLowerCase()]
  }

  return dataFromCache
}

export function findSearchTermInResults(
  searchTerm,
  searchResults,
  brndConf,
  lastSearch = null,
) {
  let result = null
  if (!isEmpty(searchTerm) && !isEmpty(searchResults)) {
    for (const searchResult of searchResults) {
      if (searchTerm.toLowerCase() === searchResult.name.toLowerCase()) {
        result = searchResult
        break
      }
    }
    if (result === null) {
      for (const state of brndConf.states) {
        if (searchTerm.toLowerCase() === state.stateCode.toLowerCase()) {
          result = getSearchResultObjFromContract(state)
          break
        }
      }
    }
  } else if (!isEmpty(searchTerm) && !isEmpty(lastSearch)) {
    const st = getCurrentSearchTerm(lastSearch)
    if (searchTerm === st) {
      result = lastSearch.selectedSearchObj
    }
  }

  return result
}

export function createSearchObj(
  searchTerm = null,
  type = null,
  stateCode = null,
  longitude = null,
  latitude = null,
  contractCode = null,
  facilityId = null,
  placeId = null,
  region = null,
) {
  const searchObj = {
    searchTerm,
    type,
    stateCode,
    longitude,
    latitude,
    contractCode,
    facilityId,
    placeId,
    region,
  }

  return searchObj
}

export function getSearchObj(searchTerm, selectedSearchResultsItem) {
  const type = getVal(selectedSearchResultsItem, 'type')
  const stateCode = getVal(selectedSearchResultsItem, 'stateCode')

  let longitude = getVal(selectedSearchResultsItem, 'lng')
  let latitude = getVal(selectedSearchResultsItem, 'lat')

  if (!isValue(longitude)) {
    longitude = getVal(selectedSearchResultsItem, 'longitude')
  }
  if (!isValue(latitude)) {
    latitude = getVal(selectedSearchResultsItem, 'latitude')
  }

  const contractCode = getVal(selectedSearchResultsItem, 'contractCode')
  const facilityId = getVal(selectedSearchResultsItem, 'facilityId')
  const placeId = getVal(selectedSearchResultsItem, 'placeId')
  const region = getVal(selectedSearchResultsItem, 'region')

  return createSearchObj(
    searchTerm,
    type,
    stateCode,
    longitude,
    latitude,
    contractCode,
    facilityId,
    placeId,
    region,
  )
}

export function getLocationSearchObj(position) {
  return createSearchObj(
    null,
    'location',
    null,
    position.coords.longitude,
    position.coords.latitude,
  )
}

export function getSearchType(searchObj) {
  let stype = null
  if (isEmpty(searchObj)) {
    return stype
  }
  if (!isEmpty(searchObj.type)) {
    if (searchObj.type === 'contract') {
      stype = 'state'
    } else if (
      searchObj.type === 'web' ||
      searchObj.type === 'internal' ||
      searchObj.type === 'location'
    ) {
      stype = 'nearby'
    }
  }

  return stype
}

export function isSearchTypeLocation(searchObj) {
  let isLocationType = false

  if (
    !isEmpty(searchObj) &&
    !isUndefined(searchObj.type) &&
    !isEmpty(searchObj.type) &&
    searchObj.type === 'nearby'
  ) {
    if (isValue(searchObj.latitude) && isValue(searchObj.longitude)) {
      isLocationType = true
    }
  }

  return isLocationType
}

export function onSearchTriggerUtil(
  dispatch,
  searchTerm,
  selectedItem,
  clearSearchFilters = true,
) {
  dispatch(getQuickSearchClearAll())
  const searchObj = getSearchObj(searchTerm, selectedItem)
  if (clearSearchFilters) {
    dispatch(updateSearchFilterObj({}))
  }
  dispatch(updateSearchObj(searchObj))
}

export function onSearchTermChangeUtil(
  dispatch,
  searchTermIn,
  mainSearchConfig,
  brandConfig,
  shouldSearchWeb = true,
) {
  const searchTerm = searchTermIn.trim()
  const minCharsForAnySearchTerm = getMinCharsForAnySearchTerm(mainSearchConfig)
  if (searchTerm.length < minCharsForAnySearchTerm) {
    dispatch(getQuickSearchClearAll())
  } else {
    // Trigger contract search
    if (
      searchTerm.length >= mainSearchConfig.typeaheadMinCharsTrigger.contract
    ) {
      dispatch(getQuickSearchContract(searchTerm))
    }

    // Trigger internal search
    if (
      searchTerm.length >= mainSearchConfig.typeaheadMinCharsTrigger.internal
    ) {
      dispatch(getQuickSearchInternal(searchTerm))
    }

    // Trigger web search
    if (
      shouldSearchWeb &&
      searchTerm.length >= mainSearchConfig.typeaheadMinCharsTrigger.web
    ) {
      if (
        !isEmpty(brandConfig) &&
        !isUndefined(brandConfig.suggestionsCfg) &&
        !isUndefined(brandConfig.suggestionsCfg.countries)
      ) {
        dispatch(
          getQuickSearchWeb(
            searchTerm,
            getIsWebSearchProviderSourceInternal(mainSearchConfig),
            brandConfig.suggestionsCfg.countries,
          ),
        )
      } else {
        dispatch(
          getQuickSearchWeb(
            searchTerm,
            getIsWebSearchProviderSourceInternal(mainSearchConfig),
          ),
        )
      }
    }
  }
}

export function getMinCharsForAnySearchTerm(mainSearchConfig) {
  const {
    contract = Infinity,
    internal = Infinity,
    web = Infinity,
  } = mainSearchConfig.typeaheadMinCharsTrigger

  return Math.min(contract, internal, web)
}

export function onSearchResultItemSelectRequestUtil(
  dispatch,
  selectedItem,
  clearSearchFilters = true,
) {
  dispatch(getQuickSearchClearAll())
  const searchObj = getSearchObj(selectedItem.name, selectedItem)
  if (clearSearchFilters) {
    dispatch(updateSearchFilterObj({}))
  }
  dispatch(updateSearchObj(searchObj))
}

export function onSearchNearMeSelectRequestUtil(
  dispatch,
  location,
  clearSearchFilters = true,
) {
  dispatch(getQuickSearchClearAll())
  const searchObj = getLocationSearchObj(location)
  if (clearSearchFilters) {
    dispatch(updateSearchFilterObj({}))
  }
  dispatch(updateSearchObj(searchObj))
}

function getVal(obj, key) {
  if (isEmpty(obj)) {
    return null
  }
  if (has(obj, key)) {
    return obj[key]
  }

  return null
}

export function getCurrentSearchTerm(currSearch) {
  let result = ''
  if (!isEmpty(currSearch) && currSearch.searchTerm !== null) {
    result = currSearch.searchTerm
    if (!isEmpty(currSearch.selectedSearchObj)) {
      if (currSearch.selectedSearchObj.type === 'internal') {
        if (!result.endsWith(currSearch.selectedSearchObj.region)) {
          // region has not been added yet - so add
          result += currSearch.selectedSearchObj.region
        }
      }
    }
  }

  return result
}

export function isCurrSearchSelected(currSearch, selectedSearch) {
  let isSame = false
  if (!isEmpty(currSearch) && !isEmpty(selectedSearch)) {
    if (currSearch === selectedSearch) {
      isSame = true
    }
  }

  return isSame
}

export function getInternalSearchObjFromNameValue(nameValueObj) {
  const searchObj = {}
  searchObj.type = 'internal'
  for (const item of nameValueObj) {
    if (item.name === 'facilityID') {
      searchObj.facilityId = item.value
    }
    if (item.name === 'contractCode') {
      searchObj.contractCode = item.value
    }
  }

  return searchObj
}

export function getFacilityFromSuggestion(suggestion = null) {
  const facility = {}
  if (!isEmpty(suggestion)) {
    facility.details = {}
    facility.id = suggestion.facilityMatch.landmark.facilityID
    facility.name = suggestion.facilityMatch.facilityName
    facility.details.contrCode = suggestion.facilityMatch.landmark.contractCode
    facility.details.availability = null
    facility.details.facilityTypes = suggestion.facilityMatch.facilityTypes
    facility.details.crossOverCustomLanding =
      suggestion.facilityMatch.crossOverCustomLanding
    facility.details.baseURL = suggestion.facilityMatch.facilityURL
  }

  return facility
}

// Hack solution to filter by OTA facilities
export function getFacilitiesFilteredByAgency(facilities, agency) {
  if (agency === 'ota') {
    const records = filterForOTAAgencies(facilities.records)

    facilities.records = records
    facilities.endIndex = records.length
    facilities.totalRecords = records.length
    facilities.totalPages = 1
  }

  return facilities
}

function filterForOTAAgencies(facilities) {
  if (!facilities) return facilities

  const agencyList = process.env.CAMPERHUB_AGENCIES
  const agencies = agencyList.split(',')

  // Hardcoded to force firefly agency in even thought it's not a OTA agency
  agencies.push('firefly agency')

  return facilities.filter((facility) =>
    facility?.details?.affiliation?.agencies.some((agency) =>
      agencies.includes(agency?.code?.toLowerCase()),
    ),
  )
}
