import PropTypes from 'prop-types'
import React from 'react'
import { ToastContainer, toast } from 'react-toastify'

import { clone } from '../../utilities/GeneralUtils'
import { ROLES } from '../../utilities/accessibility/Definitions'
import Cont from '../layout/Cont'

/** AAlert is a wrapper around react-toastify library to display alerts in the application.

DOCS: https://github.com/fkhadra/react-toastify

@example ../../../../../docs/external/overlay/AAlert.md */

const POSITION_DEFAULT = 'top-right'
const TIMEOUT_DEFAULT = 3000
const THEME_DEFAULT = 'colored'

const STACK_LIMIT_DEFAULT = 4

const defaultSettings = {
  position: POSITION_DEFAULT,
  autoClose: TIMEOUT_DEFAULT,
}

const AAlert = ({
  stacked = true,
  limit = STACK_LIMIT_DEFAULT,
  theme = THEME_DEFAULT,
}) => <ToastContainer stacked={stacked} limit={limit} theme={theme} />

const alertContentWrapper = function (content) {
  return <Cont cmpRole={ROLES.WIDGET.ALERT}>{content}</Cont>
}

export function getDefaultSettings() {
  return clone(defaultSettings)
}

export function warning(content, settings = defaultSettings) {
  return toast.warning(alertContentWrapper(content), settings)
}

export function error(content, settings = defaultSettings) {
  return toast.error(alertContentWrapper(content), settings)
}

export function info(content, settings = defaultSettings) {
  return toast.info(alertContentWrapper(content), settings)
}

export function success(content, settings = defaultSettings) {
  return toast.success(alertContentWrapper(content), settings)
}

export function showAlert(content, settings = defaultSettings, alertType) {
  if (alertType === 'success') {
    success(content, settings)
  } else if (alertType === 'warning') {
    warning(content, settings)
  } else if (alertType === 'error') {
    error(content, settings)
  } else {
    info(content, settings)
  }
}

export function close(alertId) {
  return toast.close(alertId)
}

export function closeAll() {
  return toast.closeAll()
}

AAlert.propTypes = {
  stacked: PropTypes.bool,
  limit: PropTypes.number,
  theme: PropTypes.oneOf(['colored', 'dark', 'light']),
}

export default AAlert
