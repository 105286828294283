/**
 * @file   : CardBack.js
 * @author : Amarjeet Lamba (Amarjeet.Lamba@AspiraConnect.com)
 * @date   : 10/26/2018, 11:08:42 AM
 */
import PropTypes from 'prop-types'
import React from 'react'

import { isUndefined } from '../../../utilities/GeneralUtils'
import { ROLES } from '../../../utilities/accessibility/Definitions'
import AIconAction from '../../link/AIconAction'
import { FLIPCARD_BACK, FLIPCARD_FRONT } from './FlipCard'

const CardFront = ({
  isShowMoreButton = true,
  onMoreButtonClick,
  showing,
  content = null,
  cardBtnLabel = '',
}) => {
  const getStyle = function () {
    let style = 'card-side side-front'
    if (showing === FLIPCARD_FRONT) {
      style += ' card-front-flip'
    }

    return style
  }

  const getMoreButton = function () {
    if (isShowMoreButton) {
      return (
        <div className="card-action-button">
          <AIconAction
            faStyle={'fa-chevron-circle-right'}
            cmpLabel={cardBtnLabel}
            onClick={() => {
              if (!isUndefined(onMoreButtonClick)) {
                onMoreButtonClick(FLIPCARD_FRONT)
              }
            }}
          />
        </div>
      )
    }

    return null
  }

  const getCardStyle = function () {
    let style = 'card-main-content'
    if (!isShowMoreButton) {
      style += ' card-main-content--no-backcontent'
    }

    return style
  }

  return (
    <div
      className={getStyle()}
      role={showing === FLIPCARD_BACK ? ROLES.WIDGET.ALERT : null}
    >
      <div className={getCardStyle()}>
        {content}
        {getMoreButton()}
      </div>
    </div>
  )
}

CardFront.propTypes = {
  isShowMoreButton: PropTypes.bool,
  onMoreButtonClick: PropTypes.func,
  showing: PropTypes.string.isRequired,
  content: PropTypes.element,
  cardBtnLabel: PropTypes.string,
}

export default CardFront
