import PropTypes from 'prop-types'
import React from 'react'

import { isBoolean } from '../../utilities/GeneralUtils'

export const FONT_SIZE = {
  // smallest
  X1: 'font-size-1x',
  X2: 'font-size-2x',
  X3: 'font-size-3x',
  X4: 'font-size-4x',
  X5: 'font-size-5x',
  X6: 'font-size-6x',
  X7: 'font-size-7x',
  // largest
  X8: 'font-size-8x',
}
export const FONT_WEIGHT = {
  NORMAL: 'font-weight-400',
  600: 'font-weight-600',
  BOLD: 'font-weight-700',
}
export const FONT_STYLES = {
  ITALIC: 'italic',
  UNDERLINE: 'underline',
}
export const LINECLAMP_LIMIT = {
  L1: 'text-truncate-1',
  L2: 'text-truncate-2',
  L3: 'text-truncate-3',
  L4: 'text-truncate-4',
  L5: 'text-truncate-5',
  L6: 'text-truncate-6',
}

// predefined styles
export const DEFAULT_TEXT = {
  mainClass: 'default-text',
  fontWeight: FONT_WEIGHT.NORMAL,
  fontSize: FONT_SIZE.X3,
  italic: false,
  underline: false,
  lineClamp: false,
}
export const SERIF_TEXT = {
  mainClass: 'default-serif-text',
  fontWeight: FONT_WEIGHT.NORMAL,
  fontSize: FONT_SIZE.X3,
  italic: false,
  underline: false,
  lineClamp: false,
}
export const DEFAULT_HEADER = {
  mainClass: 'default-header',
  fontWeight: FONT_WEIGHT.BOLD,
  fontSize: FONT_SIZE.X5,
  italic: false,
  underline: false,
  lineClamp: false,
}
export const CARD_HEADER = {
  mainClass: 'card-header',
  fontWeight: FONT_WEIGHT.NORMAL,
  fontSize: FONT_SIZE.X4,
  italic: false,
  underline: false,
  lineClamp: false,
}
export const SERIF_HEADER = {
  mainClass: 'default-serif-header',
  fontWeight: FONT_WEIGHT.NORMAL,
  fontSize: FONT_SIZE.X7,
  italic: false,
  underline: false,
  lineClamp: false,
}
export const CERULEAN_TEXT = {
  mainClass: 'cerulean-text',
  fontWeight: FONT_WEIGHT.NORMAL,
  fontSize: FONT_SIZE.X3,
  italic: false,
  underline: false,
  lineClamp: false,
}
export const DARK_BLUE_TEXT = {
  mainClass: 'blue-dark-text',
  fontWeight: FONT_WEIGHT.NORMAL,
  fontSize: FONT_SIZE.X3,
  italic: false,
  underline: false,
  lineClamp: false,
}
export const GREEN_TEXT = {
  mainClass: 'green-text',
  fontWeight: FONT_WEIGHT.NORMAL,
  fontSize: FONT_SIZE.X3,
  italic: false,
  underline: false,
  lineClamp: false,
}
export const GREY_TEXT = {
  mainClass: 'grey-text',
  fontWeight: FONT_WEIGHT.NORMAL,
  fontSize: FONT_SIZE.X3,
  italic: false,
  underline: false,
  lineClamp: false,
}
export const ORANGE_TEXT = {
  mainClass: 'orange-text',
  fontWeight: FONT_WEIGHT.NORMAL,
  fontSize: FONT_SIZE.X3,
  italic: false,
  underline: false,
  lineClamp: false,
}
export const RED_TEXT = {
  mainClass: 'red-text',
  fontWeight: FONT_WEIGHT.NORMAL,
  fontSize: FONT_SIZE.X3,
  italic: false,
  underline: false,
  lineClamp: false,
}
export const WHITE_TEXT = {
  mainClass: 'white-text',
  fontWeight: FONT_WEIGHT.NORMAL,
  fontSize: FONT_SIZE.X3,
  italic: false,
  underline: false,
  lineClamp: false,
}

/**
 * Get the current attribute value
 * @param  {object} currentObj - the current object of attributes
 * @param  {string} currentAttr - the current attribute name
 * @return {string} the classname associated with the current attribute
 */
const getValue = (currentObj, currentAttr) => {
  if (isBoolean(currentObj[currentAttr])) {
    if (currentObj[currentAttr]) {
      return FONT_STYLES[currentAttr.toUpperCase()]
    }

    return ''
  }

  return currentObj[currentAttr]
}

/** component for styling text.  Use a pre-existing style and then customize size, font-weight, italic and underline if necessary

@example ../../../../docs/external/text/TextStyler.md */

const TextStyler = ({ styleObj = DEFAULT_TEXT, children }) => {
  // first push in the default style to ensure all the properties are set to at least the default
  const tempClassName = {}
  Object.keys(DEFAULT_TEXT).map((attr) => {
    tempClassName[attr] = getValue(DEFAULT_TEXT, attr)
  })

  // then overwrite any classes with what was passed in
  Object.keys(styleObj).map((attr) => {
    // make sure it is a valid attribute
    if (typeof tempClassName[attr] !== 'undefined') {
      tempClassName[attr] = getValue(styleObj, attr)
    }
  })

  // push the object of classnames into an array
  const className = Object.keys(tempClassName).map(
    (attr) => tempClassName[attr],
  )

  return <span className={className.join(' ')}>{children}</span>
}

TextStyler.propTypes = {
  /** object containing the attribute types and their associated classes which will be used to style the text e.g. {fontSize: FONT_SIZE.X3, fontWeight: FONT_WEIGHT.BOLD}*/
  styleObj: PropTypes.object,
  /** the text to display */
  children: PropTypes.node,
}

export default TextStyler
