import * as types from './ActionTypes'

/* Themes */
export function changeTheme(theme) {
  console.log(`changing theme to ${theme}`)

  return {
    type: types.CHANGE_THEME,
    data: theme,
  }
}

/* Errors */
export function throwError(error) {
  return {
    type: types.THROW_ERROR,
    data: error,
  }
}

export function clearError() {
  return {
    type: types.CLEAR_ERROR,
    data: null,
  }
}

/* Local State */
export function saveLocalState() {
  return {
    type: types.SAVE_LOCAL_STATE,
    data: null,
  }
}

export function getLocalState() {
  return {
    type: types.GET_LOCAL_STATE,
    data: null,
  }
}

export function s2nToggleAbandonCartModal(data) {
  return {
    type: types.S2N_TOGGLE_ABANDON_CART_MODAL,
    data,
  }
}
