import PropTypes from 'prop-types'
import React from 'react'
import Button from 'react-bootstrap/Button'

import { isEmpty, isUndefined } from '../../utilities/GeneralUtils'

/** Default react-bootstrap button component -  https://react-bootstrap.github.io/components.html#buttons.
However, we override the primary, success, and info buttons with our own custom styles.

@example ../../../../docs/external/button/AButton.md 
*/

const AButton = ({
  active,
  block,
  className = '',
  size,
  style,
  as,
  disabled,
  href,
  type,
  onClick,
  children,
  cmpRef,
  cmpLabel,
  cmpDescribedBy,
  widthSize,
  hideFocusOutline = false,
  asDiv = false,
}) => {
  const customStyles = ['highlight', 'highlight-light']

  const isCustomStyle = function (matchStyle) {
    let custom = false
    if (!isUndefined(matchStyle) && !isEmpty(matchStyle)) {
      for (const item of customStyles) {
        if (item === matchStyle) {
          custom = true
          break
        }
      }
    }

    return custom
  }

  const getStyleName = function () {
    if (isCustomStyle(style)) {
      return null
    }

    return style
  }

  const getClassName = function () {
    if (isCustomStyle(style)) {
      className += ` btn-${style}`
    }

    if (widthSize) {
      className += ` btn-fixed-width-size-${widthSize}`
    }

    if (hideFocusOutline) {
      className += ' btn-hide-focus'
    }

    return className
  }

  if (asDiv) {
    return (
      <div
        className={getClassName()}
        onClick={onClick}
        data-ref={cmpRef}
        aria-label={cmpLabel}
        aria-describedby={cmpDescribedBy}
      >
        {children}
      </div>
    )
  }

  return (
    <Button
      className={getClassName()}
      active={active}
      block={block}
      size={size}
      variant={getStyleName()}
      as={as}
      disabled={disabled}
      href={href}
      type={type}
      onClick={onClick}
      data-ref={cmpRef}
      aria-label={cmpLabel}
      aria-describedby={cmpDescribedBy}
    >
      {children}
    </Button>
  )
}

AButton.propTypes = {
  /** whether or not the button is currently selected/active */
  active: PropTypes.bool,
  block: PropTypes.bool,
  /** override the default bootstrap classes */
  className: PropTypes.string,
  /** bootstrap sizes */
  size: PropTypes.oneOf(['lg', 'large', 'sm', 'small', 'xs', 'xsmall']),
  /** bootstrap button styles */
  style: PropTypes.oneOf([
    'success',
    'warning',
    'danger',
    'info',
    'default',
    'primary',
    'link',
    'highlight',
    'highlight-light',
  ]),
  as: PropTypes.element,
  /** whether or not button is disabled */
  disabled: PropTypes.bool,
  href: PropTypes.string,
  /** clickable button, submit - submits a form, reset - reset form data to initial values */
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  /** function to run when clicked */
  onClick: PropTypes.func,
  /** usually just the button label */
  children: PropTypes.node,
  /** label used by test automation to identify element */
  cmpRef: PropTypes.string,
  /** accessibility label */
  cmpLabel: PropTypes.string,
  /** accessibility described by used especially for button selector */
  cmpDescribedBy: PropTypes.string,
  /** predefined fixed widths (80px, 140px, 210px) */
  widthSize: PropTypes.oneOf(['1x', '2x', '3x']),
  /** hide focus of the button (default is false) */
  hideFocusOutline: PropTypes.bool,
  /** use a div instead of button tag to wrap the children. Use this with caution because of the limited number of props. (default is false) */
  asDiv: PropTypes.bool,
}

export default AButton
