import { func, number, object, string } from 'prop-types'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { REL_NOFOLLOW } from '../../../external/components/link/ABaseLink'
import MenuLink from '../../../external/components/navigation/menu/MenuLink'
import { getLeaveBetaRoute } from '../../../router/ExtRouteManager'
import {
  selectHomeMobileSubMenuItems,
  selectLegacyLinkShown,
} from '../../../selectors/config'
import { simpleTranslate } from '../../Translation'
import { useClickOutside } from '../hooks/useClickOutside'
import MainMenuItems from './MainMenuItems'

const BurgerMenu = ({
  intl,
  displayType,
  userProfile,
  brandConfig,
  mainConfig,
  cartTimeToExpiry,
  cartItemCount,
  location,
  onSignOutRequest,
  homeConfig,
}) => {
  const [isOpen, setOpen] = useState(false)

  const isLegacyLinkShown = useSelector(selectLegacyLinkShown)
  const menuItems = useSelector(selectHomeMobileSubMenuItems)

  const onOpenToggle = () => {
    setOpen((isOpen) => !isOpen)
  }

  const onClose = () => {
    setOpen(false)
  }

  const onClickOutside = () => {
    onClose()
  }

  const { containerRef } = useClickOutside({ onClickOutside })

  return (
    <div
      ref={containerRef}
      className={`site-navigation-menu site-navigation-menu--${
        isOpen ? 'active' : 'inactive'
      }`}
    >
      <button
        aria-label={simpleTranslate(intl, 'labels_navigation_button')}
        onClick={onOpenToggle}
        className="site-navigation-menu__button"
      >
        <i
          className={`fa fa-6 ${isOpen ? 'fa-times' : 'fa-bars'}`}
          aria-hidden="true"
        />
      </button>
      <div className="site-navigation-menu__main-menu">
        <div className="site-navigation-menu__menu-items">
          <MainMenuItems
            intl={intl}
            userProfile={userProfile}
            brandConfig={brandConfig}
            mainConfig={mainConfig}
            displayType={displayType}
            cartTimeToExpiry={cartTimeToExpiry}
            cartItemCount={cartItemCount}
            location={location}
            closeOnClickOutside={false}
            onClickWrapper={onClose}
            onSignOutRequest={onSignOutRequest}
            menuType="burger"
            homeConfig={homeConfig}
          />
        </div>
        <div className="site-navigation-menu__menu-links">
          {menuItems.map((item) => (
            <MenuLink
              key={item.id}
              to={item.link}
              internal={false}
              newWin={true}
              label={simpleTranslate(
                intl,
                item.label.token,
                item.label.default,
              )}
            />
          ))}
          {isLegacyLinkShown && (
            <MenuLink
              cmpRel={[REL_NOFOLLOW]}
              to={getLeaveBetaRoute()}
              internal={false}
              newWin={false}
              label={simpleTranslate(intl, 'labels_return_to_legacy_site')}
            />
          )}
        </div>
      </div>
    </div>
  )
}

BurgerMenu.propTypes = {
  intl: object.isRequired,
  theme: string,
  displayType: string,
  userProfile: object.isRequired,
  brandConfig: object.isRequired,
  mainConfig: object.isRequired,
  cartTimeToExpiry: object.isRequired,
  cartItemCount: number.isRequired,
  location: object,
  onSignOutRequest: func.isRequired,
  homeConfig: object.isRequired,
}

export { BurgerMenu }
