import PropTypes from 'prop-types'
import React, { Component } from 'react'

import Charts from '../../../external/components/chart/Charts'
import Cont from '../../../external/components/layout/Cont'
import ABaseLink from '../../../external/components/link/ABaseLink'
import SimpleModal from '../../../external/components/overlay/modal/SimpleModal'
import { getNumberOfStars } from '../../../utilities/RatingUtil'
import { simpleTranslate } from '../../Translation'

/**
 * @author Amarjeet Lamba <Amarjeet.Lamba@activenetwork.com>
 *
 * RatingCmp description
 * @type {Object}
 *
 */
class RatingCmp extends Component {
  constructor() {
    super()
    this.state = { popupOpen: false }
  }

  onRatingClick() {
    this.setState({
      ...this.state,
      popupOpen: true,
    })
  }

  getModal() {
    return (
      <SimpleModal
        show={this.state.popupOpen}
        onHide={this.onHideModal.bind(this)}
        closeButton
        header={this.props.facilityName}
        body={this.getModalContent()}
      />
    )
  }

  onHideModal() {
    this.setState({
      ...this.state,
      popupOpen: false,
    })
  }

  getSeries() {
    const { summary, averageValue } = this.props.rating
    const average = averageValue
    const series = []

    for (let i = 0; i < summary.length; i++) {
      series.push(
        `${average} ${simpleTranslate(
          this.props.intl,
          'labels_favorites_list_serie_label',
        )}`,
      )
    }

    return series
  }

  getLabels() {
    const { summary } = this.props.rating
    const labels = []
    const values = summary.map((list) => list.value).reverse()

    for (const value of values) {
      labels.push(
        `${value} ${simpleTranslate(
          this.props.intl,
          'labels_favorites_list_stars_label',
        )}`,
      )
    }

    return labels
  }

  getValues() {
    const { summary } = this.props.rating
    const percentage = summary.map((list) => list.percentage).reverse()
    const values = []

    for (const percent of percentage) {
      values.push(
        `${percent} ${simpleTranslate(
          this.props.intl,
          'labels_favorites_list_percent_label',
        )}`,
      )
    }

    return values
  }

  getData() {
    const { summary } = this.props.rating
    const ratinglist = summary.map((list) => list.count).reverse()

    return ratinglist
  }

  getModalContent() {
    return (
      <Cont>
        <Charts
          data={[this.getData()]}
          labels={this.getLabels()}
          series={this.getSeries()}
          values={this.getValues()}
          chartType={'horizontal'}
          showBackground={true}
          showPercentage={true}
        />
      </Cont>
    )
  }

  render() {
    const { rating } = this.props
    const totalCount = getNumberOfStars(rating)
    const stars = []
    const statement = `${rating.totalCount} ${simpleTranslate(
      this.props.intl,
      'labels_favorites_list_ratings_label',
    )}`

    for (let i = 0; i < totalCount; i++) {
      stars.push(
        <i key={`fa-star_${i}`} className="fa fa-star" aria-hidden="true"></i>,
      )
    }
    for (let i = 0; i < 5 - totalCount; i++) {
      stars.push(
        <i
          key={`fa-star-o_${i}`}
          className="fa fa-star-o"
          aria-hidden="true"
        ></i>,
      )
    }

    return (
      <div className="rating">
        <ABaseLink onClick={this.onRatingClick.bind(this)}>
          <span className="rating">
            {stars} {statement}
          </span>
        </ABaseLink>
        {this.getModal()}
      </div>
    )
  }
}

RatingCmp.propTypes = {
  intl: PropTypes.object.isRequired,
  rating: PropTypes.object.isRequired,
  facilityName: PropTypes.string.isRequired,
}

export default RatingCmp
