/**
 * @file   : CardBack.js
 * @author : Amarjeet Lamba (Amarjeet.Lamba@AspiraConnect.com)
 * @date   : 10/26/2018, 11:08:42 AM
 */
import PropTypes from 'prop-types'
import React from 'react'

import { isUndefined } from '../../../utilities/GeneralUtils'
import { ROLES } from '../../../utilities/accessibility/Definitions'
import AIconAction from '../../link/AIconAction'
import { FLIPCARD_BACK, FLIPCARD_FRONT } from './FlipCard'

const CardBack = ({
  isShowBackButton = true,
  onBackButtonClick,
  showing,
  content = null,
  cardBtnLabel = '',
}) => {
  const getStyle = function () {
    let style = 'card-side side-back card-back-flip'
    if (showing === FLIPCARD_FRONT) {
      style = 'card-side side-back'
    }

    return style
  }

  const getBackButton = function () {
    if (isShowBackButton) {
      return (
        <div className="card-action-button">
          <AIconAction
            faStyle={'fa-chevron-circle-left'}
            cmpLabel={cardBtnLabel}
            onClick={() => {
              if (!isUndefined(onBackButtonClick)) {
                onBackButtonClick(FLIPCARD_BACK)
              }
            }}
          />
        </div>
      )
    }

    return null
  }

  return (
    <div
      className={getStyle()}
      role={showing === FLIPCARD_FRONT ? ROLES.WIDGET.ALERT : null}
    >
      <div className="card-main-content">
        {content}
        {getBackButton()}
      </div>
    </div>
  )
}

CardBack.propTypes = {
  isShowBackButton: PropTypes.bool,
  onBackButtonClick: PropTypes.func,
  showing: PropTypes.string.isRequired,
  content: PropTypes.element,
  cardBtnLabel: PropTypes.string,
}

export default CardBack
