import { addDays } from 'date-fns'

import { createDateFromYYYYMMDD, createYYYYMMDDFromDate } from './DateUtils'

export function getDepartureDate(arrivalDate, lengthOfStay, dayUse = false) {
  let los = lengthOfStay
  if (dayUse) {
    los = lengthOfStay - 1
  }
  const dtArr = createDateFromYYYYMMDD(arrivalDate)
  const dtDep = addDays(dtArr, los)

  return createYYYYMMDDFromDate(dtDep)
}
