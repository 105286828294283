import * as AAlert from '../components/overlay/AAlert'
import { isEnvServer } from './GeneralServerUtils'
import { simpleTranslate } from './TranslationUtils'

export const doesNavigatorSupportGeolocation = function () {
  let supportsGeolocation = false
  if (isEnvServer()) {
    return supportsGeolocation
  }
  if (navigator.geolocation) {
    supportsGeolocation = true
  }

  return supportsGeolocation
}

export const navigatorGeoLocationGetCurrentPosition = function (
  callback,
  options = null,
  intl
) {
  const retObj = {}
  if (doesNavigatorSupportGeolocation()) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        retObj.success = true
        retObj.position = position

        callback(retObj)
      },
      (error) => {
        retObj.success = false
        retObj.error = error

        AAlert.error(simpleTranslate(intl, 'labels_enable_browser_location', ''))
      },
      options,
    )
  } else {
    AAlert.error(simpleTranslate(intl, 'labels_enable_browser_location', ''))
  }

  callback(retObj)
}

export const getNavigatorDimensions = function (
  defaultHeight = 0,
  defaultWidth = 0,
) {
  let height = defaultHeight
  let width = defaultWidth
  if (!isEnvServer()) {
    height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
  }

  return {
    height,
    width,
  }
}
