import { bool, func, string } from 'prop-types'
import React from 'react'

import { isEmpty } from '../../../external/utilities/GeneralUtils'

const HeaderMessageBarToggle = ({ message, onToggle, open, display }) => {
  if (isEmpty(message) || !display) {
    return <i></i>
  }

  const styleClass = open
    ? 'header-message-toggle header-message-toggle--active'
    : 'header-message-toggle'
  let symbol = <i className="fa fa-info" aria-hidden="true"></i>
  if (open) {
    symbol = <i className="fa fa-chevron-up" aria-hidden="true"></i>
  }

  return (
    <div onClick={onToggle} className={styleClass}>
      {symbol}
    </div>
  )
}

HeaderMessageBarToggle.propTypes = {
  message: string,
  onToggle: func.isRequired,
  open: bool.isRequired,
  display: bool.isRequired,
}

export default HeaderMessageBarToggle
