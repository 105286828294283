import { getMinCharsForAnySearchTerm } from '../utilities/SearchUtils'
import { selectMainConfig } from './config'

export const DELAY_TYPE_NONE = 'none'
export const DELAY_TYPE_INPUT = 'input'
export const DELAY_TYPE_INTERVAL = 'interval'

export const selectMainSearchConfig = (state) =>
  selectMainConfig(state)?.mainSearch

export const selectSearchDelayType = (state) =>
  selectMainSearchConfig(state)?.delay.type || DELAY_TYPE_NONE

export const selectSearchDelayTimeout = (state) =>
  selectMainSearchConfig(state)?.delay.delayInputTimeMS ?? 400

export const selectSearchDelayMinChar = (state) =>
  selectMainSearchConfig(state)?.delay.delayAfterMinChar ?? 4

export const selectSearchScrollToTopOnMobile = (state) =>
  !!selectMainSearchConfig(state)?.scrollToTopOnMobile.active

export const selectAnySearchTermMinLength = (state) =>
  getMinCharsForAnySearchTerm(selectMainSearchConfig(state))
