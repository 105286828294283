import { addDays, subDays } from 'date-fns'

import {
  createDateFromYYYYMMDD,
  createYYYYMMDDFromDate,
  getClientTodayYYYYMMDD,
} from '../external/utilities/DateUtils'
import {
  findObjectInArray,
  isEmpty,
  isUndefined,
} from '../external/utilities/GeneralUtils'
import { getMaximumDate } from '../external/utilities/dateRangeHelpers'
import { defaultFormatDateLiteral } from '../external/utilities/formatDate'
import { isDayBefore } from '../external/utilities/isDayBefore'

export const allStatus = []
allStatus.AVAILABLE = {
  status: 'AVAILABLE',
  text: 'A',
  token: 'SITE_GRID_AVAILABLE',
  bgStyle: 'bg-available',
  bgSelectedStyle: 'bg-selected',
  style: 'available',
  iconStyle: null,
  selectedIconStyle: 'avail-cell-check',
  isclickDisabled: false,
  selectable: true,
  showLegend: true,
}
allStatus.WALK_UP = {
  status: 'WALK_UP',
  text: 'W',
  token: 'SITE_GRID_WALK_UP',
  bgStyle: 'bg-walkup',
  bgSelectedStyle: 'bg-selected',
  style: 'walkup',
  iconStyle: null,
  selectedIconStyle: null,
  isclickDisabled: true,
  selectable: false,
  showLegend: true,
}
allStatus.NOT_AVAILABLE = {
  status: 'NOT_AVAILABLE',
  text: 'X',
  token: 'SITE_GRID_NOT_AVAILABLE',
  bgStyle: 'bg-not-available',
  bgSelectedStyle: 'bg-selected',
  style: 'not-available',
  iconStyle: 'avail-cell-not-avail',
  selectedIconStyle: null,
  isclickDisabled: true,
  selectable: false,
  showLegend: true,
}
allStatus.RESERVED = {
  status: 'RESERVED',
  text: 'R',
  token: 'SITE_GRID_RESERVED',
  bgStyle: 'bg-reserved',
  bgSelectedStyle: 'bg-selected',
  style: 'reserved',
  iconStyle: null,
  selectedIconStyle: null,
  isclickDisabled: true,
  selectable: false,
  showLegend: true,
}
allStatus.SELECTED = {
  status: 'SELECTED',
  text: 'A',
  token: 'SITE_GRID_AVAILABLE',
  bgStyle: 'bg-selected',
  bgSelectedStyle: 'bg-selected',
  style: 'selected',
  iconStyle: 'avail-cell-check',
  selectedIconStyle: null,
  isclickDisabled: false,
  selectable: true,
  showLegend: true,
}
allStatus.CALL_THE_PARK = {
  status: 'CALL_THE_PARK',
  text: 'P',
  token: 'SITE_GRID_CALL_THE_PARK',
  bgStyle: 'bg-call-the-park',
  bgSelectedStyle: 'bg-selected',
  style: 'call-the-park',
  iconStyle: null,
  selectedIconStyle: null,
  isclickDisabled: true,
  selectable: false,
  showLegend: true,
}

allStatus.NOT_AVAILABLE_BEYOND_STAY = {
  status: 'NOT_AVAILABLE_BEYOND_STAY',
  text: 'N',
  token: 'SITE_GRID_NOT_AVAILABLE_BEYOND_STAY',
  bgStyle: 'bg-not-available',
  bgSelectedStyle: 'bg-selected',
  style: 'not-available',
  iconStyle: null,
  selectedIconStyle: null,
  isclickDisabled: true,
  selectable: false,
  showLegend: true,
}
allStatus.CALL_CENTER = {
  status: 'CALL_CENTER',
  text: 'C',
  token: 'SITE_GRID_CALL_CENTER',
  bgStyle: 'bg-not-available',
  bgSelectedStyle: 'bg-selected',
  style: 'not-available',
  iconStyle: null,
  selectedIconStyle: null,
  isclickDisabled: true,
  selectable: false,
  showLegend: true,
}
allStatus.AVAILABLE_BEYOUND_STAY = {
  status: 'AVAILABLE_BEYOUND_STAY',
  text: 'a',
  token: 'SITE_GRID_AVAILABLE_BEYOUND_STAY',
  bgStyle: 'bg-available',
  bgSelectedStyle: 'bg-selected',
  style: 'available',
  iconStyle: null,
  selectedIconStyle: 'avail-cell-check',
  isclickDisabled: false,
  selectable: true,
  showLegend: true,
}
allStatus.CLOSURE = {
  status: 'CLOSURE',
  text: 'X',
  token: 'SITE_GRID_CLOSURE',
  bgStyle: 'bg-not-available',
  bgSelectedStyle: 'bg-selected',
  style: 'not-available',
  iconStyle: null,
  selectedIconStyle: null,
  isclickDisabled: true,
  selectable: false,
  showLegend: true,
}
allStatus.NOTOPEN = {
  status: 'NOTOPEN',
  text: 'N',
  token: 'SITE_GRID_NOTOPEN',
  bgStyle: 'bg-not-available',
  bgSelectedStyle: 'bg-selected',
  style: 'not-available',
  iconStyle: null,
  selectedIconStyle: null,
  isclickDisabled: true,
  selectable: false,
  showLegend: true,
}
allStatus.PARKCLOSURE = {
  status: 'PARKCLOSURE',
  text: 'P',
  token: 'SITE_GRID_PARKCLOSURE',
  bgStyle: 'bg-not-available',
  bgSelectedStyle: 'bg-selected',
  style: 'not-available',
  iconStyle: null,
  selectedIconStyle: null,
  isclickDisabled: true,
  selectable: false,
  showLegend: true,
}
allStatus.LOTTERY = {
  status: 'LOTTERY',
  text: 'L',
  token: 'SITE_GRID_INLOTTERY',
  bgStyle: 'bg-not-available',
  bgSelectedStyle: 'bg-selected',
  style: 'not-available',
  iconStyle: null,
  selectedIconStyle: null,
  isclickDisabled: false,
  selectable: true,
  showLegend: true,
}
allStatus.INLOTTERYNONAPP = {
  status: 'INLOTTERYNONAPP',
  text: 'X',
  token: 'SITE_GRID_INLOTTERYNONAPP',
  bgStyle: 'bg-not-available',
  bgSelectedStyle: 'bg-selected',
  style: 'not-available',
  iconStyle: null,
  selectedIconStyle: null,
  isclickDisabled: true,
  selectable: false,
  showLegend: true,
}
allStatus.GROUPBOOKING = {
  status: 'GROUPBOOKING',
  text: 'G',
  token: 'SITE_GRID_GROUPBOOKING',
  bgStyle: 'bg-not-available',
  bgSelectedStyle: 'bg-selected',
  style: 'reserved',
  iconStyle: null,
  selectedIconStyle: null,
  isclickDisabled: true,
  selectable: false,
  showLegend: true,
}
allStatus.NOTAPPLICABLE = {
  status: 'NOTAPPLICABLE',
  text: 'N',
  token: 'SITE_GRID_NOTAPPLICABLE',
  bgStyle: 'bg-not-available',
  bgSelectedStyle: 'bg-selected',
  style: 'reserved',
  iconStyle: null,
  selectedIconStyle: null,
  isclickDisabled: true,
  selectable: false,
  showLegend: true,
}
allStatus.ASSOCIATED_AVAILABLE = {
  status: 'ASSOCIATED_AVAILABLE',
  text: 'A',
  token: 'SITE_GRID_ASSOCIATED_AVAILABLE',
  bgStyle: 'bg-reserved',
  bgSelectedStyle: 'bg-reserved',
  style: 'reserved',
  iconStyle: null,
  selectedIconStyle: null,
  isclickDisabled: true,
  selectable: false,
  showLegend: true,
}

export function translateStatus(status) {
  status = status || 'NOT_AVAILABLE'

  let currStatus = allStatus[status]
  if (isEmpty(currStatus)) {
    currStatus = allStatus.NOT_AVAILABLE
  }

  return currStatus
}

export function isDateSelected(arrivalDate, lengthOfStay, product, date) {
  let selected = false
  // console.log('isDateSelected ' + 1)
  // console.log(arrivalDate)
  // console.log(product)
  // console.log(lengthOfStay)
  if (
    !isEmpty(arrivalDate) &&
    !isEmpty(product.availabilityGrid) &&
    lengthOfStay > 0
  ) {
    // console.log('isDateSelected ' + 2)
    if (isDateSelectable(date, product)) {
      // console.log('isDateSelected ' + 3)
      if (isLengthOfStaySelectable(arrivalDate, lengthOfStay, product)) {
        if (isBusinessRuleCheck(arrivalDate, lengthOfStay, product)) {
          // console.log('isDateSelected ' + 4)
          const allStayDates = getAllStayDates(arrivalDate, lengthOfStay)
          // console.log('isDateSelected ' + 5 + allStayDates)
          for (const allStayDate of allStayDates) {
            if (allStayDate === date) {
              // console.log('allStayDate ' + allStayDate + date)

              selected = true
              break
            }
          }
        }
      }
    }
  }

  return selected
}

export function isProductDayPass(details) {
  let isDayPass = false
  if (!isUndefined(details) && !isEmpty(details)) {
    if (details.dailyEntry) {
      isDayPass = true
    }
  }

  return isDayPass
}

export function isBusinessRuleCheck(arrivalDate, lengthOfStay, product) {
  let ruleCheck = false
  const allStayDates = getAllStayDates(arrivalDate, lengthOfStay)

  if (allStayDates.length === 0) {
    ruleCheck = true
  } else if (allStayDates.length === 1) {
    const availability = getAvailabilityGridForDate(arrivalDate, product)
    const status = translateStatus(availability.status)
    if (
      status.status === allStatus.AVAILABLE.status ||
      status.status === allStatus.LOTTERY.status
    ) {
      ruleCheck = true
    }
  } else {
    const availabilityArr = []
    for (const allStayDate of allStayDates) {
      const availability = getAvailabilityGridForDate(allStayDate, product)
      const status = translateStatus(availability.status)
      availabilityArr.push(status)
    }
    // console.log('isBusinessRuleCheck ' + JSON.stringify(availabilityArr))
    let isAllStatusSame = true
    const firstStatus = availabilityArr[0]
    let allStatusAvailableOrBeyondStay = true
    for (const stat of availabilityArr) {
      // console.log('isBusinessRuleCheck ' + stat.status + '/' + firstStatus.status)
      if (stat.status !== firstStatus.status) {
        // console.log('isBusinessRuleCheck not same')
        isAllStatusSame = false
      }
      if (
        stat.status !== allStatus.AVAILABLE.status &&
        stat.status !== allStatus.AVAILABLE_BEYOUND_STAY.status
      ) {
        allStatusAvailableOrBeyondStay = false
      }
    }
    if (isAllStatusSame) {
      if (
        firstStatus.status === allStatus.AVAILABLE.status ||
        firstStatus.status === allStatus.LOTTERY.status
      ) {
        ruleCheck = true
      }
    } else if (
      firstStatus.status === allStatus.AVAILABLE.status &&
      allStatusAvailableOrBeyondStay
    ) {
      ruleCheck = true
    } else {
      ruleCheck = false
    }
  }

  return ruleCheck
}

export function getBookingObjectForDateAction(
  currArrivalDate,
  currLengthOfStay,
  product,
  date,
  isChangeResv = false,
) {
  const newBookingObj = {}
  const selectedAvailability = getAvailabilityGridForDate(date, product)
  const selectedStatus = translateStatus(selectedAvailability.status)

  if (currLengthOfStay === 0) {
    if (selectedStatus.status !== allStatus.AVAILABLE_BEYOUND_STAY.status) {
      newBookingObj.arrivalDate = date
      newBookingObj.lengthOfStay = 1
    }
  } else if (isDateSelected(currArrivalDate, currLengthOfStay, product, date)) {
    // Unselect and reduce length of stay
    if (currLengthOfStay === 1) {
      newBookingObj.arrivalDate = null
      newBookingObj.lengthOfStay = 0
    } else {
      const allStayDates = getAllStayDates(currArrivalDate, currLengthOfStay)
      if (allStayDates[0] === date) {
        // First date (arrivalDate)
        // console.log('first date')
        const secondDayAvailability = getAvailabilityGridForDate(
          allStayDates[1],
          product,
        )
        const secondDayStatus = translateStatus(secondDayAvailability.status)
        if (secondDayStatus.status === allStatus.AVAILABLE.status) {
          newBookingObj.arrivalDate = allStayDates[1]
          newBookingObj.lengthOfStay = currLengthOfStay - 1
        }
      } else if (allStayDates[allStayDates.length - 1] === date) {
        // Last date
        // console.log('last date')
        newBookingObj.arrivalDate = currArrivalDate
        newBookingObj.lengthOfStay = currLengthOfStay - 1
      } else {
        // Not contiguous any more
        // console.log('not contiguous')
        let ctr = 0
        for (const allStayDate of allStayDates) {
          if (allStayDate === date) break

          ctr += 1
        }
        newBookingObj.arrivalDate = currArrivalDate
        newBookingObj.lengthOfStay = ctr
      }
    }
  } else {
    // Select and add length of stay if contiguous
    // else create new object with 1 length of stay
    const allStayDates = getAllStayDates(currArrivalDate, currLengthOfStay)
    let peviousDate = null
    let nextDate = null

    for (let i = 0; i < product.availabilityGrid.length; i++) {
      if (allStayDates[0] === product.availabilityGrid[i].date && i !== 0) {
        peviousDate = product.availabilityGrid[i - 1].date
      }
      if (
        allStayDates[allStayDates.length - 1] ===
          product.availabilityGrid[i].date &&
        i !== product.availabilityGrid.length - 1
      ) {
        nextDate = product.availabilityGrid[i + 1].date
      }
    }

    let newArrivalDate = null
    let newLengthOfStay = 0
    if (isChangeResv && isProductDayPass(product.details)) {
      newArrivalDate = date
      newLengthOfStay = 1
    } else if (peviousDate !== null && date === peviousDate) {
      newArrivalDate = peviousDate
      newLengthOfStay = currLengthOfStay + 1
    } else if (nextDate !== null && date === nextDate) {
      newArrivalDate = currArrivalDate
      newLengthOfStay = currLengthOfStay + 1
    } else {
      newArrivalDate = date
      newLengthOfStay = 1
    }

    const brc = isBusinessRuleCheck(newArrivalDate, newLengthOfStay, product)
    if (brc) {
      newBookingObj.arrivalDate = newArrivalDate
      newBookingObj.lengthOfStay = newLengthOfStay
    } else {
      newBookingObj.arrivalDate = currArrivalDate
      newBookingObj.lengthOfStay = currLengthOfStay
    }
  }

  return newBookingObj
}

export function isLengthOfStaySelectable(arrivalDate, lengthOfStay, product) {
  // console.log('isLengthOfStaySelectable ' + '1' + product)
  let allDatesSelectable = true
  const stayDatesArr = getAllStayDates(arrivalDate, lengthOfStay)
  // console.log('isLengthOfStaySelectable ' + 2)

  for (let j = 0; j < stayDatesArr.length; j++) {
    // console.log('isLengthOfStaySelectable ' + 3)
    if (!isDateSelectable(stayDatesArr[j], product)) {
      // console.log('isLengthOfStaySelectable ' + 4)
      allDatesSelectable = false
      break
    }
  }

  return allDatesSelectable
}

export function getAllStayDates(arrivalDate, lengthOfStay) {
  const stayDatesArr = []
  const dtArrivalDate = createDateFromYYYYMMDD(arrivalDate)
  stayDatesArr.push(arrivalDate)

  for (let i = 1; i < lengthOfStay; i++) {
    stayDatesArr.push(createYYYYMMDDFromDate(addDays(dtArrivalDate, i)))
  }

  return stayDatesArr
}

export function isDateSelectable(date, product) {
  // console.log('isDateSelectable ' + date)
  // console.log('isDateSelectable ' + product)
  let selectable = false
  const availability = getAvailabilityGridForDate(date, product)
  if (availability !== null) {
    const status = translateStatus(availability.status)
    if (status !== null && status.selectable) {
      selectable = true
    }
  }

  return selectable
}

export function getAvailabilityGridForDate(date, product) {
  let availability = null
  for (const avail of product.availabilityGrid) {
    if (avail.date === date) {
      availability = avail
      break
    }
  }

  return availability
}

export function isAvailabilityGridForLengthOfStay(
  arrivalDate,
  lengthOfStay,
  product,
) {
  let isAvailabilityGrid = true
  const stayDatesArr = getAllStayDates(arrivalDate, lengthOfStay)
  for (let j = 0; j < stayDatesArr.length; j++) {
    if (isEmpty(getAvailabilityGridForDate(stayDatesArr[j], product))) {
      isAvailabilityGrid = false
      break
    }
  }

  return isAvailabilityGrid
}

export function isLengthOfStayAvailable(arrivalDate, lengthOfStay, product) {
  let allDatesAvailable = true
  const stayDatesArr = getAllStayDates(arrivalDate, lengthOfStay)
  for (let j = 0; j < stayDatesArr.length; j++) {
    const dateSelected = isDateSelected(
      arrivalDate,
      lengthOfStay,
      product,
      stayDatesArr[j],
    )

    if (isAvailabilityGridForLengthOfStay(arrivalDate, lengthOfStay, product)) {
      if (!dateSelected) {
        allDatesAvailable = false
        break
      }
    }
  }

  return allDatesAvailable
}

export const facilityAvailabilityStatus = []
facilityAvailabilityStatus.WEB_AVAILABLE = {
  status: 'WEB_AVAILABLE',
  textToken: 'facility_available_web',
  ctaToken: 'facility_available_web_cta',
}
facilityAvailabilityStatus.CALL_AVAILABLE = {
  status: 'CALL_AVAILABLE',
  textToken: 'facility_available_call',
  ctaToken: 'facility_available_call_cta',
}
facilityAvailabilityStatus.FIELD_AVAILABLE = {
  status: 'FIELD_AVAILABLE',
  textToken: 'facility_available_field',
  ctaToken: 'facility_available_field_cta',
}
facilityAvailabilityStatus.NOT_AVAILABLE = {
  status: 'NOT_AVAILABLE',
  textToken: 'facility_not_available',
  ctaToken: null,
}
facilityAvailabilityStatus.NOT_VERIFIABLE = {
  status: 'NOT_VERIFIABLE',
  textToken: 'facility_not_verifiable',
  ctaToken: null,
}

export function getFacilityAvailabilityStatus(facility) {
  let fas = null
  if (!isEmpty(facility)) {
    if (facility.details.availability !== null) {
      // this is an availability search
      if (facility.details.availability.available === false) {
        // possibly target facility with no availability
        fas = facilityAvailabilityStatus.NOT_AVAILABLE
      } else if (isEmpty(facility.details.availability.reservableType)) {
        fas = facilityAvailabilityStatus.NOT_AVAILABLE
      } else {
        fas =
          facilityAvailabilityStatus[
            facility.details.availability.reservableType
          ]
      }
    }
  }

  return fas
}

export const productAvailabilityStatus = []
productAvailabilityStatus.DEFAULT = {
  status: 'DEFAULT',
  textToken: null,
  ctaTokenButton: null,
  ctaTokenLink: 'product_default_cta',
  allowNextAvailableDateSearch: true,
}
productAvailabilityStatus.AVAILABLE = {
  status: 'AVAILABLE',
  textToken: null,
  ctaTokenButton: 'product_available_cta',
  ctaTokenLink: null,
  allowNextAvailableDateSearch: false,
}
// productAvailabilityStatus['NOT_AVAILABLE'] = {status: 'NOT_AVAILABLE', textToken: 'product_not_available', ctaTokenButton: null, ctaTokenLink: 'product_not_available_cta', allowNextAvailableDateSearch: true}
productAvailabilityStatus.NOT_AVAILABLE = {
  status: 'NOT_AVAILABLE',
  textToken: 'product_not_available',
  ctaTokenButton: null,
  ctaTokenLink: null,
  allowNextAvailableDateSearch: true,
}
productAvailabilityStatus.WALK_UP_ONLY = {
  status: 'WALK_UP_ONLY',
  textToken: 'product_available_walkup_only',
  ctaTokenButton: null,
  ctaTokenLink: 'product_available_walkup_only_cta',
  allowNextAvailableDateSearch: false,
}

productAvailabilityStatus.CALL_THE_PARK = {
  status: 'CALL_THE_PARK',
  textToken: 'product_available_call_the_park',
  ctaTokenButton: null,
  ctaTokenLink: 'product_available_call_the_park_cta',
  allowNextAvailableDateSearch: false,
}
productAvailabilityStatus.BY_PHONE_ONLY = {
  status: 'BY_PHONE_ONLY',
  textToken: 'product_available_by_phone_only',
  ctaTokenButton: null,
  ctaTokenLink: 'product_available_by_phone_only_cta',
  allowNextAvailableDateSearch: false,
}
productAvailabilityStatus.IN_LOTTERY = {
  status: 'IN_LOTTERY',
  textToken: 'product_available_in_lottery',
  ctaTokenButton: null,
  ctaTokenLink: 'product_available_in_lottery_cta',
  allowNextAvailableDateSearch: false,
}

export function getProductAvailabilityStatus(product) {
  let cta = productAvailabilityStatus.DEFAULT
  if (
    !isEmpty(product) &&
    !isEmpty(product.details.cta) &&
    !isEmpty(product.details.cta.availabType)
  ) {
    cta = productAvailabilityStatus[product.details.cta.availabType]
  }

  return cta
}

export function getProductAvailabilityStatusForMapFromCat(cat) {
  let cta = productAvailabilityStatus.DEFAULT
  if (!isEmpty(cat) && !isEmpty(cat.attributes) && cat.attributes.length > 0) {
    const avail = findObjectInArray(
      cat.attributes,
      'key',
      'campsiteAvailabType',
    )
    if (!isEmpty(avail) && !isEmpty(avail.value)) {
      cta = productAvailabilityStatus[avail.value]
    }
  }

  return cta
}

export function getProductAvailabilityStatusForMap(product) {
  let cta = productAvailabilityStatus.DEFAULT
  if (
    !isEmpty(product) &&
    !isEmpty(product.attributes) &&
    product.attributes.length > 0
  ) {
    const cat = findObjectInArray(
      product.attributes,
      'category',
      'AVAILABCTA_ATTRIBUTES',
    )
    cta = getProductAvailabilityStatusForMapFromCat(cat)
  }

  return cta
}

export function isDayUse(product) {
  let dayUse = false
  if (
    !isEmpty(product) &&
    !isEmpty(product.records) &&
    product.records.length > 0
  ) {
    if (product.records[0].details.stayType === 'DAILY') {
      dayUse = true
    }
  }

  return dayUse
}

export function getFirstProduct(product) {
  if (
    !isEmpty(product) &&
    !isEmpty(product.records) &&
    product.records.length > 0
  ) {
    return product.records[0]
  }

  return null
}

export function isSiteMappable(order = null, products = null) {
  let mappable = false
  if (!isEmpty(products)) {
    if (!isUndefined(order) && !isEmpty(order)) {
      if (!order.webOrder.dailyEntry) {
        mappable = true
      }
    }
  }

  return mappable
}

export function isProductHourlyUse(details) {
  let isHourlyUse = false
  if (!isUndefined(details) && !isEmpty(details)) {
    if (details.hourlyUse) {
      isHourlyUse = true
    }
  }

  return isHourlyUse
}

export function getDayPassDates(details, intl) {
  let dates = ''
  if (!isUndefined(details) && !isEmpty(details)) {
    if (
      !isUndefined(details.dailyEntryDates) &&
      !isEmpty(details.dailyEntryDates)
    ) {
      for (const date of details.dailyEntryDates) {
        let prepender = ''
        if (dates !== '') {
          prepender = ', '
        }
        dates += prepender + defaultFormatDateLiteral(date, intl)
      }
    }
  }

  return dates
}

export function getMaxBookingWindowDate(facility, facilityConfig) {
  let referenceDate
  let maxBookingWindow
  if (!isEmpty(facility) && !isEmpty(facility.facilityDate)) {
    referenceDate = facility.facilityDate
  }
  if (
    !isEmpty(facilityConfig) &&
    !isEmpty(facilityConfig.campingCfg) &&
    !isEmpty(facilityConfig.campingCfg.campsitesSearch)
  ) {
    maxBookingWindow =
      facilityConfig.campingCfg.campsitesSearch.maxBookingWindow
  }
  const maximumBookingWindowDate = getMaximumDate(
    maxBookingWindow,
    referenceDate,
  )

  return maximumBookingWindowDate
}

export function getPreviousAvailabilityGridDate(facility, date, numDays) {
  let referenceDate
  if (!isEmpty(facility) && !isEmpty(facility.facilityDate)) {
    referenceDate = facility.facilityDate
  } else {
    referenceDate = getClientTodayYYYYMMDD()
  }
  const refDate = createDateFromYYYYMMDD(referenceDate)

  let newDate = null
  let newDateStr = ''

  newDate = subDays(date, numDays)
  if (isDayBefore(newDate, refDate)) {
    newDate = refDate
  }
  newDateStr = createYYYYMMDDFromDate(newDate)

  return newDateStr
}

export function getNextAvailabilityGridDate(
  facility,
  facilityConfig,
  date,
  numDays,
) {
  const currStartDate = subDays(date, numDays - 1)
  const maxBookingWindowDate = getMaxBookingWindowDate(facility, facilityConfig)
  const newGridEndDate = addDays(date, numDays)
  let newGridStartDate = null

  if (isDayBefore(newGridEndDate, maxBookingWindowDate)) {
    newGridStartDate = addDays(date, 1)
  } else {
    newGridStartDate = subDays(maxBookingWindowDate, numDays)
  }

  if (isDayBefore(newGridStartDate, currStartDate)) {
    newGridStartDate = currStartDate
  }

  const newDateStr = createYYYYMMDDFromDate(newGridStartDate)

  return newDateStr
}

export function getAllDisplayedStatus(
  products,
  showSelectedStatus = false,
  showAssociatedAvailableStatus = false,
) {
  const ads = []
  if (isEmpty(products) || isEmpty(products.records)) {
    return ads
  }
  for (const product of products.records) {
    if (!isEmpty(product.availabilityGrid)) {
      for (let i = 0; i < product.availabilityGrid.length; i++) {
        const currStatus = translateStatus(product.availabilityGrid[i].status)
        // console.log('getAllDisplayedStatus ' + currStatus.status)
        ads[currStatus.status] = currStatus
      }
    }
  }

  if (showSelectedStatus) {
    ads[allStatus.SELECTED.status] = allStatus.SELECTED
  }
  if (showAssociatedAvailableStatus) {
    ads[allStatus.ASSOCIATED_AVAILABLE.status] = allStatus.ASSOCIATED_AVAILABLE
  }

  return ads
}

export function getProductsAvailStartDate(products) {
  let startDate = ''
  if (
    !isEmpty(products) &&
    !isEmpty(products.records) &&
    products.records.length > 0
  ) {
    startDate = products.records[0].availabilityGrid[0].date
  }

  return startDate
}

export function isProductNotificationAllowed(availabilitySupport) {
  let allowed = false
  if (!isEmpty(availabilitySupport)) {
    if (availabilitySupport.availabNotifDisponible) {
      allowed = true
    }
  }

  return allowed
}

export function isProductNotificationQuotaExceeded(availabilitySupport) {
  let exceeded = false
  if (!isEmpty(availabilitySupport)) {
    if (availabilitySupport.availabNotifQuotaExceeded) {
      exceeded = true
    }
  }

  return exceeded
}

export function isProductDefined(productId) {
  let defined = false
  if (productId !== undefined && !isEmpty(productId) && productId !== 'null') {
    defined = true
  }

  return defined
}

export function getSlotDef(slot) {
  let slotDef = null
  if (!isEmpty(slot)) {
    slotDef = slot.startTime.toString()
  }

  return slotDef
}

export function isSlotSelected(
  slotDef,
  date,
  productSelectedSlots,
  arrivalDate,
) {
  let selected = false
  if (
    !isEmpty(productSelectedSlots) &&
    !isEmpty(arrivalDate) &&
    date === arrivalDate
  ) {
    for (const currSlotDef of productSelectedSlots) {
      if (currSlotDef === slotDef) {
        selected = true
      }
    }
  }

  return selected
}

export function areSlotsSelected(selectedSlots, arrivalDate, currentDate) {
  if (
    !isEmpty(selectedSlots) &&
    !isEmpty(arrivalDate) &&
    arrivalDate === currentDate
  ) {
    return true
  }

  return false
}

export function getEmptyProductDefinition() {
  return 'null'
}
