import { array, object, string } from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import MetaData from '../../external/components/meta/MetaData'
import { getErrors } from '../../utilities/ErrorUtils'
import { getLiteralValuesForApp } from '../../utilities/MetaDataUtils'
import Error from './Error'
import ErrorGuidance from './ErrorGuidance'

const PAGE_ID = 'PAGE_ERROR'

class ErrorContainer extends Component {
  render() {
    const { theme, errors, intl, metaConfig, mainConfig } = this.props

    return (
      <div>
        {metaConfig && mainConfig && (
          <MetaData
            intlObj={intl}
            data={metaConfig}
            literalValues={getLiteralValuesForApp(mainConfig)}
            pageId={PAGE_ID}
          />
        )}
        <div className="container container--errors">
          <Error theme={theme} intl={intl} errors={errors} />
          <ErrorGuidance intl={intl} theme={theme} />
        </div>
      </div>
    )
  }

  static propTypes = {
    intl: object,
    theme: string,
    errors: array,
    metaConfig: object,
    mainConfig: object,
  }
}
function mapStateToProps(state) {
  return {
    intl: state.backend.intl,
    theme: state.application.theme,
    errors: getErrors(state.backend.error, state.application.error),
    metaConfig: state.backend.config?.meta,
    mainConfig: state.backend.config?.main,
  }
}

export default connect(mapStateToProps)(ErrorContainer)
