import { isEnvServer } from '../../external/utilities/GeneralServerUtils'
import { getBrazeAppKey, getBrazeSdkHost } from '../../utilities/ConfigUtils'
import { getPurchaseData } from './dataUtils'

let ready = false
let turnOff = false

let braze

export const initBraze = async (state) => {
  if (isEnvServer()) return

  const mainConfig = { ...state.backend.config.main }
  const brandConfig = { ...state.backend.config.brand }
  const userID = state.backend?.user?.profile?.userID
  const loginName = state.backend?.user?.profile?.loginName

  const appKey = getBrazeAppKey(mainConfig, brandConfig.appEnvType)
  const sdkHost = getBrazeSdkHost(mainConfig, brandConfig.appEnvType)

  if (!sdkHost) {
    console.warn('[Braze] SDK host is missing.')
  }

  if (!appKey) {
    console.warn('[Braze] Setup key is missing.')
    turnOff = true

    return
  }

  braze = (await import('./braze')).default

  return new Promise((res) => {
    ready = braze.initialize(appKey, {
      baseUrl: sdkHost,
      enableLogging: true,
    })
    if (userID) {
      braze.changeUser(userID)
      braze.getUser().setEmail(loginName)
    }
    braze.openSession()
    res(braze)
  })
}

const wrapTrackingActions = (trackingActions) => {
  const wrappedActions = {}

  Object.keys(trackingActions).forEach((key) => {
    const trackingAction = trackingActions[key]
    const wrappedAction = (...args) => {
      if (!ready) {
        if (!turnOff) {
          console.warn(`Braze tracking '${key}' called before init`)
        }

        return
      }
      try {
        trackingAction(...args)
      } catch (exception) {
        console.error('Braze tracking error:', exception)
      }
    }
    wrappedActions[key] = wrappedAction
  })

  return wrappedActions
}

const trackingActions = {
  successfulSignIn: (userID, emailAddress) => {
    braze.changeUser(userID)
    braze.getUser().setEmail(emailAddress)
  },
  successfulCreateAcct: (userID, emailAddress) => {
    braze.changeUser(userID)
    braze.getUser().setEmail(emailAddress)
  },
  clickRVRental: (data) => {
    if (data) {
      braze.logCustomEvent('rv_rental_clicked', data)
    } else {
      braze.logCustomEvent('rv_rental_clicked')
    }
  },
  clickApexClub: (data) => {
    if (data) {
      braze.logCustomEvent('apex_club_clicked', data)
    } else {
      braze.logCustomEvent('apex_club_clicked')
    }
  },
  homepageClickOnMarketingSpot: (data) => {
    trackingActions.clickApexClub(data)
  },
  abandonCart(data) {
    braze.logCustomEvent('cart_abandoned', data)
  },
  purchase(data, bookingType, checkoutId = null, agencyName = null) {
    const purchaseData = getPurchaseData(
      data,
      checkoutId,
      bookingType,
      agencyName,
    )
    braze.logPurchase(
      purchaseData.parkName,
      purchaseData.price,
      purchaseData.currency,
      purchaseData.quantity,
      purchaseData.item,
    )
  },
  otaPurchase(data, bookingType, checkoutId, agencyName) {
    const purchaseData = getPurchaseData(
      data,
      checkoutId,
      bookingType,
      agencyName,
    )
    braze.logCustomEvent('ota_purchase', purchaseData)
  },
  removeSW(data) {
    braze.logCustomEvent('sensible_weather_removed', data)
  },
  removeApex(data) {
    braze.logCustomEvent('apex_club_removed', data)
  },
}

export default wrapTrackingActions(trackingActions)
