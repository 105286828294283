import { getResultsFromCache } from '../utilities/SearchUtils'

export const selectFavoritesState = (state) => state.backend.favorites

export const selectFavoritesSearchCache = (state) =>
  selectFavoritesState(state).placesSearchResultsCache

export const selectFavoritesCacheByTerm = (state, searchTerm) => {
  const cache = selectFavoritesSearchCache(state)

  return getResultsFromCache(searchTerm, cache)
}
