import PropTypes from 'prop-types'
import React from 'react'

/** Used to display an X as a close button */

const ACloseButton = ({ onClose }) => (
  <div className={'dialog-close-button'} onClick={onClose}>
    <i className={'fa fa-times'} aria-hidden={'true'} />
  </div>
)

ACloseButton.propTypes = {
  /** function to run when clicking on the close button */
  onClose: PropTypes.func.isRequired,
}

export default ACloseButton
