import { has } from 'lodash'

import {
  DAY_PASSES,
  DAY_PASSES_AND_BOOKING_DETAILS,
  HOURLY_SITE_LIST,
  OVERVIEW,
  SITES_CALENDAR,
  SITE_AND_BOOKING_DETAILS,
  SITE_LIST,
} from '../constants/Routes'
import {
  clone,
  isEmpty,
  isNumber,
  isUndefined,
  merge,
} from '../external/utilities/GeneralUtils'
import { getDepartureDate } from '../external/utilities/getDepartureDate'
import { getCartId } from './CartUtils'
import { getSearchType } from './SearchUtils'

export const PRM = {
  /* Main Search */
  searchType: {
    url: 'type',
    api: 'stype',
  },
  searchTerm: {
    url: 'searchTerm',
    api: 'term',
  },
  placeId: {
    url: 'placeId',
    api: 'placeId',
  },
  stateCode: {
    url: 'stateCode',
    api: 'tstc',
  },
  longitude: {
    url: 'longitude',
    api: 'lng',
  },
  latitude: {
    url: 'latitude',
    api: 'lat',
  },
  contractCode: {
    url: 'contractCode',
    api: 'ctcd',
  },
  facilityId: {
    url: 'facilityId',
    api: 'pid',
    ext: 'parkId',
  },
  match: {
    url: 'match',
    api: 'match',
    ext: 'match',
  },

  /* Product Search */
  productId: {
    url: 'productId',
    api: 'prdId',
    filter: 'productId',
    dynamicFilter: 'prdId',
    ext: 'siteId',
    pageIds: [OVERVIEW, DAY_PASSES, SITE_AND_BOOKING_DETAILS],
  },
  arrivalDate: {
    url: 'arrivalDate',
    api: 'arv',
    filter: 'arrivaldate',
    dynamicFilter: 'arv',
    pageIds: [
      OVERVIEW,
      SITE_LIST,
      SITES_CALENDAR,
      DAY_PASSES,
      SITE_AND_BOOKING_DETAILS,
      DAY_PASSES_AND_BOOKING_DETAILS,
      HOURLY_SITE_LIST,
    ],
  },
  departureDate: {
    url: 'departureDate',
    api: 'depDate',
    filter: 'departuredate',
    pageIds: [OVERVIEW, SITE_LIST, SITES_CALENDAR, SITE_AND_BOOKING_DETAILS],
  },
  lengthOfStay: {
    url: 'lengthOfStay',
    api: 'lsy',
    dynamicFilter: 'lsy',
    pageIds: [
      OVERVIEW,
      SITE_LIST,
      SITES_CALENDAR,
      DAY_PASSES,
      SITE_AND_BOOKING_DETAILS,
      DAY_PASSES_AND_BOOKING_DETAILS,
      HOURLY_SITE_LIST,
    ],
  },
  siteTypeId: {
    url: 'siteTypeId',
    api: 'stid',
    pageIds: [OVERVIEW, SITE_LIST, SITES_CALENDAR, SITE_AND_BOOKING_DETAILS],
  },
  nextAvailableDate: {
    url: 'nextAvailableDate',
    api: 'next',
    pageIds: [
      OVERVIEW,
      SITE_LIST,
      SITES_CALENDAR,
      SITE_AND_BOOKING_DETAILS,
      HOURLY_SITE_LIST,
    ],
  },

  lookingFor: {
    url: 'lookingFor',
    api: 'pa99999',
    filter: 'lookingfor',
    pageIds: [OVERVIEW, SITE_LIST, SITES_CALENDAR, SITE_AND_BOOKING_DETAILS],
  },
  maxPeople: {
    url: 'maxPeople',
    api: 'pa12',
    filter: 'attribute_12',
    pageIds: [
      OVERVIEW,
      SITE_LIST,
      SITES_CALENDAR,
      SITE_AND_BOOKING_DETAILS,
      HOURLY_SITE_LIST,
    ],
  },
  driveEntry: {
    url: 'driveEntry',
    api: 'pa13',
    filter: 'attribute_13',
    pageIds: [OVERVIEW, SITE_LIST, SITES_CALENDAR, SITE_AND_BOOKING_DETAILS],
  },
  waterfront: {
    url: 'waterfront',
    api: 'pa24',
    filter: 'attribute_24',
    pageIds: [
      OVERVIEW,
      SITE_LIST,
      SITES_CALENDAR,
      SITE_AND_BOOKING_DETAILS,
      HOURLY_SITE_LIST,
    ],
  },
  waterHookup: {
    url: 'waterHookup',
    api: 'pa32',
    filter: 'attribute_32',
    pageIds: [OVERVIEW, SITE_LIST, SITES_CALENDAR, SITE_AND_BOOKING_DETAILS],
  },
  sewerHookup: {
    url: 'sewerHookup',
    api: 'pa33',
    filter: 'attribute_33',
    pageIds: [OVERVIEW, SITE_LIST, SITES_CALENDAR, SITE_AND_BOOKING_DETAILS],
  },
  accessible: {
    url: 'accessible',
    api: 'pa104',
    filter: 'attribute_104',
    pageIds: [
      OVERVIEW,
      SITE_LIST,
      SITES_CALENDAR,
      SITE_AND_BOOKING_DETAILS,
      HOURLY_SITE_LIST,
    ],
  },
  vehiclelength: {
    url: 'vehiclelength',
    api: 'pa109',
    filter: 'attribute_109',
    pageIds: [OVERVIEW, SITE_LIST, SITES_CALENDAR, SITE_AND_BOOKING_DETAILS],
  },
  electric: {
    url: 'electric',
    api: 'pa218',
    filter: 'attribute_218',
    pageIds: [
      OVERVIEW,
      SITE_LIST,
      SITES_CALENDAR,
      SITE_AND_BOOKING_DETAILS,
      HOURLY_SITE_LIST,
    ],
  },
  pets: {
    url: 'pets',
    api: 'pa220',
    filter: 'attribute_220',
    pageIds: [
      OVERVIEW,
      SITE_LIST,
      SITES_CALENDAR,
      SITE_AND_BOOKING_DETAILS,
      HOURLY_SITE_LIST,
    ],
  },
  loopId: {
    url: 'loopId',
    api: 'lpid',
    filter: 'loops',
    pageIds: [OVERVIEW, SITE_LIST, SITES_CALENDAR, SITE_AND_BOOKING_DETAILS],
  },
  flexibleDates: {
    url: 'flexibleDates',
    api: 'flxd',
    filter: 'flexibledates',
    pageIds: [
      OVERVIEW,
      SITE_LIST,
      SITES_CALENDAR,
      SITE_AND_BOOKING_DETAILS,
      HOURLY_SITE_LIST,
    ],
  },
  interest: {
    url: 'interest',
    api: 'interest',
    filter: 'interest',
    pageIds: [OVERVIEW, SITE_LIST, SITES_CALENDAR, SITE_AND_BOOKING_DETAILS],
  },
  facilityType: {
    url: 'facilityType',
    api: 'fct',
    filter: 'facilityType',
    pageIds: [OVERVIEW, SITE_LIST, SITES_CALENDAR, SITE_AND_BOOKING_DETAILS],
  },
  detailed: {
    url: 'detailed',
    api: 'detailed',
    ext: 'detailed',
  },
  nsQty: {
    url: 'nsQty',
    api: 'nsqty',
    dynamicFilter: 'nsqty',
    pageIds: [OVERVIEW, DAY_PASSES],
  },
  displayGISMap: {
    url: 'displayGISMap',
    api: 'displayGISMap',
  },

  /* Paging */
  pageNumber: {
    url: 'pageNumber',
    api: 'rcp',
  },
  recordsPerPage: {
    url: 'recordsPerPage',
    api: 'rcs',
  },

  /* Product Availability */
  productAvailabilityStartDate: {
    url: 'availStartDate',
    api: 'gad',
  },
  productAvailabilityLength: {
    url: 'availLength',
    api: 'gal',
  },

  /* Cart */
  cartId: {
    url: 'cartId',
    api: 'cartId',
  },
  cartItemId: {
    url: 'cartItemId',
    api: 'cid',
    ext: 'currentItemId',
  },
  addItemToCart: {
    url: 'addItemToCart',
    api: 'addItemToCart',
  },
  processId: {
    url: 'processID',
    api: 'processID',
  },
  primaryItemId: {
    url: 'primaryItemID',
    api: 'primItemID',
  },
  primaryResvNo: {
    url: 'primaryResvNo',
    api: 'primRes',
  },

  /* RootInfo */
  brand: {
    url: 'brandName',
    api: 'brandName',
  },

  /* Mode */
  mode: {
    url: 'mode',
    api: 'mode',
  },

  /* Favorites */
  addFaveContractCode: {
    url: 'afcc',
    api: 'afcc',
  },
  addFaveFacilityId: {
    url: 'affi',
    api: 'affi',
  },

  /* My Account */
  currentReservations: {
    url: 'current',
    api: 'curRsv',
  },

  currentPasses: {
    url: 'current',
    api: 'isActive',
  },

  reservationId: {
    url: 'reservationId',
    api: 'resNumber',
    ext: 'reservationNumber',
  },

  reservationType: {
    url: 'reservationType',
    api: 'rtp',
  },

  passId: {
    url: 'passId',
    api: 'passID',
  },

  equipmentId: {
    url: 'equipmentId',
    api: 'equipID',
  },

  marina: {
    url: 'marina',
    api: 'marina',
  },

  category: {
    url: 'category',
    api: 'cat',
    ext: 'cat',
  },

  mainContractCode: {
    url: 'contractCode',
    api: 'contractCode',
    ext: 'contractCode',
  },

  /* Feedback */
  feedbackGroup: {
    url: 'feedbackGroup',
    api: 'fgrp',
  },

  /* View States */
  viewState: {
    url: 'viewState',
    api: 'viewState',
  },
  subViewState: {
    url: 'subViewState',
    api: 'subViewState',
  },

  /* Tours */
  tourId: {
    url: 'tourId',
    api: 'tourId',
  },
  tourInventoryId: {
    url: 'tourInventoryId',
    api: 'tourInventoryId',
    ext: 'tourInv',
  },

  /* POS */
  bundle: {
    url: 'bundle',
    api: 'bundle',
  },

  /* Dynamic Updates Param for URL */
  dynamicUpdate: {
    url: 'dynamicUpdate',
    api: 'dynamicUpdate',
  },

  /* General */
  change: {
    url: 'change',
    api: 'change',
  },

  reset: {
    url: 'reset',
    api: 'reset',
  },

  /* sorting */
  sortType: {
    url: 'sortType',
    api: 'sortby',
  },

  sortDir: {
    url: 'sortDir',
    api: 'sortdir',
  },

  productVehicleOccupantQuantity: {
    url: 'productVehicleOccupantQuantity',
    api: 'productVehicleOccupantQuantity',
  },

  productSelectedSlots: {
    url: 'productSelectedSlots',
    api: 'productSelectedSlots',
  },

  /* lob */
  lob: {
    url: 'lob',
    api: 'lob',
  },

  /* temp */
  tempFacilityDetailsOnly: {
    url: 'temp_facility_details_only',
    api: 'temp_facility_details_only',
  },

  /* verification token */
  userChallengeVerificationToken: {
    url: 'userChallengeVerificationToken',
    api: 'userChallengeVerificationToken',
  },

  /* Mixpanel */
  signInAcct: {
    url: 'signInAcct',
    api: 'signInAcct',
  },

  createAcct: {
    url: 'createAcct',
    api: 'createAcct',
  },

  /* Marketing */
  agency: {
    url: 'agency',
    api: 'agency',
  },
}

export const MODE_NEW = 'modeNew'
export const MODE_CHANGE = 'modeChange'
export const MODE_CHANGE_RESERVATION = 'modeChangeReservation'
export const MODE_PENDING = 'modePending'

const DEFAULT_PAGE_SIZE = 20
const DEFAULT_PRODUCT_AVAILABILITY_LENGTH = 14

/* Paging */
export function getPagingObj({
  isURL = true,
  pageNumber,
  recordsPerPage = DEFAULT_PAGE_SIZE,
}) {
  if (recordsPerPage === DEFAULT_PAGE_SIZE) {
    return composeParamObj(isURL, [PRM.pageNumber, pageNumber])
  }

  return composeParamObj(isURL, [
    PRM.pageNumber,
    pageNumber,
    PRM.recordsPerPage,
    recordsPerPage,
  ])
}

export function getPagingApiObj(query = null) {
  return composeParamObj(
    false,
    addAllFromQuery(query, [PRM.pageNumber, PRM.recordsPerPage]),
  )
}

/* Sorting */
export const getSortingObj = function ({ isURL = true, sortType, sortDir }) {
  return composeParamObj(isURL, [PRM.sortType, sortType, PRM.sortDir, sortDir])
}

export const getSortingObjFromQuery = function (queryObj) {
  const sortingObj = {}

  if (!isUndefined(queryObj)) {
    if (has(queryObj, PRM.sortType.url)) {
      sortingObj[PRM.sortType.url] = queryObj[PRM.sortType.url]
    }

    if (has(queryObj, PRM.sortDir.url)) {
      sortingObj[PRM.sortDir.url] = queryObj[PRM.sortDir.url]
    }
  }

  return sortingObj
}

export const getSortTypeFromQuery = function (query) {
  let sortType = null
  const sortingObj = getSortingObjFromQuery(query)
  if (!isEmpty(sortingObj) && !isUndefined(sortingObj[PRM.sortType.url])) {
    sortType = sortingObj[PRM.sortType.url]
  }

  return sortType
}

export const getSortDirFromQuery = function (query) {
  let sortDir = null
  const sortingObj = getSortingObjFromQuery(query)
  if (!isEmpty(sortingObj) && !isUndefined(sortingObj[PRM.sortDir.url])) {
    sortDir = sortingObj[PRM.sortDir.url]
  }

  return sortDir
}

/* Main Search */
export function getMainSearchObj(isURL = true, searchObj) {
  const arr = []
  if (searchObj.searchTerm !== null) {
    arr.push(PRM.searchTerm)
    arr.push(encodeURIComponent(searchObj.searchTerm))
  }

  const searchType = getSearchType(searchObj)
  if (searchType !== null) {
    arr.push(PRM.searchType)
    arr.push(searchType)
  }

  if (searchObj.placeId !== null) {
    arr.push(PRM.placeId)
    arr.push(searchObj.placeId)
  }

  if (searchObj.stateCode !== null) {
    arr.push(PRM.stateCode)
    arr.push(searchObj.stateCode)
  }

  if (searchObj.longitude !== null) {
    arr.push(PRM.longitude)
    arr.push(searchObj.longitude)
  }

  if (searchObj.latitude !== null) {
    arr.push(PRM.latitude)
    arr.push(searchObj.latitude)
  }

  if (searchObj.contractCode !== null) {
    arr.push(PRM.contractCode)
    arr.push(searchObj.contractCode)
  }

  if (searchObj.facilityId !== null) {
    arr.push(PRM.facilityId)
    arr.push(searchObj.facilityId)
  }

  return composeParamObj(isURL, arr)
}

export function getMainSearchApiObj(query = null) {
  return composeParamObj(
    false,
    addAllFromQuery(query, [
      PRM.searchType,
      PRM.stateCode,
      PRM.longitude,
      PRM.latitude,
      PRM.contractCode,
      PRM.facilityId,
      PRM.facilityId,
    ]),
  )
}

export function getMainSearchSuggestionsApiObj(query = null) {
  return composeParamObj(false, addAllFromQuery(query, [PRM.searchTerm]))
}

export function getMainSearchObjFromQuery(queryObj) {
  const mainSearchObj = {}

  if (has(queryObj, PRM.searchTerm.url)) {
    mainSearchObj[PRM.searchTerm.url] = decodeURIComponent(
      queryObj[PRM.searchTerm.url],
    )
  }
  if (has(queryObj, PRM.searchType.url)) {
    mainSearchObj[PRM.searchType.url] = queryObj[PRM.searchType.url]
  }
  if (has(queryObj, PRM.placeId.url)) {
    mainSearchObj[PRM.placeId.url] = queryObj[PRM.placeId.url]
  }
  if (has(queryObj, PRM.stateCode.url)) {
    mainSearchObj[PRM.stateCode.url] = queryObj[PRM.stateCode.url]
  }
  if (has(queryObj, PRM.longitude.url)) {
    mainSearchObj[PRM.longitude.url] = queryObj[PRM.longitude.url]
  }
  if (has(queryObj, PRM.latitude.url)) {
    mainSearchObj[PRM.latitude.url] = queryObj[PRM.latitude.url]
  }
  if (has(queryObj, PRM.contractCode.url)) {
    mainSearchObj[PRM.contractCode.url] = queryObj[PRM.contractCode.url]
  }
  if (has(queryObj, PRM.facilityId.url)) {
    mainSearchObj[PRM.facilityId.url] = queryObj[PRM.facilityId.url]
  }

  return mainSearchObj
}

/* Product Availability */
export function getProductAvailabilityObj({
  isURL = true,
  productAvailabilityStartDate,
  productAvailabilityLength = DEFAULT_PRODUCT_AVAILABILITY_LENGTH,
}) {
  if (productAvailabilityLength === DEFAULT_PRODUCT_AVAILABILITY_LENGTH) {
    return composeParamObj(isURL, [
      PRM.productAvailabilityStartDate,
      productAvailabilityStartDate,
    ])
  }

  return composeParamObj(isURL, [
    PRM.productAvailabilityStartDate,
    productAvailabilityStartDate,
    PRM.productAvailabilityLength,
    productAvailabilityLength,
  ])
}

export function getProductAvailabilityApiObj(query = null) {
  return composeParamObj(
    false,
    addAllFromQuery(query, [
      PRM.productAvailabilityStartDate,
      PRM.productAvailabilityLength,
    ]),
  )
}

/* Add to Cart */
export function getAddItemToCartObj(isURL = true, isAddToCart = true) {
  return composeParamObj(isURL, [PRM.addItemToCart, isAddToCart])
}

export function removeAddItemToCartObj(query) {
  if (has(query, PRM.addItemToCart.url)) {
    delete query[PRM.addItemToCart.url]
  }

  return query
}

export function getLatitudeObj(latitude) {
  return composeParamObj(true, [PRM.latitude, latitude])
}
export function getLongitudeObj(longitude) {
  return composeParamObj(true, [PRM.longitude, longitude])
}
export function getSearchTypeObj() {
  return composeParamObj(true, [PRM.searchType, 'nearby'])
}

/* Mixpanel */
export function getSignInAcctObj() {
  return composeParamObj(true, [PRM.signInAcct, 'false'])
}

export function getCreateAcctObj() {
  return composeParamObj(true, [PRM.createAcct, 'false'])
}

/* Marketing */
export function getAgencyObjFromURLQuery(query) {
  return {
    agency: getQueryValue(query, PRM.agency),
  }
}

/* Product Vehicle or Occupant Quantity */
export function getProductVehicleOccupantQuantityObj(
  isURL = true,
  quantity = 1,
) {
  return composeParamObj(isURL, [PRM.productVehicleOccupantQuantity, quantity])
}

export function getProductVehicleOccupantQuantityFromURLQuery(query) {
  let voq = 0
  const voqs = getQueryValue(query, PRM.productVehicleOccupantQuantity)
  if (voqs !== null) {
    voq = parseInt(voqs)
  }

  return voq
}

/* Product Selected Slots */
export function getProductSelectedSlotsFromURLQuery(query) {
  let pss = []
  const pssq = getQueryValue(query, PRM.productSelectedSlots)
  if (pssq !== null) {
    pss = pssq.split(',')
  }

  return pss
}

export function isProductAvailabilityApiObjMatch(query1, query2) {
  const obj1 = getProductAvailabilityApiObj(query1)
  const obj2 = getProductAvailabilityApiObj(query2)

  if (JSON.stringify(obj1) === JSON.stringify(obj2)) {
    return true
  }

  return false
}

export function getProductSelectedDatesApiObj(query = null) {
  return composeParamObj(
    false,
    addAllFromQuery(query, [PRM.arrivalDate, PRM.lengthOfStay]),
  )
}

export function getPrimaryItemIDApiObj(query = null) {
  return composeParamObj(
    false,
    addAllFromQuery(query, [PRM.primaryItemId, PRM.primaryResvNo]),
  )
}

export function isProductSelectedDatesApiObjMatch(query1, query2) {
  const obj1 = getProductSelectedDatesApiObj(query1)
  const obj2 = getProductSelectedDatesApiObj(query2)

  if (JSON.stringify(obj1) === JSON.stringify(obj2)) {
    return true
  }

  return false
}

/* Product Search */
export function getProductSearchObj({
  isURL = true,
  arrivalDate = null,
  departureDate = null,
  lengthOfStay = null,
  siteTypeId = null,
  lookingFor = null,
  maxPeople = null,
  driveEntry = null,
  waterfront = null,
  waterHookup = null,
  sewerHookup = null,
  accessible = null,
  vehiclelength = null,
  electric = null,
  pets = null,
  loopId = null,
  flexibleDates = null,
  interest = null,
  facilityType = null,
}) {
  const arr = []
  if (arrivalDate !== null) {
    arr.push(PRM.arrivalDate)
    arr.push(arrivalDate)
  }
  if (departureDate !== null) {
    arr.push(PRM.departureDate)
    arr.push(departureDate)
  }
  if (lengthOfStay !== null) {
    arr.push(PRM.lengthOfStay)
    arr.push(lengthOfStay)
  }
  if (siteTypeId !== null) {
    arr.push(PRM.siteTypeId)
    arr.push(siteTypeId)
  }

  if (lookingFor !== null) {
    arr.push(PRM.lookingFor)
    arr.push(lookingFor)
  }
  if (maxPeople !== null) {
    arr.push(PRM.maxPeople)
    arr.push(maxPeople)
  }
  if (driveEntry !== null) {
    arr.push(PRM.driveEntry)
    arr.push(driveEntry)
  }
  if (waterfront !== null) {
    arr.push(PRM.waterfront)
    arr.push(waterfront)
  }
  if (waterHookup !== null) {
    arr.push(PRM.waterHookup)
    arr.push(waterHookup)
  }
  if (sewerHookup !== null) {
    arr.push(PRM.sewerHookup)
    arr.push(sewerHookup)
  }
  if (accessible !== null) {
    arr.push(PRM.accessible)
    arr.push(accessible)
  }
  if (vehiclelength !== null) {
    arr.push(PRM.vehiclelength)
    arr.push(vehiclelength)
  }
  if (electric !== null) {
    arr.push(PRM.electric)
    arr.push(electric)
  }
  if (pets !== null) {
    arr.push(PRM.pets)
    arr.push(pets)
  }
  if (loopId !== null) {
    arr.push(PRM.loopId)
    arr.push(loopId)
  }
  if (flexibleDates !== null) {
    arr.push(PRM.flexibleDates)
    arr.push(flexibleDates)
  }
  if (interest !== null) {
    arr.push(PRM.interest)
    arr.push(interest)
  }
  if (facilityType !== null) {
    arr.push(PRM.facilityType)
    arr.push(facilityType)
  }

  return composeParamObj(isURL, arr)
}

export function getProductSearchApiObj(query = null) {
  const arr = [
    PRM.arrivalDate,
    PRM.departureDate,
    PRM.lengthOfStay,
    PRM.siteTypeId,
    PRM.lookingFor,
    PRM.maxPeople,
    PRM.driveEntry,
    PRM.waterfront,
    PRM.waterHookup,
    PRM.sewerHookup,
    PRM.accessible,
    PRM.vehiclelength,
    PRM.electric,
    PRM.pets,
    PRM.loopId,
    PRM.flexibleDates,
    PRM.interest,
    PRM.facilityType,
  ]

  return composeParamObj(false, addAllFromQuery(query, arr))
}

export function getProductSearchObjFromQuery(query = null) {
  const arr = [
    PRM.arrivalDate,
    PRM.departureDate,
    PRM.lengthOfStay,
    PRM.siteTypeId,
    PRM.lookingFor,
    PRM.maxPeople,
    PRM.driveEntry,
    PRM.waterfront,
    PRM.waterHookup,
    PRM.sewerHookup,
    PRM.accessible,
    PRM.vehiclelength,
    PRM.electric,
    PRM.pets,
    PRM.loopId,
    PRM.flexibleDates,
    PRM.interest,
    PRM.facilityType,
  ]

  return composeParamObj(true, addAllFromQuery(query, arr))
}

export function getProductSearchObjFromQueryUtil(queryObj, isDayUse = false) {
  let departureDate = null
  if (
    has(queryObj, PRM.arrivalDate.url) &&
    has(queryObj, PRM.lengthOfStay.url)
  ) {
    if (queryObj[PRM.lengthOfStay.url] > 0) {
      departureDate = getDepartureDate(
        queryObj[PRM.arrivalDate.url],
        queryObj[PRM.lengthOfStay.url],
        isDayUse,
      )
    }
  }

  return getProductSearchObj({
    arrivalDate: queryObj[PRM.arrivalDate.url],
    departureDate,
    lengthOfStay: queryObj[PRM.lengthOfStay.url],
  })
}

export function updateProductSearchObj(
  productSearchObj,
  arrivalDate,
  lengthOfStay,
) {
  const clonedProductSearchObj = clone(productSearchObj)
  clonedProductSearchObj[PRM.arrivalDate.url] = arrivalDate
  clonedProductSearchObj[PRM.lengthOfStay.url] = lengthOfStay

  return clonedProductSearchObj
}

export function getProductSearchObjFromFilter(
  isURL = true,
  filterObj,
  lengthOfStay = -1,
) {
  const arr = []

  if (has(filterObj, PRM.arrivalDate.filter)) {
    arr.push(PRM.arrivalDate)
    arr.push(filterObj[PRM.arrivalDate.filter])
  }

  if (has(filterObj, PRM.arrivalDate.dynamicFilter)) {
    arr.push(PRM.arrivalDate)
    arr.push(filterObj[PRM.arrivalDate.dynamicFilter])
  }

  if (has(filterObj, PRM.lengthOfStay.dynamicFilter)) {
    arr.push(PRM.lengthOfStay)
    arr.push(filterObj[PRM.lengthOfStay.dynamicFilter])
  } else if (lengthOfStay !== -1 && lengthOfStay !== 0) {
    arr.push(PRM.lengthOfStay)
    arr.push(lengthOfStay)
  }

  if (has(filterObj, PRM.lookingFor.filter)) {
    arr.push(PRM.lookingFor)
    arr.push(filterObj[PRM.lookingFor.filter])
  }
  if (has(filterObj, PRM.maxPeople.filter)) {
    arr.push(PRM.maxPeople)
    arr.push(filterObj[PRM.maxPeople.filter])
  }
  if (has(filterObj, PRM.driveEntry.filter)) {
    arr.push(PRM.driveEntry)
    arr.push(filterObj[PRM.driveEntry.filter])
  }
  if (has(filterObj, PRM.waterfront.filter)) {
    arr.push(PRM.waterfront)
    arr.push(filterObj[PRM.waterfront.filter])
  }
  if (has(filterObj, PRM.waterHookup.filter)) {
    arr.push(PRM.waterHookup)
    arr.push(filterObj[PRM.waterHookup.filter])
  }
  if (has(filterObj, PRM.sewerHookup.filter)) {
    arr.push(PRM.sewerHookup)
    arr.push(filterObj[PRM.sewerHookup.filter])
  }
  if (has(filterObj, PRM.accessible.filter)) {
    arr.push(PRM.accessible)
    arr.push(filterObj[PRM.accessible.filter])
  }
  if (has(filterObj, PRM.vehiclelength.filter)) {
    arr.push(PRM.vehiclelength)
    arr.push(filterObj[PRM.vehiclelength.filter])
  }
  if (has(filterObj, PRM.electric.filter)) {
    arr.push(PRM.electric)
    arr.push(filterObj[PRM.electric.filter])
  }
  if (has(filterObj, PRM.pets.filter)) {
    arr.push(PRM.pets)
    arr.push(filterObj[PRM.pets.filter])
  }
  if (has(filterObj, PRM.loopId.filter)) {
    arr.push(PRM.loopId)
    arr.push(filterObj[PRM.loopId.filter])
  }
  if (has(filterObj, PRM.flexibleDates.filter)) {
    arr.push(PRM.flexibleDates)
    arr.push(filterObj[PRM.flexibleDates.filter])
  }
  if (has(filterObj, PRM.interest.filter)) {
    arr.push(PRM.interest)
    arr.push(filterObj[PRM.interest.filter])
  }
  if (has(filterObj, PRM.facilityType.filter)) {
    arr.push(PRM.facilityType)
    arr.push(filterObj[PRM.facilityType.filter])
  }

  return composeParamObj(isURL, arr)
}

export function getFilterObjFromQuery(queryObj, dateOfDeparture = null) {
  const filterObj = {}

  if (has(queryObj, PRM.arrivalDate.url)) {
    filterObj[PRM.arrivalDate.filter] = queryObj[PRM.arrivalDate.url]
  }
  if (dateOfDeparture !== null) {
    filterObj[PRM.departureDate.filter] = dateOfDeparture
  }
  if (has(filterObj, PRM.arrivalDate.dynamicFilter)) {
    filterObj[PRM.arrivalDate.fildynamicFilterter] =
      queryObj[PRM.arrivalDate.dynamicFilter]
  }
  if (has(queryObj, PRM.lookingFor.url)) {
    filterObj[PRM.lookingFor.filter] = queryObj[PRM.lookingFor.url]
  }
  if (has(queryObj, PRM.maxPeople.url)) {
    filterObj[PRM.maxPeople.filter] = queryObj[PRM.maxPeople.url]
  }
  if (has(queryObj, PRM.driveEntry.url)) {
    filterObj[PRM.driveEntry.filter] = queryObj[PRM.driveEntry.url]
  }
  if (has(queryObj, PRM.waterfront.url)) {
    filterObj[PRM.waterfront.filter] = queryObj[PRM.waterfront.url]
  }
  if (has(queryObj, PRM.waterHookup.url)) {
    filterObj[PRM.waterHookup.filter] = queryObj[PRM.waterHookup.url]
  }
  if (has(queryObj, PRM.sewerHookup.url)) {
    filterObj[PRM.sewerHookup.filter] = queryObj[PRM.sewerHookup.url]
  }
  if (has(queryObj, PRM.accessible.url)) {
    filterObj[PRM.accessible.filter] = queryObj[PRM.accessible.url]
  }
  if (has(queryObj, PRM.vehiclelength.url)) {
    filterObj[PRM.vehiclelength.filter] = queryObj[PRM.vehiclelength.url]
  }
  if (has(queryObj, PRM.electric.url)) {
    filterObj[PRM.electric.filter] = queryObj[PRM.electric.url]
  }
  if (has(queryObj, PRM.pets.url)) {
    filterObj[PRM.pets.filter] = queryObj[PRM.pets.url]
  }
  if (has(queryObj, PRM.loopId.url)) {
    filterObj[PRM.loopId.filter] = queryObj[PRM.loopId.url]
  }
  if (has(queryObj, PRM.flexibleDates.url)) {
    filterObj[PRM.flexibleDates.filter] = queryObj[PRM.flexibleDates.url]
  }
  if (has(queryObj, PRM.interest.url)) {
    filterObj[PRM.interest.filter] = queryObj[PRM.interest.url]
  }
  if (has(queryObj, PRM.facilityType.url)) {
    filterObj[PRM.facilityType.filter] = queryObj[PRM.facilityType.url]
  }

  return filterObj
}

export function isArrivalDateSet(location) {
  let isSet = false
  const arrLosObj = getArrivalAndLOSFromQuery(location.query)
  if (!isEmpty(arrLosObj) && arrLosObj[PRM.lengthOfStay.url] > 0) {
    isSet = true
  }

  return isSet
}

export function getNextAvailableDateObj({
  isURL = true,
  nextAvailableDate = null,
}) {
  const arr = []
  if (nextAvailableDate !== null) {
    arr.push(PRM.nextAvailableDate)
    arr.push(nextAvailableDate)
  }

  return composeParamObj(isURL, arr)
}

export function getNextAvailableDateApiObj(query = null) {
  return composeParamObj(false, addAllFromQuery(query, [PRM.nextAvailableDate]))
}

export function getNextAvailableDateObjFromQuery(queryObj) {
  const nextAvailableDateObj = {}

  if (has(queryObj, PRM.nextAvailableDate.url)) {
    nextAvailableDateObj[PRM.nextAvailableDate.url] =
      queryObj[PRM.nextAvailableDate.url]
  }

  return nextAvailableDateObj
}

export function isNextAvailableDateApiObjMatch(query1, query2) {
  const obj1 = getNextAvailableDateApiObj(query1)
  const obj2 = getNextAvailableDateApiObj(query2)

  if (JSON.stringify(obj1) === JSON.stringify(obj2)) {
    return true
  }

  return false
}

export function getNextAvailableDateOptionObj(isAdd) {
  const option = isAdd === true ? 'true' : 'false'
  const nextAvailableDateObj = getNextAvailableDateObj({
    nextAvailableDate: option,
  })

  return nextAvailableDateObj
}

export function getNextAvailableDateOptionObjIfExistsInQuery(
  isAdd,
  query = null,
) {
  if (query === null) {
    return getNextAvailableDateOptionObj(isAdd)
  }

  const falseOption = isEmpty(getNextAvailableDateObjFromQuery(query))
    ? null
    : 'false'
  const option = isAdd === true ? 'true' : falseOption
  const nextAvailableDateObj = getNextAvailableDateObj({
    nextAvailableDate: option,
  })

  return nextAvailableDateObj
}

export function isFindingNextAvailableDateMode(location) {
  let isFinding = false
  const nextAvailableDateObj = getNextAvailableDateObjFromQuery(location.query)
  if (
    !isEmpty(nextAvailableDateObj) &&
    nextAvailableDateObj[PRM.nextAvailableDate.url] === 'true'
  ) {
    isFinding = true
  }

  return isFinding
}

export function getArrivalAndLOSFromQuery(queryObj) {
  const filterObj = {}

  if (has(queryObj, PRM.arrivalDate.url)) {
    filterObj[PRM.arrivalDate.url] = queryObj[PRM.arrivalDate.url]
  }
  if (has(queryObj, PRM.lengthOfStay.url)) {
    filterObj[PRM.lengthOfStay.url] = queryObj[PRM.lengthOfStay.url]
  }

  return filterObj
}

export function getArrivalDateFromQuery(location) {
  const arrLosObj = getArrivalAndLOSFromQuery(location.query)
  if (!isEmpty(arrLosObj) && arrLosObj[PRM.lengthOfStay.url] > 0) {
    return arrLosObj[PRM.arrivalDate.url]
  }

  return null
}

export function getDepartureDateFromQuery(location) {
  const arrLosObj = getArrivalAndLOSFromQuery(location.query)
  if (
    !isEmpty(arrLosObj) &&
    !isEmpty(arrLosObj[PRM.arrivalDate.url]) &&
    arrLosObj[PRM.lengthOfStay.url] > 0
  ) {
    return getDepartureDate(
      arrLosObj[PRM.arrivalDate.url],
      arrLosObj[PRM.lengthOfStay.url],
    )
  }

  return null
}

export function getMaxPeopleFromQuery(location) {
  if (has(location.query, PRM.maxPeople.url)) {
    return location.query[PRM.maxPeople.url]
  }

  return null
}

/* Product Booking Details */
export function getProductBookingDetailsObj({
  isURL = true,
  arrivalDate = null,
  departureDate = null,
  lengthOfStay = null,
  cartItemId = null,
  cartId = null,
  reservationId = null,
  reservationType = null,
  productVehicleOccupantQuantity = null,
  productSelectedSlots = null,
}) {
  const arr = []
  if (arrivalDate !== null) {
    arr.push(PRM.arrivalDate)
    arr.push(arrivalDate)
  }
  if (departureDate !== null) {
    arr.push(PRM.departureDate)
    arr.push(departureDate)
  }
  if (lengthOfStay !== null) {
    arr.push(PRM.lengthOfStay)
    arr.push(lengthOfStay)
  }
  if (cartItemId !== null) {
    arr.push(PRM.cartItemId)
    arr.push(cartItemId)
  }
  if (cartId !== null) {
    arr.push(PRM.cartId)
    arr.push(cartId)
  }
  if (reservationId !== null) {
    arr.push(PRM.reservationId)
    arr.push(reservationId)
  }

  if (reservationType !== null) {
    arr.push(PRM.reservationType)
    arr.push(reservationType)
  }

  if (productVehicleOccupantQuantity !== null) {
    arr.push(PRM.productVehicleOccupantQuantity)
    arr.push(productVehicleOccupantQuantity)
  }

  if (productSelectedSlots !== null) {
    arr.push(PRM.productSelectedSlots)
    arr.push(productSelectedSlots.join(','))
  }

  return composeParamObj(isURL, arr)
}

export function getProductBookingDetailsApiObj(query = null) {
  return composeParamObj(
    false,
    addAllFromQuery(query, [
      PRM.arrivalDate,
      PRM.departureDate,
      PRM.lengthOfStay,
      PRM.cartItemId,
      PRM.reservationId,
      PRM.reservationType,
    ]),
  )
}

export function getProductCartObj({ isURL = true, cartItemId = null }) {
  const arr = []
  if (cartItemId !== null) {
    arr.push(PRM.cartItemId)
    arr.push(cartItemId)
  }

  return composeParamObj(isURL, arr)
}

export function getPrimaryItemCartObj(primaryItemId) {
  return composeParamObj(true, [PRM.primaryItemId, primaryItemId])
}

export function getPrimaryItemCartObjFromQuery(query) {
  return composeParamObj(
    true,
    addAllFromQuery(query, [PRM.primaryItemId, PRM.primaryResvNo]),
  )
}

export function getPrimaryResvNoObj(reservationNumber) {
  return composeParamObj(true, [PRM.primaryResvNo, reservationNumber])
}

export function getProductCartApiObj(query = null) {
  return composeParamObj(false, addAllFromQuery(query, [PRM.cartItemId]))
}

export function getProductReservationApiObj(query = null) {
  return composeParamObj(false, addAllFromQuery(query, [PRM.reservationId]))
}

export function getDisplayGISMapObj(displayGISMap = false) {
  return composeParamObj(true, [PRM.displayGISMap, displayGISMap])
}

export function getConfirmationObj({ isURL = true, processId = null }) {
  const arr = []
  if (processId !== null) {
    arr.push(PRM.processId)
    arr.push(processId)
  }

  return composeParamObj(isURL, arr)
}

export function getProcessIdFromURLQuery(query) {
  return getQueryValue(query, PRM.processId)
}

export function getModeObj({ isURL = true, mode = MODE_NEW }) {
  const arr = []
  arr.push(PRM.mode)
  arr.push(mode)

  return composeParamObj(isURL, arr)
}

export const getLOBObj = function (isURL = true, lob = '') {
  return composeParamObj(isURL, [PRM.lob, lob])
}

/* ViewState */
export function getViewStateObj({
  isURL = true,
  viewState = null,
  subViewState = null,
}) {
  const arr = []
  if (viewState !== null) {
    arr.push(PRM.viewState)
    arr.push(viewState)
  }
  if (subViewState !== null) {
    arr.push(PRM.subViewState)
    arr.push(subViewState)
  }

  return composeParamObj(isURL, arr)
}

/* Favorites */
export function getAddToFavoritesObj({
  isURL = true,
  contractCode = null,
  facilityId = null,
}) {
  const arr = []
  if (contractCode !== null) {
    arr.push(PRM.addFaveContractCode)
    arr.push(contractCode)
  }
  if (facilityId !== null) {
    arr.push(PRM.addFaveFacilityId)
    arr.push(facilityId)
  }

  return composeParamObj(isURL, arr)
}

export function getAddToFavoritesObjFromURLQuery(query) {
  return {
    contractCode: getQueryValue(query, PRM.addFaveContractCode),
    facilityId: getQueryValue(query, PRM.addFaveFacilityId),
  }
}

/* My Account */
export const getCurrentReservationsObjFromQuery = function (queryObj) {
  const currentReservationsObj = {}

  if (!isUndefined(queryObj) && has(queryObj, PRM.currentReservations.url)) {
    currentReservationsObj[PRM.currentReservations.url] =
      queryObj[PRM.currentReservations.url]
  }

  return currentReservationsObj
}

export const isCurrentReservationsMode = function (query) {
  let isCurrentMode = true
  const currentReservationsObj = getCurrentReservationsObjFromQuery(query)
  if (
    !isEmpty(currentReservationsObj) &&
    currentReservationsObj[PRM.currentReservations.url] === 'false'
  ) {
    isCurrentMode = false
  }

  return isCurrentMode
}

export const getCurrentReservationsObj = function ({
  isURL = true,
  isCurrentReservations = null,
}) {
  const arr = []
  arr.push(PRM.currentReservations)
  arr.push(isCurrentReservations)

  return composeParamObj(isURL, arr)
}

export const getCurrentReservationsApiObj = function (query = null) {
  return composeParamObj(
    false,
    addAllFromQuery(query, [
      PRM.currentReservations,
      PRM.sortType,
      PRM.sortDir,
    ]),
  )
}

export const getReservationObj = function ({
  isURL = true,
  reservationId = null,
  reservationType = null,
  mainContractCode = null,
}) {
  const arr = []
  arr.push(PRM.reservationId)
  arr.push(reservationId)

  arr.push(PRM.reservationType)
  arr.push(reservationType)

  arr.push(PRM.mainContractCode)
  arr.push(mainContractCode)

  return composeParamObj(isURL, arr)
}

export const getReservationApiObj = function (query = null) {
  return composeParamObj(
    false,
    addAllFromQuery(query, [PRM.reservationType, PRM.mainContractCode]),
  )
}

export const getReservationIdFromQuery = function (queryObj) {
  let reservationId = null

  if (!isUndefined(queryObj) && has(queryObj, PRM.reservationId.url)) {
    reservationId = queryObj[PRM.reservationId.url]
  }

  return reservationId
}

export const getReservationTypeFromQuery = function (queryObj) {
  let reservationType = null

  if (!isUndefined(queryObj) && has(queryObj, PRM.reservationType.url)) {
    reservationType = queryObj[PRM.reservationType.url]
  }

  return reservationType
}

export const getReservationObjFromQuery = function (query = null) {
  const arr = [PRM.reservationId, PRM.reservationType, PRM.mainContractCode]

  return composeParamObj(true, addAllFromQuery(query, arr))
}

export const getRateReservationObj = function ({
  isURL = true,
  reservationId = null,
  mainContractCode = null,
  reservationType = null,
}) {
  const arr = []
  arr.push(PRM.reservationId)
  arr.push(reservationId)

  arr.push(PRM.mainContractCode)
  arr.push(mainContractCode)

  arr.push(PRM.reservationType)
  arr.push(reservationType)

  return composeParamObj(isURL, arr)
}

export const getRateReservationApiObj = function (query = null) {
  return composeParamObj(
    false,
    addAllFromQuery(query, [
      PRM.reservationId,
      PRM.mainContractCode,
      PRM.reservationType,
    ]),
  )
}

export const getPointsObj = function ({
  isURL = true,
  passId = null,
  mainContractCode = null,
}) {
  const arr = []
  arr.push(PRM.passId)
  arr.push(passId)

  arr.push(PRM.mainContractCode)
  arr.push(mainContractCode)

  return composeParamObj(isURL, arr)
}

export const getPointsApiObj = function (query = null) {
  return composeParamObj(false, addAllFromQuery(query, [PRM.mainContractCode]))
}

export const getFeedbackObj = function ({
  isURL = true,
  feedbackGroup = null,
}) {
  const arr = []
  arr.push(PRM.feedbackGroup)
  arr.push(feedbackGroup)

  return composeParamObj(isURL, arr)
}

export const getPassIdFromQuery = function (queryObj) {
  let passId = null

  if (!isUndefined(queryObj) && has(queryObj, PRM.passId.url)) {
    passId = queryObj[PRM.passId.url]
  }

  return passId
}

export const getCurrentPassesObj = function ({
  isURL = true,
  isCurrentPasses = null,
}) {
  const arr = []
  arr.push(PRM.currentPasses)
  arr.push(isCurrentPasses)

  return composeParamObj(isURL, arr)
}

export const getCurrentPassesObjFromQuery = function (queryObj) {
  const currentPassesObj = {}

  if (!isUndefined(queryObj) && has(queryObj, PRM.currentPasses.url)) {
    currentPassesObj[PRM.currentPasses.url] = queryObj[PRM.currentPasses.url]
  }

  return currentPassesObj
}

export const getCurrentPassesApiObj = function (query = null) {
  return composeParamObj(false, addAllFromQuery(query, [PRM.currentPasses]))
}

export const isCurrentPassesMode = function (query) {
  let isCurrentMode = true
  const currentPassesObj = getCurrentPassesObjFromQuery(query)
  if (
    !isEmpty(currentPassesObj) &&
    currentPassesObj[PRM.currentPasses.url] === 'false'
  ) {
    isCurrentMode = false
  }

  return isCurrentMode
}

export const getEquipmentObj = function ({
  isURL = true,
  equipmentId = null,
  mainContractCode = null,
}) {
  const arr = []
  arr.push(PRM.equipmentId)
  arr.push(equipmentId)

  arr.push(PRM.mainContractCode)
  arr.push(mainContractCode)

  return composeParamObj(isURL, arr)
}

export const getEquipmentIdFromQuery = function (queryObj) {
  let equipmentId = null

  if (!isUndefined(queryObj) && has(queryObj, PRM.equipmentId.url)) {
    equipmentId = queryObj[PRM.equipmentId.url]
  }

  return equipmentId
}

export const getMainContractCodeFromQuery = function (queryObj) {
  let mainContractCode = null

  if (!isUndefined(queryObj) && has(queryObj, PRM.mainContractCode.url)) {
    mainContractCode = queryObj[PRM.mainContractCode.url]
  }

  return mainContractCode
}

export const getEquipmentApiObj = function (query = null) {
  return composeParamObj(false, addAllFromQuery(query, [PRM.equipmentId]))
}

export const getExtReservationObj = function ({
  reservationId = null,
  mainContractCode = null,
  facilityId = null,
}) {
  const arr = []
  arr.push(PRM.reservationId)
  arr.push(reservationId)

  arr.push(PRM.mainContractCode)
  arr.push(mainContractCode)

  arr.push(PRM.facilityId)
  arr.push(facilityId)

  return composeExtParamObj(arr)
}

export const getExtMarinaObj = function ({ marina = null }) {
  const arr = []
  arr.push(PRM.marina)
  arr.push(marina)

  return composeExtParamObj(arr)
}

export const getExtTourObj = function ({
  tourId = null,
  tourInventoryId = null,
  category = null,
}) {
  const arr = []
  arr.push(PRM.tourId)
  arr.push(tourId)

  arr.push(PRM.tourInventoryId)
  arr.push(tourInventoryId)

  arr.push(PRM.category)
  arr.push(category)

  return composeExtParamObj(arr)
}

export const getDynamicUpdateObj = function ({
  isURL = true,
  dynamicUpdate = null,
}) {
  return composeParamObj(isURL, [PRM.dynamicUpdate, dynamicUpdate])
}

export const getDynamicUpdateObjFromQuery = function (query = null) {
  return composeParamObj(false, addAllFromQuery(query, [PRM.dynamicUpdate]))
}

export const isDynamicUpdate = function (query = null) {
  let dynamicUpdate = false
  const dynamicUpdateObj = getDynamicUpdateObjFromQuery(query)
  if (
    !isEmpty(dynamicUpdateObj) &&
    dynamicUpdateObj[PRM.dynamicUpdate.url] === 'true'
  ) {
    dynamicUpdate = true
  }

  return dynamicUpdate
}

export const getResetObj = function ({ isURL = true, reset = null }) {
  const arr = []
  arr.push(PRM.reset)
  arr.push(reset)

  return composeParamObj(isURL, arr)
}

export const getResetApiObj = function (query = null) {
  return composeParamObj(false, addAllFromQuery(query, [PRM.reset]))
}

/* POS */
export const getPOSApiObj = function (query = null) {
  return composeParamObj(false, addAllFromQuery(query, [PRM.bundle]))
}

export const getPOSObj = function ({
  isURL = true,
  bundle = null,
  mainContractCode = null,
}) {
  const arr = []
  arr.push(PRM.bundle)
  arr.push(bundle)

  arr.push(PRM.mainContractCode)
  arr.push(mainContractCode)

  return composeParamObj(isURL, arr)
}

export function getQueryStringFromObj(obj) {
  let ctr = 0
  let qs = ''
  for (const key in obj) {
    if (has(obj, key)) {
      if (!isEmpty(obj[key]) || isNumber(obj[key])) {
        if (ctr === 0) {
          qs += '?'
        } else {
          qs += '&'
        }
        qs += `${key}=${obj[key]}`
        ctr += 1
      }
    }
  }

  return qs
}

export function addObjToQueryString(obj, qs = '') {
  for (const key in obj) {
    if (has(obj, key)) {
      if (!isEmpty(obj[key]) || isNumber(obj[key])) {
        if (qs === '') {
          qs += '?'
        } else {
          qs += '&'
        }
        qs += `${key}=${obj[key]}`
      }
    }
  }

  return qs
}

function removeParamFromQueryString(removeParams, qs) {
  const deleteRegex = new RegExp(`${removeParams.join('=|')}=`)

  const params = qs.split('&')
  const search = []
  for (let i = 0; i < params.length; i++) {
    if (deleteRegex.test(params[i]) === false) {
      search.push(params[i])
    }
  }

  return (search.length ? search.join('&') : '') + location.hash
}

export function removeSignInTrackingParams(qs) {
  const newQuery = removeParamFromQueryString(
    [PRM.signInAcct.url, PRM.createAcct.url],
    qs,
  )

  return newQuery
}

export function mergeQuery(currQuery, newQuery) {
  for (const key in currQuery) {
    if (!has(newQuery, key) && has(currQuery, key)) {
      newQuery[key] = currQuery[key]
    }
  }
  for (const key1 in newQuery) {
    if (has(newQuery, key1)) {
      if (newQuery[key1] === '') {
        delete newQuery[key1]
      }
    }
  }

  return newQuery
}

export function mergeQueries(queryArr, newQuery, clean = true) {
  if (!isEmpty(queryArr)) {
    for (let i = 0; i < queryArr.length; i++) {
      mergeQuery(queryArr[i], newQuery, clean)
    }
  }

  return newQuery
}

export function getQueryValue(query, keyObj, isURL = true) {
  let value = null
  let key = keyObj.url
  if (!isURL) {
    key = keyObj.api
  }
  if (has(query, key)) {
    value = query[key]
  }

  return value
}

export function getLengthOfStayFromURLQuery(query) {
  let los = 0
  const loss = getQueryValue(query, PRM.lengthOfStay)
  if (loss !== null) {
    los = parseInt(loss)
  }

  return los
}

export function getArrivalDateFromURLQuery(query) {
  return getQueryValue(query, PRM.arrivalDate)
}

export function getProductAvailabilityStartDateFromURLQuery(query) {
  return getQueryValue(query, PRM.productAvailabilityStartDate)
}

export function getCartItemIdFromURLQuery(query) {
  return getQueryValue(query, PRM.cartItemId)
}

export function getPrimaryItemIdFromURLQuery(query) {
  return getQueryValue(query, PRM.primaryItemId)
}
export function getPrimaryResvNoFromURLQuery(query) {
  return getQueryValue(query, PRM.primaryResvNo)
}

export function getCartIdFromURLQuery(query) {
  return getQueryValue(query, PRM.cartId)
}

export function getModeFromURLQuery(query) {
  return getQueryValue(query, PRM.mode)
}

export function getViewStateFromURLQuery(query) {
  return getQueryValue(query, PRM.viewState)
}

export function getSubViewStateFromURLQuery(query) {
  return getQueryValue(query, PRM.subViewState)
}

export const getChangeDatesQuery = function (
  productBookingOptions,
  currentCart,
) {
  const cartOrder = productBookingOptions.cartOrder
  const webOrder = cartOrder.webOrder
  const cartItemId = cartOrder.cartItemId
  const los = webOrder.units
  const arrivalDate = webOrder.arrivalDate

  let query = {}
  if (!isEmpty(arrivalDate) && los > 0) {
    const prodBookingObj = getProductBookingDetailsObj({
      arrivalDate,
      lengthOfStay: los,
      cartItemId,
      cartId: getCartId(currentCart),
    })
    const prodAvailabilityObj = getProductAvailabilityObj({
      productAvailabilityStartDate: arrivalDate,
    })
    const modeObj = getModeObj({ mode: MODE_CHANGE })
    let primaryItemCartObj = null
    if (!isEmpty(productBookingOptions.childSiteRestriction)) {
      primaryItemCartObj = isEmpty(
        productBookingOptions.childSiteRestriction.primaryItemID,
      )
        ? getPrimaryResvNoObj(
            productBookingOptions.childSiteRestriction.primaryResNum,
          )
        : getPrimaryItemCartObj(
            productBookingOptions.childSiteRestriction.primaryItemID,
          )
    }
    query = mergeQueries(
      [prodAvailabilityObj, prodBookingObj, modeObj, primaryItemCartObj],
      query,
    )
  }

  return query
}

/* Temp facility details only */
export function getTempFacilityDetailsOnlyObj(isURL = true, value = 'true') {
  return composeParamObj(isURL, [PRM.tempFacilityDetailsOnly, value])
}

export function normalizeQueryObj(obj) {
  for (const key in obj) {
    if (has(obj, key)) {
      if (obj[key] === true) {
        obj[key] = 'true'
      } else if (obj[key] === false) {
        obj[key] = 'false'
      }
    }
  }

  return obj
}

function getQueryItem(
  queryObj,
  nvObj,
  bypassNames = [],
  PRMItem,
  isObjURL,
  isTargetURL,
) {
  let PRMName = PRMItem.api
  if (isObjURL) {
    PRMName = PRMItem.url
  }
  for (const nvItem of nvObj) {
    if (nvItem.name === PRMName || `pa${nvItem.name}` === PRMName) {
      if (bypassNames.indexOf(nvItem.name) === -1) {
        let targetName = PRMItem.api
        if (isTargetURL) {
          targetName = PRMItem.url
        }
        queryObj[targetName] = nvItem.value
        break
      }
    }
  }
}

export function createQueryObjFromNameValue(
  nvObj,
  bypassNames = [],
  isObjURL = false,
  isTargetURL = true,
) {
  let queryObj = null
  queryObj = {}
  for (const key in PRM) {
    if (has(PRM, key)) {
      const currPRM = PRM[key]
      getQueryItem(queryObj, nvObj, bypassNames, currPRM, isObjURL, isTargetURL)
    }
  }

  return queryObj
}

//* **************************DYNAMIC FILTER STUFF START**************************/
export const getPRMType = function (keyValue, sourceTypeKey, targetTypeKey) {
  let PRMType = null
  for (const key in PRM) {
    if (has(PRM, key)) {
      if (
        !isUndefined(PRM[key][sourceTypeKey]) &&
        PRM[key][sourceTypeKey] === keyValue
      ) {
        if (!isUndefined(PRM[key][targetTypeKey])) {
          PRMType = PRM[key]
        }

        break
      }
    }
  }

  return PRMType
}

export const hasPRMTypePageId = function (PRMType, pageId) {
  let hasPageId = false
  if (!isEmpty(PRMType) && !isEmpty(PRMType.pageIds)) {
    for (const prmPageId of PRMType.pageIds) {
      if (prmPageId === pageId) {
        hasPageId = true
        break
      }
    }
  }

  return hasPageId
}

export const getDynamicQueryObj = function (
  criteriaObj = {},
  pageId,
  sourceTypeKey = 'api',
  targetTypeKey = 'url',
) {
  const prmArray = []
  for (const key in criteriaObj) {
    if (has(criteriaObj, key)) {
      if (!isEmpty(criteriaObj[key])) {
        const PRMType = getPRMType(key, sourceTypeKey, targetTypeKey)
        // console.log('getDynamicQueryObj-->PRMType', PRMType)
        if (isEmpty(pageId) && !isEmpty(PRMType)) {
          prmArray.push(PRMType, criteriaObj[key])
        } else if (!isEmpty(PRMType) && hasPRMTypePageId(PRMType, pageId)) {
          prmArray.push(PRMType, criteriaObj[key])
        }
      }
    }
  }

  return composeParamObj(targetTypeKey === 'url', prmArray)
}

export const getDynamicQueryObjAPIToURL = function (
  criteriaObj = {},
  pageId = null,
) {
  return getDynamicQueryObj(criteriaObj, pageId)
}

export const getDynamicQueryObjDynamicFilterToURL = function (
  criteriaObj = {},
  pageId = null,
) {
  return getDynamicQueryObj(criteriaObj, pageId, 'dynamicFilter', 'url')
}

export const getDynamicQueryObjURLToDynamicFilter = function (
  criteriaObj = {},
  pageId = null,
) {
  return getDynamicQueryObj(criteriaObj, pageId, 'url', 'dynamicFilter')
}

export const getDynamicQueryObjURLToAPI = function (
  criteriaObj = {},
  pageId = null,
) {
  return getDynamicQueryObj(criteriaObj, pageId, 'url', 'api')
}

export const getDynamicQueryObjURLToURL = function (
  criteriaObj = {},
  pageId = null,
) {
  return getDynamicQueryObj(criteriaObj, pageId, 'url', 'url')
}

export const getDynamicMergedFilterObj = function (
  dynamicSearchFilter,
  savedSearchFilter,
) {
  let filterQueryObjURL = {}
  let filterQueryObj = {}

  filterQueryObjURL = getFilterObjFromQuery(
    merge(savedSearchFilter, dynamicSearchFilter),
  )
  filterQueryObj = getProductSearchObjFromFilter(true, filterQueryObjURL)

  return filterQueryObj
}

//* **************************DYNAMIC FILTER STUFF START**************************/

/* Verification Token */
export function getUserChallengeVerificationTokenObj(isURL = true, token = '') {
  return composeParamObj(isURL, [PRM.userChallengeVerificationToken, token])
}

export function getUserChallengeVerificationTokenFromURLQuery(query) {
  let userChallengeVerificationToken = getQueryValue(
    query,
    PRM.userChallengeVerificationToken,
  )
  if (isEmpty(userChallengeVerificationToken)) {
    userChallengeVerificationToken = ''
  }

  return userChallengeVerificationToken
}

function addAllFromQuery(query, objArr) {
  const arr = []
  if (!isEmpty(objArr)) {
    for (let i = 0; i < objArr.length; i++) {
      addFromQuery(query, objArr[i], arr)
    }
  }

  return arr
}

function addFromQuery(query, obj, arr) {
  if (has(query, obj.url)) {
    arr.push(obj)
    arr.push(query[obj.url])
  }

  return arr
}

export function composeParamObj(isURL, params) {
  const key = isURL === true ? 'url' : 'api'

  const obj = {}
  for (let i = 0; i < params.length; i += 2) {
    obj[params[i][key]] = params[i + 1]
  }

  return obj
}

function composeExtParamObj(params) {
  const obj = {}
  for (let i = 0; i < params.length; i += 2) {
    let key = params[i].url
    if (!isUndefined(params[i].ext)) {
      key = params[i].ext
    }
    obj[key] = params[i + 1]
  }

  return obj
}
