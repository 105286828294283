import { func, object, string } from 'prop-types'
import React, { Component } from 'react'

import SimpleAnimate, {
  CMP_TYPE_DIV,
} from '../../../external/components/animation/SimpleAnimate'
import ACol from '../../../external/components/layout/ACol'
import ARow from '../../../external/components/layout/ARow'
import Cont from '../../../external/components/layout/Cont'
import { isEmpty, isUndefined } from '../../../external/utilities/GeneralUtils'
import { createAndLogEvent } from '../../../utilities/Analytics'
import Advert from '../../shared/ad/Advert'
import ArticleCategory from './ArticleCategory'
import ArticleFeature from './ArticleFeature'
import ArticleHeader from './ArticleHeader'

class ArticlePanelCmp extends Component {
  constructor(props, context) {
    super(props, context)
    const { brandConfig } = this.props
    this.state = { selectedCategory: brandConfig.articles.categories[0] }
  }

  onCategorySelectRequest(selectedCategory) {
    this.setState({
      ...this.state,
      selectedCategory,
    })

    if (
      !isUndefined(selectedCategory.analytics) &&
      !isEmpty(selectedCategory.analytics)
    ) {
      createAndLogEvent(selectedCategory.analytics)
    }
  }

  render() {
    const {
      theme,
      intl,
      brandConfig,
      getArticleSelectLinkObj,
      onAllArticlesSelectRequest,
      adConfig,
      onArticleSelectRequest,
    } = this.props

    // TEST for React MS PL. To remove.
    if (theme === 'ms') {
      return null
    }

    return (
      <Cont className="hack-home-outdoor-guide" asContainer limitExtraLarge>
        <Cont className={'home-outdoor-guide__container'}>
          <ArticleHeader
            theme={theme}
            intl={intl}
            articles={brandConfig.articles}
            onCategorySelectRequest={this.onCategorySelectRequest.bind(this)}
            onAllArticlesSelectRequest={onAllArticlesSelectRequest}
            selectedCategory={this.state.selectedCategory}
          />
          <ARow>
            <ACol md={12} lg={8}>
              <SimpleAnimate
                transitionLeave={false}
                componentType={CMP_TYPE_DIV}
              >
                <ArticleCategory
                  key={this.state.selectedCategory.name}
                  theme={theme}
                  intl={intl}
                  articles={brandConfig.articles}
                  selectedCategory={this.state.selectedCategory}
                  getArticleSelectLinkObj={getArticleSelectLinkObj}
                  adConfig={adConfig}
                  showAds={
                    this.state.selectedCategory ===
                    brandConfig.articles.categories[0]
                  }
                  onArticleSelectRequest={onArticleSelectRequest}
                />
              </SimpleAnimate>
            </ACol>
            <ACol md={12} lg={4}>
              <Advert id={'HP5'} adConfig={adConfig} />
              <ArticleFeature
                theme={theme}
                intl={intl}
                featuredArticles={brandConfig.articles.featured}
                getArticleSelectLinkObj={getArticleSelectLinkObj}
                onArticleSelectRequest={onArticleSelectRequest}
              />
            </ACol>
          </ARow>
        </Cont>
      </Cont>
    )
  }
}

ArticlePanelCmp.propTypes = {
  theme: string.isRequired,
  intl: object.isRequired,
  brandConfig: object.isRequired,
  getArticleSelectLinkObj: func.isRequired,
  onAllArticlesSelectRequest: func.isRequired,
  adConfig: object.isRequired,
  onArticleSelectRequest: func.isRequired,
}

export default ArticlePanelCmp
