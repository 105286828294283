import { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { isEnvServer } from '../../../external/utilities/GeneralServerUtils'
import { isEmpty, isUndefined } from '../../../external/utilities/GeneralUtils'
import { getPageMetaForAdvert } from '../../../utilities/MetaDataUtils'
import './Advert.less'
import { getHouseAd, shouldUseHouseAd } from './HouseAdUtil'

const INIT_PROMISE_KEY = Symbol('ad-init-promise')

export const getAdObj = function (id, adConfig) {
  let adObj = null

  if (!isUndefined(adConfig) && !isEmpty(adConfig)) {
    for (const item of adConfig.slots) {
      if (item.id === id) {
        adObj = item
        break
      }
    }
  }

  return adObj
}

export const areAdsActive = function (adConfig) {
  let adsActive = false
  if (!isUndefined(adConfig) && !isEmpty(adConfig)) {
    if (adConfig.active) {
      adsActive = true
    }
  }

  return adsActive
}

export const isAdvert = function (id, adConfig) {
  const adObj = getAdObj(id, adConfig)

  return !isEmpty(adObj) && adObj.active && areAdsActive(adConfig)
}

class Advert extends Component {
  constructor(props) {
    super(props)
    this.initComplete = false
    this.adObj = null
    this.slot = null
  }

  componentDidMount() {
    this.initAdvert()
  }

  componentDidUpdate(prevProps) {
    const { id, pageMetaData } = this.props

    if (
      id !== this.props.id ||
      (pageMetaData && isEqual(pageMetaData, prevProps.pageMetaData))
    ) {
      this.destroyAdvert()
    } else {
      this.initAdvert()
    }
  }

  get freestar() {
    return !isEnvServer() && window.freestar
  }

  componentWillUnmount() {
    this.destroyAdvert()
  }

  destroyAdvert() {
    if (
      this.initComplete &&
      !isEmpty(this.adObj) &&
      !shouldUseHouseAd(this.adObj, this.props.pageMetaData)
    ) {
      this.freestar.deleteAdSlots(this.adObj.optDiv)
      this.initComplete = false
    }
  }

  async initAdvert() {
    const { id, adConfig, pageMetaData, parentInitComplete } = this.props
    const adObj = getAdObj(id, adConfig)
    const adsActive = areAdsActive(adConfig)

    if (
      this.initComplete ||
      shouldUseHouseAd(adObj, pageMetaData) ||
      !adsActive ||
      !adObj?.active ||
      !parentInitComplete
    )
      return

    const pageMetaForAdvert = getPageMetaForAdvert(pageMetaData)

    try {
      let initPromise = this.freestar[INIT_PROMISE_KEY]

      if (!this.freestar.newAdSlots && !initPromise) {
        initPromise = new Promise((res) => {
          this.freestar.initCallback = () => {
            delete this.freestar[INIT_PROMISE_KEY]
            res()
          }
        })

        this.freestar[INIT_PROMISE_KEY] = initPromise
      }

      if (initPromise) {
        await initPromise
      }

      this.initComplete = true

      this.freestar.newAdSlots([
        {
          placementName: adObj.optDiv,
          slotId: adObj.optDiv,
          targeting: pageMetaForAdvert,
        },
      ])
    } catch (err) {
      console.error(err)
    }
  }

  shouldComponentUpdate() {
    if (this.initComplete) {
      return false
    }

    return true
  }

  render() {
    const { id, adConfig, intl, pageMetaData } = this.props
    this.adObj = getAdObj(id, adConfig)

    if (!areAdsActive(adConfig) || isEmpty(this.adObj) || !this.adObj.active) {
      return null
    }

    if (shouldUseHouseAd(this.adObj, pageMetaData)) {
      return getHouseAd(this.adObj, intl)
    }

    return <div className="ra-advert-container" id={this.adObj.optDiv}></div>
  }

  static defaultProps = { parentInitComplete: true }

  static propTypes = {
    id: PropTypes.string.isRequired,
    adConfig: PropTypes.object.isRequired,
    pageMetaData: PropTypes.object,
    parentInitComplete: PropTypes.bool,
    intl: PropTypes.object,
  }
}

export default Advert
