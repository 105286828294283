/* External */
import { any, func, object } from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import Cont from '../../external/components/layout/Cont'
import { createSimpleLinkProps } from '../../external/components/link/LinkUtils'
import MetaData from '../../external/components/meta/MetaData'
import { isEmpty, isUndefined } from '../../external/utilities/GeneralUtils'
import {
  doesNavigatorSupportGeolocation,
  navigatorGeoLocationGetCurrentPosition,
} from '../../external/utilities/NavigatorUtils'
import IntlContext from '../../intl/IntlContext'
import {
  forwardToFacilityOverview,
  forwardToSearchResults,
  getFacilityOverviewRoute,
  sendToExternalPath,
} from '../../router/RouteManager'
import braze from '../../thirdParty/braze'
import mixpanel from '../../thirdParty/mixpanel'
import { createAndLogEvent } from '../../utilities/Analytics'
import {
  addDynamicParamsFromStorage,
  addDynamicParamsToURL,
  isLinkDynamic,
} from '../../utilities/DynamicLinkUtils'
import { getErrors } from '../../utilities/ErrorUtils'
import {
  clearRecentlyViewedFacilityData,
  getRecentlyViewedFacilityData,
} from '../../utilities/LocalStateUtils'
import {
  getLiteralValuesForApp,
  getPageMetaData,
} from '../../utilities/MetaDataUtils'
import { getMainSearchObj, mergeQueries } from '../../utilities/QueryParamUtils'
import {
  getAllQuickSearchResults,
  getAllSearchProviderSourceAttribs,
  isCurrSearchSelected,
  onSearchNearMeSelectRequestUtil,
  onSearchResultItemSelectRequestUtil,
  onSearchTermChangeUtil,
  onSearchTriggerUtil,
} from '../../utilities/SearchUtils'
import PageMeta from '../shared/meta/PageMeta'

/* Internal */
import HomeLayout from './HomeLayout'

const PAGE_ID = 'PAGE_HOME'

class HomeContainer extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      doesNavigatorSupportsGeolocation: false,
      recentlyViewedFacilityData: null,
      recentlyViewedFacilityDataPulled: false,
    }

    IntlContext.get().clear()
  }

  componentDidUpdate(prevProps) {
    const {
      mainConfig,
      hackTempRedirectInd,
      selectedSearchObj,
      savedSearchFilter,
    } = this.props

    if (
      !isUndefined(mainConfig) &&
      !isEmpty(mainConfig) &&
      !this.state.recentlyViewedFacilityDataPulled
    ) {
      this.setState({
        recentlyViewedFacilityData: this.localGetRecentlyViewedFacilityData(),
        recentlyViewedFacilityDataPulled: true,
      })
    }

    if (
      hackTempRedirectInd !== prevProps.hackTempRedirectInd &&
      hackTempRedirectInd
    ) {
      let queryObj = {}
      const mainSearchQueryObj = getMainSearchObj(true, selectedSearchObj)
      queryObj = mergeQueries([mainSearchQueryObj, savedSearchFilter], queryObj)
      forwardToSearchResults(queryObj)
    }
  }

  componentDidMount() {
    mixpanel.landingHomepage()
    const { mainConfig } = this.props
    let recentlyViewedFacilityData = null
    let recentlyViewedFacilityDataPulled = false
    if (!isUndefined(mainConfig) && !isEmpty(mainConfig)) {
      recentlyViewedFacilityData = this.localGetRecentlyViewedFacilityData()
      recentlyViewedFacilityDataPulled = true
    }
    this.setState({
      ...this.state,
      doesNavigatorSupportsGeolocation: doesNavigatorSupportGeolocation(),
      recentlyViewedFacilityData,
      recentlyViewedFacilityDataPulled,
    })
  }

  localGetRecentlyViewedFacilityData() {
    const { mainConfig } = this.props
    let recentlyViewedFacilityData = null
    if (
      !isUndefined(mainConfig) &&
      !isEmpty(mainConfig) &&
      mainConfig.mainSearch.recentlyViewed.active
    ) {
      recentlyViewedFacilityData = getRecentlyViewedFacilityData(
        mainConfig.mainSearch.recentlyViewed.maxCacheTimeMinutes,
      )
    }

    return recentlyViewedFacilityData
  }

  onAllArticlesSelectRequest() {
    const { brandConfig } = this.props
    const url = brandConfig.articles.allArticlesURL
    if (
      !isUndefined(brandConfig.articles.allArticles.analytics) &&
      !isEmpty(brandConfig.articles.allArticles.analytics)
    ) {
      createAndLogEvent(brandConfig.articles.allArticles.analytics)
    }
    sendToExternalPath(url, false)
  }

  getArticleSelectLinkObj(article) {
    return createSimpleLinkProps(article.link, false)
  }

  onArticleSelectRequest(article) {
    mixpanel.homepageClickOnArticle(article)
    if (!isUndefined(article.analytics) && !isEmpty(article.analytics)) {
      createAndLogEvent(article.analytics)
    }
    sendToExternalPath(article.link, false)
  }

  getMarketingSpotSelectLinkObj(marketingSpot) {
    const link = isLinkDynamic(marketingSpot.link) ? '#' : marketingSpot.link

    return createSimpleLinkProps(link, false, marketingSpot.newWindow)
  }

  onMarketingSpotDynamicLocationResponse(marketingSpot, response) {
    if (response.success && !isUndefined(response.position)) {
      sendToExternalPath(
        addDynamicParamsToURL(marketingSpot.link, {
          lat: response.position.coords.latitude,
          long: response.position.coords.longitude,
        }),
      )
    }
  }

  onMarketingSpotSelectRequest(marketingSpot) {
    braze.homepageClickOnMarketingSpot(marketingSpot)
    if (
      !isUndefined(marketingSpot.analytics) &&
      !isEmpty(marketingSpot.analytics)
    ) {
      createAndLogEvent(marketingSpot.analytics)
    }
    if (isLinkDynamic(marketingSpot.link)) {
      navigatorGeoLocationGetCurrentPosition(
        this.onMarketingSpotDynamicLocationResponse.bind(this, marketingSpot),
        null,
        this.props.intl
      )
    } else {
      let link = marketingSpot.link
      if (!isEmpty(marketingSpot.linkParams)) {
        link = addDynamicParamsFromStorage(link, marketingSpot.linkParams)
      }
      sendToExternalPath(link, marketingSpot.newWindow)
    }
  }

  onNavigatorGeolocationResponse(response) {
    if (response.success) {
      this.props.onSearchNearMeSelectRequest(response.position)
    }
  }

  onSelectNearMeRequest() {
    const { mainSearchConfig } = this.props
    createAndLogEvent(mainSearchConfig.mainSearch.nearMe.analytics)
    navigatorGeoLocationGetCurrentPosition(
      this.onNavigatorGeolocationResponse.bind(this),
      null,
      this.props.intl
    )
  }

  onClearRecentlyViewedFacilityDataRequest() {
    clearRecentlyViewedFacilityData()
    this.setState({
      ...this.state,
      recentlyViewedFacilityData: this.localGetRecentlyViewedFacilityData(),
    })
  }

  getFacilityLinkObj(facility) {
    const { mainSearchConfig, savedSearchFilter } = this.props
    if (
      isUndefined(facility) ||
      isEmpty(facility) ||
      isEmpty(mainSearchConfig)
    ) {
      return null
    }

    const query = mainSearchConfig.mainSearch.recentlyViewed
      .useSavedSearchFilter
      ? savedSearchFilter
      : {}
    const link = getFacilityOverviewRoute(
      facility.name,
      facility.contrCode,
      facility.id,
      query,
    )
    const linkObj = createSimpleLinkProps(link)

    return linkObj
  }

  onFacilitySelectRequest(facility) {
    const { mainSearchConfig, savedSearchFilter } = this.props
    const query = mainSearchConfig.mainSearch.recentlyViewed
      .useSavedSearchFilter
      ? savedSearchFilter
      : {}

    if (
      !isUndefined(facility) &&
      !isEmpty(facility) &&
      !isEmpty(mainSearchConfig)
    ) {
      createAndLogEvent(mainSearchConfig.mainSearch.recentlyViewed.analytics)
      forwardToFacilityOverview(
        facility.name,
        facility.contrCode,
        facility.id,
        query,
      )
    }
  }

  render() {
    const {
      brandConfig,
      theme,
      intl,
      errors,
      onSearchTrigger,
      onSearchTermChange,
      allQuickSearchResults,
      allQuickSearchResultsAttribs,
      onSearchResultItemSelectRequest,
      mainSearchConfig,
      brndConf,
      currSearch,
      metaConfig,
      mainConfig,
    } = this.props

    if (isEmpty(brandConfig)) {
      return null
    }

    const pageMetaData = getPageMetaData(PAGE_ID, brndConf)

    return (
      <Cont>
        <MetaData
          intlObj={intl}
          literalValues={getLiteralValuesForApp(mainConfig)}
          data={metaConfig}
          pageId={PAGE_ID}
        />
        <PageMeta pageMetaData={pageMetaData} />
        <HomeLayout
          theme={theme}
          brandConfig={brandConfig}
          intl={intl}
          errors={errors}
          onSearchTrigger={onSearchTrigger}
          onSearchTermChange={onSearchTermChange}
          allQuickSearchResults={allQuickSearchResults}
          allQuickSearchResultsAttribs={allQuickSearchResultsAttribs}
          mainSearchConfig={mainSearchConfig}
          brndConf={brndConf}
          onSearchResultItemSelectRequest={onSearchResultItemSelectRequest}
          currSearch={currSearch}
          getArticleSelectLinkObj={this.getArticleSelectLinkObj.bind(this)}
          getMarketingSpotSelectLinkObj={this.getMarketingSpotSelectLinkObj.bind(
            this,
          )}
          onAllArticlesSelectRequest={this.onAllArticlesSelectRequest.bind(
            this,
          )}
          onMarketingSpotSelectRequest={this.onMarketingSpotSelectRequest.bind(
            this,
          )}
          onArticleSelectRequest={this.onArticleSelectRequest.bind(this)}
          pageMetaData={pageMetaData}
          doesNavigatorSupportsGeolocation={
            this.state.doesNavigatorSupportsGeolocation
          }
          onSelectNearMeRequest={this.onSelectNearMeRequest.bind(this)}
          recentlyViewedFacilityData={this.state.recentlyViewedFacilityData}
          onClearRecentlyViewedFacilityDataRequest={this.onClearRecentlyViewedFacilityDataRequest.bind(
            this,
          )}
          getFacilityLinkObj={this.getFacilityLinkObj.bind(this)}
          onFacilitySelectRequest={this.onFacilitySelectRequest.bind(this)}
        />
      </Cont>
    )
  }

  static propTypes = {
    brandConfig: object,
    theme: any,
    intl: object,
    errors: any,
    onSearchTrigger: func,
    onSearchTermChange: func,
    allQuickSearchResults: any,
    allQuickSearchResultsAttribs: any,
    onSearchResultItemSelectRequest: func,
    mainSearchConfig: any,
    brndConf: object,
    currSearch: any,
    metaConfig: object,
    mainConfig: object,
    savedSearchFilter: any,
    onSearchNearMeSelectRequest: func,
    hackTempRedirectInd: any,
    selectedSearchObj: any,
  }
}

function mapStateToProps(state) {
  return {
    brandConfig: state.backend.config.home,
    theme: state.application.theme,
    intl: state.backend.intl,
    errors: getErrors(state.backend.error, state.application.error),
    allQuickSearchResults: getAllQuickSearchResults(
      state.backend.search.quickSearch,
      state.backend.config.main.mainSearch,
    ),
    allQuickSearchResultsAttribs: getAllSearchProviderSourceAttribs(
      state.backend.config.main.mainSearch,
    ),
    hackTempRedirectInd: state.backend.hackTempRedirectInd,
    selectedSearchObj: state.backend.search.selectedSearchObj,
    mainSearchConfig: state.backend.config.main,
    brndConf: state.backend.config.brand,
    currSearch: state.backend.search,
    savedSearchFilter: state.backend.search.searchFilter,
    metaConfig: state.backend.config.meta,
    mainConfig: state.backend.config.main,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSearchTrigger: (searchTerm, selectedItem, currSearch) => {
      mixpanel.homepageSearch(searchTerm, selectedItem)
      if (!isEmpty(searchTerm)) {
        let clearSearch = true
        const currSearchSelected = isCurrSearchSelected(
          currSearch,
          selectedItem,
        )
        if (currSearchSelected) {
          clearSearch = false
        }
        onSearchTriggerUtil(dispatch, searchTerm, selectedItem, clearSearch)
      }
    },
    onSearchTermChange: (searchTerm, mainSearchConfig, brandConfig) => {
      onSearchTermChangeUtil(
        dispatch,
        searchTerm,
        mainSearchConfig,
        brandConfig,
      )
    },
    onSearchResultItemSelectRequest: (
      selectedItem,
      selectedSearchObj,
      currSearch,
    ) => {
      mixpanel.homepageSelectSearchSuggestion(selectedItem)
      let clearSearch = true
      const currSearchSelected = isCurrSearchSelected(currSearch, selectedItem)
      if (currSearchSelected) {
        clearSearch = false
      }
      onSearchResultItemSelectRequestUtil(dispatch, selectedItem, clearSearch)
    },

    onSearchNearMeSelectRequest: (position) => {
      const clearSearch = true

      onSearchNearMeSelectRequestUtil(dispatch, position, clearSearch)
    },
  }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign({}, ownProps, {
    ...stateProps,
    ...dispatchProps,
    onSearchTrigger: (searchTerm, selectedItem) =>
      dispatchProps.onSearchTrigger(
        searchTerm,
        selectedItem,
        stateProps.selectedSearchObj,
        stateProps.mainSearchConfig,
      ),
    onSearchResultItemSelectRequest: (selectedItem) =>
      dispatchProps.onSearchResultItemSelectRequest(
        selectedItem,
        stateProps.selectedSearchObj,
      ),
  })
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(HomeContainer)
