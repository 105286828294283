import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet-async'

import {
  cloneDeep,
  isBoolean,
  isEmpty,
  isUndefined,
  isValue,
} from '../../utilities/GeneralUtils'
import { translateWithKeyQualifiers } from '../../utilities/TranslationUtils'

/**
 * @author Amarjeet Lamba <Amarjeet.Lamba@activenetwork.com>
 *
 * MetaData description
 * @returns {element} MetaData
 *
 */
const MetaData = ({
  data,
  pageId,
  intlObj,
  literalValues,
  keyQualifiers,
  translateValues,
}) => {
  const getPageData = function () {
    let pageData = {}
    for (const currPageData of data.pages) {
      if (currPageData.id === pageId) {
        pageData = currPageData
        break
      }
    }

    return pageData
  }

  const isIntlObj = function () {
    let intl = false
    if (!isUndefined(intlObj) && !isUndefined(intlObj.messages)) {
      intl = true
    }

    return intl
  }

  const getTitleTag = function (pageData) {
    if (isEmpty(pageData) || isUndefined(pageData.title)) {
      return null
    }
    let title = pageData.title.default
    if (
      !isUndefined(pageData.title.token) &&
      !isEmpty(pageData.title.token) &&
      isIntlObj()
    ) {
      title = translateWithKeyQualifiers(
        intlObj,
        pageData.title.token,
        literalValues,
        translateValues,
        keyQualifiers,
        pageData.title.default,
      )
    }

    const titleTag = <title>{title}</title>

    return titleTag
  }

  const getTranslation = function (tag) {
    if (!isUndefined(tag.token) && !isEmpty(tag.token) && isIntlObj()) {
      return translateWithKeyQualifiers(
        intlObj,
        tag.token,
        literalValues,
        translateValues,
        keyQualifiers,
        tag.content,
      )
    }

    return tag.content
  }

  const renderTag = function (tag, index) {
    let renderedTag = null
    if (!isUndefined(tag.name)) {
      renderedTag = (
        <meta key={index} name={tag.name} content={getTranslation(tag)} />
      )
    } else if (!isUndefined(tag.rel)) {
      renderedTag = <link key={index} rel={tag.rel} href={tag.href} />
    } else if (!isUndefined(tag.property)) {
      renderedTag = (
        <meta
          key={index}
          property={tag.property}
          content={getTranslation(tag)}
        />
      )
    }

    return renderedTag
  }

  const getMetaTags = function (pageData) {
    if (isEmpty(pageData) || isUndefined(pageData.meta)) {
      return null
    }

    const metaTags = pageData.meta.map((tag, index) => renderTag(tag, index))

    return metaTags
  }

  const substituteObjectValues = function (obj) {
    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        const oldContent = obj[key]
        if (
          typeof oldContent === 'object' &&
          isUndefined(oldContent.token) &&
          isUndefined(oldContent.default)
        ) {
          substituteObjectValues(oldContent)
        } else if (
          !isUndefined(oldContent.token) ||
          !isUndefined(oldContent.default)
        ) {
          let val = translateWithKeyQualifiers(
            intlObj,
            oldContent.token,
            literalValues,
            translateValues,
            keyQualifiers,
            oldContent.default,
          )
          if (val === null || val === 'null') {
            val = ''
          }
          if (val.charAt(0) === '[' && val.charAt(val.length - 1) === ']') {
            val = val.slice(1, -1)
            obj[key] = val.split(',')
          } else {
            obj[key] = val
          }
        }
      }
    }
  }

  const isObjValuesEmpty = function (obj) {
    let empty = true
    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        if (key.lastIndexOf('@', 0) !== 0 && key.lastIndexOf('-', 0) !== 0) {
          const value = obj[key]
          if (isValue(value) || isBoolean(value)) {
            empty = false
            break
          }
        }
      }
    }

    return empty
  }

  const checkObjectValues = function (obj) {
    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        const value = obj[key]
        if (
          typeof value === 'object' &&
          !isUndefined(value['-hideIfEmpty']) &&
          value['-hideIfEmpty']
        ) {
          if (isObjValuesEmpty(value)) {
            // eslint-disable-next-line prefer-reflect
            delete obj[key]
          } else {
            // eslint-disable-next-line prefer-reflect
            delete value['-hideIfEmpty']
          }
        }
      }
    }
  }

  const createStructuredData = function (structuredDataData) {
    const localData = cloneDeep(structuredDataData)

    substituteObjectValues(localData)
    checkObjectValues(localData)

    return localData
  }

  // All the sturcured data routines were coded in a hurry. Maybe
  // we will refactor when we make changes.
  const getStructuredData = function (pageData) {
    if (isEmpty(pageData) || isUndefined(pageData.structuredData)) {
      return null
    }

    const structuredDataContent = pageData.structuredData.data

    return (
      <script type={pageData.structuredData.type}>
        {JSON.stringify(createStructuredData(structuredDataContent))}
      </script>
    )
  }

  const getTags = function () {
    const pageData = getPageData()

    return (
      <Helmet>
        {getTitleTag(pageData)}
        {getMetaTags(pageData)}
        {getStructuredData(pageData)}
      </Helmet>
    )
  }

  if (
    isUndefined(data) ||
    isUndefined(pageId) ||
    isEmpty(data) ||
    isEmpty(pageId)
  ) {
    return <Helmet />
  }

  return getTags()
}

MetaData.propTypes = {
  data: PropTypes.object.isRequired,
  pageId: PropTypes.string.isRequired,
  intlObj: PropTypes.object,
  literalValues: PropTypes.object,
  keyQualifiers: PropTypes.array,
  translateValues: PropTypes.object,
}

export default MetaData
