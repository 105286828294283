export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd'
export const MOMENT_DEFAULT_DATE_FORMAT = 'YYYY-MM-DD'

/**
 * Create a date from a String literal date
 * @param dateLit String in the format YYYYMMDD
 * @param separator String optional delimiter
 * @return {Date}
 */
export function createDateFromYYYYMMDD(dateLit, separator = '-') {
  if (dateLit === null) {
    return new Date()
  }
  const da = dateLit.split(separator)

  return new Date(parseInt(da[0]), parseInt(da[1]) - 1, parseInt(da[2]))
}

export function createDateFromMMDDYYYY(dateLit, separator = '-') {
  if (dateLit === null) {
    return new Date()
  }
  const da = dateLit.split(separator)

  return new Date(parseInt(da[2]), parseInt(da[0]) - 1, parseInt(da[1]))
}

export function createMMDDYYYYFromYYYYMMDD(
  dateLit,
  separator = '-',
  newSeparator = ' ',
) {
  const da = dateLit.split(separator)

  return da[1] + newSeparator + da[2] + newSeparator + da[0]
}

/**
 * Add leading zero to single diget numbers
 * @param Number
 * @return String
 */
export function addZero(dig) {
  if (dig > 9) {
    return dig.toString()
  }

  return `0${dig.toString()}`
}

/**
 * Create a date String from a date object
 * @param date Date
 * @param separator String optional delimiter
 * @return String
 */
export function createYYYYMMDDFromDate(date, separator = '-') {
  return (
    date.getFullYear().toString() +
    separator +
    addZero(date.getMonth() + 1) +
    separator +
    addZero(date.getDate())
  )
}

export function getClientToday() {
  return new Date() // today
}

export function getClientTodayYear() {
  return getClientToday().getFullYear()
}

export function getClientTodayYYYYMMDD() {
  return createYYYYMMDDFromDate(getClientToday())
}

/**
 * Current date timestamp
 * @return Number
 */
export function getNowTimestamp() {
  if (!Date.now) {
    return new Date().getTime()
  }

  return Date.now()
}

/**
 * Current timestamp
 * @param date Date
 * @return Number
 */
export function getTimestampFromDate(date) {
  return date.getTime()
}

/**
 * Date from timestamp
 * @param Number
 * @return Date
 */
export function getDateFromTimestamp(timestamp) {
  return new Date(timestamp)
}

/** change date format to weekday */
export function weekDay(date) {
  const weekDays = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ]
  const index = new Date(date).getDay()

  return weekDays[index]
}

export default {
  DEFAULT_DATE_FORMAT,
  createDateFromYYYYMMDD,
  createYYYYMMDDFromDate,
  getNowTimestamp,
  getTimestampFromDate,
  getDateFromTimestamp,
  addZero,
}
