import { array, bool, element, object } from 'prop-types'
import React, { Component } from 'react'

import { ERROR_BLOCK } from '../../constants/Elements'
import ScrollPoint, {
  scrollToPoint,
} from '../../external/components/link/ScrollPoint'
import { difference, isEmpty } from '../../external/utilities/GeneralUtils'
import { getIntlErrorMsg } from '../../utilities/ErrorUtils'

class ErrorBlock extends Component {
  componentDidUpdate(prevProps) {
    const prevErrors = prevProps.errors
    const currentErrors = this.props.errors
    let scrollToErrors = false
    if (isEmpty(prevErrors) && !isEmpty(currentErrors)) {
      scrollToErrors = true
    } else if (!isEmpty(prevErrors) && !isEmpty(currentErrors)) {
      scrollToErrors = !isEmpty(difference(prevErrors, currentErrors))
    }
    if (scrollToErrors) {
      scrollToPoint(ERROR_BLOCK)
    }
  }

  getFaultList(faultList) {
    const faults = faultList.map((fault, i) => {
      return <div key={i}>{fault}</div>
    })

    return faults
  }

  render() {
    const { errors, intl, content, disableScroll = false } = this.props
    const getFaultListBound = this.getFaultList.bind(this)
    let errorList = null

    if (!isEmpty(errors)) {
      errorList = errors
        .map((error, i) => {
          const faultList = getIntlErrorMsg(
            Array.isArray(error) ? error[i] : error,
            intl
          )

          return <div key={i}>{getFaultListBound(faultList)}</div>
        })
    } else if (content !== undefined) {
      errorList = content
    }
    if (isEmpty(errors) && content === undefined) {
      return <div />
    }
    const errorBody = (
      <div className="alert alert-danger" role="alert">
        {errorList}
      </div>
    )
    if (disableScroll) {
      return errorBody
    }

    return <ScrollPoint name={ERROR_BLOCK}>{errorBody}</ScrollPoint>
  }

  static propTypes = {
    intl: object,
    errors: array.isRequired,
    content: element,
    disableScroll: bool,
  }
}

export default ErrorBlock
