import PropTypes from 'prop-types'
import React from 'react'

/** Component to display any image

@example ../../../../../docs/external/media/img/AImg.md  */

const AImg = ({
  imageURL,
  responsive = true,
  className = '',
  cmpLabel,
  cmpTitle,
}) => {
  let localClassName = className
  if (responsive) {
    localClassName += ' img-fluid'
  }

  return (
    <img
      src={imageURL}
      className={localClassName}
      title={cmpTitle}
      alt={cmpLabel}
    />
  )
}

AImg.propTypes = {
  /** url of image to display */
  imageURL: PropTypes.string.isRequired,
  /** whether or not image is responsive */
  responsive: PropTypes.bool,
  /** wrapper classname for custom styling */
  className: PropTypes.string,
  /** alt text */
  cmpLabel: PropTypes.string,
  /** img title */
  cmpTitle: PropTypes.string,
}

export default AImg
