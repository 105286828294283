import axios from 'axios'

import { getAxiosConfig, root, rootChub } from '../utilities/BackendUtils'
import {
  getMainSearchApiObj,
  getMainSearchSuggestionsApiObj,
  getPagingApiObj,
  getProductSearchApiObj,
  mergeQueries,
} from '../utilities/QueryParamUtils'
import { MAP_PROVIDERS } from '../utilities/consts'
import { fetchGooglePlacesPredictions } from './googlePlaces'
import { searchMapBoxFavorites } from './mapboxPlaces'

export async function searchInternalResults(query, limit = 40) {
  const apiQuery = {}
  mergeQueries(
    [
      getPagingApiObj(query),
      getMainSearchApiObj(query),
      getProductSearchApiObj(query),
    ],
    apiQuery,
  )
  apiQuery.rcs = limit

  const searchTxn = getAxiosConfig('get', `${root}/search`, apiQuery)

  const result = await axios(searchTxn)

  return result
}

export function searchInternalSuggestions(query, limit = 50) {
  const queryObj = getMainSearchSuggestionsApiObj(query)
  queryObj.match = 'NAME_ALIAS'
  queryObj.rcs = limit
  const searchTxn = getAxiosConfig(
    'get',
    `${root}/search/suggestions`,
    queryObj,
  )

  return axios(searchTxn)
}

export function searchInternalPlaces(query) {
  const axiosConfig = getAxiosConfig(
    'get',
    `${root}/search/places`,
    getMainSearchSuggestionsApiObj(query),
  )

  return axios(axiosConfig)
}

const defaultSearchCountries = ['us']

export async function searchPlaces({
  isInternal,
  query,
  country = defaultSearchCountries,
}) {
  if (typeof isInternal !== 'boolean') return null

  if (isInternal) {
    const {
      data: { results },
    } = await searchInternalPlaces(query)

    return results
  }

  return fetchGooglePlacesPredictions({
    input: query.searchTerm,
    country,
    types: ['geocode'],
  })
}

export function searchFavorites({
  searchTerm,
  searchProviderId = MAP_PROVIDERS.MAPBOX,
  accessToken,
  searchCountries = defaultSearchCountries,
}) {
  if (searchProviderId === MAP_PROVIDERS.MAPBOX) {
    return searchMapBoxFavorites({
      searchTerm,
      accessToken,
    })
  } else if (searchProviderId === MAP_PROVIDERS.GOOGLE) {
    return fetchGooglePlacesPredictions({
      input: searchTerm,
      country: searchCountries,
    })
  }
}
