import { object, string } from 'prop-types'
import React from 'react'

import { isEmpty } from '../../../external/utilities/GeneralUtils'

export const HELP_TYPE_ERROR = 'HELP_TYPE_ERROR'
export const HELP_TYPE_HINT = 'HELP_TYPE_HINT'

const SearchHelper = ({ content, contentType }) => {
  const getContent = function () {
    if (!isEmpty(content)) {
      let style = 'search-help-error'
      if (contentType === HELP_TYPE_HINT) {
        style = 'search-help-hint'
      }

      return <div className={style}>{content}</div>
    }

    return null
  }

  return <div>{getContent()}</div>
}

SearchHelper.propTypes = {
  intl: object.isRequired,
  content: string,
  contentType: string,
}

export default SearchHelper
