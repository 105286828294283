/**
 * @file   : RolloutMenu.js
 * @author : Amarjeet Lamba (Amarjeet.Lamba@AspiraConnect.com)
 * @date   : 1/2/2019, 12:47:50 PM
 */
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { isUndefined } from '../../../utilities/GeneralUtils'
import ABaseLink from '../../link/ABaseLink'

export const POSITION_100 = 'position-100'
export const POSITION_95 = 'position-95'
export const POSITION_90 = 'position-90'
export const POSITION_85 = 'position-85'
export const POSITION_80 = 'position-80'
export const POSITION_75 = 'position-75'
export const POSITION_70 = 'position-70'
export const POSITION_65 = 'position-65'
export const POSITION_60 = 'position-60'
export const MENU_OPEN = 'menuOpen'
export const MENU_CLOSED = 'menuClosed'

class RolloutMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showingChildren: props.open,
      prevShowingChildren: props.open,
    }
  }

  componentDidUpdate(prevProps) {
    const { open } = this.props

    if (
      !isUndefined(open) &&
      open !== prevProps.open &&
      open !== this.state.showingChildren
    ) {
      this.setState({
        showingChildren: open,
        prevShowingChildren: !open,
      })
    }
  }

  getChildItemsUI() {
    const { childItems } = this.props
    const childItemsUI = childItems.map((childItem, i) => {
      const ctr = i
      let style = ''
      if (!this.state.showingChildren && !this.state.prevShowingChildren) {
        style = 'rollout-menu-item rollout-menu-item-init'
      } else if (!this.state.prevShowingChildren) {
        style = `rollout-menu-item rollout-menu-item-in rollout-menu-item-${ctr}`
      } else if (!this.state.showingChildren) {
        style = `rollout-menu-item rollout-menu-item-out rollout-menu-item-${ctr}`
      }

      return (
        <div key={i} className={style}>
          {childItem}
        </div>
      )
    })

    return childItemsUI
  }

  onMainActionItemClick() {
    const isMenuOpen = !this.state.showingChildren
    const currOpenState = isMenuOpen ? MENU_OPEN : MENU_CLOSED
    const { onMenuOpenClose } = this.props

    this.setState({
      ...this.state,
      showingChildren: !this.state.showingChildren,
      prevShowingChildren: this.state.showingChildren,
    })

    if (!isUndefined(onMenuOpenClose)) {
      onMenuOpenClose(currOpenState)
    }
  }

  render() {
    const { mainActionItem, mainActionItemHideFocusOutline } = this.props
    const containerStyle = 'rollout-menu-container'
    const contentStyle = 'rollout-menu-content'

    return (
      <div className={containerStyle}>
        <ABaseLink
          onClick={this.onMainActionItemClick.bind(this)}
          cmpExpanded={this.state.showingChildren}
          hideFocusOutline={mainActionItemHideFocusOutline}
        >
          {mainActionItem}
        </ABaseLink>
        <div className={contentStyle} aria-hidden={!this.state.showingChildren}>
          {this.getChildItemsUI()}
        </div>
      </div>
    )
  }
}

RolloutMenu.defaultProps = {
  mainActionItem: <div></div>,
  position: POSITION_100,
}

RolloutMenu.propTypes = {
  /** main action element */
  mainActionItem: PropTypes.element.isRequired,
  /** main action element hide focus outline*/
  mainActionItemHideFocusOutline: PropTypes.bool,
  /** child items */
  childItems: PropTypes.arrayOf(PropTypes.element),
  /** is open */
  open: PropTypes.bool,
  /** position in percent from top */
  position: PropTypes.oneOf([
    POSITION_100,
    POSITION_95,
    POSITION_90,
    POSITION_85,
    POSITION_80,
    POSITION_75,
    POSITION_70,
    POSITION_65,
    POSITION_60,
  ]),
  /** callback for open/close change */
  onMenuOpenClose: PropTypes.func,
}

export default RolloutMenu
