import PropTypes from 'prop-types'
import React from 'react'

import ACol from '../../../external/components/layout/ACol'
import ARow from '../../../external/components/layout/ARow'
import Cont, { ALIGN_CENTER } from '../../../external/components/layout/Cont'
import AIconAction from '../../../external/components/link/AIconAction'
import TextHoverable from '../../../external/components/link/TextHoverable'
import SimpleSelector from '../../../external/components/navigation/selector/SimpleSelector'
import { isEmpty } from '../../../external/utilities/GeneralUtils'
import { getArticlesRoute } from '../../../router/ExtRouteManager'

const ArticleHeader = ({
  onCategorySelectRequest,
  onAllArticlesSelectRequest,
  selectedCategory,
  articles,
}) => {
  const getCategoryNames = function () {
    const categoryNames = []
    for (const category of articles.categories) {
      categoryNames.push(category.name)
    }

    return categoryNames
  }

  const getCategoryIndex = function (selectedCategory) {
    let index = 0
    if (typeof selectedCategory !== 'undefined' && !isEmpty(selectedCategory)) {
      for (let i = 0; i < articles.categories.length; i++) {
        const category = articles.categories[i]
        if (category.name === selectedCategory.name) {
          index = i
          break
        }
      }
    }

    return index
  }

  const getCategoryFromIndex = function (index) {
    return articles.categories[index]
  }

  const getCategorySelector = function () {
    const categoryNames = getCategoryNames()

    return (
      <Cont className={'home-outdoor-guide__selector'}>
        <SimpleSelector
          itemLabels={categoryNames}
          onItemSelectRequest={(index) => {
            onCategorySelectRequest(getCategoryFromIndex(index))
          }}
          selectedIndex={getCategoryIndex(selectedCategory)}
          useMargin
        />
      </Cont>
    )
  }

  const getHeader = function () {
    return (
      <Cont
        align={ALIGN_CENTER}
        className={'home-outdoor-guide__header__title'}
      >
        <TextHoverable
          fontSizeType={2}
          fontFamily={'Georgia'}
          fontColor={'blue-dark'}
          selected
        >
          {articles.headline}
        </TextHoverable>
      </Cont>
    )
  }

  const getOtherContent = function () {
    return (
      <AIconAction
        label={articles.allArticlesLabel}
        faStyle={'fa-arrow-right'}
        to={getArticlesRoute()}
        internal={false}
        isShowAsLink
        iconPos={'right'}
        onClick={onAllArticlesSelectRequest}
      />
    )
  }

  return (
    <Cont>
      <Cont className={'home-outdoor-guide__header'} hideXS>
        {getHeader()}
        <ARow>
          <ACol xs={12} sm={8} md={9}>
            {getCategorySelector()}
          </ACol>
          <ACol xs={12} sm={4} md={3}>
            {getOtherContent()}
          </ACol>
        </ARow>
      </Cont>
      <Cont hideSM hideMD hideLG>
        <Cont className={'home-outdoor-guide__header'}>
          {getHeader()}
          {getCategorySelector()}
        </Cont>
        <Cont formGroup textAlign={'right'}>
          {getOtherContent()}
        </Cont>
      </Cont>
    </Cont>
  )
}

ArticleHeader.propTypes = {
  theme: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  onCategorySelectRequest: PropTypes.func.isRequired,
  onAllArticlesSelectRequest: PropTypes.func.isRequired,
  selectedCategory: PropTypes.object,
  articles: PropTypes.object.isRequired,
}

export default ArticleHeader
