export const selectCurrentUser = (state) => state.backend.user?.profile

export const selectCurrentUserId = (state) => {
  const user = selectCurrentUser(state)

  return user?.userID
}

export const selectIsCurrentUserAnonymous = (state) =>
  !selectCurrentUserId(state)

export const selectCurrentUserEmail = (state) => {
  return selectCurrentUser(state)?.person?.emailAddress1
}
