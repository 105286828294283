import PropTypes from 'prop-types'
import React from 'react'
import Modal from 'react-bootstrap/Modal'

/** Wrapper class for react-bootstrap modal.  https://react-bootstrap.github.io/components.html#modals */

const AModal = ({
  animation = true,
  autoFocus,
  backdrop,
  bsPrefix,
  size,
  dialogClassName,
  dialogComponentClass,
  enforceFocus,
  keyboard,
  onEnter,
  onEntered,
  onEntering,
  onExit,
  onExited,
  onExiting,
  onHide,
  show,
  restoreFocus,
  children,
}) => (
  <Modal
    animation={animation}
    autoFocus={autoFocus}
    backdrop={backdrop}
    bsPrefix={bsPrefix}
    size={size}
    dialogClassName={dialogClassName}
    dialogComponentClass={dialogComponentClass}
    enforceFocus={enforceFocus}
    keyboard={keyboard}
    onEnter={onEnter}
    onEntered={onEntered}
    onEntering={onEntering}
    onExit={onExit}
    onExited={onExited}
    onExiting={onExiting}
    onHide={onHide}
    restoreFocus={restoreFocus}
    show={show}
  >
    {children}
  </Modal>
)

AModal.propTypes = {
  /** Open and close the Modal with a slide and fade animation. default to true */
  animation: PropTypes.bool,
  /** When true The modal will automatically shift focus to itself when it opens, and replace it to the last focused element when it closes. Generally this should never be set to false as it makes the Modal less accessible to assistive technologies, like screen-reader */
  autoFocus: PropTypes.bool,
  /** Include a backdrop component. Specify 'static' for a backdrop that doesn't trigger an "onHide" when clicked */
  backdrop: PropTypes.oneOf(['static', true, false]),
  /** Base CSS class and prefix for the component. Generally one should only change bsPrefix to provide new, non-Bootstrap, CSS styles for a component. */
  bsPrefix: PropTypes.string,
  /** Component size variations. */
  size: PropTypes.oneOf(['lg', 'large', 'sm', 'small']),
  /** A css class to apply to the Modal dialog DOM node. */
  dialogClassName: PropTypes.string,
  /** A Component type that provides the modal content Markup. This is a useful prop when you want to use your own styles and markup to create a custom modal component. */
  dialogComponentClass: PropTypes.string,
  /** When true The modal will prevent focus from leaving the Modal while open. Consider leaving the default value here, as it is necessary to make the Modal work well with assistive technologies, such as screen readers. */
  enforceFocus: PropTypes.bool,
  /** Close the modal when escape key is pressed */
  keyboard: PropTypes.bool,
  /** Callback fired before the Modal transitions in */
  onEnter: PropTypes.func,
  /** Callback fired after the Modal finishes transitioning in */
  onEntered: PropTypes.func,
  /** Callback fired as the Modal begins to transition in */
  onEntering: PropTypes.func,
  /** Callback fired right before the Modal transitions out */
  onExit: PropTypes.func,
  /** Callback fired after the Modal finishes transitioning out */
  onExited: PropTypes.func,
  /** Callback fired as the Modal begins to transition out */
  onExiting: PropTypes.func,
  /** A callback fired when the header closeButton or non-static backdrop is clicked. Required if either are specified. */
  onHide: PropTypes.func,
  /** When true The modal will restore focus to previously focused element once modal is hidden */
  restoreFocus: PropTypes.bool,
  /** When true The modal will show itself. */
  show: PropTypes.bool,
  /** Contents of modal */
  children: PropTypes.node,
}

export default AModal
