import { isEmpty } from '../external/utilities/GeneralUtils'
import { getNavigatorDimensions } from '../external/utilities/NavigatorUtils'

export const VIEW_CONSTANTS = {
  resolutions: [
    {
      name: 'XS',
      min: 0,
      max: 767,
    },
    {
      name: 'SM',
      min: 768,
      max: 991,
    },
    {
      name: 'MD',
      min: 992,
      max: 1199,
    },
    {
      name: 'LG',
      min: 1200,
      max: null,
    },
  ],
  VIEWS: {
    MAIN_HEADER: {
      layers: [
        {
          name: 'LAYER_1',
          XS: 0,
          SM: 30,
          MD: 30,
          LG: 30,
        },
        {
          name: 'LAYER_2',
          XS: 50,
          SM: 55,
          MD: 55,
          LG: 55,
        },
        {
          name: 'LAYER_3',
          XS: 45,
          SM: 0,
          MD: 0,
          LG: 0,
        },
      ],
    },
    MAIN_HEADER_HOME: {
      layers: [
        {
          name: 'LAYER_1',
          XS: 0,
          SM: 30,
          MD: 30,
          LG: 30,
        },
        {
          name: 'LAYER_2',
          XS: 50,
          SM: 55,
          MD: 55,
          LG: 55,
        },
        {
          name: 'LAYER_3',
          XS: 0,
          SM: 0,
          MD: 0,
          LG: 0,
        },
      ],
    },
  },
}

export const getResolution = function (width = 0) {
  let resolution = null
  if (width > 0) {
    for (const res of VIEW_CONSTANTS.resolutions) {
      if (width >= res.min && (res.max === null || width <= res.max)) {
        resolution = res
      }
    }
  }

  return resolution
}

export const getViewHeight = function (viewObject = null, resolution = null) {
  let height = 0

  if (!isEmpty(viewObject) && !isEmpty(resolution)) {
    for (const layer of viewObject.layers) {
      height += layer[resolution.name]
    }
  }

  return height
}

export const getMainHeaderHomeHeight = function () {
  const navigatorDimensions = getNavigatorDimensions(0, 0)
  const resolution = getResolution(navigatorDimensions.width)

  return getViewHeight(VIEW_CONSTANTS.VIEWS.MAIN_HEADER_HOME, resolution)
}
