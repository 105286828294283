import { array, bool, object, string } from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import * as utils from '../external/utilities/GeneralUtils'
import IntlContext from '../intl/IntlContext'
import { selectIntl } from '../selectors/intl'

const Translation = ({
  intl,
  messageKey,
  mkey,
  keyQualifiers,
  literalValues,
  translatedValues,
  defaultMessage,
  ignoreContext,
  styleClass,
}) => {
  const path = utils.isEmpty(mkey) ? messageKey : mkey
  if (!utils.isEmpty(intl)) {
    return (
      <span className={styleClass}>
        {translate(
          intl,
          path,
          literalValues,
          translatedValues,
          keyQualifiers,
          defaultMessage,
          false,
          ignoreContext,
        )}
      </span>
    )
  }

  return <span>you provided a key: {path} but no intl</span>
}

export const Translate = connect((state) => ({ intl: selectIntl(state) }))(
  Translation,
)

Translation.propTypes = {
  messageKey: string /* Base key to the translation */,
  mkey: string /* optional short form. */,
  keyQualifiers:
    array /* optional array of qualifieres for more specific keys "hello world {key1} {key2} {key3}" */,
  literalValues:
    object /* direct text for substititions { key : "value", key2 : "value2"} */,
  translatedValues:
    object /* translation text for substititions { key : "TRANSLATION_KEY", key2 : "TRANSLATION_KEY2"} */,
  defaultMessage:
    string /* literal that will be returned if there is no message found */,
  intl: object.isRequired /* required intl messages object */,
  ignoreContext: bool /* Ignore Intl Context */,
  styleClass: string /* Ignore Intl Context */,
}

export default Translation

/*
 * TRANSLATION HELPER METHODS
 */

/*
 * simpleTranslate
 */
export function simpleTranslate(intl, path, defaultMessage) {
  return translate(intl, path, null, null, null, defaultMessage, false, true)
}

/*
 * contextTranslate
 */
export function contextTranslate(intl, path, defaultMessage) {
  return translate(intl, path, null, null, null, defaultMessage, false, false)
}

/*
 * Translate
 */
export function translate(
  intl,
  path,
  literalValues,
  translateValues,
  keyQualifiers,
  defaultMessage,
  allowNull,
  ignoreContext,
  defaultToEmptyString = true,
) {
  let baseMessage = null
  let applicableQualifiers = keyQualifiers

  if (!ignoreContext) {
    applicableQualifiers = utils.isEmpty(keyQualifiers)
      ? IntlContext.get().getQualifiers()
      : keyQualifiers
  }

  const hasCount = literalValues && 'count' in literalValues

  if (hasCount && literalValues.count === 1) {
    path += '_one'
  } else if (hasCount) {
    path += '_other'
  }

  applicableQualifiers = utils.cloneArray(applicableQualifiers)

  if (
    !utils.isUndefined(applicableQualifiers) &&
    !utils.isEmpty(applicableQualifiers)
  ) {
    const arrlength = applicableQualifiers.length
    for (let i = arrlength; i > 0; i--) {
      applicableQualifiers.length = i
      const translatedMessage = getTranslation(
        intl,
        `${path}_${applicableQualifiers.join('_')}`,
        defaultMessage,
        true,
      )
      if (!utils.isEmpty(translatedMessage)) {
        baseMessage = translatedMessage
        break
      }
    }
  }

  if (!baseMessage) {
    baseMessage = getTranslation(
      intl,
      path,
      defaultMessage,
      allowNull,
      defaultToEmptyString,
    )
  }

  if (!baseMessage) {
    return null
  }

  const substitutions = {}

  if (translateValues) {
    for (const tprop in translateValues) {
      substitutions[tprop] = getTranslation(intl, translateValues[tprop])
    }
  }

  if (literalValues) {
    for (const lprop in literalValues) {
      substitutions[lprop] = literalValues[lprop]
    }
  }

  if (substitutions) {
    for (const prop in substitutions) {
      const replaceKey = `{${prop}}`
      baseMessage = utils.replaceAll(
        baseMessage,
        replaceKey,
        substitutions[prop],
      )
    }
  }

  return baseMessage
}

function getTranslation(
  intl,
  path,
  defaultMessage,
  allowNull,
  defaultToEmptyString = true,
) {
  const pathParts = [path]
  let message
  try {
    message = pathParts.reduce((obj, pathPart) => obj[pathPart], intl.messages)
  } finally {
    if (!message && allowNull) {
      message = null
    } else if (!message) {
      message =
        defaultMessage ||
        (defaultToEmptyString
          ? ''
          : getTranslation(intl, 'error.general', null, allowNull))
    }
  }

  return message
}

/*
EXAMPLE USAGE

1. simplest case

"SIMPLE" : "Simple translation example",
<Translation messageKey={"SIMPLE"}/>

"SIMPLE_WITH_DIRECT_VARIABLE_TRANSLATION" : "Simple translation example {substitutedValue}",
<Translation messageKey={"SIMPLE_WITH_DIRECT_VARIABLE_TRANSLATION"} literalValues={{substitutedValue:"literal substitution"}}/>

"SIMPLE_WITH_TRANSLATED_VARIABLE_TRANSLATION" : "Simple translation example {translateThis}",
"TRANSLATE_KEY" : "a translated variable substitution"
<Translation messageKey={"SIMPLE_WITH_DIRECT_VARIABLE_TRANSLATION"} translatedValues={{translateThis:"TRANSLATE_KEY"}}/>

"SIMPLE_RA" : "Simple translation example with a keyQualifier specific to RA",
"SIMPLE_TOURS" : "Simple translation example with a keyQualifier specific to tours",
<Translation messageKey={"SIMPLE"} keyQualifiers=['RA','TOURS']/>

TRANSLATED_ONE:"translated one",
TRANSLATED_TWO:"translated two",
"COMPLEX_EXAMPLE_70029" : "Complex example with {literalOne} {literalTwo} {translatedOne} {translatedTwo}"

<Translation
  messageKey={"COMPLEX_EXAMPLE_70029"}
  keyQualifiers=['70029']
  literalValues={
    {literalOne:"literal substitution one",
    literalTwo:"literal substition two"}
  }
  literalValues={
    {translatedOne:"TRANSLATED_ONE",
    translatedTwo:"TRANSLATED_TWO"}
  }
/>

TESTS

  <h3>Translation Tests</h3>
	<p><Translation messageKey={"SIMPLE"}/></p>
	<p><Translation messageKey={"SIMPLE_WITH_DIRECT_VARIABLE_TRANSLATION"} literalValues={{substitutedValue:"literal substitution"}}/></p>
	<p><Translation messageKey={"SIMPLE_WITH_TRANSLATED_VARIABLE_TRANSLATION"} translatedValues={{translateThis:"TRANSLATE_KEY"}}/></p>
	<p><Translation messageKey={"SIMPLE"} keyQualifiers={['RA','TOURS']}/></p>
	<p>
	<Translation
	  messageKey={"COMPLEX_EXAMPLE"}
	  keyQualifiers={['70029']}
	  literalValues={
	    {literalOne:"literal substitution one",
	    literalTwo:"literal substitution two"}
	  }
	  translatedValues={
	    {translatedOne:"TRANSLATED_ONE",
	    translatedTwo:"TRANSLATED_TWO"}
	  }
	/>
	</p>

	<p>
	<Translation
	  messageKey={"COMPLEX_EXAMPLE"}
	  keyQualifiers={['CASP','PERMITS']}
	  literalValues={
	    {literalOne:"literal substitution one",
	    literalTwo:"literal substitution two"}
	  }
	  translatedValues={
	    {translatedOne:"TRANSLATED_ONE",
	    translatedTwo:"TRANSLATED_TWO"}
	  }
	/>
	</p>

	<p><Translation messageKey={"WILL_NOT_BE_FOUND"} defaultMessage={"This message will default as the key provided is not present"}/></p>
*/
