import { bool, func, string } from 'prop-types'
import React from 'react'

import { htmlBlock, isEmpty } from '../../../external/utilities/GeneralUtils'
import HeaderMessageBarToggle from './HeaderMessageBarToggle'

const HeaderMessageBar = ({ message, open, display, toggleMessage }) => {
  if (isEmpty(message)) {
    return <i></i>
  }

  const styleClass = open
    ? 'header-message header-message--active'
    : 'header-message'

  if (!display) {
    return <i></i>
  }

  let displayMessage

  if (open) {
    displayMessage = <span dangerouslySetInnerHTML={htmlBlock(message)} />
  }

  return (
    <div className={styleClass}>
      <div className="header-message__notification">{displayMessage}</div>
      <HeaderMessageBarToggle
        display={display}
        message={message}
        open={open}
        onToggle={toggleMessage}
      />
    </div>
  )
}

HeaderMessageBar.propTypes = {
  message: string,
  open: bool.isRequired,
  display: bool.isRequired,
  toggleMessage: func.isRequired,
}

export default HeaderMessageBar
