import PropTypes from 'prop-types'
import React from 'react'

import AButton from '../../external/components/button/AButton'
import Cont from '../../external/components/layout/Cont'
import Section, { SUB_INLINE } from '../../external/components/layout/Section'
import AIcon, { ICONS } from '../../external/components/media/icon/AIcon'
import RolloutMenu, {
  POSITION_80,
} from '../../external/components/navigation/rolloutMenu/RolloutMenu'
import { isWebChatAgentAvailable } from '../../utilities/HackChatUtils'
import { simpleTranslate } from '../Translation'

// eslint-disable-next-line no-unused-vars
const ContactTrigger = ({
  theme,
  intl,
  onFeedbackRequest,
  onSMSRequest,
  onWebChatRequest,
  open = false,
  webChatAgentStatus = '',
  webChatStatus = '',
  onMenuOpenClose,
  allowWebChat = false,
}) => {
  const getMainActionItem = function () {
    return (
      <Section style={SUB_INLINE}>
        <AButton
          cmpRef={'contact-us-button'}
          style={'highlight'}
          cmpLabel={simpleTranslate(intl, 'labels_contact-us')}
          asDiv={true}
        >
          <AIcon icon={ICONS.paperPlane} padded />
        </AButton>
      </Section>
    )
  }

  const getWebChatItem = function () {
    if (!allowWebChat) {
      return null
    }

    const webChatAgentAvailable = isWebChatAgentAvailable(webChatAgentStatus)

    let label = simpleTranslate(intl, 'web_chat_offline')
    let buttonDisabled = true

    if (webChatAgentAvailable) {
      label = simpleTranslate(intl, 'web_chat')
      buttonDisabled = false
    }

    return (
      <Section style={SUB_INLINE}>
        <AButton
          cmpRef={'chat-button'}
          onClick={onWebChatRequest}
          style={'highlight'}
          widthSize={'2x'}
          hideFocusOutline={true}
          disabled={buttonDisabled}
        >
          <AIcon icon={ICONS.comments} padded />
          {label}
        </AButton>
      </Section>
    )
  }

  const getSMSItem = function () {
    return (
      <Section style={SUB_INLINE}>
        <AButton
          cmpRef={'SMS-button'}
          onClick={onSMSRequest}
          style={'highlight'}
          widthSize={'2x'}
          hideFocusOutline={true}
        >
          <AIcon icon={ICONS.mobile} padded />
          {simpleTranslate(intl, 'labels_sms')}
        </AButton>
      </Section>
    )
  }

  const getFeedbackActionItem = function () {
    return (
      <Section style={SUB_INLINE}>
        <AButton
          cmpRef={'Feedback-button'}
          onClick={onFeedbackRequest}
          style={'highlight'}
          widthSize={'2x'}
          hideFocusOutline={true}
        >
          <AIcon icon={ICONS.leftAlign} padded />
          {simpleTranslate(intl, 'labels_feedback')}
        </AButton>
      </Section>
    )
  }

  const getUI = function () {
    const childItems = []

    childItems.push(getWebChatItem())
    childItems.push(getFeedbackActionItem())
    childItems.push(getSMSItem())

    return (
      <Cont className="contact-us-trigger-container">
        <RolloutMenu
          open={open}
          position={POSITION_80}
          mainActionItem={getMainActionItem()}
          childItems={childItems}
          onMenuOpenClose={onMenuOpenClose}
          mainActionItemHideFocusOutline
        />
      </Cont>
    )
  }

  return getUI()
}

ContactTrigger.propTypes = {
  theme: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  onFeedbackRequest: PropTypes.func.isRequired,
  onSMSRequest: PropTypes.func.isRequired,
  onWebChatRequest: PropTypes.func.isRequired,
  open: PropTypes.bool,
  webChatAgentStatus: PropTypes.string,
  webChatStatus: PropTypes.string,
  onMenuOpenClose: PropTypes.func,
  allowWebChat: PropTypes.bool.isRequired,
}

export default ContactTrigger
