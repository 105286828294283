import PropTypes from 'prop-types'
import React from 'react'

import Cont from '../layout/Cont'

const Legend = ({ labels, colors }) => (
  <Cont className="Legend">
    {labels.map((label, labelIndex) => (
      <Cont key={labelIndex}>
        <span
          className="Legend--color"
          style={{ backgroundColor: colors[labelIndex % colors.length] }}
        />
        <span className="Legend--label">{label}</span>
      </Cont>
    ))}
  </Cont>
)

Legend.propTypes = {
  labels: PropTypes.array,
  colors: PropTypes.array,
}

export default Legend
