import PropTypes from 'prop-types'
import React from 'react'

import { pushRoute } from '../../../router/RouteManager'
import { isEmpty, isUndefined } from '../../utilities/GeneralUtils'
import { ROLES } from '../../utilities/accessibility/Definitions'
import Cont from '../layout/Cont'

export const HOVER_COLOR = { ORANGE: 'base-link--orange-hover' }
export const TARGET_BLANK = '_blank'
export const TARGET_SELF = '_self'
export const TARGET_PARENT = '_parent'
export const TARGET_TOP = '_top'
export const targets = ['_blank', '_self', '_parent', '_top']
export const DEFAULT_ANCHOR = '#'

export const REL_ALTERNATE = 'alternate'
export const REL_AUTHOR = 'author'
export const REL_BOOKMARK = 'bookmark'
export const REL_EXTERNAL = 'external'
export const REL_HELP = 'help'
export const REL_LICENSE = 'license'
export const REL_NEXT = 'next'
export const REL_NOFOLLOW = 'nofollow'
export const REL_NOREFERRER = 'noreferrer'
export const REL_NOOPENER = 'noopener'
export const REL_PREV = 'prev'
export const REL_SEARCH = 'search'

/** Component used for all links or to wrap html elements that behave as links.  Created for accessibility to ensure all links are tabbable, focusable, etc.

@example ../../../../docs/external/link/ABaseLink.md
*/

const ABaseLink = ({
  to = DEFAULT_ANCHOR,
  cmpTarget = null,
  newWin = null,
  internal = true,
  onClick = null,
  style = '',
  className: classNameProp = '',
  cmpLabel = null,
  cmpTitle = null,
  asSpan = true,
  children,
  hoverColor = '',
  addRightMargin = false,
  addLeftMargin = false,
  tabIndex = null,
  cmpRel = null,
  stopClickEventPropogation = false,
  cmpExpanded = null,
  cmpControlsId = null,
  hideFocusOutline = false,
  setNoOpener = true,
  setNoReferrer = true,
}) => {
  if (isEmpty(to)) {
    to = DEFAULT_ANCHOR
  }
  const isClickHandledByFunction = function () {
    if (!isUndefined(onClick) && onClick !== null) {
      return true
    }

    return false
  }

  const handleClick = function (event, params) {
    if (isClickHandledByFunction()) {
      event.preventDefault()
      if (stopClickEventPropogation) {
        event.stopPropagation()
      }
      onClick(params)
    } else if (to !== DEFAULT_ANCHOR && internal) {
      event.preventDefault()
      if (stopClickEventPropogation) {
        event.stopPropagation()
      }
      pushRoute(to)
    }
  }

  const isLinkOrOnClickDefined = function () {
    let isLinkOrOnClick = false
    if (to === DEFAULT_ANCHOR && isClickHandledByFunction()) {
      isLinkOrOnClick = true
    } else if (to !== DEFAULT_ANCHOR) {
      isLinkOrOnClick = true
    }

    return isLinkOrOnClick
  }

  const addTag = function (currentTags, newTag) {
    if (currentTags === null) {
      return newTag
    }

    return `${currentTags} ${newTag}`
  }

  let className = `base-link ${classNameProp}`
  let rel = null
  let role = null

  if (style !== '') {
    className += ` ${style}`
  }

  if (hoverColor !== '') {
    className += ` ${hoverColor}`
  }

  if (addRightMargin) {
    className += ' base-link--right-margin'
  }

  if (addLeftMargin) {
    className += ' base-link--left-margin'
  }

  if (hideFocusOutline) {
    className += ' btn-hide-focus'
  }

  if (newWin !== null && newWin) {
    cmpTarget = TARGET_BLANK
  }

  if (cmpRel !== null) {
    for (const item of cmpRel) {
      rel = addTag(rel, item)
    }
  }

  if (cmpTarget === TARGET_BLANK) {
    if (setNoOpener) {
      rel = addTag(rel, 'noopener')
    }
    if (setNoReferrer) {
      rel = addTag(rel, 'noreferrer')
    }
  }

  if (isClickHandledByFunction() && to === DEFAULT_ANCHOR) {
    role = ROLES.WIDGET.BUTTON
  }

  if (!isLinkOrOnClickDefined()) {
    return (
      <Cont className={className} asSpan={asSpan}>
        {children}
      </Cont>
    )
  }

  return (
    <a
      href={to}
      className={className}
      tabIndex={tabIndex}
      onClick={handleClick}
      target={cmpTarget}
      rel={rel}
      role={role}
      aria-label={cmpLabel}
      title={cmpTitle}
      aria-expanded={cmpExpanded}
      aria-controls={cmpControlsId}
    >
      {children}
    </a>
  )
}

ABaseLink.propTypes = {
  /** url for the link */
  to: PropTypes.string,
  /** the target for opening the link */
  cmpTarget: PropTypes.oneOf(targets),
  /** should the link open in a new window - overrides cmpTarget */
  newWin: PropTypes.bool,
  /** is link internal - if yes then no server request will be sent - link will be opened by single page app */
  internal: PropTypes.bool,
  /** function to run when clicking the link */
  onClick: PropTypes.func,
  /** wrapper classname for link */
  style: PropTypes.string,
  /** aria-label text for link */
  cmpLabel: PropTypes.string,
  /** title for link */
  cmpTitle: PropTypes.string,
  /** use a span instead of a div to wrap the children - default is trues */
  asSpan: PropTypes.bool,
  /** link label or elements that are being treated as a link */
  children: PropTypes.node,
  /** hover colour of the link */
  hoverColor: PropTypes.oneOf([HOVER_COLOR.ORANGE]),
  /** if true, add a margin to the right */
  addRightMargin: PropTypes.bool,
  /** if true, add a margin to the left */
  addLeftMargin: PropTypes.bool,
  /** tab index for contents */
  tabIndex: PropTypes.number,
  /** rel attribute */
  cmpRel: PropTypes.arrayOf(PropTypes.string),
  /** should click event propogtion be stopped */
  stopClickEventPropogation: PropTypes.bool,
  /** aria-expanded for link */
  cmpExpanded: PropTypes.bool,
  /** aria-controls id for link */
  cmpControlsId: PropTypes.string,
  /** hide focus of the button (default is false) */
  hideFocusOutline: PropTypes.bool,
  /** hide 'noopener' rel attribute */
  setNoOpener: PropTypes.bool,
  /** hide 'noreferrer' rel attribute */
  setNoReferrer: PropTypes.bool,
}

export default ABaseLink
