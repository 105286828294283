import { isEmpty } from 'lodash'

import { fetchGooglePlacesCoordinates } from '../api/googlePlaces'
import {
  searchFavorites,
  searchInternalResults,
  searchInternalSuggestions,
  searchPlaces,
} from '../api/search'
import {
  selectBrandContractsByTerm,
  selectCountriesSuggestions,
  selectIsWebSearchProviderSourceInternal,
} from '../selectors/config'
import { selectFavoritesCacheByTerm } from '../selectors/favorites'
import {
  selectSearchInternalCacheByTerm,
  selectSearchWebCacheByTerm,
} from '../selectors/search'
import {
  batchFetchBackendData,
  receiveData,
  receiveError,
  requestData,
  sendToError,
} from '../utilities/BackendUtils'
import {
  getMainSearchApiObj,
  getMainSearchSuggestionsApiObj,
} from '../utilities/QueryParamUtils'
import { getSearchResultObjFromWebList } from '../utilities/SearchUtils'
import {
  GET_FAVORITES_SEARCH_PLACES,
  GET_QUCK_SEARCH_CLEAR_ALL,
  GET_QUCK_SEARCH_CONTRACT,
  GET_QUCK_SEARCH_INTERNAL,
  GET_QUCK_SEARCH_WEB,
  GET_SEARCH_RESULTS_INIT,
  GET_SEARCH_SUGGESTIONS_INIT,
  UPDATE_SEARCH_TERM,
} from './ActionTypes'

export function getSearchResultsInitData(query, isResolving = false) {
  const initSearchQueryObj = getMainSearchApiObj(query)
  const initSearchSuggestionsQueryObj = getMainSearchSuggestionsApiObj(query)

  if (isEmpty(initSearchQueryObj) && isEmpty(initSearchSuggestionsQueryObj)) {
    return function (dispatch) {
      dispatch(receiveData({}, GET_SEARCH_SUGGESTIONS_INIT))
    }
  }

  if (!query.type) {
    return async function searchSuggestions(dispatch, getState) {
      dispatch(requestData())

      const state = getState()

      try {
        const isInternal = selectIsWebSearchProviderSourceInternal(state)

        const [{ data: suggestions }, places] = await Promise.all([
          searchInternalSuggestions(query),
          searchPlaces({
            isInternal,
            query,
            country: selectCountriesSuggestions(state),
          }),
        ])

        dispatch(
          receiveData(
            [
              selectBrandContractsByTerm(state, query.searchTerm),
              suggestions,
              getSearchResultObjFromWebList(places, isInternal),
            ],
            GET_SEARCH_SUGGESTIONS_INIT,
          ),
        )
      } catch (err) {
        dispatch(receiveError(err.data))
        if (isResolving) {
          sendToError()
        }
      }
    }
  }

  // User selected an item from suggestions - go get results
  if (
    query.type === 'nearby' &&
    query.placeId &&
    (!query.latitude || !query.longitude)
  ) {
    // Nearby search but no latitide or longitude so get coords first
    return async function searchResults(dispatch) {
      dispatch(requestData())

      const coords = await fetchGooglePlacesCoordinates(query.placeId)

      if (coords) {
        query = { ...query, ...coords }
      }

      try {
        const { data } = await searchInternalResults(query)

        dispatch(receiveData([data], GET_SEARCH_RESULTS_INIT))
      } catch (err) {
        dispatch(receiveError(err.data))
        if (isResolving) {
          sendToError()
        }
      }
    }
  }

  const searchTxn = searchInternalResults(query)

  return batchFetchBackendData(
    [searchTxn],
    GET_SEARCH_RESULTS_INIT,
    isResolving,
  )
}

export function getQuickSearchWeb(
  searchTerm,
  isWebSearchProviderSourceInternal = false,
  searchCountries,
) {
  return async function (dispatch, getState) {
    try {
      let results = selectSearchWebCacheByTerm(getState(), searchTerm)

      if (!results) {
        results = await searchPlaces({
          isInternal: isWebSearchProviderSourceInternal,
          query: { searchTerm, rcs: 10 },
          country: searchCountries,
        })
      }

      dispatch(
        receiveData(
          {
            searchTerm,
            results,
          },
          GET_QUCK_SEARCH_WEB,
        ),
      )
    } catch (err) {
      // failing silently to favor search suggestions
      // dispatch(receiveError(err))
    }
  }
}

export function getQuickSearchInternal(searchTerm) {
  return async function (dispatch, getState) {
    try {
      let results = selectSearchInternalCacheByTerm(getState(), searchTerm)

      if (!results) {
        const { data } = await searchInternalSuggestions({ searchTerm }, 10)

        results = data
      }

      dispatch(
        receiveData(
          {
            searchTerm,
            results,
          },
          GET_QUCK_SEARCH_INTERNAL,
        ),
      )
    } catch (err) {
      dispatch(receiveError(err))
    }
  }
}

export function getQuickSearchContract(searchTerm) {
  return function (dispatch, getState) {
    const results = selectBrandContractsByTerm(getState(), searchTerm)

    dispatch(
      receiveData(
        {
          searchTerm,
          results,
        },
        GET_QUCK_SEARCH_CONTRACT,
      ),
    )
  }
}

export function getQuickSearchClearAll() {
  return {
    type: GET_QUCK_SEARCH_CLEAR_ALL,
    data: {},
  }
}

export function updateSearchObj(searchObj) {
  return async function (dispatch) {
    if (searchObj.type === 'web' && searchObj.placeId) {
      const coords = await fetchGooglePlacesCoordinates(searchObj.placeId)

      if (coords) {
        searchObj = { ...searchObj, ...coords }
      }
    }

    dispatch(receiveData(searchObj, UPDATE_SEARCH_TERM))
  }
}

export function getFavoritesSearchPlaces(
  searchTerm,
  searchTermMinLength,
  searchCountries,
  searchProviderId,
  accessToken,
) {
  return async function (dispatch, getState) {
    const isSearchTermValid = searchTerm.length >= searchTermMinLength

    let results = isSearchTermValid
      ? selectFavoritesCacheByTerm(getState(), searchTerm)
      : []

    if (!results) {
      results = await searchFavorites({
        searchTerm,
        searchCountries,
        searchProviderId,
        accessToken,
      })
    }

    dispatch(
      receiveData(
        {
          searchTerm,
          results,
        },
        GET_FAVORITES_SEARCH_PLACES,
      ),
    )
  }
}
