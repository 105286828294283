import PropTypes from 'prop-types'
import React from 'react'

import { htmlBlock, isEmpty } from '../../utilities/GeneralUtils'

/** component for displaying regular text or HTML text.  HTML must be passed in a text string e.g. '<span>string to display</span>'

@example ../../../../docs/external/text/TextBlock.md */

const TextBlock = ({
  isHTML = false,
  content = null,
  className = '',
  isDefaultHTMLStyle = false,
}) => {
  let contentUI = <span />
  if (!isEmpty(content)) {
    if (isHTML) {
      contentUI = (
        <span
          className={
            className + (isDefaultHTMLStyle ? 'default-html-style' : '')
          }
          dangerouslySetInnerHTML={htmlBlock(content)}
        />
      )
    } else {
      contentUI = <span className={className}>{content}</span>
    }
  }

  return contentUI
}

TextBlock.propTypes = {
  /** whether or not the content is HTML content or straight text */
  isHTML: PropTypes.bool,
  /** whether or not the default HTML style should be applied */
  isDefaultHTMLStyle: PropTypes.bool,
  /** the content of the text block - must be a string even if it's HTML. */
  content: PropTypes.string,
  /** wrapper classname for custom styling */
  className: PropTypes.string,
}

export default TextBlock
