import { array, object, string } from 'prop-types'
import React from 'react'

import Translation from '../Translation'
import ErrorBlock from '../shared/ErrorBlock'
import HackHTag from '../shared/text/HackHTag'

const Error = ({ errors, intl }) => (
  <div>
    <HackHTag h1>
      <Translation intl={intl} mkey={'labels_errorpage_oops'} />
    </HackHTag>
    <ErrorBlock errors={errors} intl={intl} />
  </div>
)

Error.propTypes = {
  intl: object.isRequired,
  theme: string.isRequired,
  errors: array.isRequired,
}

export default Error
