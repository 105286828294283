import * as types from '../actions/ActionTypes'
import {
  getApplicationLocalState,
  saveApplicationLocalState,
} from '../utilities/LocalStateUtils'

export const initialState = {
  theme: 'ra',
  error: {
    isError: false,
    errorsData: [],
  },
  s2n: {
    ui: {
      showAbandonCartModal: false,
    },
  },
}

function changeTheme(state = initialState, action) {
  return {
    ...state,
    theme: action.data,
  }
}

function throwError(state = initialState, action) {
  return {
    ...state,
    error: {
      ...state.error,
      isError: true,
      errorsData: action.data,
    },
  }
}

function clearError(state = initialState, action) {
  return {
    ...state,
    error: {
      ...state.error,
      isError: false,
      errorsData: [],
    },
  }
}

function saveLocalState(state = initialState, action) {
  console.log('saveLocalState Application')

  return saveApplicationLocalState(state)
}

function getLocalState(state = initialState, action) {
  console.log('getLocalState Application')

  return getApplicationLocalState(state)
}

function s2nToggleAbandonCartModal(state = initialState, action) {
  return {
    ...state,
    s2n: {
      ...state.s2n,
      ui: {
        ...state.s2n.ui,
        showAbandonCartModal: !state.s2n.ui.showAbandonCartModal,
      },
    },
  }
}

export default function application(state = initialState, action) {
  switch (action.type) {
    case types.CHANGE_THEME:
      return changeTheme(state, action)
    case types.THROW_ERROR:
      return throwError(state, action)
    case types.CLEAR_ERROR:
      return clearError(state, action)
    case types.SAVE_LOCAL_STATE:
      return saveLocalState(state, action)
    case types.GET_LOCAL_STATE:
      return getLocalState(state, action)
    case types.S2N_TOGGLE_ABANDON_CART_MODAL:
      return s2nToggleAbandonCartModal(state, action)
    default:
      return clearError(state, action)
  }
}
