import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { selectIntl } from '../../../selectors/intl'
import { translate } from '../../Translation'

export function useTranslation() {
  const intl = useSelector(selectIntl)

  return useCallback(
    (...args) => {
      return translate(intl, ...args)
    },
    [intl],
  )
}
