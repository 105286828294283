import axios from 'axios'

import { getCookie, setCookie } from '../utilities/CookiesUtils'

/* 
  Auth data expires each 30 min. This buffer makes us refresh auth data 
  when it is {EXPIRE_BUFFER} ms earlier before the token is outdated.
*/
const EXPIRE_BUFFER = 10 * 60 * 1000

let localIdToken = getCookie('idToken')
let localA1Data = getCookie('a1Data')

let refreshPromise

const parseJwt = (token) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((char) => {
        return `%${`00${char.charCodeAt(0).toString(16)}`.slice(-2)}`
      })
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

const isA1DataExpired = (a1Data) => {
  return Date.now() + EXPIRE_BUFFER > a1Data.date + a1Data.maxInactive
}

const isTokenExpired = (token) => {
  if (!token) return true

  let jwt

  try {
    jwt = parseJwt(token)

    return Date.now() + EXPIRE_BUFFER > jwt.exp * 1000
  } catch (err) {
    console.warn('token is expired', localIdToken, 'err', err, +'exp', jwt?.exp)

    return true
  }
}

const refreshAuth = async () => {
  if (!refreshPromise) {
    const payload = {
      a1Data: localA1Data,
      refreshToken: getCookie('ra2Refresh') || null,
    }

    // Prevent parallel requests triggering multiple refresh auth requests
    refreshPromise = axios.post('/refreshAuth', payload)
  }

  const res = await refreshPromise

  refreshPromise = null

  const { idToken, refreshToken, a1Data } = res.data

  localIdToken = idToken

  // Set cookie only if it's an authenticated user.
  if (refreshToken) {
    setCookie('idToken', idToken || '')
    setCookie('ra2Refresh', refreshToken || '')
  }

  if (a1Data) {
    localA1Data = a1Data
    setCookie('a1Data', JSON.stringify(a1Data))
  }
}

export const refreshTokens = (idToken, refreshToken) => {
  setCookie('idToken', idToken || '')
  setCookie('ra2Refresh', refreshToken || '')

  localIdToken = idToken
}

export const setupAxiosInterceptors = () => {
  axios.interceptors.request.use(
    async (req) => {
      if (req.url.includes('refreshAuth')) {
        // Prevent infinite recursion
        return req
      }

      if (refreshPromise) await refreshPromise

      if (isTokenExpired(localIdToken) || isA1DataExpired(localA1Data)) {
        console.warn('auth has expired, refreshing...')

        await refreshAuth()
      }

      req.headers.Authorization = localIdToken
      req.headers.A1Data = JSON.stringify(localA1Data)

      return req
    },
    (err) => {
      console.error('debug:err', err)
    },
  )
}
