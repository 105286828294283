import PropTypes from 'prop-types'
import React, { useState } from 'react'
import AccordionCollapse from 'react-bootstrap/AccordionCollapse'

/** Active default Card built off of react-bootstrap Card -  https://react-bootstrap.github.io/components.html#Cards.

@example ../../../../docs/external/container/APanel.md */

const APanel = ({
  id,
  className,
  children,
  eventKey,
  header,
  onSelect,
  collapsible,
  defaultExpanded = false,
  expanded: externalExpanded,
}) => {
  const [internalExpanded, setInternalExpanded] = useState(defaultExpanded)

  const isControlled = externalExpanded !== undefined
  const isExpanded = isControlled ? externalExpanded : internalExpanded

  const handleClick = () => {
    if (!collapsible) return

    if (onSelect) onSelect()

    if (!isControlled) setInternalExpanded(!internalExpanded)
  }

  return (
    <div id={id} className={`panel panel-default ${className}`}>
      {!!header && (
        <div className="panel-heading" onClick={handleClick}>
          <h4 role="presentation" className="panel-title">
            <a
              aria-expanded={String(isExpanded)}
              aria-selected={String(isExpanded)}
              className="collapsed"
            >
              {header}
            </a>
          </h4>
        </div>
      )}
      <AccordionCollapse eventKey={eventKey} in={isExpanded}>
        <div className="panel-body">{children}</div>
      </AccordionCollapse>
    </div>
  )
}

APanel.propTypes = {
  /** content of the Card */
  children: PropTypes.node.isRequired,
  /** id to identify the Card (used by accordion to decide which one to open and close) */
  eventKey: PropTypes.any,
  /** header summary for the Card */
  header: PropTypes.string,
  /** whether or not Card is expandable */
  collapsible: PropTypes.bool,
  /** current expanded state */
  expanded: PropTypes.bool,
  /** whether or not Card is expanded on load */
  defaultExpanded: PropTypes.bool,
  /** custom class to apply to the Card */
  className: PropTypes.string,
  /** function to run when Card is selected */
  onSelect: PropTypes.func,
  /** id from aria-controls. Do not use for other purpose */
  id: PropTypes.string,
}

export default APanel
