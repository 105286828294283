import { differenceInSeconds } from 'date-fns'

import { getClientToday } from '../external/utilities/DateUtils'
import { isEmpty, isUndefined } from '../external/utilities/GeneralUtils'

export const TTL_LONG_TERM_EXTREME = 3600
export const TTL_LONG_TERM = 1800
export const TTL_SHORT_TERM = 300
export const TTL_SHORT_TERM_EXTREME = 40

class BackendCache {
  constructor() {
    this.clear()
    this.config = {
      shouldCache: true,
      maxEntries: 12,
      logging: false,
      logMainVars: true,
    }
  }

  clear() {
    this.cache = {}
    this.cacheLookup = []
  }

  isCacheEmpty() {
    return isEmpty(this.cache)
  }

  addData(keyObj, data, ttl = TTL_LONG_TERM) {
    if (this.config.shouldCache) {
      const key = JSON.stringify(keyObj)
      const currentValue = this.getData(keyObj, true)
      const isNewValue = this.isNewKey(key)

      let trimmed = false

      // trim if adding a new value
      if (isNewValue) {
        trimmed = this.trimCacheIfRequired()
        this.cacheLookup.push(key)
      }
      this.cache[key] = BackendCache.generateValueObject(data, ttl)

      // eslint-disable-next-line one-var
      const logEntries = []
      logEntries.push(BackendCache.getLg('key for Cache: ', key))
      logEntries.push(BackendCache.getLg('Current value: ', currentValue))
      logEntries.push(BackendCache.getLg('New Entry in Cache: ', isNewValue))
      logEntries.push(BackendCache.getLg('Cache length was trimmed: ', trimmed))
      this.log('Adding Data to Cache', logEntries)
    }
  }

  getData(key, internalCall = false) {
    if (!this.config.shouldCache) {
      return null
    }
    let data = null
    let stale = false
    const currentValue = this.cache[JSON.stringify(key)]
    const isCurrentValue = !isUndefined(currentValue) && !isEmpty(currentValue)

    if (isCurrentValue) {
      if (this.isStale(currentValue, internalCall) && !internalCall) {
        // this.removeDataFromCache(key)
        stale = true
      } else {
        ;({ data } = currentValue)
      }
    }

    const logEntries = []
    logEntries.push(BackendCache.getLg('key for Cache: ', key))
    logEntries.push(BackendCache.getLg('Was stale: ', stale))
    logEntries.push(BackendCache.getLg('Data: ', data))
    if (!internalCall) {
      this.log('Getting Data from Cache', logEntries)
    }

    return data
  }

  getCache() {
    return this.cache
  }

  removeDataFromCache(key) {
    delete this.cache[key]
  }

  isStale(valueObj, internalCall = false) {
    const now = getClientToday()
    const elapsedTime = differenceInSeconds(now, valueObj.date)

    if (!internalCall) {
      const logEntries = []
      logEntries.push(BackendCache.getLg('Set TTL: ', valueObj.ttl))
      logEntries.push(BackendCache.getLg('Elapsed time: ', elapsedTime))
      this.log('Checking Stale', logEntries)
    }

    if (elapsedTime > valueObj.ttl) {
      return true
    }

    return false
  }

  trimCacheIfRequired() {
    let trimmed = false
    if (this.cacheLookup.length >= this.config.maxEntries) {
      const deleteElement = this.cacheLookup.shift()
      this.removeDataFromCache(deleteElement)
      trimmed = true
    }

    return trimmed
  }

  isNewKey(key) {
    let isNew = true
    for (const currKey of this.cacheLookup) {
      if (currKey === key) {
        isNew = false
        break
      }
    }

    return isNew
  }

  static generateValueObject(data, ttl) {
    const valueObj = {}
    const date = getClientToday()

    valueObj.data = data
    valueObj.ttl = ttl
    valueObj.date = date

    return valueObj
  }

  log(header, entries) {
    if (this.config.logging) {
      if (this.config.logMainVars) {
        entries.push(BackendCache.getLg('Cache: ', this.cache))
        entries.push(BackendCache.getLg('Cache Lookup: ', this.cacheLookup))
      }

      /* eslint-disable no-console */
      console.log('**********************************')
      console.log('*         Cache Log Start        *')
      console.log('**********************************')
      console.log(`******** ${header} ********`)
      for (const entry of entries) {
        console.log(`**** ${entry.name}`, entry.value)
      }
      console.log('**********************************')
      console.log('*              End               *')
      console.log('**********************************')
      /* eslint-enable no-console */
    }
  }

  static getLg(name, value) {
    return {
      name,
      value,
    }
  }
}

// Singleton Instance.
let instance = null

// eslint-disable-next-line one-var
const getInstance = function () {
  if (!instance) {
    instance = new BackendCache()
  }

  return instance
}

// Exposed methods
export default { get: getInstance }
