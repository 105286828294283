import lodash from 'lodash'
import { v4 as uuidv4 } from 'uuid'

const DOMPurify = require('dompurify')
/**
 * These utilities wrap the lodash implementations.
 */

/* LANG */
/*
Checks if value is an empty collection or object. A value is considered empty if it’s an arguments object, array, string,
or jQuery-like collection with a length of 0 or has no own enumerable properties.
*/
export function isEmpty(obj) {
  let emp = true
  try {
    if (!isEmptyObj(obj)) {
      emp = false
    }
  } catch (e) {
    // may be empty
  }

  return emp
}

export function isDate(obj) {
  let date = false
  try {
    if (obj instanceof Date) {
      console.log('instanceof Date')
      date = true
    }
  } catch (e) {
    // may be empty
  }

  return date
}

export function isUndefined(obj) {
  if (typeof obj === 'undefined') {
    return true
  }

  return false
}

export function isEmptyObj(obj) {
  return lodash.isEmpty(obj)
}

/* the above will not cover the case where isEmpty({}) is required to return true */
export function isObjectEmpty(obj) {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false
    }
  }

  return true && JSON.stringify(obj) === JSON.stringify({})
}

/*
Not empty or is a number
*/
export function isValue(val) {
  return !isEmpty(val) || lodash.isNumber(val)
}

/*
Is a Number
*/
export function isNumber(val) {
  return lodash.isNumber(val)
}

/*
Is String
*/
export function isString(val) {
  return lodash.isString(val)
}

/*
Is boolean
*/
export function isBoolean(val) {
  return lodash.isBoolean(val)
}

/*
Is Null
*/
export function isNull(val) {
  return lodash.isNull(val)
}

/*
Is Array
*/
export function isArray(val) {
  return Array.isArray(val)
}

/* OBJECT */

/*
Return a clone of the object argument
*/
export function clone(obj) {
  return { ...obj }
}

export function cloneDeep(obj) {
  return lodash.cloneDeep(obj)
}

export function cloneDeepJSON(jsonData) {
  return JSON.parse(JSON.stringify(jsonData))
}

export function cloneArray(arr) {
  return isEmpty(arr) ? arr : [...arr]
}

/*
Merges two objects - https://lodash.com/docs/#merge
*/
export function merge(obj1, obj2) {
  return lodash.merge(obj1, obj2)
}

/* ARRAYS */
/* Creates an array of elements split into groups the length of size. If array can’t be split evenly, the final chunk will be the remaining elements. */
export function chunk(array, size) {
  return lodash.chunk(array, size)
}

/* Creates an array of unique array values not included in the other given arrays using SameValueZero for equality comparisons. The order of result values is determined by the order they occur in the first array.
 */
export function difference(array, values) {
  return lodash.difference(array, values)
}

export function isNumberEven(num) {
  return num % 2 === 0
}

export function getDecimalPlacesFromNumString(numStr) {
  console.log('Math.floor(num)', Math.floor(numStr), numStr)
  if (Math.floor(numStr).toString() !== numStr) {
    return numStr.toString().split('.')[1].length || 0
  }

  return 0
}

export function positiveToNegative(num) {
  return -Math.abs(num)
}

/* Iterates over elements of collection, returning the first element predicate returns truthy for. The predicate is invoked with three arguments:
(value, index|key, collection).
 
Arguments
collection (Array|Object): The collection to search.
[predicate=lodash.identity] (Function|Object|string): The function invoked per iteration.
 
The `lodash.matches` iteratee shorthand.
find(users, { 'age': 1, 'active': true })
 
The `lodash.matchesProperty` iteratee shorthand.
find(users, ['active', false])
 
The `lodash.property` iteratee shorthand.
find(users, 'active')
 
*/
export function find(arrayOfObjects, predicate) {
  return lodash.find(arrayOfObjects, predicate)
}

/* This method is like lodash.find except that it returns the index of the first element predicate returns truthy for instead of the element itself. */
export function findIndex(arrayOfObjects, predicate) {
  return lodash.findIndex(arrayOfObjects, predicate)
}

/* finds an object having the property named IdName equal to the value of id. */
export function findObjectInArray(array, idName, id) {
  let foundObj = null
  if (array && array != null) {
    for (let i = 0; i < array.length; i++) {
      if (array[i][idName] === id) {
        foundObj = array[i]
        break
      }
    }
  }

  return foundObj
}

/* Removes all given values from array using SameValueZero for equality comparisons.
returns an array of the removed items and modifies the supplied array. */
export function pull(array, ...values) {
  return lodash.pull(array, ...values)
}

/*
Creates a slice of array from start up to, but not including, end.
*/
export function slice(array, start, end) {
  return lodash.slice(array, start, end)
}

/*
Array Includes value
*/
export function contains(array, value) {
  let ret = false
  if (!isUndefined(array)) {
    for (const item of array) {
      if (item === value) {
        ret = true
        break
      }
    }
  }

  return ret
}

/*
Trim array to max sixe
*/
export function trimArray(array, maxElements, removeFromTop = true) {
  if (array.length > maxElements) {
    const numElementsToRemove = array.length - maxElements
    let startIndex = 0
    if (!removeFromTop) {
      startIndex = maxElements
    }
    array.splice(startIndex, numElementsToRemove)
  }
}

/* STRINGS */
/*
Format a String as Title Case.
*/
export function toTitleCase(sentance, isForceTitleCase = false) {
  const lower = sentance.toLowerCase()
  if (!sentance) {
    return
  }

  if (sentance !== sentance.toUpperCase() && !isForceTitleCase) {
    return sentance
  }

  const smallWords =
    /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|vs?\.?|via)$/i

  return lower.replace(
    /[A-Za-z0-9\u00C0-\u00FF]+[^\.\s-]*/g,
    (match, index, title) => {
      if (
        index > 0 &&
        index + match.length !== title.length &&
        match.search(smallWords) > -1 &&
        title.charAt(index - 2) !== ':' &&
        (title.charAt(index + match.length) !== '-' ||
          title.charAt(index - 1) === '-') &&
        title.charAt(index - 1).search(/[^\s-]/) < 0
      ) {
        return match.toLowerCase()
      }
      if (match.substr(1).search(/[A-Z]|\../) > -1) {
        return match
      }

      return match.charAt(0).toUpperCase() + match.substr(1)
    },
  )
}

export function replace(original, pattern, replacement) {
  return original.replace(pattern, replacement)
}

export function replaceAll(str, find, replacement) {
  return str.split(find).join(replacement)
}

export function stripHTML(str) {
  return DOMPurify.sanitize(str, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] })
}

export function shortenText(str, limit, useWordBoundary) {
  const toLong = str.length > limit
  let s_ = toLong ? str.substr(0, limit - 1) : str
  s_ = useWordBoundary && toLong ? s_.substr(0, s_.lastIndexOf(' ')) : s_
  const shortenedStr = toLong ? `${s_}...` : s_

  return shortenedStr
}

export function excerpt(str, limit, useWordBoundary) {
  const toLong = str.length > limit
  let s_ = toLong ? str.substr(0, limit - 1) : str
  s_ = useWordBoundary && toLong ? s_.substr(0, s_.lastIndexOf(' ')) : s_
  const shortenedStr = toLong ? `${s_}...` : s_
  if (toLong) {
    return {
      excerpt: shortenedStr,
      remaining: str.substr(s_.length, str.length),
    }
  }

  return {
    excerpt: shortenedStr,
    remaining: null,
  }
}

/* Test if running in client agent or server */
export function isClientAgent() {
  if (typeof window === 'undefined') {
    return false
  }

  return true
}

function isStorageAvailable(type) {
  let available = false
  const test = 'test'
  if (isClientAgent()) {
    try {
      window[type].setItem(test, test)
      window[type].removeItem(test)
      available = true
    } catch (e) {
      available = false
    }
  } else {
    available = false
  }

  return available
}

/* Test if localstorage is available */
export function isLocalStorage() {
  return isStorageAvailable('localStorage')
}

export function isSessionStorage() {
  return isStorageAvailable('sessionStorage')
}

export function htmlBlock(htmlContent) {
  if (htmlContent) {
    return { __html: htmlContent }
  }

  return { __html: 'missing note content' }
}

export function formatMinutesAsTime(minutes) {
  if (isEmpty(minutes)) {
    return ''
  }
  const hourOfDay = Math.floor(parseInt(minutes, 10) / 60)
  let ampm
  let resultHour = hourOfDay
  if (hourOfDay < 12) {
    ampm = ' AM'
  } else {
    if (resultHour > 12) {
      resultHour = hourOfDay - 12
    }
    ampm = ' PM'
  }

  const minuteOfHour = Math.floor(parseInt(minutes, 10) % 60)
  let minuteOfHourStr = minuteOfHour.toString()
  if (minuteOfHourStr.length < 2) {
    minuteOfHourStr = `0${minuteOfHourStr}`
  }

  return `${resultHour}:${minuteOfHourStr}${ampm}`
}

export function yesToY(value) {
  if (!isEmpty(value) && value.toLowerCase === 'yes') {
    return 'Y'
  }

  return value
}

export function omit(mainObj, omitArray) {
  return lodash.omit(mainObj, omitArray)
}

export function numberWithCommas(x) {
  const parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return parts.join('.')
}

export function add(augend, addend) {
  // return lodash.add(augend, addend)
  return augend + addend
}

export function subtract(minuend, subtrahend) {
  // return lodash.subtract(minuend, subtrahend)
  return minuend - subtrahend
}

export function divide(dividend, divisor) {
  // return lodash.divide(dividend, divisor)
  return dividend / divisor
}

export function multiply(multiplier, multiplicand) {
  // return lodash.multiply(multiplier, multiplicand)
  return multiplier * multiplicand
}

export function generateUUID() {
  return uuidv4()
}

export function formatCurrency(strAmount, currencySymbol = '$') {
  let result = strAmount
  const strLength = strAmount.length
  const decimalIndex = strAmount.indexOf('.')
  if (decimalIndex === -1 || decimalIndex === strLength - 1) {
    result += '.00'
  } else if (decimalIndex === strAmount.length - 2) {
    result += '0'
  } else if (decimalIndex === strAmount.length - 4) {
    result = result.substring(0, strAmount.length - 1)
  }

  return currencySymbol + result
}

export function convertNullString(stringValue, convertToValue = 'n/a') {
  if (stringValue === null || stringValue === '') {
    return convertToValue
  }

  return stringValue
}

export function convertNullNumber(numberValue, convertToValue = 0) {
  if (numberValue === null) {
    return convertToValue
  }

  return numberValue
}

export function convertNullCurrency(
  currencyValue,
  convertToValue = '0',
  currencySymbol = '$',
) {
  if (currencyValue === null) {
    return formatCurrency(convertToValue, currencySymbol)
  }

  return currencyValue
}

export function uriEncode(uri) {
  return encodeURI(uri)
}

export function uriDecode(uri) {
  return decodeURI(uri)
}

export function replaceNonAlphaNumericAndSpace(str, replacement = '') {
  str = str.replace(/[^a-z0-9\s-]/gi, replacement)

  return str
}

export function uriFriendlyString(str) {
  str = replaceNonAlphaNumericAndSpace(str)
  str = str.split(' ').join('-').toLowerCase()

  return str
}

const htmlEntities = {
  "'": '&apos;',
  '<': '&lt;',
  '>': '&gt;',
  ' ': '&nbsp;',
  '¡': '&iexcl;',
  '¢': '&cent;',
  '£': '&pound;',
  '¤': '&curren;',
  '¥': '&yen;',
  '¦': '&brvbar;',
  '§': '&sect;',
  '¨': '&uml;',
  '©': '&copy;',
  ª: '&ordf;',
  '«': '&laquo;',
  '¬': '&not;',
  '®': '&reg;',
  '¯': '&macr;',
  '°': '&deg;',
  '±': '&plusmn;',
  '²': '&sup2;',
  '³': '&sup3;',
  '´': '&acute;',
  µ: '&micro;',
  '¶': '&para;',
  '·': '&middot;',
  '¸': '&cedil;',
  '¹': '&sup1;',
  º: '&ordm;',
  '»': '&raquo;',
  '¼': '&frac14;',
  '½': '&frac12;',
  '¾': '&frac34;',
  '¿': '&iquest;',
  À: '&Agrave;',
  Á: '&Aacute;',
  Â: '&Acirc;',
  Ã: '&Atilde;',
  Ä: '&Auml;',
  Å: '&Aring;',
  Æ: '&AElig;',
  Ç: '&Ccedil;',
  È: '&Egrave;',
  É: '&Eacute;',
  Ê: '&Ecirc;',
  Ë: '&Euml;',
  Ì: '&Igrave;',
  Í: '&Iacute;',
  Î: '&Icirc;',
  Ï: '&Iuml;',
  Ð: '&ETH;',
  Ñ: '&Ntilde;',
  Ò: '&Ograve;',
  Ó: '&Oacute;',
  Ô: '&Ocirc;',
  Õ: '&Otilde;',
  Ö: '&Ouml;',
  '×': '&times;',
  Ø: '&Oslash;',
  Ù: '&Ugrave;',
  Ú: '&Uacute;',
  Û: '&Ucirc;',
  Ü: '&Uuml;',
  Ý: '&Yacute;',
  Þ: '&THORN;',
  ß: '&szlig;',
  à: '&agrave;',
  á: '&aacute;',
  â: '&acirc;',
  ã: '&atilde;',
  ä: '&auml;',
  å: '&aring;',
  æ: '&aelig;',
  ç: '&ccedil;',
  è: '&egrave;',
  é: '&eacute;',
  ê: '&ecirc;',
  ë: '&euml;',
  ì: '&igrave;',
  í: '&iacute;',
  î: '&icirc;',
  ï: '&iuml;',
  ð: '&eth;',
  ñ: '&ntilde;',
  ò: '&ograve;',
  ó: '&oacute;',
  ô: '&ocirc;',
  õ: '&otilde;',
  ö: '&ouml;',
  '÷': '&divide;',
  ø: '&oslash;',
  ù: '&ugrave;',
  ú: '&uacute;',
  û: '&ucirc;',
  ü: '&uuml;',
  ý: '&yacute;',
  þ: '&thorn;',
  ÿ: '&yuml;',
  Œ: '&OElig;',
  œ: '&oelig;',
  Š: '&Scaron;',
  š: '&scaron;',
  Ÿ: '&Yuml;',
  ƒ: '&fnof;',
  ˆ: '&circ;',
  '˜': '&tilde;',
  Α: '&Alpha;',
  Β: '&Beta;',
  Γ: '&Gamma;',
  Δ: '&Delta;',
  Ε: '&Epsilon;',
  Ζ: '&Zeta;',
  Η: '&Eta;',
  Θ: '&Theta;',
  Ι: '&Iota;',
  Κ: '&Kappa;',
  Λ: '&Lambda;',
  Μ: '&Mu;',
  Ν: '&Nu;',
  Ξ: '&Xi;',
  Ο: '&Omicron;',
  Π: '&Pi;',
  Ρ: '&Rho;',
  Σ: '&Sigma;',
  Τ: '&Tau;',
  Υ: '&Upsilon;',
  Φ: '&Phi;',
  Χ: '&Chi;',
  Ψ: '&Psi;',
  Ω: '&Omega;',
  α: '&alpha;',
  β: '&beta;',
  γ: '&gamma;',
  δ: '&delta;',
  ε: '&epsilon;',
  ζ: '&zeta;',
  η: '&eta;',
  θ: '&theta;',
  ι: '&iota;',
  κ: '&kappa;',
  λ: '&lambda;',
  μ: '&mu;',
  ν: '&nu;',
  ξ: '&xi;',
  ο: '&omicron;',
  π: '&pi;',
  ρ: '&rho;',
  ς: '&sigmaf;',
  σ: '&sigma;',
  τ: '&tau;',
  υ: '&upsilon;',
  φ: '&phi;',
  χ: '&chi;',
  ψ: '&psi;',
  ω: '&omega;',
  ϑ: '&thetasym;',
  ϒ: '&Upsih;',
  ϖ: '&piv;',
  '–': '&ndash;',
  '—': '&mdash;',
  '‘': '&lsquo;',
  '’': '&rsquo;',
  '‚': '&sbquo;',
  '“': '&ldquo;',
  '”': '&rdquo;',
  '„': '&bdquo;',
  '†': '&dagger;',
  '‡': '&Dagger;',
  '•': '&bull;',
  '…': '&hellip;',
  '‰': '&permil;',
  '′': '&prime;',
  '″': '&Prime;',
  '‹': '&lsaquo;',
  '›': '&rsaquo;',
  '‾': '&oline;',
  '⁄': '&frasl;',
  '€': '&euro;',
  ℑ: '&image;',
  '℘': '&weierp;',
  ℜ: '&real;',
  '™': '&trade;',
  ℵ: '&alefsym;',
  '←': '&larr;',
  '↑': '&uarr;',
  '→': '&rarr;',
  '↓': '&darr;',
  '↔': '&harr;',
  '↵': '&crarr;',
  '⇐': '&lArr;',
  '⇑': '&UArr;',
  '⇒': '&rArr;',
  '⇓': '&dArr;',
  '⇔': '&hArr;',
  '∀': '&forall;',
  '∂': '&part;',
  '∃': '&exist;',
  '∅': '&empty;',
  '∇': '&nabla;',
  '∈': '&isin;',
  '∉': '&notin;',
  '∋': '&ni;',
  '∏': '&prod;',
  '∑': '&sum;',
  '−': '&minus;',
  '∗': '&lowast;',
  '√': '&radic;',
  '∝': '&prop;',
  '∞': '&infin;',
  '∠': '&ang;',
  '∧': '&and;',
  '∨': '&or;',
  '∩': '&cap;',
  '∪': '&cup;',
  '∫': '&int;',
  '∴': '&there4;',
  '∼': '&sim;',
  '≅': '&cong;',
  '≈': '&asymp;',
  '≠': '&ne;',
  '≡': '&equiv;',
  '≤': '&le;',
  '≥': '&ge;',
  '⊂': '&sub;',
  '⊃': '&sup;',
  '⊄': '&nsub;',
  '⊆': '&sube;',
  '⊇': '&supe;',
  '⊕': '&oplus;',
  '⊗': '&otimes;',
  '⊥': '&perp;',
  '⋅': '&sdot;',
  '⌈': '&lceil;',
  '⌉': '&rceil;',
  '⌊': '&lfloor;',
  '⌋': '&rfloor;',
  '⟨': '&lang;',
  '⟩': '&rang;',
  '◊': '&loz;',
  '♠': '&spades;',
  '♣': '&clubs;',
  '♥': '&hearts;',
  '♦': '&diams;',
}

export function dangerouslyDecodeHTMLEntity(string) {
  const entityMap = htmlEntities
  for (const key in entityMap) {
    const entity = entityMap[key]
    const regex = new RegExp(entity, 'g')
    string = string.replace(regex, key)
  }
  string = string.replace(/&quot;/g, '"')
  string = string.replace(/&amp;/g, '&')

  return string
}

export function encodeHTMLEntity(string) {
  const entityMap = htmlEntities
  string = string.replace(/&/g, '&amp;')
  string = string.replace(/"/g, '&quot;')
  for (const key in entityMap) {
    const entity = entityMap[key]
    const regex = new RegExp(key, 'g')
    string = string.replace(regex, entity)
  }

  return string
}

export function isStringContains(str, matchStr) {
  if (str.indexOf(matchStr) > -1) {
    return true
  }

  return false
}

export function findPairPosition(strings, openingTag, closingTag, pairCount) {
  let openingCount = 0
  let closingCount = 0
  let position = 0

  for (const string of strings) {
    if (isStringContains(string, openingTag)) {
      openingCount += 1
    } else if (isStringContains(string, closingTag)) {
      closingCount += 1
      if (pairCount === openingCount && pairCount === closingCount) {
        break
      }
    }
    position += 1
  }

  return position
}

export function insertString(mainStr, insertStr, pos) {
  return [mainStr.slice(0, pos + 1), insertStr, mainStr.slice(pos + 1)].join('')
}

// Provided by vendor for mobile SMS. DO NOT USE for any other purpose.
export function hackIsMobile() {
  let check = false
  ;(function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od|ad)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    ) {
      check = true
    }
  })(navigator.userAgent || navigator.vendor || window.opera)

  return check
}

export function hackIsIE() {
  if (
    navigator.userAgent.indexOf('MSIE') > -1 ||
    navigator.userAgent.indexOf('Trident') > -1
  ) {
    return true
  }

  return false
}

export function nonNull(obj) {
  if (isEmpty(obj) || obj === 'null') {
    return ''
  }

  return obj
}

export function isMatchSingleLevelObject(o1, o2) {
  for (const p in o1) {
    if (o1.hasOwnProperty(p)) {
      if (o1[p] !== o2[p]) {
        return false
      }
    }
  }
  for (const p in o2) {
    if (o2.hasOwnProperty(p)) {
      if (o1[p] !== o2[p]) {
        return false
      }
    }
  }

  return true
}

export default {
  isEmpty,
  isObjectEmpty,
  isValue,
  isNumber,
  isNull,
  clone,
  chunk,
  difference,
  find,
  findIndex,
  findObjectInArray,
  pull,
  slice,
  toTitleCase,
  replace,
  replaceAll,
  shortenText,
  stripHTML,
  isClientAgent,
  isLocalStorage,
  contains,
  excerpt,
  formatMinutesAsTime,
  yesToY,
}
