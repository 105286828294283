import { isEmpty, isUndefined } from '../external/utilities/GeneralUtils'
import { getPathWithoutQuery, getQuery } from '../utilities/ServerUtils'
import { getObjFromStorage } from './LocalStateUtils'
import {
  addObjToQueryString,
  getLatitudeObj,
  getLongitudeObj,
  getSearchTypeObj,
  mergeQueries,
} from './QueryParamUtils'

const GEOLOCATION_PARAM = 'geoLocation'

// eslint-disable-next-line one-var
export const isLinkDynamic = function (link) {
  return !isEmpty(link) && link.includes(`{${GEOLOCATION_PARAM}}`)
}

export const addDynamicParamsToURL = function (
  link,
  paramsObj = {
    lat: null,
    long: null,
  },
) {
  if (link.includes(GEOLOCATION_PARAM)) {
    const actualPath = link.replace(`{${GEOLOCATION_PARAM}}`, '')

    if (isUndefined(paramsObj.lat) || isUndefined(paramsObj.long)) {
      return actualPath
    }

    // eslint-disable-next-line one-var
    const latObj = getLatitudeObj(paramsObj.lat),
      longObj = getLongitudeObj(paramsObj.long),
      sTypeObj = getSearchTypeObj()

    let queryString = getQuery(actualPath)
    if (!isEmpty(queryString)) {
      queryString = `?${queryString}`
    }

    // eslint-disable-next-line one-var
    const query = {}
    mergeQueries([latObj, longObj, sTypeObj], query)

    // eslint-disable-next-line one-var
    const newQueryStr = addObjToQueryString(query, queryString),
      pt = getPathWithoutQuery(actualPath) + newQueryStr

    return pt
  }

  return '#'
}

export function addDynamicParamsFromStorage(link, config) {
  if (config?.source && config?.valueKeyParamMap) {
    const { source, valueKeyParamMap } = config

    const data = getObjFromStorage(source.name, source.key)

    if (!data) return link

    const storageQuery = valueKeyParamMap.reduce((acc, { param, valueKey }) => {
      if (data?.[valueKey]) acc[param] = data[valueKey]

      return acc
    }, {})

    let query = getQuery(link)

    if (!isEmpty(query)) query = `/?${query}`

    query = addObjToQueryString(storageQuery, query)

    return getPathWithoutQuery(link) + query
  }

  return link
}
