import ReactGA from 'react-ga'
import ReactGA4 from 'react-ga4'
import root from 'window-or-global'

import { isEmpty, isUndefined } from '../external/utilities/GeneralUtils'
import { isEnvProd } from './AppUtils'

export const EVENTS = {
  /* Find Availability */
  findAvailabilityMainSearch: {
    category: 'findAvailability',
    action: 'findNextAvailableMainSearch',
  },
  findAvailabilityFacility: {
    category: 'findAvailability',
    action: 'findNextAvailableFacility',
  },
  findAvailabilitySite: {
    category: 'findAvailability',
    action: 'findNextAvailableSite',
  },
  findAvailabilityCheckAvail: {
    category: 'findAvailability',
    action: 'checkAvailability',
  },
}

// eslint-disable-next-line one-var
const logUA = true,
  logGA4 = true

export function initAnalytics(googleAnalyticsKey, googleAnalytics4Key) {
  const debug = !isEnvProd()
  if (logUA) {
    ReactGA.initialize(googleAnalyticsKey, {
      debug,
      titleCase: false,
    })

    ReactGA.plugin.require('ecommerce')
  }

  if (logGA4) {
    ReactGA4.initialize([{ trackingId: googleAnalytics4Key }])
  }
}

export function logECommerce(
  gaECommerceObj = null,
  bookingType = 'A1',
  checkoutId = null,
  agencyName = null,
) {
  if (bookingType === 'A1') {
    if (logUA) {
      if (!isEmpty(gaECommerceObj)) {
        for (const currentTransaction of gaECommerceObj.transactions) {
          ReactGA.plugin.execute(
            'ecommerce',
            'addTransaction',
            currentTransaction.transaction,
          )

          for (const item of currentTransaction.items) {
            ReactGA.plugin.execute('ecommerce', 'addItem', item)
          }
        }
        ReactGA.plugin.execute('ecommerce', 'send')
        ReactGA.plugin.execute('ecommerce', 'clear')
      }
    }

    if (logGA4) {
      if (!isEmpty(gaECommerceObj)) {
        for (const currentTransaction of gaECommerceObj.transactionsGA4) {
          const transactionEvent = {
            ...currentTransaction.transaction,
            booking_type: bookingType,
          }
          ReactGA4.event('purchase', transactionEvent)
          console.log('logging ecommerce GA4 - ', transactionEvent)
        }
      }
    }
  } else if (!isEmpty(gaECommerceObj)) {
    const transactionEvent = {
      transaction_id: checkoutId,
      affiliation: agencyName,
      currency: 'USD',
      value: gaECommerceObj.total,
      items: [
        {
          item_id: gaECommerceObj.park?.id,
          item_name: gaECommerceObj.park?.name,
          item_category: gaECommerceObj.spot?.category?.name,
          price: gaECommerceObj.total /* total - taxesAndFees ?? */,
          quantity: 1,
        },
      ],
      booking_type: bookingType,
    }

    if (logUA) {
      ReactGA.plugin.execute('ecommerce', 'addTransaction', transactionEvent)
      for (const item of transactionEvent.items) {
        ReactGA.plugin.execute('ecommerce', 'addItem', item)
      }
      ReactGA.plugin.execute('ecommerce', 'send')
      ReactGA.plugin.execute('ecommerce', 'clear')
    }

    if (logGA4) {
      ReactGA4.event('purchase', transactionEvent)
      console.log('logging ecommerce GA4 - ', transactionEvent)
    }
  }
}

export function logOTAPurchase(data, checkoutId, agencyName) {
  const transactionEvent = {
    category: 'OTA',
    action: 'OTA Purchase',
    transaction: checkoutId,
    affiliation: agencyName,
    currency: 'USD',
    value: data.total,
    items: [
      {
        item_id: data.park?.id,
        item_name: data.park?.name,
        item_category: data.spot?.category?.name,
        price: data.total,
        quantity: 1,
      },
    ],
  }
  if (logGA4) {
    ReactGA4.event('ota_purchase', transactionEvent)
    console.log('logging ota GA4 - ', transactionEvent)
  }
}

export function logEvent(eventObj) {
  if (logUA) {
    ReactGA.event(eventObj)
  }
  if (logGA4) {
    ReactGA4.event(eventObj)
  }
}

export function createAndLogEvent(analyticsObj) {
  if (
    !isUndefined(analyticsObj) &&
    !isEmpty(analyticsObj) &&
    !isUndefined(analyticsObj.log) &&
    analyticsObj.log
  ) {
    const eventObj = {}
    if (!isUndefined(analyticsObj.category)) {
      eventObj.category = analyticsObj.category
    }

    if (!isUndefined(analyticsObj.label)) {
      eventObj.label = analyticsObj.label
    }

    if (!isUndefined(analyticsObj.action)) {
      eventObj.action = analyticsObj.action
    }

    logEvent(eventObj)
  }
}

export function getPathForAnalytics() {
  // this should only be triggered on the client side - never on the server
  return window.location.pathname + window.location.search
}

export function logAnalytics(path = null) {
  // const path = window.location.pathname
  // const pathnameExt = transition.location.pathname
  // const query = transition.location.search
  // // const fullPath = path + '#' + pathnameExt + query
  // const fullPath = path + query

  const fullPath = path === null ? getPathForAnalytics() : path

  if (logUA) {
    ReactGA.set({ page: fullPath })
    ReactGA.pageview(fullPath)
  }

  if (logGA4) {
    // ReactGA4.event('page_view', {'page_location': fullPath})
    ReactGA4.send({
      hitType: 'pageview',
      page: fullPath,
    })
  }

  // ******* Removing Tealium Implementation ***********
  // if (window.utag !== undefined) {
  //   console.debug('tealium: calling utag.view with ' + path + pathnameExt)
  //   window.utag.view({ 'page_name': path + pathnameExt })
  // } else {
  //   window.pendingTag = path + pathnameExt
  //   window.utag_cfg_ovrd = window.utag_cfg_ovrd || {}
  //   window.utag_cfg_ovrd.noview = false
  //   window.utag_data = {page_name: path + pathnameExt}
  //   console.debug('tealium: setting utag data')
  //   console.log(window.utag_data)
  // }
}

export function logPendingAnalytics() {
  // ******* Removing Tealium Implementation ***********
  // console.debug('logAnalytics - pending' + window.utag)
  // if (window.utag !== undefined && window.pendingTag !== undefined) {
  //   console.debug('tealium: calling utag.view with ' + window.pendingTag)
  //   window.utag.view({ 'page_name': window.pendingTag })
  // }
}
