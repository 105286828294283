import PropTypes from 'prop-types'
import React from 'react'
import ModalHeader from 'react-bootstrap/ModalHeader'

/** Wrapper class for react-bootstrap modal body.  https://react-bootstrap.github.io/components.html#modals-props-modal-header */

const AModalHeader = ({
  bsPrefix,
  closeButton,
  closeLabel,
  onHide,
  children,
}) => (
  <ModalHeader
    bsPrefix={bsPrefix}
    closeButton={closeButton}
    closeLabel={closeLabel}
    onHide={onHide}
  >
    {children}
  </ModalHeader>
)

AModalHeader.propTypes = {
  /** Base CSS class and prefix for the component. Generally one should only change bsPrefix to provide new, non-Bootstrap, CSS styles for a component. */
  bsPrefix: PropTypes.string,
  /** Specify whether the Component should contain a close button */
  closeButton: PropTypes.bool,
  /** Provides an accessible label for the close button. It is used for Assistive Technology when the label text is not readable. */
  closeLabel: PropTypes.string,
  /** A Callback fired when the close button is clicked. If used directly inside a Modal component, the onHide will automatically be propagated up to the parent Modal onHide. */
  onHide: PropTypes.func,
  /** Contents of modal body */
  children: PropTypes.node,
}

export default AModalHeader
