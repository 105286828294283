import { arrayOf, number, shape, string } from 'prop-types'

export const cartMixpanelPayloadShape = shape({
  totalAmount: number,
  contractCode: string,
  contractType: string,
  agencyName: string,
  parkName: string,
  parkState: string,
  siteId: string,
  parkId: string,
  orderType: string,
  orderUnits: string,
  arrivalDate: string,
  endDate: string,
  totalNumberOfParks: number,
  totalNumberOfSites: number,
  offers: arrayOf(
    shape({
      type: string,
      vendor: string,
      totalPrice: number,
    }),
  ),
  failedOffers: arrayOf(
    shape({
      type: string,
      vendor: string,
      totalPrice: number,
      errorCode: number,
      errorMesage: string,
    }),
  ),
  swOffersTotal: number,
  apexOffersTotal: number,
})
