import { bool, object, string } from 'prop-types'
import React from 'react'

const Currency = ({ amount, style, hideSymbol }) => {
  const styleClass = style ? `currency ${style}` : 'currency'

  return <span className={styleClass}>{format(amount, !hideSymbol)}</span>
}

export function format(strAmount, displaySymbol) {
  let result = strAmount
  const strLength = strAmount.length
  const decimalIndex = strAmount.indexOf('.')
  if (decimalIndex === -1 || decimalIndex === strLength - 1) {
    result += '.00'
  } else if (decimalIndex === strAmount.length - 2) {
    result += '0'
  }
  // else if (decimalIndex === strAmount.length - 4) {
  //   result = result.substring(0, strAmount.length - 1)
  // }

  return displaySymbol ? `$${result}` : result
}

export function add(augend, addend) {
  return convertToDollars(convertToCents(augend) + convertToCents(addend))
}

export function subtract(minuend, subtrahend) {
  return convertToDollars(convertToCents(minuend) - convertToCents(subtrahend))
}

export function divide(dividend, divisor) {
  return convertToDollars(convertToCents(dividend) / convertToCents(divisor))
}

export function multiply(multiplier, multiplicand) {
  return convertToDollars(
    convertToCents(multiplier) * convertToCents(multiplicand),
  )
}

export function convertToCents(dollars) {
  return dollars * 100
}

export function convertToDollars(cents) {
  return parseFloat((cents / 100).toFixed(2))
}

Currency.propTypes = {
  theme: string,
  intl: object.isRequired,
  amount: string.isRequired,
  style: string,
  hideSymbol: bool,
}

export default Currency
