import { isEmpty } from '../GeneralUtils'

export const getLandmarkTag = function (landmark, asSpan) {
  let tag = asSpan ? 'span' : 'div'

  if (!isEmpty(landmark)) {
    tag = landmark.tag
  }

  return tag
}

export const getLandmarkRole = function (landmark, role) {
  if (!isEmpty(role)) {
    return role
  }

  let landmarkRole = null

  // Set landmark role only if landmark tag has not already been set.
  // This <main role="main" is invalid. When a tag is defined the role is not needed.
  // This is valid <div role="main".
  if (!isEmpty(landmark) && isEmpty(landmark.tag) && !isEmpty(landmark.role)) {
    landmarkRole = landmark.role
  }

  return landmarkRole
}
