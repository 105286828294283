import { CHUB_CHECKOUT } from '../../../actions/ActionTypes'
import { declineUnpurchasedSWQuotes, updateChubCartResponse } from '../../../actions/Backend'
import mixpanel from '../../../thirdParty/mixpanel'
import { receiveData, receiveError } from '../../../utilities/BackendUtils'
import { getMixpanelParamsCompletedPayment } from '../../../utilities/CartUtils'

const SUCCESSFUL_CODES = ['200', '201']
const ADDON_OFFER_VENDORS = ['aspira', 'sensible_weather']
const INITIAL_ADDON_OFFERS_RESPONSE = {
  vendor: 'addon_offers',
  cartItemIds: {},
  faults: {},
}

const getUserFriendlyErrorMessage = (err) => {
  switch (err.vendor) {
    case 'sensible_weather':
      return {
        title: 'Sensible Weather',
        message: err.paymentResponse.api_response.errorMessage,
      }
    default:
      break
  }
}

const mergeAddonOfferFailureResponses = (errors) => {
  const addonOfferFailures = errors
    .filter((err) => ADDON_OFFER_VENDORS.includes(err.vendor))
    .reduce(
      (failures, err) => ({
        ...failures,
        cartItemIds: {
          ...failures.cartItemIds,
          [err.vendor]: err.cartItemIds,
        },
        faults: {
          ...failures.faults,
          [err.vendor]: [
            ...(failures?.faults?.[err.vendor] || []),
            getUserFriendlyErrorMessage(err),
          ],
        },
      }),
      { ...INITIAL_ADDON_OFFERS_RESPONSE },
    )

  return errors
    .filter((err) => !ADDON_OFFER_VENDORS.includes(err.vendor))
    .concat(addonOfferFailures)
}

export class CheckoutResponseHandler {
  static inject(dependencies) {
    CheckoutResponseHandler.dependencies = dependencies
  }

  static handle(response) {
    const { data, metadata } = response

    if (metadata.failure > 0) {
      const failures = CheckoutResponseHandler.collectFailures(data)

      CheckoutResponseHandler.handleAPIFailures(failures, response)

      return
    }

    CheckoutResponseHandler.redirectToConfirmationPage({ response })
  }

  static collectFailures(data) {
    const failures = data.filter(
      ({ statusCode }) => !SUCCESSFUL_CODES.includes(statusCode),
    )

    return mergeAddonOfferFailureResponses(failures)
  }

  static handleAPIFailures(failures, response) {
    failures.forEach((failure) => {
      switch (failure.vendor) {
        case 'a1':
          CheckoutResponseHandler.handleA1Failure(failure)
          break
        case 'addon_offers':
          CheckoutResponseHandler.handleAddonOfferFailures(failure, response)
          break
        default:
          CheckoutResponseHandler.handleUnknownFailure(failure)
          break
      }
    })
  }

  static handleA1Failure(failure) {
    const data = failure.paymentResponse.api_response

    const { dispatch, currentCart, chubCart } =
      CheckoutResponseHandler.dependencies

    const status = failure.statusCode
    const reason = failure.paymentResponse.message

    const errorFaults =
      failure.paymentResponse?.api_response?.faults[0]?.defaultMessage ||
      failure.paymentResponse?.api_response?.faults[0]?.messageTemplate

    const eventPayload = getMixpanelParamsCompletedPayment(
      currentCart,
      chubCart,
    )

    mixpanel.failedPayment({ ...eventPayload, reason, status, errorFaults })

    data.faults = data?.faults?.map((fault) => ({ ...fault, isA1: true })) || []

    dispatch(receiveError(data))
  }

  static handleAddonOfferFailures(failures, response) {
    if (Object.keys(failures.faults).length > 0) {
      CheckoutResponseHandler.redirectToConfirmationPage({ failures, response })
    }
  }

  static handleUnknownFailure(failure) {
    const status = failure.statusCode
    const reason = failure?.paymentResponse?.message || failure?.message

    const { dispatch, currentCart, chubCart } =
      CheckoutResponseHandler.dependencies

    const eventPayload = getMixpanelParamsCompletedPayment(
      currentCart,
      chubCart,
    )

    const errorFaults =
      failure.paymentResponse?.api_response?.faults[0]?.defaultMessage ||
      failure.paymentResponse?.api_response?.faults[0]?.messageTemplate

    mixpanel.failedPayment({ ...eventPayload, reason, status, errorFaults })

    dispatch(receiveError(failure?.data || failure))
  }

  static async redirectToConfirmationPage({ failures = [], response }) {
    const { data } = response
    const { dispatch, currentCart, chubCart, availableSWQuotes } =
      CheckoutResponseHandler.dependencies

    await dispatch(declineUnpurchasedSWQuotes(availableSWQuotes))
    await dispatch(updateChubCartResponse(data))

    const eventPayload = getMixpanelParamsCompletedPayment(
      currentCart,
      chubCart,
      data.filter(({ vendor }) => ADDON_OFFER_VENDORS.includes(vendor)),
    )

    mixpanel.completePayment(eventPayload)

    dispatch(receiveData({ response: data, failures }, CHUB_CHECKOUT))
  }
}
