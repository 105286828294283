import { translate } from '../components/Translation'
import { isEmpty } from '../external/utilities/GeneralUtils'

export function getErrors(backendError, error) {
  const errorsObj = []
  if (backendError?.isError) {
    errorsObj.push(backendError?.errorData)
  }
  if (error?.isError && !isEmpty(error?.errorsData)) {
    for (let i = 0; i < error?.errorsData.length; i++) {
      errorsObj.push(error?.errorsData[i])
    }
  }

  return errorsObj
}

export function getIntlErrorMsg(error, intl = null) {
  const messages = []

  if (!isEmpty(error?.faults)) {
    for (let i = 0; i < error?.faults.length; i++) {
      const {
        isA1 = false,
        msgKey,
        defaultMessage,
        messageTemplate,
      } = error?.faults[i] || {}

      if (!isA1 && msgKey) {
        messages.push(
          translate(
            intl,
            msgKey,
            getErrorMsgParams(error?.faults[i]),
            null,
            null,
            defaultMessage || messageTemplate,
            undefined,
            undefined,
            false,
          ),
        )
      } else if (defaultMessage) {
        messages.push(defaultMessage)
      } else if (messageTemplate) {
        messages.push(messageTemplate)
      } else {
        messages.push(translate(intl, 'error.general'))
      }
    }
  } else if (messages.length === 0) {
    if (intl === null) {
      messages.push(error?.message)
    } else {
      messages.push(
        translate(
          intl,
          error?.msgKey || 'error.general',
          error?.valuesObj,
          null,
          null,
          error?.message,
          undefined,
          undefined,
          false,
        ),
      )
    }
  }

  return messages
}

export function getErrorMsgParams(fault) {
  const params = {}
  if (fault.field !== undefined && !isEmpty(fault.field)) {
    if (fault.msgParams !== undefined && !isEmpty(fault.msgParams)) {
      for (const param of fault.msgParams) {
        params[param.name] = param.value
      }
    }
  }

  return params
}

export function createError(msgKey, message, valuesObj = null) {
  const errorsData = []
  errorsData.push(createErrorObject(msgKey, message, valuesObj))

  return errorsData
}

export function createErrorFromObj(keyMsg, valuesObj = null) {
  return createError(keyMsg.msgKey, keyMsg.message, valuesObj)
}

export function createErrorObject(msgKey, message, valuesObj = null) {
  return { msgKey, message, valuesObj }
}

export function addError(error, errorsData) {
  errorsData.push(error)

  return errorsData
}
