import { array, bool, func, number, object, oneOf, string } from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import AButton from '../../../external/components/button/AButton'
import AInput from '../../../external/components/form/AInput'
import ScrollPoint, {
  scrollToPoint,
} from '../../../external/components/link/ScrollPoint'
import { isEnvServer } from '../../../external/utilities/GeneralServerUtils'
import {
  hackIsMobile,
  positiveToNegative,
} from '../../../external/utilities/GeneralUtils'
import {
  DELAY_TYPE_INPUT,
  DELAY_TYPE_INTERVAL,
  DELAY_TYPE_NONE,
  selectAnySearchTermMinLength,
  selectSearchDelayMinChar,
  selectSearchDelayTimeout,
  selectSearchDelayType,
  selectSearchScrollToTopOnMobile,
} from '../../../selectors/searchConfig'
import {
  findSearchTermInResults,
  getCurrentSearchTerm,
} from '../../../utilities/SearchUtils'
import { getMainHeaderHomeHeight } from '../../../utilities/ViewUtils'
import { useClickOutside } from '../hooks/useClickOutside'
import { useTranslation } from '../hooks/useTranslation'
import SearchHelper, { HELP_TYPE_ERROR, HELP_TYPE_HINT } from './SearchHelper'
import SearchResults from './SearchResults'

const MIN_SELECTED_INPUT = -1

const SearchBarCmp = ({
  currSearch,
  onSearchTermChange,
  mainSearchConfig,
  brndConf,
  allQuickSearchResults,
  scrollPointName = 'searchBarScrollPoint',
  onSearchResultItemSelectRequest,
  allQuickSearchResultsAttribs,
  intl,
  showHelper,
  onSearchTrigger,
}) => {
  const [isOpen, setOpen] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [selectedInput, setSelectedInput] = useState(MIN_SELECTED_INPUT)
  const [searchString, setSearchString] = useState(() =>
    getCurrentSearchTerm(currSearch),
  )
  const [helperContent, setHelperContent] = useState(null)
  const [helperContentType, setHelperContentType] = useState(null)

  const delayType = useSelector(selectSearchDelayType)
  const delayAfterMinChar = useSelector(selectSearchDelayMinChar)
  const delayInputTimeMS = useSelector(selectSearchDelayTimeout)
  const scrollToTopOnMobile = useSelector(selectSearchScrollToTopOnMobile)
  const searchStringMinLength = useSelector(selectAnySearchTermMinLength)

  const t = useTranslation()

  const updateSearchTerm = useCallback(
    (inputSearchTerm) => {
      onSearchTermChange(inputSearchTerm, mainSearchConfig, brndConf)
    },
    [mainSearchConfig, brndConf, onSearchTermChange],
  )

  useEffect(() => {
    if (!delayType) {
      return
    }

    setIsSearching(true)

    const timeout = setTimeout(() => {
      updateSearchTerm(searchString)
      setIsSearching(false)
    }, delayInputTimeMS)

    return () => clearTimeout(timeout)
  }, [
    delayType,
    searchString,
    updateSearchTerm,
    delayInputTimeMS,
    delayAfterMinChar,
  ])

  const onChange = (e) => {
    const { value } = e.target

    setSearchString(value)
    setSelectedInput(-1)
    setOpen(true)
    setHelperContent(null)
    setHelperContentType(null)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const selectedItem =
      allQuickSearchResults[selectedInput] ??
      findSearchTermInResults(
        searchString,
        allQuickSearchResults,
        brndConf,
        currSearch,
      ) ??
      null
    const selectedSearchTerm =
      allQuickSearchResults[selectedInput]?.name ?? searchString

    if (!showHelper || selectedSearchTerm) {
      onSearchTrigger(selectedSearchTerm, selectedItem)
    } else {
      const msg = t('error.search.noinput', {
        minNumChar: searchStringMinLength,
      })

      setHelperContent(msg)
      setHelperContentType(HELP_TYPE_ERROR)
    }
  }

  const onKeyDown = (e) => {
    if (e.key === 'ArrowUp') {
      setSelectedInput((selectedInput) =>
        Math.max(MIN_SELECTED_INPUT, selectedInput - 1),
      )
    } else if (e.key === 'ArrowDown') {
      const maxSelectedInput = allQuickSearchResults.length - 1
      setSelectedInput((selectedInput) =>
        Math.min(maxSelectedInput, selectedInput + 1),
      )
    }
  }

  const onFocus = () => {
    if (!showHelper) return

    if (scrollToTopOnMobile && !isEnvServer() && hackIsMobile()) {
      const offsetMargin = 10
      const headerHeight = getMainHeaderHomeHeight()
      const offset =
        headerHeight > 0 ? positiveToNegative(headerHeight) - offsetMargin : 0
      scrollToPoint(this.props.scrollPointName, 100, null, offset)
    }

    if (!searchString) {
      const msg = t('labels_search_bar_hint')
      setHelperContent(msg)
      setHelperContentType(HELP_TYPE_HINT)
    }
  }

  const onClickOutside = () => {
    setOpen(false)
    setHelperContent(null)
    setHelperContentType(null)
  }

  const { containerRef } = useClickOutside({ onClickOutside })

  return (
    <div
      className={'searchbar'}
      onKeyUp={onKeyDown}
      tabIndex={0}
      ref={containerRef}
    >
      <form className="searchForm" onSubmit={onSubmit}>
        <div className="searchbar__controls">
          <div className="searchbar__input">
            <ScrollPoint name={scrollPointName} />
            <AInput
              maxLength={50}
              type="text"
              placeholder={t('labels_search_bar_placeholder')}
              onClick={onFocus}
              onChange={onChange}
              value={searchString}
              cmpRef={'searchbar-input'}
              cmpLabel={t('labels_search_bar_hint')}
            />
          </div>
          <div className="searchbar__button">
            <AButton
              type="submit"
              style="primary"
              size="large"
              cmpRef={'searchbar-button'}
              cmpLabel={'search'}
            >
              <i className="fa fa-search" aria-hidden="true" />
            </AButton>
          </div>
        </div>
        <SearchResults
          intl={intl}
          allQuickSearchResults={allQuickSearchResults}
          allQuickSearchResultsAttribs={allQuickSearchResultsAttribs}
          onSearchResultItemSelectRequest={onSearchResultItemSelectRequest}
          selectedIndex={selectedInput}
          show={isOpen}
          searchString={searchString}
          cmpRef={'search-result'}
          isSearching={isSearching}
        />
        <SearchHelper
          intl={intl}
          content={helperContent}
          contentType={helperContentType}
        />
      </form>
    </div>
  )
}

SearchBarCmp.propTypes = {
  intl: object.isRequired,
  onSearchTrigger: func,
  onSearchTermChange: func,
  allQuickSearchResults: array,
  allQuickSearchResultsAttribs: array,
  mainSearchConfig: object,
  brndConf: object,
  onSearchResultItemSelectRequest: func,
  currSearch: object,
  showHelper: bool,
  delayType: oneOf([DELAY_TYPE_NONE, DELAY_TYPE_INPUT, DELAY_TYPE_INTERVAL]),
  delayInputTimeMS: number,
  delayIntervalTimeMS: number,
  delayAfterMinChar: number,
  scrollToTopOnMobile: bool,
  scrollPointName: string,
  setTimeout: func,
  setInterval: func,
  clearTimeouts: func,
  clearIntervals: func,
}

export default SearchBarCmp
