import PropTypes from 'prop-types'
import React from 'react'

/** Custom component for displaying error messages

@example ../../../../docs/external/error/ErrorInline.md*/

export const ErrorInline = ({ className = '', children }) => (
  <div className={`error-inline-wrapper ${className}`}>{children}</div>
)

/**
 * function to return dark or light text
 * @param {string} errorText The text to return.
 * @param {string} iconClass the font awesome icon to use at the beginning of the error.  Default is exclamation point in a circle.
 * @param {string} className wrapper class name for custom classes/styles
 * @returns {element} div with icon and error text inside.
 */
export function getInlineError(
  errorText,
  iconClass = 'fa fa-exclamation-circle',
  className = '',
) {
  return (
    <div className={`error-inline-text ${className}`}>
      <i className={iconClass} aria-hidden="true" role="alert" />
      {errorText}
    </div>
  )
}

ErrorInline.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

export default ErrorInline
