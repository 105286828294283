import { translate } from '../components/Translation'
import LOBs from '../constants/LOBS'
import { isEmpty, isValue } from '../external/utilities/GeneralUtils'

class IntlContext {
  constructor() {
    this.clear()
  }

  clear() {
    this.lineOfBusiness = null
    this.contractCode = null
    this.facilityId = null
    this.getKeys()
  }

  setLOB(lineOfBusiness) {
    this.lineOfBusiness = lineOfBusiness
  }

  setCamping() {
    this.setLOB(LOBs.CAMPING)
  }

  setMarinas() {
    this.setLOB(LOBs.MARINAS)
  }

  setPermits() {
    this.setLOB(LOBs.PERMITS)
  }

  setTicketing() {
    this.setLOB(LOBs.TICKETING)
  }

  setContractCode(contractCode) {
    this.contractCode = contractCode
  }

  setFacilityId(facilityId) {
    this.facilityId = facilityId
  }

  getContext() {
    return {
      LOB: this.lineOfBusiness,
      contractCode: this.contractCode,
      facilityId: this.facilityId,
    }
  }

  setContextFromFacility(facility) {
    if (!isEmpty(facility)) {
      this.setContractCode(facility.contrCode.toLowerCase())
      this.setFacilityId(facility.id)
      this.getKeys()
    }
  }

  setContextFromFacilityAndProduct(facility, product) {
    this.setContextFromFacility(facility)
    // .. other settings.
  }

  getKeys() {
    const qualifiers = this.getQualifiers()
  }

  getQualifiers() {
    const result = []
    /* if (!isEmpty(this.lineOfBusiness)) {
      result.push(this.lineOfBusiness)
    } */
    if (!isEmpty(this.contractCode)) {
      result.push(this.contractCode)
    }
    if (isValue(this.facilityId)) {
      result.push(this.facilityId)
    }

    return result
  }

  test(intl) {
    this.clear()
    const mKey = 'test_message'
    console.log('initial test with no key qualifiers')
    console.log(translate(intl, mKey, null, null, null, 'testing', true))

    this.clear()

    console.log('test with contract only key qualifier')
    this.setContractCode('RA')
    console.log(translate(intl, mKey, null, null, null, 'testing', true))

    this.clear()

    console.log('test with lob and contract key qualifiers')
    this.setContractCode('RA')
    this.setMarinas()
    console.log(translate(intl, mKey, null, null, null, 'testing', true))

    this.clear()

    console.log('test with lob, facility, and contract key qualifiers')
    this.setContractCode('RA')
    this.setFacilityId('111111')
    this.setMarinas()
    console.log(translate(intl, mKey, null, null, null, 'testing', true))

    this.clear()

    console.log('test with facility, and contract key qualifiers')
    this.setContractCode('RA')
    this.setFacilityId('111111')
    console.log(translate(intl, mKey, null, null, null, 'testing', true))

    this.clear()
    console.log('Iterate through keys')

    this.setContractCode('RA')
    this.setFacilityId('111111')
    this.setMarinas()

    const qualifiers = this.getQualifiers()
    const arrlength = qualifiers.length

    for (let i = arrlength; i > 0; i--) {
      qualifiers.length = i
      console.log(qualifiers.join('.'))
    }
  }
}

// Singleton Instance.
let _instance

function getInstance() {
  if (!_instance) {
    _instance = new IntlContext()
  }

  return _instance
}

// Exposed mathods
export default {
  get: getInstance,
}
