import { applyMiddleware, compose, createStore } from 'redux'
import promise from 'redux-promise'
import thunkMiddleware from 'redux-thunk'

import { isEnvServer } from '../external/utilities/GeneralServerUtils'
import reducers from '../reducers'

const middlewares = [promise, thunkMiddleware]

const composeEnhancers =
  // eslint-disable-next-line no-underscore-dangle
  (!isEnvServer() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

export default function configureStore(initialState) {
  const store = createStore(
    reducers,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares)),
  )

  return store
}
