import * as CartUtils from './CartUtils'

export const FEEDBACK_GROUPS = {
  ALL: 'All',
  CHECKOUT: 'Checkout',
  NONCHECKOUT: 'NonCheckout',
}

export const getSelectedFeedbackConfig = function (feedbackConfig) {
  if (feedbackConfig.currentSelection === FEEDBACK_GROUPS.CHECKOUT) {
    return feedbackConfig.checkoutConfig
  }

  return feedbackConfig.nonCheckoutConfig
}

export const getOrderData = function (feedbackConfig) {
  const orderData = []
  if (feedbackConfig.currentSelection === FEEDBACK_GROUPS.CHECKOUT) {
    if (
      CartUtils.isSomeContractCartsProcessed(
        feedbackConfig.cartDetailedProcessResult.shoppingCartVO,
      )
    ) {
      for (const contractCart of feedbackConfig.cartDetailedProcessResult
        .shoppingCartVO.contractViewList) {
        if (CartUtils.isContractCartProcessed(contractCart)) {
          for (const order of contractCart.cartOrderViews) {
            orderData.push({
              contractCode: order.webOrder.contractCode,
              orderNumber: order.webOrder.orderNumber,
            })
          }
        }
      }
    }
  }

  return orderData
}
