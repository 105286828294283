import PropTypes from 'prop-types'
import React from 'react'

import Translation from '../Translation'
import ErrorBlock from '../shared/ErrorBlock'
import HackHTag from '../shared/text/HackHTag'
import ErrorGuidance from './ErrorGuidance'

const NotLoggedIn = ({ errors, intl, theme }) => (
  <div className="with-search">
    <div className="container container--errors">
      <HackHTag h1>
        <Translation intl={intl} mkey={'labels_errorpage_not_logged_in'} />
      </HackHTag>
      <ErrorBlock errors={errors} intl={intl} />
      <ErrorGuidance intl={intl} theme={theme} mode={'notLoggedIn'} />
    </div>
  </div>
)

NotLoggedIn.propTypes = {
  theme: PropTypes.string.isRequired,
  errors: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
}

export default NotLoggedIn
