import { func, object, string } from 'prop-types'
import React from 'react'

import Cont from '../../../external/components/layout/Cont'
import Section, {
  MAIN,
  SUB_INLINE,
} from '../../../external/components/layout/Section'
import ABaseLink from '../../../external/components/link/ABaseLink'
import TextHoverable from '../../../external/components/link/TextHoverable'
import { isEmpty } from '../../../external/utilities/GeneralUtils'
import { ROLES } from '../../../external/utilities/accessibility/Definitions'

const Article = ({
  getArticleSelectLinkObj,
  article,
  onArticleSelectRequest,
}) => {
  const onArticleSelectRequestLocal = function () {
    onArticleSelectRequest(article)
  }

  const getArticle = function () {
    if (!isEmpty(article)) {
      const imageStyle = { backgroundImage: `url(${article.image})` }

      return (
        <div className={'home-article'}>
          <ABaseLink
            {...getArticleSelectLinkObj(article)}
            tabIndex={-1}
            onClick={onArticleSelectRequestLocal}
          >
            <div className={'home-article__image'} style={imageStyle}>
              <Cont
                asSpan
                cmpSRonly
                cmpRole={ROLES.DOC_STRUCTURE.IMG}
                cmpLabel={article.title}
              >
                {article.title}
              </Cont>
            </div>
          </ABaseLink>
          <Cont className={'home-article__description'}>
            <Cont>
              <ABaseLink
                {...getArticleSelectLinkObj(article)}
                tabIndex={-1}
                onClick={onArticleSelectRequestLocal}
              >
                <Section
                  style={SUB_INLINE}
                  className={'home-featured-article__category'}
                >
                  {article.categoryDisplayName}
                </Section>
              </ABaseLink>
            </Cont>
            <TextHoverable
              lineClamp={2}
              fontSizeType={4}
              fontFamily={'Georgia'}
              fontColor={'blue-dark'}
              {...getArticleSelectLinkObj(article)}
              onClick={onArticleSelectRequestLocal}
            >
              {article.title}
            </TextHoverable>
          </Cont>
        </div>
      )
    }

    return <div />
  }

  return <Section style={MAIN}>{getArticle()}</Section>
}

Article.propTypes = {
  theme: string.isRequired,
  intl: object.isRequired,
  getArticleSelectLinkObj: func.isRequired,
  article: object,
  onArticleSelectRequest: func.isRequired,
}

export default Article
