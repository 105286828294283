/**
 * @file   : FlipCard.js
 * @author : Amarjeet Lamba (Amarjeet.Lamba@AspiraConnect.com)
 * @date   : 10/26/2018, 2:01:51 PM
 */
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import CardBack from './CardBack'
import CardFront from './CardFront'

export const FLIPCARD_FRONT = 'front'
export const FLIPCARD_BACK = 'back'

// TODO
// The flipcard front and back states are reversed.
// Need to be fixed.
class FlipCard extends Component {
  constructor() {
    super()
    this.state = { showing: 'none' }
  }

  onMoreOrBackButtonClick(target) {
    this.setState({
      ...this.state,
      showing: target,
    })
  }

  render() {
    const {
      cardContentFront,
      cardContentBack,
      cardSize,
      cardLabelBtnBack,
      cardLabelBtnFront,
      isShowMoreButton,
    } = this.props
    let style = 'card-body'
    if (this.state.showing === FLIPCARD_FRONT) {
      style = 'card-body card-container-flip'
    }

    return (
      <div className={`card-container cardsize-${cardSize}`}>
        <div className={style}>
          <CardBack
            showing={this.state.showing}
            content={cardContentBack}
            cardBtnLabel={cardLabelBtnBack}
            onBackButtonClick={this.onMoreOrBackButtonClick.bind(this)}
          />
          <CardFront
            isShowMoreButton={isShowMoreButton}
            showing={this.state.showing}
            content={cardContentFront}
            cardBtnLabel={cardLabelBtnFront}
            onMoreButtonClick={this.onMoreOrBackButtonClick.bind(this)}
          />
        </div>
      </div>
    )
  }
}

FlipCard.propTypes = {
  cardContentFront: PropTypes.element,
  cardContentBack: PropTypes.element,
  isShowMoreButton: PropTypes.bool,
  isShowBackButton: PropTypes.bool,
  /** predefined sizes of the card. the '1x' is left open for future (default is '3x') */
  cardSize: PropTypes.oneOf(['1x', '2x', '3x', '4x', '5x', '6x']),
  cardLabelBtnBack: PropTypes.string,
  cardLabelBtnFront: PropTypes.string,
}

FlipCard.defaultProps = {
  cardSize: '3x',
  cardContentBack: null,
  isShowMoreButton: true,
}

export default FlipCard
