/**
 * @file   : MarketingSpotInfo.js
 * @author : Amarjeet Lamba (Amarjeet.Lamba@AspiraConnect.com)
 * @date   : 10/26/2018, 2:36:39 PM
 */
import PropTypes from 'prop-types'
import React from 'react'

import Cont, { ALIGN_RIGHT } from '../../../external/components/layout/Cont'
import Section, { SUB } from '../../../external/components/layout/Section'
import ABaseLink from '../../../external/components/link/ABaseLink'
import TextStyler, {
  DARK_BLUE_TEXT,
  LINECLAMP_LIMIT,
} from '../../../external/components/text/TextStyler'
import { simpleTranslate } from '../../Translation'

const MarketingSpotInfo = ({
  theme,
  intl,
  getMarketingSpotSelectLinkObj,
  onMarketingSpotSelectRequest,
  marketingSpot,
}) => {
  const testFunc = function () {
    varOne = 'bar'
  }

  return (
    <Cont className={'home-marketing-spot__info'}>
      <TextStyler
        styleObj={{
          ...DARK_BLUE_TEXT,
          lineClamp: LINECLAMP_LIMIT.L4,
        }}
      >
        {marketingSpot.excerpt}
      </TextStyler>
      <Section style={SUB} />
      <Cont align={ALIGN_RIGHT}>
        <ABaseLink
          {...getMarketingSpotSelectLinkObj(marketingSpot)}
          onClick={() => {
            onMarketingSpotSelectRequest(marketingSpot)
          }}
        >
          {simpleTranslate(intl, 'label_marketing_card_action_link')}
        </ABaseLink>
      </Cont>
    </Cont>
  )
}

MarketingSpotInfo.propTypes = {
  theme: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  getMarketingSpotSelectLinkObj: PropTypes.func.isRequired,
  onMarketingSpotSelectRequest: PropTypes.func.isRequired,
  marketingSpot: PropTypes.object.isRequired,
}

export default MarketingSpotInfo
