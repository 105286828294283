import { array, bool, element } from 'prop-types'
import React from 'react'

import { isEmpty } from '../../../external/utilities/GeneralUtils'

const Video = ({ sources, random, children }) => {
  let vidoeSelection = sources[0]
  if (sources.length > 1 && random) {
    const rand = Math.floor(Math.random() * sources.length)
    vidoeSelection = sources[rand]
  }

  const posterStyle = {
    backgroundImage: `url(${vidoeSelection.posterImageURL})`,
  }

  const videoContent = function () {
    if (
      isEmpty(vidoeSelection.mp4Source) &&
      isEmpty(vidoeSelection.ogvSource) &&
      isEmpty(vidoeSelection.webmSource)
    ) {
      return null
    }

    return (
      <video
        id="videoplayer"
        className="video-player"
        autoPlay
        muted
        poster={vidoeSelection.poster}
      >
        <source src={vidoeSelection.mp4Source} type="video/mp4" />
        <source src={vidoeSelection.ogvSource} type="video/ogg" />
        <source src={vidoeSelection.webmSource} type="video/webm" />
      </video>
    )
  }

  const vidoeContainer = (
    <div className="video-container" style={posterStyle}>
      <div className="transparency-overlay-top"></div>
      <div className="transparency-overlay-bottom"></div>
      {children}
      {videoContent()}
    </div>
  )

  return vidoeContainer
}

Video.propTypes = {
  sources: array.isRequired,
  random: bool.isRequired,
  children: element,
}

export default Video
