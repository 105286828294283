/**
 * @file   : HackHTag.js
 * @author : Amarjeet Lamba (Amarjeet.Lamba@AspiraConnect.com)
 * @date   : 2018-6-14 14:25:59
 * @deprecated
 *
 * DO NOT USE this for any new code. This component was created so we can
 * swap the current h1-h6 implementations out with this. We need the actual
 * h1-h6 implementations to use the HTag and be SEO/accessibility compliant.
 */
import PropTypes from 'prop-types'
import React from 'react'

import Cont from '../../../external/components/layout/Cont'

const HackHTag = ({
  h1 = false,
  h2 = false,
  h3 = false,
  h4 = false,
  h5 = false,
  h6 = false,
  children,
}) => {
  const getHStyle = function () {
    if (h1) {
      return 'hack-h-tag-h1'
    } else if (h2) {
      return 'hack-h-tag-h2'
    } else if (h3) {
      return 'hack-h-tag-h3'
    } else if (h4) {
      return 'hack-h-tag-h4'
    } else if (h5) {
      return 'hack-h-tag-h5'
    } else if (h6) {
      return 'hack-h-tag-h6'
    }

    return 'h1'
  }

  return <Cont className={getHStyle()}>{children}</Cont>
}

HackHTag.propTypes = {
  /** h1 - is tag h1 */
  h1: PropTypes.bool,
  /** h2 - is tag h2 */
  h2: PropTypes.bool,
  /** h3 - is tag h3 */
  h3: PropTypes.bool,
  /** h4 - is tag h4 */
  h4: PropTypes.bool,
  /** h5 - is tag h5 */
  h5: PropTypes.bool,
  /** h6 - is tag h6 */
  h6: PropTypes.bool,
  /** the body of the tag, should be just text or spans (no divs)  */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}

export default HackHTag
