import { isEmpty } from '../external/utilities/GeneralUtils'
import { setMainConfig } from '../router/ExtRouteManager'

export const isAppInitComplete = function (pageInits) {
  let init = false
  if (pageInits.mainApp && pageInits.locale) {
    init = true
  }

  return init
}

export const isEnvProd = function () {
  if (process.env.NODE_ENV === 'production') {
    return true
  }

  return false
}

export const injectData = function (state) {
  const mainConfig = state.backend.config?.main

  if (!isEmpty(mainConfig)) {
    setMainConfig(mainConfig)
  }
}
