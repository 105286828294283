import PropTypes from 'prop-types'
import React from 'react'

/** Component to display a spinning wheel whenever a page is loading */

const Spinner = ({ loading = false, label = '', inline = false }) => {
  let classd = ['no-spinner']

  if (loading) {
    classd = ['spinner', label ? 'spinner--labelled' : '', inline ? 'spinner--inline' : '']
  }

  return <div className={classd.join(' ').trim()} data-label={label} />
}

Spinner.propTypes = {
  /** Whether or not the page is still loading */
  loading: PropTypes.bool,
}

export default Spinner
