/**
 * @file   : Attribution.js
 * @author :  ()
 * @date   : 11/26/2019, 11:12:32 AM
 */
import PropTypes from 'prop-types'
import React from 'react'

import Cont from '../../../external/components/layout/Cont'
import ABaseLink from '../../../external/components/link/ABaseLink'
import AImg from '../../../external/components/media/img/AImg'
import { isEmpty, isUndefined } from '../../../external/utilities/GeneralUtils'
import { translate } from '../../../external/utilities/TranslationUtils'

const Attribution = ({ intl, attrib }) => {
  const getContent = function () {
    let contentUI = null

    // eslint-disable-next-line no-negated-condition
    if (!isEmpty(attrib.imageURL)) {
      contentUI = <AImg imageURL={attrib.imageURL} />
    } else {
      contentUI = (
        <Cont asSpan>
          {translate(
            intl,
            attrib.description.token,
            { entityName: attrib.entityName },
            null,
            attrib.description.default,
          )}
        </Cont>
      )
    }

    return contentUI
  }

  if (!isUndefined(attrib) && !isEmpty(attrib) && attrib.active) {
    if (isEmpty(attrib.linkURL)) {
      return getContent()
    }

    return (
      <Cont>
        <ABaseLink to={attrib.linkURL} internal={false} newWin={true}>
          {getContent()}
        </ABaseLink>
      </Cont>
    )
  }

  return null
}

Attribution.propTypes = {
  intl: PropTypes.object.isRequired,
  attrib: PropTypes.object,
}

export default Attribution
