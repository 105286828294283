import { useBasename } from 'history'
import createHashHistory from 'history/lib/createHashHistory'
import { browserHistory } from 'react-router'

import { USE_BROWSER_HISTORY } from '../config/AppConfig'

let history = browserHistory
if (!USE_BROWSER_HISTORY) {
  history = useBasename(createHashHistory)({})
}

export default history
