export const RECEIVE_ERROR = 'RECEIVE_ERROR'
export const REQUEST_DATA = 'REQUEST_DATA'
export const RECEIVE_DATA = 'RECEIVE_DATA'
export const CHANGE_THEME = 'CHANGE_THEME'
export const GET_LOCALE = 'GET_LOCALE'
export const GET_FACILITY_INIT = 'GET_FACILITY_INIT'
export const GET_FACILITY_INIT_FAILED = 'GET_FACILITY_INIT_FAILED'
export const GET_PRODUCTS_INIT = 'GET_PRODUCTS_INIT'
export const GET_PRODUCTS_DAYPASSES_INIT = 'GET_PRODUCTS_DAYPASSES_INIT'
export const GET_PRODUCTS_HOURLY_SITES_INIT = 'GET_PRODUCTS_HOURLY_SITES_INIT'
export const GET_PRODUCT_AND_BOOKING_INIT = 'GET_PRODUCT_AND_BOOKING_INIT'
export const UPDATE_PRODUCT_AND_BOOKING = 'UPDATE_PRODUCT_AND_BOOKING'
export const THROW_ERROR = 'THROW_ERROR'
export const CLEAR_ERROR = 'CLEAR_ERROR'

export const SAVE_LOCAL_STATE = 'SAVE_LOCAL_STATE'
export const GET_LOCAL_STATE = 'GET_LOCAL_STATE'

export const SET_ROOT_INFO = 'SET_ROOT_INFO'

export const SHOW_LOADER = 'SHOW_LOADER'

/* Product */
export const UPDATE_SELECTED_PRODUCT = 'UPDATE_SELECTED_PRODUCT'
export const GET_PRODUCT_DETAILS_INIT = 'GET_PRODUCT_DETAILS_INIT'
export const SUBMIT_PRODUCT_BOOKING_DETAILS_SUB_FORM =
  'SUBMIT_PRODUCT_BOOKING_DETAILS_SUB_FORM'
export const GET_PRODUCT_BOOKING_SUPPLEMENT_DETAILS =
  'GET_PRODUCT_BOOKING_SUPPLEMENT_DETAILS'
export const UPDATE_PRODUCT_BOOKING_SUPPLEMENT_DETAILS =
  'UPDATE_PRODUCT_BOOKING_SUPPLEMENT_DETAILS'

/* Product Notification */
export const GET_PRODUCT_NOTIFICATION_INIT = 'GET_PRODUCT_NOTIFICATION_INIT'
export const CREATE_PRODUCT_NOTIFICATION = 'CREATE_PRODUCT_NOTIFICATION'

/* Search */
export const UPDATE_SEARCH_TERM = 'UPDATE_SEARCH_TERM'
export const GET_SEARCH_RESULTS_INIT = 'GET_SEARCH_RESULTS_INIT'
export const GET_SEARCH_SUGGESTIONS_INIT = 'GET_SEARCH_SUGGESTIONS_INIT'
export const GET_QUCK_SEARCH_WEB = 'GET_QUCK_SEARCH_WEB'
export const GET_QUCK_SEARCH_INTERNAL = 'GET_QUCK_SEARCH_INTERNAL'
export const GET_QUCK_SEARCH_CONTRACT = 'GET_QUCK_SEARCH_CONTRACT'
export const GET_QUCK_SEARCH_CLEAR_ALL = 'GET_QUCK_SEARCH_CLEAR_ALL'
export const UPDATE_SEARCH_FILTER = 'UPDATE_SEARCH_FILTER'

/* Testing */
export const GET_PAGE_ONE_DATA = 'GET_PAGE_ONE_DATA'
export const GET_PAGE_TWO_DATA = 'GET_PAGE_TWO_DATA'
export const GET_FORM_TEST_DATA = 'GET_FORM_TEST_DATA'
export const POST_FORM_TEST_DATA = 'POST_FORM_TEST_DATA'
export const TEST_SUBMIT_SUB_FORM = 'TEST_SUBMIT_SUB_FORM'

/* Facility Search */
export const UPDATE_PRODUCT_SEARCH_CRITERIA = 'UPDATE_PRODUCT_SEARCH_CRITERIA'
export const RESET_PRODUCT_SEARCH_CRITERIA = 'RESET_PRODUCT_SEARCH_CRITERIA'

/* Cart */
export const LOCK_INVENTORY = 'LOCK_INVENTORY'
export const GET_CART = 'GET_CART'
export const GET_DETAILED_CART_PROCESS_RESULT =
  'GET_DETAILED_CART_PROCESS_RESULT'
export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART'
export const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM'
export const REMOVE_CART_ITEMS = 'REMOVE_CART_ITEMS'
export const PROCESS_CART = 'PROCESS_CART'
export const ABANDON_CART = 'ABANDON_CART'
export const UPDATE_CART_ITEM_DATES = 'UPDATE_CART_ITEM_DATES'
export const VERIFY_GIFT_CARD = 'VERIFY_GIFT_CARD'
export const GET_CHECKOUT_INIT = 'GET_CHECKOUT_INIT'
export const ADD_POS = 'ADD_POS'

/* Offer */
export const GET_OFFER = 'GET_OFFER'
export const PROCESS_OFFER = 'PROCESS_OFFER'

/* Offer */
export const GET_POS_ITEM_INIT = 'GET_POS_ITEM_INIT'
export const ADD_POS_ITEM_TO_CART = 'ADD_POS_ITEM_TO_CART'
export const ADD_PENDING_POS_ITEM_TO_CART = 'ADD_PENDING_POS_ITEM_TO_CART'

/* Favorites */
export const GET_FAVORITES_INIT = 'GET_FAVORITES_INIT'
export const ADD_TO_FAVORITES_COMPLETE = 'ADD_TO_FAVORITES_COMPLETE'
export const REMOVE_FROM_FAVORITES_COMPLETE = 'REMOVE_FROM_FAVORITES_COMPLETE'
export const GET_FAVORITES_SEARCH_PLACES = 'GET_FAVORITES_SEARCH_PLACES'

/* Contact Us */
export const GET_CONTACT_US_INIT = 'GET_CONTACT_US_INIT'

/* Advertise */
export const GET_ADVERTISE_INIT = 'GET_ADVERTISE_INIT'

/* Info */
export const GET_INFO_INIT = 'GET_INFO_INIT'

/* My Account */
export const GET_DASHBOARD_INIT = 'GET_DASHBOARD_INIT'
export const GET_RESERVATIONS_CUURENT = 'GET_RESERVATIONS_CUURENT'
export const GET_RESERVATIONS_PAST = 'GET_RESERVATIONS_PAST'
export const GET_RESERVATION_DETAILS_INIT = 'GET_RESERVATION_DETAILS_INIT'
export const GET_RESERVATION_CANCEL_INIT = 'GET_RESERVATION_CANCEL_INIT'
export const GET_RESERVATION_PAYMENT_INIT = 'GET_RESERVATION_PAYMENT_INIT'
export const GET_RESERVATION_PRE_CHECKIN_INIT =
  'GET_RESERVATION_PRE_CHECKIN_INIT'
export const PRE_CHECKIN_RESERVATION = 'PRE_CHECKIN_RESERVATION'
export const GET_RESERVATION_CHANGE_INIT = 'GET_RESERVATION_CHANGE_INIT'
export const GET_RESERVATION_CHANGE_SUPPLEMENT_DETAILS_INIT =
  'GET_RESERVATION_CHANGE_SUPPLEMENT_DETAILS_INIT'
export const SUBMIT_CHANGE_RESERVATION_SUB_FORM =
  'SUBMIT_CHANGE_RESERVATION_SUB_FORM'
export const UPDATE_RESERVATION_CHANGE_SUPPLEMENT_DETAILS =
  'UPDATE_RESERVATION_CHANGE_SUPPLEMENT_DETAILS'
export const CONFIRM_CHANGE_RESERVATION = 'CONFIRM_CHANGE_RESERVATION'
export const CHANGE_RESERVATION_SITE_DATE = 'CHANGE_RESERVATION_SITE_DATE'
export const GET_RESERVATION_CHANGE_CONFIRMATION_INIT =
  'GET_RESERVATION_CHANGE_CONFIRMATION_INIT'
export const CANCEL_RESERVATION = 'CANCEL_RESERVATION'
export const CHANGE_RESERVATION = 'CHANGE_RESERVATION'
export const CANCEL_CHANGE_RESERVATION = 'CANCEL_CHANGE_RESERVATION'
export const PAY_RESERVATION = 'PAY_RESERVATION'
export const GET_NOTIFICATIONS_INIT = 'GET_NOTIFICATIONS_INIT'
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION'
export const GET_APPLICATIONS_INIT = 'GET_APPLICATIONS_INIT'
export const GET_PASSES_CURRENT_INIT = 'GET_PASSES_CURRENT_INIT'
export const GET_PASSES_EXPIRED_INIT = 'GET_PASSES_EXPIRED_INIT'
export const GET_VOUCHERS_INIT = 'GET_VOUCHERS_INIT'
export const GET_POINTS_INIT = 'GET_POINTS_INIT'
export const GET_POINTS_DETAILS_INIT = 'GET_POINTS_DETAILS_INIT'
export const GET_EQUIPMENT_INIT = 'GET_EQUIPMENT_INIT'
export const GET_EQUIPMENT_DETAILS_INIT = 'GET_EQUIPMENT_DETAILS_INIT'
export const REMOVE_EQUIPMENT = 'REMOVE_EQUIPMENT'
export const UPDATE_EQUIPMENT = 'UPDATE_EQUIPMENT'
export const GET_PROFILE_INIT = 'GET_PROFILE_INIT'
export const GET_CAMP_CLUB_PROFILE_INIT = 'GET_CAMP_CLUB_PROFILE_INIT'
export const UPDATE_CAMP_CLUB_PROFILE = 'UPDATE_CAMP_CLUB_PROFILE'
export const GET_RATE_RESERVATION_INIT = 'GET_RATE_RESERVATION_INIT'
export const RATE_RESERVATION = 'RATE_RESERVATION'
export const REQUEST_CONFIRMATION_EMAIL = 'REQUEST_CONFIRMATION_EMAIL'

/* List Campground */
export const GET_LIST_CAMPGROUND_INIT = 'GET_LIST_CAMPGROUND_INIT'
export const CREATE_CG_OWNER_ACCT = 'CREATE_CG_OWNER_ACCT'

/* General Route Change */
export const ROUTE_CHANGE_ACTION = 'ROUTE_CHANGE_ACTION'

/* App */
export const GET_APP_INIT = 'GET_APP_INIT'

/* Feedback */
export const SEND_FEEDBACK = 'SEND_FEEDBACK'
export const REQUEST_FEEDBACK = 'REQUEST_FEEDBACK'

/* Redirect */
export const REQUEST_REDIRECT = 'REQUEST_REDIRECT'

/* SSR Help */
export const LAUNCH_DATA_UPDATE_SKIP = 'LAUNCH_DATA_UPDATE_SKIP'

/* CamperHub */
export const GET_CAMPER_HUB_FACILITY = 'GET_CAMPER_HUB_FACILITY'
export const UPDATE_CAMPER_HUB_FACILITY_AVAILABLE_DATES =
  'UPDATE_CAMPER_HUB_FACILITY_AVAILABLE_DATES'
export const UPDATE_CAMPER_HUB_FACILITY = 'UPDATE_CAMPER_HUB_FACILITY'

/* Card Vault */
export const SET_CARDVAULT_IFRAME_REF = 'SET_CARDVAULT_IFRAME_REF'

/* CHub Cart */
export const CHUB_CREATE_CART = 'CHUB_CREATE_CART'
export const CHUB_CLAIM_CART = 'CHUB_CLAIM_CART'
export const CHUB_GET_CART_BY_ID = 'CHUB_GET_CART_BY_ID'
export const CHUB_ADD_ITEM_TO_CART = 'CHUB_ADD_ITEM_TO_CART'
export const CHUB_REMOVE_ITEM_FROM_CART = 'CHUB_REMOVE_ITEM_FROM_CART'
export const CHUB_GET_LATEST_CART_FROM_CURRENT_USER =
  'GET_LATEST_CART_FROM_CURRENT_USER'
export const CHUB_GET_CART_CONFIG = 'CHUB_GET_CART_CONFIG'
export const CHUB_CHECKOUT = 'CHUB_CHECKOUT'
export const CHUB_TOGGLE_CART = 'CHUB_TOGGLE_CART'
export const CHUB_TOGGLE_CART_MODAL = 'CHUB_TOGGLE_CART_MODAL'

export const CHUB_GET_APEX_CLUB_STATUS = 'CHUB_GET_APEX_CLUB_STATUS'
export const CHUB_UPDATE_AVAILABLE_SPOTS = 'CHUB_UPDATE_AVAILABLE_SPOTS'

export const CHUB_DECLINE_QUOTE = 'CHUB_DECLINE_QUOTE'
export const SET_EVAULT_WAS_MOUNTED = 'SET_EVAULT_WAS_MOUNTED'
export const SET_AVAILABLE_SW_QUOTES = 'SET_AVAILABLE_SW_QUOTES'
export const UPDATE_CHUBCART_RESPONSE = 'UPDATE_CHUBCART_RESPONSE'
export const DECLINE_UNPURCHASED_SW_QUOTES = 'DECLINE_UNPURCHASED_SW_QUOTES'
export const CHUB_GET_ADDON_OFFER_STATUSES = 'CHUB_GET_ADDON_OFFER_STATUSES'

/* CHUB Form */
export const CHUB_SET_SELECTED_RESERVATION_DATES =
  'CHUB_SET_SELECTED_RESERVATION_DATES'
export const CHUB_SET_EXCLUDED_RESERVATION_DATES =
  'CHUB_SET_EXCLUDED_RESERVATION_DATES'
export const CHUB_SET_SELECTED_RESERVATION_DATE_MODE =
  'CHUB_SET_SELECTED_RESERVATION_DATE_MODE'
export const CHUB_SET_RESERVATION_DATE_BOUNDARIES = 'CHUB_SET_MAX_DATE'

/* S2N */
export const S2N_TOGGLE_ABANDON_CART_MODAL = 'S2N_TOGGLE_ABANDON_CART_MODAL'

/* FRONTED SINGIN */
export const USER_DATA = 'USER_DATA'
