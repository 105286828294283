import { isUndefined } from '../external/utilities/GeneralUtils'

export const AGENT_AVAILABLE = 'chat with an expert'
export const CHAT_CONNECTED = 'connected'

export const getWebChatAgentStatus = function () {
  if (!isUndefined(window.getWebChatAgentStatus)) {
    return window.getWebChatAgentStatus()
  }

  return ''
}

export const getWebChatStatus = function () {
  if (!isUndefined(window.getWebChatStatus)) {
    return window.getWebChatStatus()
  }

  return null
}

export const startWebChat = function () {
  if (!isUndefined(window.startWebChat)) {
    window.startWebChat()
  }
}

export const isWebChatAgentAvailable = function (currChatAgentStatus) {
  if (currChatAgentStatus.toLowerCase() === AGENT_AVAILABLE) {
    return true
  }

  return false
}

export const isWebChatConnected = function (currChatStatus) {
  if (currChatStatus.toLowerCase() === CHAT_CONNECTED) {
    return true
  }

  return false
}
