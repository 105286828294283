/**
 * @file   : HTag.js
 * @author : Amarjeet Lamba (Amarjeet.Lamba@AspiraConnect.com)
 * @date   : 2018-6-13 14:48:05
 */
import PropTypes from 'prop-types'
import React from 'react'

import { isNumber, isUndefined } from '../../utilities/GeneralUtils'
import TextTag, { TAG_TYPE } from './TextTag'

export const HEADER_TYPES = [
  TAG_TYPE.H1,
  TAG_TYPE.H2,
  TAG_TYPE.H3,
  TAG_TYPE.H4,
  TAG_TYPE.H5,
  TAG_TYPE.H6,
]
export const isHTagFromHTagObj = function (obj) {
  let isHTag = false
  if (!isUndefined(obj) && obj.visible) {
    isHTag = true
  }

  return isHTag
}
export const getTagTypeFromHTagObj = function (obj) {
  let tagType = null
  if (!isUndefined(obj) && obj.visible && isNumber(obj.level)) {
    if (obj.level === 1) {
      tagType = TAG_TYPE.H1
    } else if (obj.level === 2) {
      tagType = TAG_TYPE.H2
    } else if (obj.level === 3) {
      tagType = TAG_TYPE.H3
    } else if (obj.level === 4) {
      tagType = TAG_TYPE.H4
    } else if (obj.level === 5) {
      tagType = TAG_TYPE.H5
    } else if (obj.level === 6) {
      tagType = TAG_TYPE.H6
    }
  }

  return tagType
}

// eslint-disable-next-line one-var
const HTag = ({
  h1 = false,
  h2 = false,
  h3 = false,
  h4 = false,
  h5 = false,
  h6 = false,
  type = null,
  style,
  children,
}) => {
  const getHLevel = function () {
    if (type !== null) {
      return type
    }
    if (h1) {
      return TAG_TYPE.H1
    } else if (h2) {
      return TAG_TYPE.H2
    } else if (h3) {
      return TAG_TYPE.H3
    } else if (h4) {
      return TAG_TYPE.H4
    } else if (h5) {
      return TAG_TYPE.H5
    } else if (h6) {
      return TAG_TYPE.H6
    }

    return TAG_TYPE.H1
  }

  return (
    <TextTag style={style} tagType={getHLevel()}>
      {children}
    </TextTag>
  )
}

HTag.propTypes = {
  /** h1 - is tag h1 */
  h1: PropTypes.bool,
  /** h2 - is tag h2 */
  h2: PropTypes.bool,
  /** h3 - is tag h3 */
  h3: PropTypes.bool,
  /** h4 - is tag h4 */
  h4: PropTypes.bool,
  /** h5 - is tag h5 */
  h5: PropTypes.bool,
  /** h6 - is tag h6 */
  h6: PropTypes.bool,
  /** header type can be specified instead of using the shorthand booleans h1 - h6 */
  type: PropTypes.oneOf(HEADER_TYPES),
  /** style classes */
  style: PropTypes.string,
  /** the body of the tag, should be just text or spans (no divs)  */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}

export default HTag
