import PropTypes from 'prop-types'
import React from 'react'
import FormCheck from 'react-bootstrap/FormCheck'
import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
import InputGroup from 'react-bootstrap/InputGroup'

/**
 * Wrapper component for react-bootstrap input https://react-bootstrap.github.io/components.html#forms-controls
 *
 * @example ../../../../docs/external/form/AInput.md
 */

const AInput = ({
  className = '',
  name,
  autoFocus,
  checked,
  children,
  cmpRef,
  defaultValue,
  disabled,
  help,
  id,
  inlineStyle,
  label,
  maxLength = null,
  multiple,
  onBlur,
  onChange,
  onClick,
  onKeyPress,
  placeholder = null,
  readOnly,
  rows,
  size,
  style,
  type,
  value,
  cmpLabel,
  inputRef,
  invalid,
  prepender = null,
  appender = null,
  step = null,
  pattern = null,
  minNumber = null,
  maxNumber = null,
}) => {
  const isTextBased = function () {
    if (
      type === 'text' ||
      type === 'textarea' ||
      type === 'number' ||
      type === 'email' ||
      type === 'file' ||
      type === 'tel'
    ) {
      return true
    }

    return false
  }

  const getAs = function () {
    if (isTextBased() && type !== 'textarea') {
      return 'input'
    }

    return type
  }
  const getFormLabel = function () {
    if (typeof label !== 'undefined') {
      return <FormLabel>{label}</FormLabel>
    }

    return null
  }
  const getHelpBlock = function () {
    if (typeof help !== 'undefined') {
      return <p className="form-help-block">{help}</p>
    }

    return null
  }
  const getType = function () {
    if (isTextBased() && type !== 'textarea') {
      return type
    }

    if (type === 'checkbox' || type === 'radio') {
      return type
    }

    return null
  }
  const getRows = function () {
    if (type === 'textarea') {
      return rows
    }

    return null
  }

  const getStep = function () {
    if (type === 'number' && step !== null) {
      return step
    }

    return null
  }

  const getPattern = function () {
    if (type === 'text' && pattern !== null) {
      return pattern
    }

    return null
  }

  const getMinNumber = function () {
    if (type === 'number' && minNumber !== null) {
      return minNumber
    }

    return null
  }

  const getMaxNumber = function () {
    if (type === 'number' && maxNumber !== null) {
      return maxNumber
    }

    return null
  }

  const getCoreInput = function () {
    return (
      <FormControl
        name={name}
        autoFocus={autoFocus}
        ref={inputRef}
        size={size}
        variant={style}
        checked={checked}
        data-ref={cmpRef}
        defaultValue={defaultValue}
        disabled={disabled}
        id={id}
        maxLength={maxLength}
        multiple={multiple}
        onBlur={onBlur}
        onChange={onChange}
        onClick={onClick}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        readOnly={readOnly}
        rows={getRows()}
        style={inlineStyle}
        type={getType()}
        as={getAs()}
        value={value}
        aria-label={cmpLabel}
        step={getStep()}
        pattern={getPattern()}
        min={getMinNumber()}
        max={getMaxNumber()}
      >
        {children}
      </FormControl>
    )
  }

  const getPrepnederUI = function () {
    if (type === 'text' || type === 'number' || type === 'email') {
      if (prepender !== null) {
        return <InputGroup.Addon>{prepender}</InputGroup.Addon>
      }
    }

    return null
  }

  const getAppenderUI = function () {
    if (type === 'text' || type === 'number' || type === 'email') {
      if (appender !== null) {
        return <InputGroup.Addon>{appender}</InputGroup.Addon>
      }
    }

    return null
  }

  const getInputUI = function () {
    if (prepender !== null || appender !== null) {
      return (
        <InputGroup>
          {getPrepnederUI()}
          {getCoreInput()}
          {getAppenderUI()}
        </InputGroup>
      )
    }

    return getCoreInput()
  }

  if (isTextBased()) {
    if (maxLength === null && type !== 'number' && type !== 'file') {
      maxLength = 100
    }
  }

  if (!isTextBased()) {
    placeholder = null
  }

  if (type === 'checkbox' || type === 'radio') {
    return (
      <FormGroup
        className={className}
      >
        <FormCheck
          autoFocus={autoFocus}
          size={size}
          variant={style}
          value={value}
          checked={checked}
          data-ref={cmpRef}
          defaultValue={defaultValue}
          disabled={disabled}
          id={id}
          maxLength={maxLength}
          multiple={multiple}
          onBlur={onBlur}
          onChange={onChange}
          onClick={onClick}
          onKeyPress={onKeyPress}
          placeholder={placeholder}
          readOnly={readOnly}
          rows={rows}
          style={inlineStyle}
          type={getType()}
          aria-label={cmpLabel}
          aria-checked={checked}
          label={label}
        />
        {getHelpBlock()}
      </FormGroup>
    )
  }

  return (
    <FormGroup
      className={`${className} ${invalid ? 'has-error' : ''}`}
    >
      {getFormLabel()}
      {getInputUI()}
      {getHelpBlock()}
    </FormGroup>
  )
}

AInput.propTypes = {
  inputRef: PropTypes.any,
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  checked: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.node,
  cmpRef: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  help: PropTypes.node,
  id: PropTypes.string,
  inlineStyle: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  maxLength: PropTypes.number,
  multiple: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  rows: PropTypes.number,
  size: PropTypes.number,
  style: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  cmpLabel: PropTypes.string,
  prepender: PropTypes.string,
  appender: PropTypes.string,
  step: PropTypes.string,
  pattern: PropTypes.string,
  minNumber: PropTypes.number,
  maxNumber: PropTypes.number,
  invalid: PropTypes.bool,
}

export default AInput
