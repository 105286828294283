import { addDays } from 'date-fns'
import { isNumber } from 'lodash'

import {
  createDateFromYYYYMMDD,
  getClientToday,
} from '../external/utilities/DateUtils'
import { isDayAfter } from '../external/utilities/isDayAfter'
import {
  getCalculatedTotalForOrder,
  getMixpanelParamsCompletedPayment,
  getSingleCampingOrder,
  getUnformattedArrivalDate,
  getUnformattedDepartureDate,
} from '../utilities/CartUtils'
import { selectAddonsMinDays, selectAddonsVisible } from './config'
import { selectCurrentUserEmail } from './user'

export const selectCartConfig = (state) => state.backend.cart
export const selectChubCart = (state) => state.backend.chubcart
export const selectCurrentCart = (state) => selectCartConfig(state)?.currentCart

export const selectCartProcessResult = (state) =>
  selectCartConfig(state)?.detailedProcessResult

export const selectCartV0 = (state) =>
  selectCartProcessResult(state)?.shoppingCartVO || {}

export const selectCartV0ContractViewList = (state) =>
  selectCartV0(state)?.contractViewList || []

export const selectCartContractViewList = (state) =>
  selectCurrentCart(state)?.contractViewList || []

export const selectCartOrderContractCodes = (state) =>
  selectCartV0ContractViewList(state).map(({ contractCode }) => contractCode)

export const selectCartAddonsAllowed = (state) =>
  !selectCartOrderContractCodes(state).some((code) =>
    ['PA', 'ID', 'OR', 'CT', 'RI', 'NY'].includes(code),
  )

export const selectCartCampingOrder = (state) =>
  getSingleCampingOrder(selectCartV0(state))

export const selectCartHasCampingOrder = (state) =>
  !!selectCartCampingOrder(state)

export const selectShowCartAddons = (state) =>
  selectAddonsVisible(state) &&
  selectCartAddonsAllowed(state) &&
  selectCartHasCampingOrder(state) &&
  selectCartAddonsDataValid(state)

export const selectCartPrimaryOccupantInfo = (state) =>
  selectCartCampingOrder(state)?.webOrder?.primaryOccupantInfo

export const selectCartCampingOrderCoords = (state) =>
  selectCartCampingOrder(state)?.coordinates || {}

export const selectCartAddonsData = (state) => {
  const campingOrder = selectCartCampingOrder(state)

  const primaryOccupantInfo = selectCartPrimaryOccupantInfo(state)
  const orderCoords = selectCartCampingOrderCoords(state)

  return {
    custfirstname: primaryOccupantInfo?.firstName || '',
    custlastname: primaryOccupantInfo?.lastName || '',
    custemail: primaryOccupantInfo?.email || '',
    custphone: primaryOccupantInfo?.phoneNumber || '',
    arrivaldate: getUnformattedArrivalDate(campingOrder),
    departuredate: getUnformattedDepartureDate(campingOrder),
    facilityname: campingOrder?.parkName || '',
    facilitylatitude: orderCoords?.latitude || '',
    facilitylongitude: orderCoords?.longitude || '',
    ordernumber: campingOrder?.webOrder?.orderNumber,
    ordertotal: getCalculatedTotalForOrder(campingOrder),
  }
}

export const selectCartAddonsDataValid = (state) => {
  const {
    arrivaldate,
    departuredate,
    facilityname,
    facilitylatitude,
    facilitylongitude,
    ordernumber,
    ordertotal,
  } = selectCartAddonsData(state)
  const minDaysNum = selectAddonsMinDays(state)

  const minAddonDate = addDays(getClientToday(), minDaysNum)
  const arrivalDate = createDateFromYYYYMMDD(arrivaldate)

  return (
    !!arrivaldate &&
    !!departuredate &&
    !!ordernumber &&
    !!facilityname &&
    !!ordertotal &&
    isNumber(facilitylatitude) &&
    isNumber(facilitylongitude) &&
    parseInt(ordertotal) > 0 &&
    !isDayAfter(minAddonDate, arrivalDate)
  )
}

export const selectDefaultCartOrder = (state) => {
  const contractViewList = selectCartContractViewList(state)

  return contractViewList[0]?.cartOrderViews?.[0]
}

export const selectCartOrderById = (state, cartItemId) => {
  const contractViewList = selectCartContractViewList(state)

  return contractViewList?.reduce((order, contract) => {
    if (order) return order

    return contract.cartOrderViews.find(
      (order) => order.cartItemId === cartItemId,
    )
  }, undefined)
}

export const selectOrderMixpanelPayloadByCartItemId = (state, cartItemId) => {
  const order = cartItemId
    ? selectCartOrderById(state, cartItemId)
    : selectDefaultCartOrder(state)
  const emailId = selectCurrentUserEmail(state)

  if (!order) return undefined

  const {
    parkId,
    parkName,
    parkState,
    orderType,
    contractCode,
    contractType,
    agencyName,
    webOrder: {
      orderNumber,
      units,
      arrivalDate,
      endDate,
      site: { id: siteId },
    } = {},
  } = order

  return {
    emailId,
    reservationId: orderNumber,
    parkId,
    parkName,
    parkState,
    orderType,
    contractCode,
    contractType,
    agencyName,
    orderUnits: units,
    arrivalDate,
    endDate,
    siteId,
  }
}

export const selectOfferMixpanelPayloadById = (state, itemId) => {
  const chubCart = selectChubCart(state)

  const offerItem = chubCart?.cartItems.find((item) => item.item_id === itemId)

  if (!offerItem) return undefined

  const orderPayload = selectOrderMixpanelPayloadByCartItemId(
    state,
    offerItem.cartItemId,
  )

  if (!orderPayload) return undefined

  return {
    ...orderPayload,
    vendor: offerItem.vendor,
    offerType: offerItem.type,
  }
}

export const selectCurrentCartItemsCount = (state) =>
  selectCurrentCart(state)?.itemsCount

export const selectCartMixpanelPayload = (state) => {
  const currentCart = selectCurrentCart(state)
  const chubCart = selectChubCart(state)
  const email = selectCurrentUserEmail(state)

  if (!currentCart?.contractViewList?.length && !chubCart?.cartItems?.length)
    return {}

  return { email, ...getMixpanelParamsCompletedPayment(currentCart, chubCart) }
}
