/* NOTE - IMPORTANT - Aria roles should rarely be required. Most controls like buttons, inputs etc. should be left as is.
  Use Roles only when we create components that behave like another component. Use this as a last resort. */
export const ROLES = {
  WIDGET: {
    ALERT: 'alert',
    ALERTDIALOG: 'alertdialog',
    BUTTON: 'button',
    CHECKBOX: 'checkbox',
    DIALOG: 'dialog',
    GRIDCELL: 'gridcell',
    LINK: 'link',
    LOG: 'log',
    MARQUEE: 'marquee',
    MENUITEM: 'menuitem',
    MENUITEMCHECKBOX: 'menuitemcheckbox',
    MENUITEMRADIO: 'menuitemradio',
    OPTION: 'option',
    PROGRESSBAR: 'progressbar',
    RADIO: 'radio',
    SCROLLBAR: 'scrollbar',
    SLIDER: 'slider',
    SPINBUTTON: 'spinbutton',
    STATUS: 'status',
    TAB: 'tab',
    TABPANEL: 'tabpanel',
    TEXTBOX: 'textbox',
    TIMER: 'timer',
    TOOLTIP: 'tooltip',
    TREEITEM: 'treeitem',
    COMBOBOX: 'combobox',
    GRID: 'grid',
    LISTBOX: 'listbox',
    MENU: 'menu',
    MENUBAR: 'menubar',
    RADIOGROUP: 'radiogroup',
    TABLIST: 'tablist',
    TREE: 'tree',
    TREEGRID: 'treegrid',
  },
  DOC_STRUCTURE: {
    ARTICLE: 'article',
    COLUMNHEADER: 'columnheader',
    DEFINITION: 'definition',
    DIRECTORY: 'directory',
    DOCUMENT: 'document',
    GROUP: 'group',
    HEADING: 'heading',
    IMG: 'img',
    LIST: 'list',
    LISTITEM: 'listitem',
    MATH: 'math',
    NOTE: 'note',
    PRESENTATION: 'presentation',
    REGION: 'region',
    ROW: 'row',
    ROWGROUP: 'rowgroup',
    ROWHEADER: 'rowheader',
    SEPARATOR: 'separator',
    TOOLBAR: 'toolbar',
  },
  LANDMARK: {
    APPLICATION: 'application',
    BANNER: 'banner',
    COMPLEMENTARY: 'complementary',
    CONTENTINFO: 'contentinfo',
    FORM: 'form',
    MAIN: 'main',
    NAVIGATION: 'navigation',
    SEARCH: 'search',
  },
}

export const ATTRIBUTES = {
  WIDGET: {
    ARIA_AUTOCOMPLETE: 'aria-autocomplete',
    ARIA_CHECKED: 'aria-checked',
    ARIA_DISABLED: 'aria-disabled',
    ARIA_EXPANDED: 'aria-expanded',
    ARIA_HASPOPUP: 'aria-haspopup',
    ARIA_HIDDEN: 'aria-hidden',
    ARIA_INVALID: 'aria-invalid',
    ARIA_LABEL: 'aria-label',
    ARIA_LEVEL: 'aria-level',
    ARIA_MULTILINE: 'aria-multiline',
    ARIA_MULTISELECTABLE: 'aria-multiselectable',
    ARIA_ORIENTATION: 'aria-orientation',
    ARIA_PRESSED: 'aria-pressed',
    ARIA_READONLY: 'aria-readonly',
    ARIA_REQUIRED: 'aria-required',
    ARIA_SELECTED: 'aria-selected',
    ARIA_SORT: 'aria-sort',
    ARIA_VALUEMAX: 'aria-valuemax',
    ARIA_VALUMIN: 'aria-valuemin',
    ARIA_VALUENOW: 'aria-valuenow',
    ARIA_VALUETEXT: 'aria-valuetext',
  },
  LIVE_REGION: {
    ARIA_ATOMIC: 'aria-atomic',
    ARIA_BUSY: 'aria-busy',
    ARIA_LIVE: 'aria-live',
    ARIA_RELEVANT: 'aria-relevant',
  },
  DRAG_AND_DROP: {
    ARIA_DROPEFFECT: 'aria-dropeffect',
    ARIA_GRABBED: 'aria-grabbed',
  },
  RELATIONSHIP: {
    ARIA_ACTIVEDESCENDANT: 'aria-activedescendant',
    ARIA_CONTROLS: 'aria-controls',
    ARIA_DESCRIBEDBY: 'aria-describedby',
    ARIA_FLOWTO: 'aria-flowto',
    ARIA_LABELLEDBY: 'aria-labelledby',
    ARIA_OWNS: 'aria-owns',
    ARIA_POSINET: 'aria-posinset',
    ARIA_SETSIZE: 'aria-setsize',
  },
}
export const LANDMARKS = {
  HEADER: {
    tag: 'header',
    role: ROLES.LANDMARK.BANNER,
  },
  NAV: {
    tag: 'nav',
    role: ROLES.LANDMARK.NAVIGATION,
  },
  MAIN: {
    tag: 'main',
    role: ROLES.LANDMARK.MAIN,
  },
  FOOTER: {
    tag: 'footer',
    role: ROLES.LANDMARK.CONTENTINFO,
  },
  ASIDE: {
    tag: 'aside',
    role: ROLES.LANDMARK.COMPLEMENTARY,
  },
  SECTION: {
    tag: 'section',
    role: ROLES.DOC_STRUCTURE.REGION,
  },
  ARTICLE: {
    tag: 'article',
    role: ROLES.DOC_STRUCTURE.ARTICLE,
  },
  SEARCH: {
    tag: 'div',
    role: ROLES.LANDMARK.SEARCH,
  },
  FORM: {
    tag: 'form',
    role: ROLES.LANDMARK.FORM,
  },
  NONE: {
    tag: 'div',
    role: null,
  },
}
