import PropTypes from 'prop-types'
import React from 'react'

import ABaseLink from '../../link/ABaseLink'

/** Submenu items - should be preceeded by a SubMenu component.  Can be either just text, or an icon followed by text

@example  ../../../../../docs/external/navigation/menu/SubMenuItem.md */

const SubMenuItem = ({
  className = 'sub-menu-item',
  labelClassName = 'sub-menu-item__text',
  onClick,
  label,
  selected,
  to,
  internal,
}) => (
  <li className={className + (selected ? ' sub-menu-item--selected' : '')}>
    <div className={labelClassName}>
      <ABaseLink cmpLabel={label} onClick={onClick} to={to} internal={internal}>
        {label}
      </ABaseLink>
    </div>
  </li>
)

SubMenuItem.propTypes = {
  /** wrapper classname for custom styling */
  className: PropTypes.string,
  /** wrapper classname for label for custom styling */
  labelClassName: PropTypes.string,
  /** label for sub menu item */
  label: PropTypes.string,
  /** function to run when sub menu item is clicked */
  onClick: PropTypes.func.isRequired,
  /** whether or not this item is the selected item */
  selected: PropTypes.bool,
  to: PropTypes.string,
  internal: PropTypes.bool,
}

export default SubMenuItem
