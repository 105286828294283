export const getPurchaseData = (
  data,
  checkoutId = null,
  bookingType,
  agencyName = null,
) => {
  if (data.transactionsGA4) {
    const items = []
    let total = 0
    let transactionId
    const quantity = data.transactionsGA4?.length

    for (const currentTransaction of data.transactionsGA4) {
      const transactionEvent = { ...currentTransaction.transaction }
      transactionId = transactionEvent.transaction_id
      total += transactionEvent.value

      items.push(transactionEvent)
    }

    return {
      transaction_id: transactionId,
      parkName: items[0]?.items[0]?.item_name ?? 'NO_NAME',
      currency: 'USD',
      price: total,
      quantity,
      item: {
        bookingType,
        items,
      },
    }
  }

  return {
    transaction_id: checkoutId,
    parkName: data.park?.name,
    currency: 'USD',
    price: data.total,
    afilliation: agencyName,
    quantity: 1,
    item: {
      bookingType,
      item_id: data.park?.id,
      item_name: data.park?.name,
      item_category: data.spot?.category?.name,
      price: data.total,
    },
  }
}
