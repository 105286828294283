import { addMonths, parseISO } from 'date-fns'
import { isEmpty } from 'lodash'

export function getMinimumDate() {
  return new Date()
}

export function getMaximumDate(numberOfWindowMonths, referenceDateLiteral) {
  const referenceDate = isEmpty(referenceDateLiteral)
    ? new Date()
    : parseISO(referenceDateLiteral)
  const numMonths = numberOfWindowMonths || 12

  return addMonths(referenceDate, numMonths) // today + provided number of months
}

export function getMaximumDateDate(numberOfWindowMonths, referenceDateLiteral) {
  return getMaximumDate(numberOfWindowMonths, referenceDateLiteral).toDate()
}
