const process = require('../environment.json')

const secure = process.env.ENVIRONMENT !== 'dev' ? ';Secure' : ''

// **** ---- Added for RA Decuple ----- *****
const getDocumentCookies = () => {
  try {
    const cookie = `; ${document.cookie}`
    const values = cookie.split(';').reduce((res, item) => {
      const data = item.trim().split('=')

      return { ...res, [data[0]]: data[1] }
    }, {})
    // console.log('Cookies on value returned -> ', values)

    return values
  } catch (e) {
    return []
  }
}

exports.getCookie = (name) => {
  const cookie = getDocumentCookies()
  const cookieValue = cookie[name]
  try {
    const co = JSON.parse(decodeURIComponent(cookieValue))

    return co
  } catch (error) {
    return cookieValue
  }
}

exports.setCookie = (cookieName, cookieValue) => {
  document.cookie = `${cookieName}=${cookieValue};path=/${secure}`
}

exports.getCookies = getDocumentCookies()
