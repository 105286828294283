import PropTypes from 'prop-types'
import React from 'react'

export const TAG_TYPE = {
  BOLD: 'b',
  EMPHASIS: 'em',
  ITALICS: 'i',
  SMALL: 'small',
  STRONG: 'strong',
  SUBSCRIPT: 'sub',
  SUPERSCRIPT: 'sup',
  INSERTED: 'ins',
  DELETED: 'del',
  MARKED: 'mark',
  PARAGRAPH: 'p',
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  H6: 'h6',
  SPAN: 'span',
}

/**
 * @author Paul Fabbroni <Paul.Fabbroni@activenetwork.com>
 *
 * Component to return children wrapped in an html tag.
 * @returns {element} children wrapped in an html tag.
 *
 * @example ../../../../docs/external/text/TextTag.md
 *
 */

// eslint-disable-next-line one-var
const TextTag = ({ tagType, style = null, children }) => {
  switch (tagType) {
    case TAG_TYPE.BOLD:
      return <b>{children}</b>
    case TAG_TYPE.EMPHASIS:
      return <em>{children}</em>
    case TAG_TYPE.ITALICS:
      return <i>{children}</i>
    case TAG_TYPE.SMALL:
      return <small>{children}</small>
    case TAG_TYPE.STRONG:
      return <strong>{children}</strong>
    case TAG_TYPE.SUBSCRIPT:
      return <sub>{children}</sub>
    case TAG_TYPE.SUPERSCRIPT:
      return <sup>{children}</sup>
    case TAG_TYPE.INSERTED:
      return <ins>{children}</ins>
    case TAG_TYPE.DELETED:
      return <del>{children}</del>
    case TAG_TYPE.MARKED:
      return <mark>{children}</mark>
    case TAG_TYPE.PARAGRAPH:
      return <p className={style}>{children}</p>
    case TAG_TYPE.H1:
      return <h1 className={style}>{children}</h1>
    case TAG_TYPE.H2:
      return <h2 className={style}>{children}</h2>
    case TAG_TYPE.H3:
      return <h3 className={style}>{children}</h3>
    case TAG_TYPE.H4:
      return <h4 className={style}>{children}</h4>
    case TAG_TYPE.H5:
      return <h5 className={style}>{children}</h5>
    case TAG_TYPE.H6:
      return <h6 className={style}>{children}</h6>
    case TAG_TYPE.SPAN:
      return <span className={style}>{children}</span>
    default:
      return <span>{children}</span>
  }
}

TextTag.propTypes = {
  /** the type of html tag you want */
  tagType: PropTypes.oneOf(Object.keys(TAG_TYPE).map((key) => TAG_TYPE[key])),
  /** style classes */
  style: PropTypes.string,
  /** the body of the tag, should be just text or spans (no divs)  */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default TextTag
