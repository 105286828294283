import PropTypes from 'prop-types'
import React from 'react'

/**
 * ICON naming convention
 *
 * Alternative - if the icon is another variation
 * Shape - if the icon has border e.g. circle, square
 * Outline - if the icon is not solid
 * Direction - if the icon is pointing to speficific direction e.g right, left
 *
 * E.g.(1) fa-caret-square-o-right - caretSquareOutlineRight
 * E.g.(2) fa-arrow-circle-o-down - arrowCircleOutlineDown
 *
 */

export const ICONS = []
/**
 * These icons are using older conventions, please use the new convention suggested above and do not add new using this convention.
 * We are leaving these icons here because we have some data sent by the server using this convention.
 */
ICONS.smiley = {
  fa: 'fa-smile-o',
  glyph: '',
  material: '',
}
ICONS.frowny = {
  fa: 'fa-frown-o',
  glyph: '',
  material: '',
}
ICONS.info = {
  fa: 'fa-info-circle',
  glyph: '',
  material: '',
}
ICONS.error = {
  fa: 'fa-exclamation-circle',
  glyph: '',
  material: '',
}
/**
 * Add the new icons below, and use the suggested naming convention.
 */
ICONS.smileOutline = {
  fa: 'fa-smile-o',
  glyph: '',
  material: '',
}
ICONS.frownOutline = {
  fa: 'fa-frown-o',
  glyph: '',
  material: '',
}
ICONS.infoCircle = {
  fa: 'fa-info-circle',
  glyph: '',
  material: '',
}
ICONS.exclamationCircle = {
  fa: 'fa-exclamation-circle',
  glyph: '',
  material: '',
}
ICONS.star = {
  fa: 'fa-star',
  glyph: '',
  material: '',
}
ICONS.starOutline = {
  fa: 'fa-star-o',
  glyph: '',
  material: '',
}
ICONS.facebook = {
  fa: 'fa-facebook',
  glyph: '',
  material: '',
}
ICONS.twitter = {
  fa: 'fa-twitter',
  glyph: '',
  material: '',
}
ICONS.instagram = {
  fa: 'fa-instagram',
  glyph: '',
  material: '',
}
ICONS.googleplus = {
  fa: 'fa-google-plus',
  glyph: '',
  material: '',
}
ICONS.pinterest = {
  fa: 'fa-pinterest',
  glyph: '',
  material: '',
}
ICONS.comment = {
  fa: 'fa-comment',
  glyph: '',
  material: '',
}
ICONS.comments = {
  fa: 'fa-comments',
  glyph: '',
  material: '',
}
ICONS.envelope = {
  fa: 'fa-envelope',
  glyph: '',
  material: '',
}
ICONS.envelopeOutline = {
  fa: 'fa-envelope-o',
  glyph: '',
  material: '',
}

ICONS.search = {
  fa: 'fa fa-search',
  glyph: '',
  material: '',
}

ICONS.paperPlane = {
  fa: 'fa fa-paper-plane',
  glyph: '',
  material: '',
}
ICONS.leftAlign = {
  fa: 'fa fa-align-left',
  glyph: '',
  material: '',
}
ICONS.mobile = {
  fa: 'fa fa-mobile',
  glyph: '',
  material: '',
}

ICONS.streetView = {
  fa: 'fa-street-view',
  glyph: '',
  material: '',
}

ICONS.locationArrow = {
  fa: 'fa-location-arrow',
  glyph: '',
  material: '',
}

ICONS.rotateRight = {
  fa: 'fa-rotate-right',
  glyph: '',
  material: '',
}

export const TYPES = {
  fa: {
    name: 'fa',
    prefix: 'fa',
  },
  glyph: {
    name: 'glyph',
    prefix: 'glyphicon',
  },
  material: {
    name: 'material',
    prefix: '',
  },
}

/** Icon generator.  This is intended to be used with multiple font sets
from diffrent providers. We need to keep this generic

@example ../../../../../docs/external/media/icon/AIcon.md  
*/

const AIcon = ({
  icon,
  iconName = '',
  type = TYPES.fa,
  size = '1x',
  padded = false,
}) => {
  let iconUI = <span />
  let padStyle = ''
  if (padded) {
    padStyle = 'a-icon-pad'
  }

  if (typeof icon !== 'undefined') {
    iconName = icon[type.name]
  }

  const sizeStyle = `a-icon-size-${size}`
  const styles = `${type.prefix} ${iconName} a-icon ${sizeStyle} ${padStyle}`

  if (type.name === TYPES.fa.name) {
    iconUI = <i className={styles} aria-hidden="true" />
  } else if (type.name === TYPES.glyph.name) {
    iconUI = <span className={styles} />
  }

  return iconUI
}

AIcon.propTypes = {
  /** object with a name and a prefix for the icon e.g. fa: {name: 'fa',prefix: 'fa'} */
  icon: PropTypes.object,
  /** icon class e.g. fa-pencil or glyphicon-asterisk */
  iconName: PropTypes.string,
  /** icon type - fa, or glyph, or others not implemented yet */
  type: PropTypes.object,
  /** different sizes of icons see components-multi.less for sizes */
  size: PropTypes.oneOf(['1x', '2x', '3x', '4x', '01x', '02x', '03x', '04x']),
  /** add left and right padding between icons */
  padded: PropTypes.bool,
}

export default AIcon
