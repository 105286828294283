/**
 * @file   : DynamicGrid.js
 * @author : Amarjeet Lamba (Amarjeet.Lamba@AspiraConnect.com)
 * @date   : 2/5/2019, 1:19:19 PM
 */
import PropTypes from 'prop-types'
import React from 'react'

import { isEmpty, isUndefined } from '../../utilities/GeneralUtils'
import ACol from '../layout/ACol'
import AGrid from '../layout/AGrid'
import ARow from '../layout/ARow'
import Cont from '../layout/Cont'

const DynamicGrid = ({
  items = [],
  fluid = false,
  maxItemsPerRow = 4,
  colXs = 12,
  colSm = 6,
  colMd = 3,
  colLg = 3,
  getCellContent,
  noContainer = false,
}) => {
  const getRow = function (rowItems, ctr) {
    const currRowItems = []
    let itemCtr = ctr - maxItemsPerRow
    for (let i = ctr - maxItemsPerRow; i < ctr; i++) {
      if (isUndefined(rowItems[itemCtr])) {
        currRowItems.push(null)
      } else {
        currRowItems.push(rowItems[itemCtr])
        itemCtr += 1
      }
    }
    // eslint-disable-next-line one-var
    const ui = currRowItems.map((item, i) => (
      <ACol key={i} xs={colXs} sm={colSm} md={colMd} lg={colLg}>
        {getCellContent(item)}
      </ACol>
    ))

    return <ARow key={ctr}>{ui}</ARow>
  }

  const getItemGrid = function () {
    const ui = items.map((item, i) => {
      // eslint-disable-next-line radix
      const ctr = parseInt(i) + 1
      const mod = ctr % maxItemsPerRow
      let rowUI = null
      if (mod === 0 && ctr !== 1) {
        rowUI = getRow(items, ctr)
      } else if (ctr === items.length) {
        let aCtr = ctr + (maxItemsPerRow - mod)
        if (items.length <= maxItemsPerRow) {
          aCtr = maxItemsPerRow
        }
        rowUI = getRow(items, aCtr)
      }

      return rowUI
    })

    return ui
  }

  if (isUndefined(items) || isEmpty(items)) {
    return null
  }

  if (noContainer) {
    return <Cont>{getItemGrid()}</Cont>
  }

  return <AGrid fluid={fluid}>{getItemGrid()}</AGrid>
}

DynamicGrid.propTypes = {
  items: PropTypes.array.isRequired,
  fluid: PropTypes.bool,
  maxItemsPerRow: PropTypes.number,
  getCellContent: PropTypes.func.isRequired,
  colXs: PropTypes.number,
  colSm: PropTypes.number,
  colMd: PropTypes.number,
  colLg: PropTypes.number,
  noContainer: PropTypes.bool,
}

export default DynamicGrid
