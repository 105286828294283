import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'

import {
  getLandmarkRole,
  getLandmarkTag,
} from '../../utilities/accessibility/AccessibilityUtils'

export const ALIGN_CENTER = 'h-align-center'
export const ALIGN_LEFT = ''
export const ALIGN_RIGHT = 'float-right'
export const ALIGN_LEFT2 = 'float-left'
export const ALIGN_SPACE_BETWEEN = 'd-flex justify-content-between'
export const TEXT_ALIGN_LEFT = 'left'
export const TEXT_ALIGN_RIGHT = 'right'
export const TEXT_ALIGN_CENTER = 'center'
export const PAD_OVERRIDE_5 = 'pad-override-5'
export const PAD_OVERRIDE_10 = 'pad-override-10'
export const PAD_OVERRIDE_20 = 'pad-override-20'

/** Generic container component

@example ../../../../docs/external/layout/Cont.md*/

const Cont = forwardRef(
  (
    {
      align = ALIGN_LEFT,
      alignRightAddClearfix = true,
      inline = false,
      inlinePadding = true,
      hideXS = false,
      hideSM = false,
      hideMD = false,
      hideLG = false,
      hideInPrint = false,
      hideInScreen = false,
      formGroup = false,
      className = '',
      dangerouslySetID,
      children,
      textAlign = 'left',
      hideAll,
      smallMargin = false,
      asContainer = false,
      limitExtraLarge = false,
      cmpLabel = null,
      cmpLabelledby = null,
      cmpTitle = null,
      cmpRole = null,
      cmpLandmark = null,
      asSpan = false,
      cmpSRonly = false,
      padOverride = null,
      backgroundColor = null,
    },
    ref,
  ) => {
    if (hideXS) {
      className += ' hidden-xs'
    }
    if (hideSM) {
      className += ' hidden-sm'
    }
    if (hideMD) {
      className += ' hidden-md'
    }
    if (hideLG) {
      className += ' hidden-lg'
    }
    if (hideAll) {
      className += ' hidden'
    }
    if (hideInPrint) {
      className += ' no-print'
    }
    if (hideInScreen) {
      className += ' no-screen'
    }
    if (formGroup) {
      className += ' form-group'
    }
    if (smallMargin) {
      className += ' form-group-small'
    }
    if (asContainer) {
      className += ' container'
    }
    if (limitExtraLarge) {
      className += ' extra-large-width'
    }
    if (cmpSRonly) {
      className += ' sr-only'
    }

    if (padOverride !== null) {
      className += ` ${padOverride}`
    }

    const Tag = getLandmarkTag(cmpLandmark, asSpan)
    const role = getLandmarkRole(cmpLandmark, cmpRole)

    const style =
      align +
      (inline ? ` inliner${inlinePadding ? ' inliner-padding' : ''}` : '') +
      (textAlign === TEXT_ALIGN_LEFT ? '' : ` t-${textAlign}`)

    return (
      <Tag
        ref={ref}
        id={dangerouslySetID}
        className={className}
        aria-label={cmpLabel}
        aria-labelledby={cmpLabelledby}
        title={cmpTitle}
        role={role}
        style={{ backgroundColor }}
      >
        {style ? <div className={style}>{children}</div> : children}
        {align === ALIGN_RIGHT && alignRightAddClearfix && (
          <div className={'clearfix'} />
        )}
      </Tag>
    )
  },
)

Cont.displayName = 'Cont'

Cont.propTypes = {
  /** wrapper classname for container */
  className: PropTypes.string,
  /** where to align the container on the screen horizontally (default is left) */
  align: PropTypes.oneOf([ALIGN_LEFT, ALIGN_RIGHT, ALIGN_CENTER]),
  /** add clearfix after the ALIGN_RIGHT (default is true) */
  alignRightAddClearfix: PropTypes.bool,
  /** how to align the contents within the container (set to true to align elements left to right on the screen instead of vertically) */
  inline: PropTypes.bool,
  /** remove the the inline padding */
  inlinePadding: PropTypes.bool,
  /** hide on mobile/extra-small */
  hideXS: PropTypes.bool,
  /** hide on small */
  hideSM: PropTypes.bool,
  /** hide on medium */
  hideMD: PropTypes.bool,
  /** hide on large */
  hideLG: PropTypes.bool,
  /** hide when printed */
  hideInPrint: PropTypes.bool,
  /** hide when on the screen (only show when printed for example) */
  hideInScreen: PropTypes.bool,
  /** always hide */
  hideAll: PropTypes.bool,
  /** add bootstrap formgroup class which will add a bottom margin */
  formGroup: PropTypes.bool,
  /** assign an id to the container */
  dangerouslySetID: PropTypes.string,
  /** where to align the text within the container */
  textAlign: PropTypes.oneOf([
    TEXT_ALIGN_LEFT,
    TEXT_ALIGN_CENTER,
    TEXT_ALIGN_RIGHT,
  ]),
  /** add a margin smaller than formGroup to the bottom of the container */
  smallMargin: PropTypes.bool,
  /** treat the container as a bootstrap container element (like the bootstrap Grid element) */
  asContainer: PropTypes.bool,
  /** use extra large screen width for container.  Normally used in conjunction with 'asContainer' */
  limitExtraLarge: PropTypes.bool,
  /** add screen reader only style */
  cmpSRonly: PropTypes.bool,
  /** aria-label for the container */
  cmpLabel: PropTypes.string,
  /** aria-labelledby for the container */
  cmpLabelledby: PropTypes.string,
  /** title for the container */
  cmpTitle: PropTypes.string,
  /** role for the container - use the accessibility definitions defined in Definitions.js */
  cmpRole: PropTypes.string,
  /** landmark for the container */
  cmpLandmark: PropTypes.object,
  /** use a span instead of a div to wrap the children */
  asSpan: PropTypes.bool,
  /** padding overrides */
  padOverride: PropTypes.oneOf([
    PAD_OVERRIDE_5,
    PAD_OVERRIDE_10,
    PAD_OVERRIDE_20,
  ]),
  /** background override */
  backgroundColor: PropTypes.string,
  /** contents of the container */
  children: PropTypes.node,
}

export default Cont
