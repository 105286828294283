import PropTypes from 'prop-types'
import React, { Component } from 'react'

import ACloseButton from '../button/ACloseButton'

/** Custom container with various coloured backgrounds

@example ../../../../docs/external/container/HighlightContainer.md */

export const HIGHLIGHT_DANGER = 'highlight-danger'
export const HIGHLIGHT_IMPORTANT = 'highlight-important'
export const HIGHLIGHT_NEUTRAL = 'highlight-neutral'
export const HIGHLIGHT_SUCCESS = 'highlight-success'
export const HIGHLIGHT_WARNING = 'highlight-warning'
export const HIGHLIGHT_NOTIFICATION = 'highlight-notification'
export const HIGHLIGHT_MESSAGE = 'highlight-message'

class HighlightContainer extends Component {
  constructor() {
    super()
    this.state = { open: true }
  }
  onCloseClick() {
    this.setState({ open: false })
  }
  render() {
    const {
      style,
      children,
      closeable = false,
      boxShadow = false,
      overridePadLR = false,
      overridePadTB = false,
    } = this.props

    if (!this.state.open) {
      return null
    }

    let closeBtnUI = null
    let boxshadowClass = ''
    let overridePadLRClass = ''
    let overridePadTBlass = ''

    if (closeable) {
      closeBtnUI = <ACloseButton onClose={this.onCloseClick.bind(this)} />
    }

    if (boxShadow) {
      boxshadowClass = ' highlight--box-shadow'
    }

    if (overridePadLR) {
      overridePadLRClass = ' override-pad-lr'
    }

    if (overridePadTB) {
      overridePadTBlass = ' override-pad-tb'
    }

    return (
      <div
        className={`highlight ${style}${boxshadowClass}${overridePadLRClass}${overridePadTBlass}`}
      >
        <div className={'highlight__child-container'}>{children}</div>
        <div className={'highlight__close-button'}>{closeBtnUI}</div>
      </div>
    )
  }
}

/**
 * function to return dark or light text
 * @param {string} text The text to return.
 * @param {string} highlight if 'highlight-important' then dark, otherwise light.
 * @returns {element} span tag with text inside.
 */
export function getStyledText(text, highlight) {
  if (highlight === HIGHLIGHT_IMPORTANT) {
    return <span className={'highlight-text-dark'}>{text}</span>
  }

  return <span className={'highlight-text-light'}>{text}</span>
}

HighlightContainer.propTypes = {
  style: PropTypes.string.isRequired,
  children: PropTypes.node,
  /** if true, close button appears */
  closeable: PropTypes.bool,
  /** if true, container has a box shadow */
  boxShadow: PropTypes.bool,
  overridePadLR: PropTypes.bool,
  overridePadTB: PropTypes.bool,
}

export default HighlightContainer
