import PropTypes from 'prop-types'
import React from 'react'

/** Wrapper class for react-bootstrap clearfix https://react-bootstrap.github.io/components.html#grids-clearfix

@example ../../../../docs/external/layout/AClearfix.md */

const AClearfix = ({ children }) => <div className={'clearfix'}>{children}</div>

AClearfix.propTypes = {
  as: PropTypes.string,
  visibleLgBlock: PropTypes.bool,
  visibleMdBlock: PropTypes.bool,
  visibleSmBlock: PropTypes.bool,
  visibleXsBlock: PropTypes.bool,
  children: PropTypes.node,
}

export default AClearfix
