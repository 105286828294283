import { getResultsFromCache } from '../utilities/SearchUtils'

export const selectSearchState = (state) => state.backend.search

export const selectQuickSearchState = (state) =>
  selectSearchState(state).quickSearch

export const selectSearchWebCache = (state) =>
  selectQuickSearchState(state).webCache

export const selectSearchInternalCache = (state) =>
  selectQuickSearchState(state).internalCache

export const selectSearchWebCacheByTerm = (state, searchTerm) => {
  const cache = selectSearchWebCache(state)

  return getResultsFromCache(searchTerm, cache)
}

export const selectSearchInternalCacheByTerm = (state, searchTerm) => {
  const cache = selectSearchInternalCache(state)

  return getResultsFromCache(searchTerm, cache)
}
