import {
  isEmpty,
  isUndefined,
  toTitleCase,
} from '../external/utilities/GeneralUtils'

export const isFaciliy = function (facility) {
  if (!isUndefined(facility) && !isEmpty(facility)) {
    return true
  }

  return false
}

export const getStateCodeFromFacility = function (facility) {
  let facilityStateCode = null
  if (
    !isUndefined(facility) &&
    !isEmpty(facility) &&
    !isEmpty(facility.stateCodes)
  ) {
    ;[facilityStateCode] = facility.stateCodes
  }

  return facilityStateCode
}

export const getFacilityFullStateName = function (brandConfig, facility) {
  const facilityStateCode = getStateCodeFromFacility(facility)
  let fullStateName = facilityStateCode
  if (
    !isUndefined(brandConfig) &&
    !isEmpty(brandConfig) &&
    !isEmpty(brandConfig.states)
  ) {
    for (const state of brandConfig.states) {
      if (state.stateCode === facilityStateCode) {
        fullStateName = state.stateName
        break
      }
    }
  }

  return fullStateName
}

export const getFacilityCountryName = function (facility) {
  if (
    isFaciliy(facility) &&
    !isUndefined(facility.contactAddress) &&
    !isEmpty(facility.contactAddress) &&
    !isUndefined(facility.contactAddress.address)
  ) {
    return facility.contactAddress.address.country
  }

  return null
}

export const getFacilityLatitude = function (facility) {
  if (
    isFaciliy(facility) &&
    !isUndefined(facility.coordinates) &&
    !isEmpty(facility.coordinates) &&
    !isUndefined(facility.coordinates.latitude)
  ) {
    return facility.coordinates.latitude
  }

  return 0
}

export const getFacilityLongitude = function (facility) {
  if (
    isFaciliy(facility) &&
    !isUndefined(facility.coordinates) &&
    !isEmpty(facility.coordinates) &&
    !isUndefined(facility.coordinates.longitude)
  ) {
    return facility.coordinates.longitude
  }

  return 0
}

export const getFacilityLocationName = function (facility) {
  if (
    isFaciliy(facility) &&
    !isUndefined(facility.name) &&
    !isEmpty(facility.name) &&
    !isUndefined(facility.name)
  ) {
    return facility.name
  }

  return null
}

export const hasCamping = function (facility) {
  let camping = false
  if (isFaciliy(facility) && !isEmpty(facility.details.facilityTypes)) {
    for (const facilityType of facility.details.facilityTypes) {
      if (facilityType.toLowerCase() === 'camping') {
        camping = true
        break
      }
    }
  }

  return camping
}

export const getFacilitySummaryFromFacility = function (facility) {
  const facilitySummary = {}
  if (!isUndefined(facility) && !isEmpty(facility)) {
    facilitySummary.uniqueId = facility.contrCode + facility.id.toString()
    facilitySummary.name = facility.name
    facilitySummary.id = facility.id
    facilitySummary.contrCode = facility.contrCode
    facilitySummary.thumbnailImageURL = facility.thumbnailImageURL
    if (!isEmpty(facility.stateCodes)) {
      facilitySummary.stateCodes = facility.stateCodes
    }
    if (!isEmpty(facility.affiliation)) {
      facilitySummary.affiliation = facility.affiliation
    }
  }

  return facilitySummary
}

export const getMixpanelPropsForFacilityLoad = function (
  facility,
  contractConfig,
  viewName,
) {
  const props = {}

  if (!isUndefined(facility) && !isEmpty(facility)) {
    props.contractCode = facility.contrCode
    props.contractType = contractConfig.contractType
    props.agencyName =
      !isEmpty(facility.affiliation) && !isEmpty(facility.affiliation.agencies)
        ? facility.affiliation.agencies[0].name
        : ''
    props.viewName = viewName
  }

  return props
}

export const getMixpanelPropsForNearbyParkOnclick = function (
  nearbyFacility,
  currentFacility,
) {
  const props = {}

  props.currentPark = currentFacility
  props.nearbyPark = toTitleCase(nearbyFacility)

  return props
}

export const getMixpanelPropsForFacilityExtGetDetailsOnClick = function (
  facility,
) {
  const props = {}

  if (!isUndefined(facility) && !isEmpty(facility)) {
    props.contractCode = facility.contrCode
    props.agencyName =
      !isEmpty(facility.affiliation) && !isEmpty(facility.affiliation.agencies)
        ? facility.affiliation.agencies[0].name
        : ''
    props.name = facility.name
  }

  return props
}
