import PropTypes from 'prop-types'
import React from 'react'

/** Container class for sub-menus.  Children can be anything but should be SubMenuItems or list items */

const SubMenu = ({
  className = '',
  textAlign = 'left',
  children,
  hasBorder,
  hasBoxShadow,
  isOverlay = true,
  fitToContent = false,
}) => {
  let localClassName = `sub-menu-item__options sub-menu-item__options--${textAlign}`
  localClassName += hasBorder ? ' sub-menu-item__options--border' : ''
  localClassName += hasBoxShadow ? ' sub-menu-item__options--box-shadow' : ''
  localClassName += isOverlay ? '' : ' sub-menu-item__options--no-overlay'
  localClassName += fitToContent
    ? ' sub-menu-item__options--fit-to-content'
    : ''
  if (className !== '') {
    localClassName += ` ${className}`
  }

  return <ul className={localClassName}>{children}</ul>
}

SubMenu.propTypes = {
  /** wrapper classname for custom styling */
  className: PropTypes.string,
  /** left align or right align sub-menu labels/descriptions */
  textAlign: PropTypes.oneOf(['left', 'right']),
  /** submenu elements */
  children: PropTypes.node,
  /** whether or not submenu has a border */
  hasBorder: PropTypes.bool,
  /** whether or not submenu has a drop shadow */
  hasBoxShadow: PropTypes.bool,
  /** whether or not submenu is an overlay or not */
  isOverlay: PropTypes.bool,
  /** if true, menu is as wide as its widest child, otherwise menu is as wide as its container */
  fitToContent: PropTypes.bool,
}

export default SubMenu
