import { bool, func, object, string } from 'prop-types'
import React from 'react'

import Cont from '../../../external/components/layout/Cont'
import ABaseLink from '../../../external/components/link/ABaseLink'
import TextHoverable from '../../../external/components/link/TextHoverable'
import { isEmpty, isUndefined } from '../../../external/utilities/GeneralUtils'
import { ROLES } from '../../../external/utilities/accessibility/Definitions'

/**
 * We are creating a fake image of the background image so that we can make it accessible and to be read by the screen reader.
 * We tried putting the image description in the div via aria-label where we display the background image but the screen reader is not reading its
 * unless we put it together role="img".
 * When the role=img is added, the screen reader will not read the contents of the div.
 * The screen reader is announcing it as clickable all the time, which is incorrect.
 * The only time it shoud be clickable is when the title and excerpt is blank.
 */

const MarketingSpot = ({
  getMarketingSpotSelectLinkObj,
  onMarketingSpotSelectRequest,
  marketingSpot,
  setNoOpener = true,
  setNoReferrer = true,
}) => {
  const getHackSRImage = function () {
    return (
      <Cont
        asSpan
        cmpSRonly
        cmpRole={ROLES.DOC_STRUCTURE.IMG}
        cmpLabel={marketingSpot.imageDescription}
      />
    )
  }

  const getSpot = function () {
    let image
    let titleBg = 'home-marketing-spot--opacity'
    if (!isEmpty(marketingSpot)) {
      image = marketingSpot.image
    }

    if (!isUndefined(marketingSpot.title) && !marketingSpot.title) {
      titleBg = ''
    }
    const imageStyle = { backgroundImage: `url(${image})` }

    if (!isUndefined(marketingSpot.imageOnly) && marketingSpot.imageOnly) {
      return (
        <Cont asSpan>
          {getHackSRImage()}
          <div
            className={'home-marketing-spot home-marketing-spot-image-only'}
            style={imageStyle}
          >
            <Cont className={titleBg} />
          </div>
        </Cont>
      )
    } else if (isEmpty(marketingSpot.title) && isEmpty(marketingSpot.excerpt)) {
      return (
        <ABaseLink
          {...getMarketingSpotSelectLinkObj(marketingSpot)}
          onClick={() => {
            onMarketingSpotSelectRequest(marketingSpot)
          }}
        >
          {getHackSRImage()}
          <div className={'home-marketing-spot'} style={imageStyle}>
            <Cont className={titleBg} />
          </div>
        </ABaseLink>
      )
    }

    return (
      <Cont asSpan>
        {getHackSRImage()}
        <div
          className={'home-marketing-spot'}
          style={imageStyle}
          onClick={() => {
            if (isEmpty(marketingSpot.excerpt)) {
              onMarketingSpotSelectRequest(marketingSpot)
            }
          }}
        >
          <Cont className={titleBg}>
            <Cont className={'home-marketing-spot__description'}>
              <TextHoverable
                stopClickEventPropogation
                fontSizeType={3}
                fontFamily={'Georgia'}
                fontColor={'white'}
                setNoOpener={setNoOpener}
                setNoReferrer={setNoReferrer}
                {...getMarketingSpotSelectLinkObj(marketingSpot)}
                onClick={() => {
                  onMarketingSpotSelectRequest(marketingSpot)
                }}
              >
                {marketingSpot.title}
              </TextHoverable>
            </Cont>
          </Cont>
        </div>
      </Cont>
    )
  }

  return getSpot()
}

MarketingSpot.propTypes = {
  theme: string.isRequired,
  intl: object.isRequired,
  getMarketingSpotSelectLinkObj: func.isRequired,
  onMarketingSpotSelectRequest: func.isRequired,
  marketingSpot: object.isRequired,
  setNoOpener: bool,
  setNoReferrer: bool,
}

export default MarketingSpot
