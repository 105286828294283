import { USE_BROWSER_HISTORY } from '../config/AppConfig'
import { SSR_PATH } from '../constants/Routes'
import { isEnvServer } from '../external/utilities/GeneralServerUtils'
import { isUndefined, merge } from '../external/utilities/GeneralUtils'

export const getInitialState = function (
  backendInitialState,
  applicationInitialState,
  initialState,
) {
  let backendEmbedState = {}
  let applicationEmbedState = {}
  if (!isUndefined(initialState) && !isUndefined(initialState.backend)) {
    backendEmbedState = initialState.backend
  }

  if (!isUndefined(initialState) && !isUndefined(initialState.application)) {
    applicationEmbedState = initialState.application
  }
  const backend = merge(backendInitialState, backendEmbedState)
  const application = merge(applicationInitialState, applicationEmbedState)
  const mergedInitialState = {
    backend,
    application,
  }

  return mergedInitialState
}

export const getEmbeddedInitialState = function (embdedStateId) {
  return JSON.parse(document.getElementById(embdedStateId).innerHTML)
}

export const isDataUpdateRequired = function (
  store,
  transition,
  numTransactions,
) {
  const { backend } = store.getState()
  if (
    transition.location.pathname === backend.launchURL &&
    backend.launchDataUpdateSkipCtr < numTransactions
  ) {
    return false
  }

  return true
}

export const getPathWithoutQuery = function (path) {
  const parts = path.split('?')

  return parts[0]
}

export const getQuery = function (path) {
  let query = ''
  const parts = path.split('?')

  if (parts.length > 1) {
    query = parts[1]
  }

  return query
}

export const getPath = function (path, usePrefix = true) {
  let configPath = path
  let prefix = SSR_PATH

  if (!usePrefix) {
    prefix = ''
  }

  if (USE_BROWSER_HISTORY) {
    configPath = prefix + path
  }

  // if (isEnvServer()) {
  //   // server - paths may contain the query string - remove query string
  //   configPath = SSR_PATH + getPathWithoutQuery(path)
  // }

  return configPath
}

export const getPathname = function (props, url) {
  if (isEnvServer()) {
    return getPathWithoutQuery(url)
  }

  return props.location.pathname
}

export const getQueryObjectFromURL = function (url) {
  const query = getQuery(url)

  let queryObject = {}
  try {
    queryObject = query
      ? JSON.parse(
          `{"${query.replace(/&/g, '","').replace(/[=]/g, '":"')}"}`,
          (key, value) => (key === '' ? value : decodeURIComponent(value)),
        )
      : {}
  } catch (e) {
    // Do notthing for now
  }

  // const queryObject = JSON.parse('{"' + decodeURI(query).replace(/"/g, '\\"').replace(/&/g, '","').replace(/\=/g, '":"') + '"}')

  return queryObject
}

export const getQueryObject = function (props, url) {
  if (isEnvServer()) {
    const query = getQuery(url)
    const queryObject = JSON.parse(
      `{"${decodeURI(query)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/[=]/g, '":"')}"}`,
    )

    return queryObject
  }

  return props.location.query
}

export const isPathMatch = function (path, configuredPath) {
  let match = false
  const pathWithoutQuery = getPathWithoutQuery(path)
  const pathParts = pathWithoutQuery.split('/').reverse()
  const configuredPathParts = configuredPath.split('/').reverse()

  if (pathParts.length >= configuredPathParts.length) {
    if (pathParts[0] === configuredPathParts[0]) {
      if (pathParts.length === 1) {
        match = true
      } else {
        let allPartsMatch = true
        for (let i = 0; i < configuredPathParts.length; i++) {
          const currConfiguredPart = configuredPathParts[i]
          if (currConfiguredPart.startsWith('*')) {
            break
          } else if (!currConfiguredPart.startsWith(':')) {
            if (pathParts[i] !== currConfiguredPart) {
              allPartsMatch = false
              break
            }
          }
        }
        if (allPartsMatch) {
          match = true
        }
      }
    }
  }

  return match
}

export const getParamsObject = function (path, configuredPath) {
  const params = {}
  const pathWithoutQuery = getPathWithoutQuery(path)
  const pathParts = pathWithoutQuery.split('/').reverse()
  const configuredPathParts = configuredPath.split('/').reverse()

  if (pathParts.length >= configuredPathParts.length) {
    if (pathParts[0] === configuredPathParts[0]) {
      if (pathParts.length === 1) {
        // linter fix
      } else {
        for (let i = 0; i < configuredPathParts.length; i++) {
          const currConfiguredPart = configuredPathParts[i]
          // console.log(`currConfiguredPart ${currConfiguredPart}`)
          if (currConfiguredPart.startsWith('*')) {
            params.splat = pathParts[i]
            break
          } else if (currConfiguredPart.startsWith(':')) {
            params[currConfiguredPart.substr(1)] = pathParts[i]
          }
        }
      }
    }
  }

  return params
}
