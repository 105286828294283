import PropTypes from 'prop-types'
import React from 'react'
import Row from 'react-bootstrap/Row'

/** Wrapper class for react-bootstrap row.  https://react-bootstrap.github.io/components.html#grid-props-row

@example ../../../../docs/external/layout/ARow.md */

const ARow = ({ bsPrefix, as, children }) => (
  <Row bsPrefix={bsPrefix} as={as}>
    {children}
  </Row>
)

ARow.propTypes = {
  bsPrefix: PropTypes.string,
  as: PropTypes.string,
  children: PropTypes.node,
}

export default ARow
