import React from 'react'
import { IndexRoute, Route } from 'react-router'

import App from './components/App'
import { AdvertiseContainer } from './components/advertise'
import { CartContainer } from './components/cart'
import { CartCheckoutContainer } from './components/cart/checkout'
import { CartConfirmationContainer } from './components/cart/confirmation'
import { CartItemContainer } from './components/cart/item'
import { CartViewContainer } from './components/cart/view'
import { ContactUsContainer } from './components/contactUs'
import ErrorContainer from './components/error/ErrorContainer'
import NotLoggedInContainer from './components/error/NotLoggedInContainer'
import { FacilityContainer } from './components/facility'
import { DaypassesContainer } from './components/facility/daypasses'
import { HourlySitesContainer } from './components/facility/hourlySites'
import { ProductNotificationContainer } from './components/facility/notifications'
import {
  CSROverviewContainer,
  OverviewContainer,
} from './components/facility/overview'
import { ProductBookingDetailsContainer } from './components/facility/productBookingDetails'
import { ProductBookingSupplementDetailsContainer } from './components/facility/productBookingDetails/supplementDetails'
import { SiteAndBookingDetailsContainer } from './components/facility/siteAndBookingDetails'
import { DaypassesAndBookingDetailsContainer } from './components/facility/siteAndBookingDetails/daypasses'
import { HourlySiteAndBookingDetailsContainer } from './components/facility/siteAndBookingDetails/hourlySites'
import { SitesContainer } from './components/facility/sites'
import { FavoritesContainer } from './components/favorites'
import HomeContainer from './components/home/HomeContainer'
import { InfoContainer } from './components/info'
import { ListCampgroundContainer } from './components/listCampground'
import { ListCampgroundHomeContainer } from './components/listCampground/home'
import { ListCampgroundSelfServeContainer } from './components/listCampground/selfServe'
import { MyAccountContainer } from './components/myAccount'
import { ApplicationsContainer } from './components/myAccount/applications'
import { CampClubProfileContainer } from './components/myAccount/campClubProfile'
import { DashboardContainer } from './components/myAccount/dashboard'
import { EquipmentContainer } from './components/myAccount/equipment'
import { EquipmentDetailsContainer } from './components/myAccount/equipment/details'
import { NotificationsContainer } from './components/myAccount/notifications'
import { PassesContainer } from './components/myAccount/passes'
import { PointsContainer } from './components/myAccount/points'
import { PointsDetailsContainer } from './components/myAccount/points/details'
import { PrintTicketsPermitsContainer } from './components/myAccount/printTicketsPermits'
import { ProfileContainer } from './components/myAccount/profile'
import { ReservationsContainer } from './components/myAccount/reservations'
import { ReservationCancelContainer } from './components/myAccount/reservations/cancel'
import { ReservationChangeContainer } from './components/myAccount/reservations/change'
import { ReservationChangeConfirmationContainer } from './components/myAccount/reservations/change/confirmation'
import { ReservationChangeSupplementDetailsContainer } from './components/myAccount/reservations/change/supplementDetails'
import { ReservationDetailsContainer } from './components/myAccount/reservations/details'
import { ReservationPaymentContainer } from './components/myAccount/reservations/payment'
import { ReservationPreCheckinContainer } from './components/myAccount/reservations/preCheckin'
import { RateReservationContainer } from './components/myAccount/reservations/rating'
import { VouchersContainer } from './components/myAccount/vouchers'
import NotFoundContainer from './components/notFound/NotFoundContainer'
import { OfferContainer } from './components/offer'
import { POSItemContainer } from './components/pos/item'
import { SearchContainer } from './components/search'
import { FormTestContainer } from './components/test/formTest'
import * as RouteNames from './constants/Routes'
import { getPath } from './utilities/ServerUtils'

const routePath = function (path) {
  // return '/' + RouteNames.LOB_CAMP + path
  return path
}

export const getRoutes = ({
  loadAppInitData,
  loadHomeInitData,
  loadFacilityInitData,
  changeFacilityInitData,
  setGenericRouteChangeActions,
  loadProductsDayPassesInitData,
  changeProductsDayPassesInitData,
  loadProductsHourlySitesInitData,
  changeProductsHourlySitesInitData,
  loadProductsInitData,
  changeProductsInitData,
  loadSearchResultsInitData,
  changeSearchResultsInitData,
  loadProductAndBookingInitData,
  changeProductAndBookingInitData,
  loadProductAndBookingInitDataSingleDay,
  changeProductAndBookingInitDataSingleDay,
  loadProductNotificationInitData,
  loadDashboardInitData,
  loadReservationsInitData,
  changeReservationsInitData,
  loadReservationDetailsInitData,
  changeReservationDetailsInitData,
  loadReservationCancelInitData,
  loadReservationPreCheckinInitData,
  loadReservationPaymentInitData,
  loadReservationChangeInitData,
  loadReservationChangeSupplementDetailsInitData,
  loadReservationChangeconfirmationInitData,
  loadNotificationsInitData,
  loadApplicationsInitData,
  loadPassesInitData,
  changePassesInitData,
  loadVouchersInitData,
  changeVouchersInitData,
  loadPointsInitData,
  loadPointsDetailsInitData,
  changePointsDetailsInitData,
  loadEquipmentInitData,
  loadEquipmentDetailsInitData,
  loadPrintTicketsPermitsInitData,
  loadProfileInitData,
  loadCampClubProfileInitData,
  loadRateReservationInitData,
  loadCartData,
  loadProductBookingDetailsInitData,
  loadProductBookingSupplementDetailsInitData,
  loadCartViewInitData,
  loadCartCheckoutInitData,
  loadCartConfirmationInitData,
  loadOfferInitData,
  loadPOSItemInitData,
  loadFavoritesInitData,
  changeFavoritesInitData,
  loadContactUsInitData,
  loadAdvertiseInitData,
  loadInfoInitData,
  loadListCampgroundData,
  loadFormTestData,
  loadErrorInitData,
  loadNotLoggedInInitData,
  loadNotFoundInitData,
} = {}) => {
  return (
    <Route path={getPath('')} onEnter={loadAppInitData} component={App}>
      {'/* Home */'}
      <IndexRoute component={HomeContainer} onEnter={loadHomeInitData} />
      <Route
        name={RouteNames.WELCOME}
        path={getPath(RouteNames.ROUTE_WELCOME)}
        onEnter={loadHomeInitData}
        component={HomeContainer}
      />

      <Route
        name={'RouteNames.WELCOME'}
        path={getPath(RouteNames.ROUTE_ROOT, false)}
        onEnter={loadHomeInitData}
        component={HomeContainer}
      />

      {'/* Facility */'}
      <Route
        component={FacilityContainer}
        path={getPath(RouteNames.ROUTE_FACILITY)}
        onEnter={loadFacilityInitData}
        onChange={changeFacilityInitData}
      >
        <Route
          key={RouteNames.CHUBCART}
          name={RouteNames.CHUBCART}
          path={getPath(routePath(RouteNames.ROUTE_OVERVIEW_CHECKOUT))}
          component={CSROverviewContainer}
          onEnter={setGenericRouteChangeActions}
        />
        <Route
          key={RouteNames.CHUBCART}
          name={RouteNames.CHUBCART_CONFIRMATION}
          path={getPath(routePath(RouteNames.ROUTE_OVERVIEW_CONFIRMATION))}
          component={CSROverviewContainer}
          onEnter={setGenericRouteChangeActions}
        />
        <Route
          name={RouteNames.OVERVIEW}
          path={getPath(routePath(RouteNames.ROUTE_OVERVIEW))}
          component={OverviewContainer}
          onEnter={setGenericRouteChangeActions}
          exact
        />

        {'/* Day Passes */'}
        <Route
          name={RouteNames.DAY_PASSES}
          path={getPath(RouteNames.ROUTE_DAY_PASSES)}
          component={DaypassesContainer}
          onEnter={loadProductsDayPassesInitData}
          onChange={changeProductsDayPassesInitData}
        />

        {'/* Hourly Sites */'}
        <Route
          onEnter={loadProductsHourlySitesInitData}
          onChange={changeProductsHourlySitesInitData}
        >
          <Route
            name={RouteNames.HOURLY_SITE_LIST}
            path={getPath(routePath(RouteNames.ROUTE_HOURLY_SITE_LIST))}
            component={HourlySitesContainer}
            onEnter={setGenericRouteChangeActions}
          />
          <Route
            name={RouteNames.HOURLY_SITES_CALENDAR}
            path={getPath(routePath(RouteNames.ROUTE_HOURLY_SITES_CALENDAR))}
            component={HourlySitesContainer}
            onEnter={setGenericRouteChangeActions}
          />
        </Route>

        {'/* Camping */'}
        <Route onEnter={loadProductsInitData} onChange={changeProductsInitData}>
          <Route
            name={RouteNames.SITE_LIST}
            path={getPath(routePath(RouteNames.ROUTE_SITE_LIST))}
            component={SitesContainer}
            onEnter={setGenericRouteChangeActions}
          />
          <Route
            name={RouteNames.SITES_CALENDAR}
            path={getPath(routePath(RouteNames.ROUTE_SITES_CALENDAR))}
            component={SitesContainer}
            onEnter={setGenericRouteChangeActions}
          />
        </Route>
      </Route>

      {'/* Search */'}
      <Route
        name={RouteNames.SEARCH_RESULTS}
        path={getPath(RouteNames.ROUTE_SEARCH_RESULTS)}
        component={SearchContainer}
        onEnter={loadSearchResultsInitData}
        onChange={changeSearchResultsInitData}
      />

      {'/* Site And Booking Details */'}
      <Route
        name={RouteNames.SITE_AND_BOOKING_DETAILS}
        path={getPath(routePath(RouteNames.ROUTE_SITE_AND_BOOKING_DETAILS))}
        component={SiteAndBookingDetailsContainer}
        onEnter={loadProductAndBookingInitData}
        onChange={changeProductAndBookingInitData}
      />

      {'/* Day Passes And Booking Details */'}
      <Route
        name={RouteNames.DAY_PASSES_AND_BOOKING_DETAILS}
        path={getPath(
          routePath(RouteNames.ROUTE_DAY_PASSES_AND_BOOKING_DETAILS),
        )}
        component={DaypassesAndBookingDetailsContainer}
        onEnter={loadProductAndBookingInitData}
        onChange={changeProductAndBookingInitData}
      />

      {'/* Hourly Site And Booking Details */'}
      <Route
        name={RouteNames.HOURLY_SITE_AND_BOOKING_DETAILS}
        path={getPath(
          routePath(RouteNames.ROUTE_HOURLY_SITE_AND_BOOKING_DETAILS),
        )}
        component={HourlySiteAndBookingDetailsContainer}
        onEnter={loadProductAndBookingInitDataSingleDay}
        onChange={changeProductAndBookingInitDataSingleDay}
      />

      {'/* Notifications */'}
      <Route
        name={RouteNames.PRODUCT_NOTIFICATION}
        path={getPath(routePath(RouteNames.ROUTE_PRODUCT_NOTIFICATION))}
        component={ProductNotificationContainer}
        onEnter={loadProductNotificationInitData}
      />

      {'/* My Account */'}
      <Route component={MyAccountContainer}>
        <Route
          name={RouteNames.DASHBOARD}
          path={getPath(RouteNames.ROUTE_DASHBOARD)}
          component={DashboardContainer}
          onEnter={loadDashboardInitData}
        />
        <Route
          name={RouteNames.RESERVATIONS}
          path={getPath(RouteNames.ROUTE_RESERVATIONS)}
          component={ReservationsContainer}
          onEnter={loadReservationsInitData}
          onChange={changeReservationsInitData}
        />
        <Route
          name={RouteNames.RESERVATION_DETAILS}
          path={getPath(RouteNames.ROUTE_RESERVATION_DETAILS)}
          component={ReservationDetailsContainer}
          onEnter={loadReservationDetailsInitData}
          onChange={changeReservationDetailsInitData}
        />
        <Route
          name={RouteNames.RESERVATION_CANCEL}
          path={getPath(RouteNames.ROUTE_RESERVATION_CANCEL)}
          component={ReservationCancelContainer}
          onEnter={loadReservationCancelInitData}
        />
        <Route
          name={RouteNames.RESERVATION_PRE_CHECKIN}
          path={getPath(RouteNames.ROUTE_RESERVATION_PRE_CHECKIN)}
          component={ReservationPreCheckinContainer}
          onEnter={loadReservationPreCheckinInitData}
        />
        <Route
          name={RouteNames.RESERVATION_PAYMENT}
          path={getPath(RouteNames.ROUTE_RESERVATION_PAYMENT)}
          component={ReservationPaymentContainer}
          onEnter={loadReservationPaymentInitData}
        />
        <Route
          name={RouteNames.RESERVATION_CHANGE}
          path={getPath(RouteNames.ROUTE_RESERVATION_CHANGE)}
          component={ReservationChangeContainer}
          onEnter={loadReservationChangeInitData}
        />
        <Route
          name={RouteNames.RESERVATION_CHANGE_SUPPLEMENT_DETAILS}
          path={getPath(RouteNames.ROUTE_RESERVATION_CHANGE_SUPPLEMENT_DETAILS)}
          component={ReservationChangeSupplementDetailsContainer}
          onEnter={loadReservationChangeSupplementDetailsInitData}
        />
        <Route
          name={RouteNames.RESERVATION_CHANGE_CONFIRMATION}
          path={getPath(RouteNames.ROUTE_RESERVATION_CHANGE_CONFIRMATION)}
          component={ReservationChangeConfirmationContainer}
          onEnter={loadReservationChangeconfirmationInitData}
        />
        <Route
          name={RouteNames.NOTIFICATIONS}
          path={getPath(RouteNames.ROUTE_NOTIFICATIONS)}
          component={NotificationsContainer}
          onEnter={loadNotificationsInitData}
        />
        <Route
          name={RouteNames.APPLICATIONS}
          path={getPath(RouteNames.ROUTE_APPLICATIONS)}
          component={ApplicationsContainer}
          onEnter={loadApplicationsInitData}
        />
        <Route
          name={RouteNames.PASSES}
          path={getPath(RouteNames.ROUTE_PASSES)}
          component={PassesContainer}
          onEnter={loadPassesInitData}
          onChange={changePassesInitData}
        />
        <Route
          name={RouteNames.VOUCHERS}
          path={getPath(RouteNames.ROUTE_VOUCHERS)}
          component={VouchersContainer}
          onEnter={loadVouchersInitData}
          onChange={changeVouchersInitData}
        />
        <Route
          name={RouteNames.POINTS}
          path={getPath(RouteNames.ROUTE_POINTS)}
          component={PointsContainer}
          onEnter={loadPointsInitData}
        />
        <Route
          name={RouteNames.POINTS_DETAILS}
          path={getPath(RouteNames.ROUTE_POINTS_DETAILS)}
          component={PointsDetailsContainer}
          onEnter={loadPointsDetailsInitData}
          onChange={changePointsDetailsInitData}
        />
        <Route
          name={RouteNames.EQUIPMENT}
          path={getPath(RouteNames.ROUTE_EQUIPMENT)}
          component={EquipmentContainer}
          onEnter={loadEquipmentInitData}
        />
        <Route
          name={RouteNames.EQUIPMENT_DETAILS}
          path={getPath(RouteNames.ROUTE_EQUIPMENT_DETAILS)}
          component={EquipmentDetailsContainer}
          onEnter={loadEquipmentDetailsInitData}
        />
        <Route
          name={RouteNames.PRINT_TICKETS_PERMITS}
          path={getPath(RouteNames.ROUTE_PRINT_TICKETS_PERMITS)}
          component={PrintTicketsPermitsContainer}
          onEnter={loadPrintTicketsPermitsInitData}
        />
        <Route
          name={RouteNames.PROFILE}
          path={getPath(RouteNames.ROUTE_PROFILE)}
          component={ProfileContainer}
          onEnter={loadProfileInitData}
        />
        <Route
          name={RouteNames.CAMP_CLUB_PROFILE}
          path={getPath(RouteNames.ROUTE_CAMP_CLUB_PROFILE)}
          component={CampClubProfileContainer}
          onEnter={loadCampClubProfileInitData}
        />
        <Route
          name={RouteNames.RATE_RESERVATION}
          path={getPath(RouteNames.ROUTE_RATE_RESERVATION)}
          component={RateReservationContainer}
          onEnter={loadRateReservationInitData}
        />
      </Route>

      {'/* Cart */'}
      <Route component={CartContainer} onEnter={loadCartData}>
        {'/* Product Booking Details */'}
        <Route
          name={RouteNames.PRODUCT_BOOKING_DETAILS}
          path={getPath(RouteNames.ROUTE_PRODUCT_BOOKING_DETAILS)}
          component={ProductBookingDetailsContainer}
          onEnter={loadProductBookingDetailsInitData}
        />
        <Route
          name={RouteNames.PRODUCT_BOOKING_SUPPLEMENT_DETAILS}
          path={getPath(RouteNames.ROUTE_PRODUCT_BOOKING_SUPPLEMENT_DETAILS)}
          component={ProductBookingSupplementDetailsContainer}
          onEnter={loadProductBookingSupplementDetailsInitData}
        />

        <Route
          name={RouteNames.CART_VIEW}
          path={getPath(RouteNames.ROUTE_CART_VIEW)}
          component={CartViewContainer}
          onEnter={loadCartViewInitData}
        />
        <Route
          name={RouteNames.CART_ITEM}
          path={getPath(RouteNames.ROUTE_CART_ITEM)}
          component={CartItemContainer}
        />
        <Route
          name={RouteNames.CART_CHECKOUT}
          path={getPath(RouteNames.ROUTE_CART_CHECKOUT)}
          component={CartCheckoutContainer}
          onEnter={loadCartCheckoutInitData}
        />
      </Route>

      <Route
        name={RouteNames.CART_CONFIRMATION}
        path={getPath(RouteNames.ROUTE_CART_CONFIRMATION)}
        component={CartConfirmationContainer}
        onEnter={loadCartConfirmationInitData}
      />

      {'/* Offer */'}
      <Route
        name={RouteNames.OFFER}
        path={getPath(RouteNames.ROUTE_OFFER)}
        component={OfferContainer}
        onEnter={loadOfferInitData}
      />

      {'/* POS Item */'}
      <Route
        name={RouteNames.POS_ITEM}
        path={getPath(RouteNames.ROUTE_POS_ITEM)}
        component={POSItemContainer}
        onEnter={loadPOSItemInitData}
      />

      {'/* Favorites */'}
      <Route
        name={RouteNames.FAVORITES}
        path={getPath(RouteNames.ROUTE_FAVORITES)}
        component={FavoritesContainer}
        onEnter={loadFavoritesInitData}
        onChange={changeFavoritesInitData}
      />

      {'/* Contact Us */'}
      <Route
        name={RouteNames.CONTACT_US}
        path={getPath(RouteNames.ROUTE_CONTACT_US)}
        component={ContactUsContainer}
        onEnter={loadContactUsInitData}
      />

      {'/* Advertise */'}
      <Route
        name={RouteNames.ADVERTISE}
        path={getPath(RouteNames.ROUTE_ADVERTISE)}
        component={AdvertiseContainer}
        onEnter={loadAdvertiseInitData}
      />

      {'/* Info */'}
      <Route
        name={RouteNames.INFO}
        path={getPath(routePath(RouteNames.ROUTE_INFO))}
        component={InfoContainer}
        onEnter={loadInfoInitData}
      />

      {'/* List Campground */'}
      <Route
        component={ListCampgroundContainer}
        onEnter={loadListCampgroundData}
      >
        <Route
          name={RouteNames.LIST_CAMPGROUND_HOME}
          path={getPath(RouteNames.ROUTE_LIST_CAMPGROUND_HOME)}
          component={ListCampgroundHomeContainer}
          onEnter={setGenericRouteChangeActions}
        />
        <Route
          name={RouteNames.LIST_CAMPGROUND_SELF_SERVE}
          path={getPath(RouteNames.ROUTE_LIST_CAMPGROUND_SELF_SERVE)}
          component={ListCampgroundSelfServeContainer}
          onEnter={setGenericRouteChangeActions}
        />
      </Route>

      <Route
        name={'form-test'}
        path={getPath('/form-test')}
        component={FormTestContainer}
        onEnter={loadFormTestData}
      />

      {'/* Other */'}

      <Route
        name={RouteNames.ERROR}
        path={getPath(RouteNames.ROUTE_ERROR)}
        component={ErrorContainer}
        onEnter={loadErrorInitData}
      />
      <Route
        name={RouteNames.NOT_LOGGED_IN}
        path={getPath(RouteNames.ROUTE_NOT_LOGGED_IN)}
        component={NotLoggedInContainer}
        onEnter={loadNotLoggedInInitData}
      />
      <Route
        path="*"
        component={NotFoundContainer}
        onEnter={loadNotFoundInitData}
      />
    </Route>
  )
}
