import { object, oneOf, string } from 'prop-types'
import React from 'react'

import {
  getFullAppPath,
  getFullLoginAndReturnPath,
  getMyAccountDashboardRoute,
} from '../../router/RouteManager'
import Translation from '../Translation'
import HackHTag from '../shared/text/HackHTag'

const ErrorGuidance = ({ intl, mode = 'error' }) => {
  const appFullPath = getFullAppPath()
  const loginWithReturnPath = getFullLoginAndReturnPath(
    getMyAccountDashboardRoute(),
  )
  const getApology = function () {
    let token = 'labels_errorpage_request_apologies'
    if (mode === 'notFound') {
      token = 'labels_notfoundpage_request_apologies'
    } else if (mode === 'notLoggedIn') {
      token = 'labels_notloggedinpage_request_apologies'
    }

    return (
      <p className="bold">
        <Translation intl={intl} mkey={token} />
      </p>
    )
  }

  const getReasons = function () {
    let reasonsUI = null

    if (mode === 'notFound') {
      reasonsUI = (
        <ul className="error-reasons">
          <li>
            <Translation
              intl={intl}
              mkey={'labels_errorpage_request_reason_3'}
            />
          </li>
          <li>
            <Translation
              intl={intl}
              mkey={'labels_errorpage_request_reason_4'}
            />
          </li>
        </ul>
      )
    } else if (mode === 'notLoggedIn') {
      reasonsUI = (
        <ul className="error-reasons">
          <li>
            <Translation
              intl={intl}
              mkey={'labels_errorpage_request_reason_2'}
            />
          </li>
          <li>
            <Translation
              intl={intl}
              mkey={'labels_errorpage_request_reason_3'}
            />
          </li>
        </ul>
      )
    } else {
      reasonsUI = (
        <ul className="error-reasons">
          <li>
            <Translation
              intl={intl}
              mkey={'labels_errorpage_request_reason_1'}
            />
          </li>
          <li>
            <Translation
              intl={intl}
              mkey={'labels_errorpage_request_reason_2'}
            />
          </li>
          <li>
            <Translation
              intl={intl}
              mkey={'labels_errorpage_request_reason_3'}
            />
          </li>
          <li>
            <Translation
              intl={intl}
              mkey={'labels_errorpage_request_reason_4'}
            />
          </li>
        </ul>
      )
    }

    return reasonsUI
  }

  const getActions = function () {
    if (mode === 'notLoggedIn') {
      return (
        <ul className="error-links">
          <li>
            <a href={loginWithReturnPath}>
              <Translation
                intl={intl}
                mkey={'labels_errorpage_request_action_3'}
              />
            </a>
          </li>
        </ul>
      )
    }

    return (
      <ul className="error-links">
        <li>
          <a href={appFullPath}>
            <Translation
              intl={intl}
              mkey={'labels_errorpage_request_action_2'}
            />
          </a>
        </li>
      </ul>
    )
  }

  return (
    <div className="error-guidance">
      <HackHTag h4>
        <Translation intl={intl} mkey={'labels_errorpage_request_problem'} />
      </HackHTag>
      {getApology()}
      {getActions()}
      {getReasons()}
      {/* {getActions()} */}
    </div>
  )
}

ErrorGuidance.propTypes = {
  theme: string.isRequired,
  intl: object.isRequired,
  mode: oneOf(['error', 'notFound', 'notLoggedIn']),
}

export default ErrorGuidance
