import React, { Component } from 'react'
import { connect } from 'react-redux'

import Cont from '../../external/components/layout/Cont'
import MetaData from '../../external/components/meta/MetaData'
import { getErrors } from '../../utilities/ErrorUtils'
import { getLiteralValuesForApp } from '../../utilities/MetaDataUtils'
import NotLoggedIn from './NotLoggedIn'

const PAGE_ID = 'PAGE_NOT_LOGGED_IN'

class NotLoggedInContainer extends Component {
  render() {
    const { theme, errors, intl, metaConfig, mainConfig } = this.props

    return (
      <Cont>
        <MetaData
          intlObj={intl}
          literalValues={getLiteralValuesForApp(mainConfig)}
          data={metaConfig}
          pageId={PAGE_ID}
        />
        <NotLoggedIn theme={theme} errors={errors} intl={intl} />
      </Cont>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    theme: state.application.theme,
    errors: getErrors(state.backend.error, state.application.error),
    intl: state.backend.intl,
    metaConfig: state.backend.config.meta,
    mainConfig: state.backend.config.main,
  }
}

export default connect(mapStateToProps)(NotLoggedInContainer)
