import axios from 'axios'

import { getAxiosConfig } from '../utilities/BackendUtils'

export async function searchMapBoxFavorites({ searchTerm, accessToken }) {
  const axiosConfig = getAxiosConfig(
    'get',
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchTerm}.json`,
    { country: 'US,CA', access_token: accessToken },
  )

  const {
    data: { features },
  } = await axios(axiosConfig)

  return features.map(
    ({
      place_name: description,
      geometry: {
        coordinates: [latitude, longitude],
      },
    }) => ({
      description,
      type: 'web',
      latitude,
      longitude,
    }),
  )
}
