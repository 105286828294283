import { func, number, object } from 'prop-types'
import React from 'react'

import { getCartTimerColorLevels } from '../../../utilities/ConfigUtils'
import { simpleTranslate } from '../../Translation'
import CountdownTimer from './CountdownTimer'

const CartCountdownTimer = ({
  intl,
  cartItemCount = 0,
  cartTimeToExpiry = null,
  mainConfig,
  onTickCallBack,
}) => {
  const formatTime = function (milliseconds) {
    const totalSeconds = Math.round(milliseconds / 1000)
    let seconds = parseInt(totalSeconds % 60, 10)
    let minutes = parseInt(totalSeconds / 60, 10) % 60

    let timerClass = ''
    const timerColorLevels = getCartTimerColorLevels(mainConfig)
    for (const i in timerColorLevels) {
      if (milliseconds < timerColorLevels[i].levelTime) {
        timerClass = timerColorLevels[i].levelClass
      }
    }

    seconds = seconds < 10 ? `0${seconds}` : seconds
    minutes = minutes < 10 ? `0${minutes}` : minutes
    if (milliseconds > 0) {
      return (
        <span className={`header-cart-summary__timer ${timerClass}`}>
          {minutes}:{seconds}
          <span
            className={'header-cart-summary__description'}
          >{` ${simpleTranslate(
            intl,
            'labels_navigation_cart_timer_desc',
          )}`}</span>
        </span>
      )
    }

    return (
      <span className={`header-cart-summary__timer ${timerClass}`}>
        <span className={'header-cart-summary__timer--hide'}>00:00</span>
        <span className={'header-cart-summary__description'}>
          {simpleTranslate(intl, 'label_cart_hold_expired')}
        </span>
      </span>
    )
  }

  const tickCallback = function (milliseconds) {
    if (onTickCallBack !== undefined) {
      onTickCallBack(milliseconds)
    }
  }

  const getContent = function () {
    if (cartItemCount > 0) {
      return (
        <CountdownTimer
          initialTimeRemaining={cartTimeToExpiry.timeToExpiry}
          formatFunc={formatTime}
          txnTimeStamp={cartTimeToExpiry.txnTimeStamp}
          tickCallback={tickCallback}
        />
      )
    }

    return ''
  }

  return <div>{getContent()}</div>
}

CartCountdownTimer.propTypes = {
  intl: object.isRequired,
  cartItemCount: number.isRequired,
  cartTimeToExpiry: object.isRequired,
  mainConfig: object.isRequired,
  onTickCallBack: func,
}

export default CartCountdownTimer
